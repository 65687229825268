import React, { useState, useEffect, useContext, useRef } from 'react'
import { StoreContext } from "../../context/StoreContext"
import './Client.scss'
const Client = () => {
    const { state, actions } = useContext(StoreContext)
    const [opened, setOpened] = useState(false)
    const dropDownRef = useRef(null)


    useEffect(() => {
        // prevent overlay from knowing the mouse was clicked, if it was clicked inside the drop-down area.
        let copyOfRef = dropDownRef.current // linter said I should copy this to a variable so the cleanup function can be reliable.
        dropDownRef.current.addEventListener('mouseup', function (e) {
            e.stopPropagation();
        });

        return () => {
            if (copyOfRef) {
                copyOfRef.removeEventListener('mouseup', function (e) {
                    e.stopPropagation();
                });
            }
        }

    }, [])

    useEffect(() => {
        if (opened) {
            if (state.mouseClick.target.id === 'client-opener') {
                return
            }
            setOpened(false)
        }
    }, [state && state.mouseClick]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleOpen = () => {
        setOpened(!opened)
    }

    useEffect(() => {
        actions.overlay(opened)
    }, [opened]) // eslint-disable-line react-hooks/exhaustive-deps

    const openOption = evt => {
        let targetted = null
        if (evt.target && evt.target.id) {
            targetted = evt.target.id
        }

        if (targetted === 'displaySavedCounters') {
            setOpened(false)
            actions.installView('')
            actions.dataDisplay('saved counters')
            return
        }
        if (targetted === 'displaySavedSheets') {
            setOpened(false)
            actions.installView('')
            actions.dataDisplay('saved sheets')
            return
        }
        if (targetted === 'displayFonts') {
            setOpened(false)
            actions.installView('')
            actions.dataDisplay('fonts')
            return
        }
        if (targetted === 'installFont') {
            setOpened(false)
            actions.dataDisplay('')
            actions.installView('install font')
            return
        }
        if (targetted === 'installSvg') {
            setOpened(false)
            actions.dataDisplay('')
            actions.installView('install svg')
            return
        }
        if (targetted === 'installedSvgs') {
            setOpened(false)
            actions.installView('')
            actions.dataDisplay('installed svgs')
            return
        }
        if (targetted === 'installImage') {
            setOpened(false)
            actions.dataDisplay('')
            actions.installView('install image')
            return
        }
        if (targetted === 'installedImages') {
            setOpened(false)
            actions.installView('')
            actions.dataDisplay('installed images')
            return
        }
        if (targetted === 'backupMenu') {
            setOpened(false)
            actions.dataDisplay('')
            actions.installView('backup menu')
            return
        }
        if (targetted === 'restoreMenu') {
            setOpened(false)
            actions.dataDisplay('')
            actions.installView('restore menu')
            return
        }
        if (targetted === 'backupWorkspace') {
            setOpened(false)
            actions.dataDisplay('')
            actions.installView('backup workspace')
            return
        }
        if (targetted === 'restoreWorkspace') {
            setOpened(false)
            actions.dataDisplay('')
            actions.installView('restore workspace')
            return
        }

        actions.dataDisplay('')
        actions.installView('')
        setOpened(false)
    }

    useEffect(() => {
        // actions.installFont(false)
        // setOpened(false)
    }, [state.fonts])

    useEffect(() => {
        actions.dataDisplay(null)
        setOpened(false)
    }, [state.userLoadSlotsSheet]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (state.restoreDatabase) {
            actions.dataDisplay('')
            actions.installView('')
            setOpened(false)
        }
    }, [state.restoreDatabase]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={opened ? 'client opened' : 'client'}>
            <div id="client-opener" className="opener" onClick={handleOpen}>Client</div>
            <div ref={dropDownRef} className="drop-down">
                <div id="displaySavedCounters" onClick={openOption}><span>saved counters</span></div>
                <div id="displayCounterTemplates" onClick={openOption}><span>counter templates</span></div>
                <div id="displaySavedSheets" onClick={openOption}><span>saved sheets</span></div>
                <div id="displayFonts" onClick={openOption}><span>fonts</span></div>
                <div id="installFont" onClick={openOption}><span>install font</span></div>
                <div id="installSvg" onClick={openOption}><span>install svg</span></div>
                <div id="installedSvgs" onClick={openOption}><span>installed svgs</span></div>
                <div id="installImage" onClick={openOption}><span>install image</span></div>
                <div id="installedImages" onClick={openOption}><span>installed images</span></div>
                <div id="backupMenu" onClick={openOption}><span>backup menu</span></div>
                <div id="restoreMenu" onClick={openOption}><span>restore menu</span></div>
                <div id="backupWorkspace" onClick={openOption}><span>backup workspace</span></div>
                <div id="restoreWorkspace" onClick={openOption}><span>restore workspace</span></div>
            </div>
        </div>
    );
}
export default Client
