import React, { useContext } from 'react'
import { StoreContext } from "../../context/StoreContext"
import Client from '../Client/Client'
import Server from '../Server/Server'
import './TopMenu.scss'
const TopMenu = () => {
    const { state, actions } = useContext(StoreContext)
    const help = () => {
        actions.help(!state.help)
    }

    return (
        <div className="top-menu">
            <div className="drop-down-div"><Server /></div>
            <div className="drop-down-div"><Client /></div>
            <div className="app-name-container">
                <div className="app-name"> 
                    <span onClick={help}>Help</span>
                </div>
            </div>
        </div>
    );

}
export default TopMenu;