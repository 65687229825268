import React, { useContext, useRef, useState, useEffect } from "react";
import { StoreContext } from "../../context/StoreContext";
import './ErrorMessage.scss'

const ErrorMessage = () => {
    const { actions, state } = useContext(StoreContext);
    const errorMessageRef = useRef(null)
    const [activateOverlay, setActivateOverlay] = useState(false)

    useEffect(() => {
        // prevent overlay from knowing the mouse was clicked, if it was clicked inside the drop-down area.
        let copyOfRef = errorMessageRef.current // linter said I should copy this to a variable so the cleanup function can be reliable.
        errorMessageRef.current.addEventListener('mouseup', function (e) {
            e.stopPropagation();
        });

        return () => {
            if (copyOfRef) {
                copyOfRef.removeEventListener('mouseup', function (e) {
                    e.stopPropagation();
                });
            }
        }

    }, [])


    useEffect(() => {
        if (state.overlay === false) {
            actions.clearErrorMessages()
        }
    }, [state.overlay]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (errorMessageRef.current) {
            if (state.errorMessages.length > 0) {
                setActivateOverlay(true) // other parts of the app may be trying to deactivate the overlay.
                actions.overlay(true)
                //let yscroll = document.documentElement.scrollTop || document.body.scrollTop
                window.scrollTo(0, 0)
                let yscroll = document.body.scrollTop
                errorMessageRef.current.style["marginTop"] = yscroll + "px"
            }
            else {
                errorMessageRef.current.style["marginTop"] = "0px"
            }
        }
    }, [state.errorMessages])// eslint-disable-line react-hooks/exhaustive-deps

    const clear = () => {
        actions.clearErrorMessages()
        actions.overlay(false)
    }

    useEffect(() => {
        if (activateOverlay && state.errorMessages.length > 0) {
            setTimeout(() => {
                actions.overlay(true)
            }, 100)
        }
        else {
            setActivateOverlay(false)
        }
    }, [activateOverlay]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div ref={errorMessageRef} id="errorMessage" name="errorMessage" className={state.errorMessages.length > 0 ? 'error-message' : 'display-none'}>

            <div id="errorMessage2">ERROR</div>
            <div onClick={clear}>X</div>
            {state.errorMessages.map((errorMessage, index) => <div id={`errorMessage_${index}`} className="message" key={index}>{errorMessage}</div>)}

        </div>
    );

}
export default ErrorMessage;