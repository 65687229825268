import Utility from './Utility'
import Snap from 'snapsvg-cjs'

const MiscUnitSymbols = {

    draw: (g, settings, svgs) => {
        let xpos = 0
        let ypos = 0
        let opacity = ''
        let rotation = 0
        let svgKey = ''
        let derivedValues = {}

        settings.forEach(setting => {
            switch (setting.name) {
                case 'dimensions':
                    if (!setting.value || Array.isArray(setting.value) === false) {
                        derivedValues.width = 55
                        derivedValues.height = 55
                    }
                    else {
                        derivedValues.width = Utility.roundFloat(setting.value[0], 1)
                        derivedValues.height = Utility.roundFloat(setting.value[1], 1)
                    }
                    break
                case 'xy position':
                    if (Array.isArray(setting.value) === false || setting.value.length !== 2) {
                        setting.value = [1, 1]
                    }
                    if (isNaN(setting.value[0])) {
                        setting.value[0] = 1
                    }
                    if (isNaN(setting.value[1])) {
                        setting.value[1] = 1
                    }
                    derivedValues.xpos = Utility.roundFloat(setting.value[0], 1)
                    derivedValues.ypos = Utility.roundFloat(setting.value[1], 1)
                    break
                case 'color': derivedValues.lineColor = setting.value
                    break
                case 'line thickness': derivedValues.lineThickness = Utility.roundFloat(setting.value / 10, 2)
                    break
                case 'background color': derivedValues.backgroundColor = setting.value
                    break
                case 'svgKey': derivedValues.svgKey = parseInt(setting.value)
                    break
                case 'opacity': derivedValues.opacity = Utility.roundFloat(setting.value / 100, 3)
                    break
                case 'rotation': derivedValues.rotation = Utility.roundFloat(setting.value, 2)
                    break
                case 'symbol gap': derivedValues.symbolGap = Utility.roundFloat(setting.value, 2)
                    break
                default: console.warn('we seem to have missed one: ', setting.name)
            }
        })

        if (derivedValues.width === undefined || derivedValues.height === undefined) {
            derivedValues.width = 66
            derivedValues.height = 66
        }

        xpos = derivedValues.xpos
        ypos = derivedValues.ypos
        rotation = derivedValues.rotation
        svgKey = derivedValues.svgKey
        let svgObj = svgs.find(svg => svg.svgKey === svgKey)
        if (!svgObj) {
            console.warn('no svgObj aborting')
            return
        }
        let svgName = svgObj.svgName.replace('misc unit symbol ', '')
        opacity = derivedValues.opacity

        if (svgName === 'air assault') {
            let width = 60 * (derivedValues.width / 100)
            let height = 30 * (derivedValues.height / 100)

            let x1 = -width / 2
            let y1 = -height / 2
            let x2 = 0
            let y2 = height / 2
            let x3 = width / 2
            let y3 = -height / 2
            let d = `M ${x1} ${y1} L ${x2} ${y2} L ${x3} ${y3}`
            derivedValues.fill = 'none'
            MiscUnitSymbols.drawPath(g, d, derivedValues)
        }

        if (svgName === 'airborne') {
            let width = 100 * (derivedValues.width / 100)
            let height = 26 * (derivedValues.height / 100)

            let x1 = -width / 2
            let y1 = height / 2
            let x2 = 0
            let y2 = y1
            let rx1 = (x2 - x1) / 2
            let ry1 = height
            let x3 = width / 2
            let y3 = y1

            x1 = Utility.roundFloat(x1, 2)
            y1 = Utility.roundFloat(y1, 2)
            x2 = Utility.roundFloat(x2, 2)
            y2 = Utility.roundFloat(y2, 2)
            rx1 = Utility.roundFloat(rx1, 2)
            ry1 = Utility.roundFloat(ry1, 2)
            x3 = Utility.roundFloat(x3, 2)
            y3 = Utility.roundFloat(y3, 2)



            let d = `M ${x1} ${y1}
                     A ${rx1} ${ry1} 0 0 1 ${x2} ${y2}
                     M ${x2} ${y2}
                     A ${rx1} ${ry1} 0 0 1 ${x3} ${y3}`

            d = d.replace(/\s+/g, ' ').trim()

            derivedValues.fill = 'none'
            derivedValues.linejoin = 'round'
            MiscUnitSymbols.drawPath(g, d, derivedValues)
        }

        if (svgName === 'amphib') {
            let width = 100 * (derivedValues.width / 100)
            let height = 13 * (derivedValues.height / 50)

            // let x = 0
            // let y = -height / 2
            //g.rect(x, y, width, height).attr({ "stroke": "red", "fill": "none" })


            let points = []
            let maxDistance = width
            let yHeight = height / 2

            // center line dots
            let dots = 10
            let dotDistance = maxDistance / (dots - 1)
            for (let i = 0; i < dots; i++) {
                let x = dotDistance * i
                let y = 0
                points.push({
                    x, y
                })
                if (i < dots - 1) {
                    x += dotDistance / 2
                    y = i % 2 ? yHeight : -yHeight
                    points.push({
                        x, y
                    })
                }
            }

            let d = ''

            for (let i = 0; i < points.length - 2; i += 2) {
                let curveMul = 1
                if (i % 4) {
                    curveMul = 0
                }

                let start = points[i]
                let arc = points[i + 1]
                let end = points[i + 2]

                let x1 = start.x - (width / 2)
                let y1 = start.y
                let rx1 = (end.x - start.x) / 2
                let ry1 = arc.y / 2
                let x2 = end.x - (width / 2)
                let y2 = end.y

                    ;[x1, y1, rx1, ry1, x2, y2] = Utility.roundFloat([x1, y1, rx1, ry1, x2, y2], 2)

                d += `M ${x1} ${y1}
                     A ${rx1} ${ry1} 0 0 ${curveMul} ${x2} ${y2} `
            }

            d = d.replace(/\s+/g, ' ').trim()
            derivedValues.fill = 'none'
            derivedValues.linejoin = 'butt'
            MiscUnitSymbols.drawPath(g, d, derivedValues)
        }

        if (svgName === 'tracked') {
            let width = 165 * (derivedValues.width / 100)
            let height = 100 * (derivedValues.height / 100)
            let x1 = width * 0.08
            let y1 = height * -0.25
            let x2 = width * 0.08
            let y2 = height * 0.25

            let curve_x1 = x1 + (width * 0.28)
            let curve_y1 = y1 - (height * 0.01)
            let curve_x2 = x2 + (width * 0.28)
            let curve_y2 = y2 + (height * 0.01)

            x1 = Utility.roundFloat(x1, 2)
            y1 = Utility.roundFloat(y1, 2)
            x2 = Utility.roundFloat(x2, 2)
            y2 = Utility.roundFloat(y2, 2)
            curve_x1 = Utility.roundFloat(curve_x1, 2)
            curve_y1 = Utility.roundFloat(curve_y1, 2)
            curve_x2 = Utility.roundFloat(curve_x2, 2)
            curve_y2 = Utility.roundFloat(curve_y2, 2)

            let curvePathLeft = 'M ' + -x1 + ' ' + y1 + ' ' +
                'C ' + -curve_x1 + ' ' + curve_y1 + ' ' +
                -curve_x2 + ' ' + curve_y2 + ' ' +
                -x2 + ' ' + y2

            let curvePathCombined = curvePathLeft +
                'L ' + x2 + ' ' + y2 + ' ' +
                'C ' + curve_x2 + ' ' + curve_y2 + ' ' +
                curve_x1 + ' ' + curve_y1 + ' ' +
                x1 + ' ' + y1 + ' z'
            g.path(curvePathCombined).attr({ "stroke": derivedValues.lineColor, "strokeWidth": derivedValues.lineThickness, "fill": "none" })
        }

        if (svgName === 'wheels') {
            let width = derivedValues.width
            let r = derivedValues.lineThickness + 1
            let x1 = -width * 0.3
            let y1 = 0
            let x2 = width * 0.3
            let y2 = 0
            g.circle(x1, y1, r).attr({ stroke: "none", fill: derivedValues.lineColor })
            g.circle(x2, y2, r).attr({ stroke: "none", fill: derivedValues.lineColor })
        }

        if (svgName === 'spoked wheel') {
            // let r = derivedValues.lineThickness + 10
            let x1 = 0
            let y1 = 0
            let r = 50 - derivedValues.lineThickness / 2
            let wheelGroup = g.group()
            wheelGroup.circle(x1, y1, r).attr({
                stroke: derivedValues.lineColor, strokeWidth: derivedValues.lineThickness,
                fill: "none"
            })
            x1 = -r
            y1 = 0
            let x2 = r
            let y2 = 0
            let line = wheelGroup.line(Utility.roundFloat(x1, 2), Utility.roundFloat(y1), Utility.roundFloat(x2), Utility.roundFloat(y2)).attr({
                "stroke": derivedValues.lineColor,
                "strokeWidth": derivedValues.lineThickness,
                "strokeLinecap": "butt"
            })
            // let line2 = line.clone(true)
            // MiscUnitSymbols.applyRotation(line2, 30)
            // let line3 = line2.clone(true)
            // MiscUnitSymbols.applyRotation(line3, 30)
            // let line4 = line3.clone(true)
            // MiscUnitSymbols.applyRotation(line4, 30)
            let lineClones = []
            for (let i = 0; i < 5; i++) {
                lineClones.push(i === 0 ? line.clone(true) : lineClones[i - 1].clone(true))
                MiscUnitSymbols.applyRotation(lineClones[i], 30)
            }
            MiscUnitSymbols.applyScale(wheelGroup, derivedValues.width / 100, derivedValues.height / 100)
        }

        if (svgName === 'rotary wing') {
            let x1 = -derivedValues.width * 0.5
            let y1 = -derivedValues.height * 0.25
            let x2 = 0
            let y2 = 0
            let x3 = -derivedValues.width * 0.5
            let y3 = derivedValues.height * 0.25
            x1 = Utility.roundFloat(x1, 2)
            y1 = Utility.roundFloat(y1, 2)
            x3 = Utility.roundFloat(x3, 2)
            y3 = Utility.roundFloat(y3, 2)
            let d = `M ${x1} ${y1} ${x2} ${y2} ${x3} ${y3} z`

            g.path(d).attr({
                stroke: derivedValues.lineColor, strokeWidth: derivedValues.lineThickness, fill: derivedValues.lineColor,
                'stroke-linejoin': derivedValues.linejoin
            });

            x1 = -x1
            x2 = 0
            x3 = -x3
            d = `M ${x1} ${y1} ${x2} ${y2} ${x3} ${y3} z`
            g.path(d).attr({
                stroke: derivedValues.lineColor, strokeWidth: derivedValues.lineThickness, fill: derivedValues.lineColor,
                'stroke-linejoin': derivedValues.linejoin
            });
        }

        if (svgName === 'over-snow') {
            let x1 = -derivedValues.width * 0.5
            let y1 = -derivedValues.height * 0.17
            let cx1 = -derivedValues.width * 0.5
            let cy1 = derivedValues.height * 0.1
            let x2 = -derivedValues.width * 0.35
            let y2 = derivedValues.height * 0.134
            let x3 = derivedValues.width * 0.5
            let y3 = derivedValues.height * 0.134
            let d = `M ${x1} ${y1}
           C ${cx1} ${cy1}
           ${x2} ${y2}  ${x2} ${y2} L
           ${x3} ${y3}`
            d = Utility.roundFloatsInString(d, 2)
            g.path(d).attr({
                stroke: derivedValues.lineColor,
                strokeWidth: derivedValues.lineThickness,
                fill: "none"
            })
        }

        if (svgName === 'medical') {
            let x1 = -derivedValues.width * 0.5
            let y1 = -derivedValues.height * 0.15
            let w = derivedValues.width
            let h = derivedValues.height * 0.3
            let firstRect = g.rect(x1, y1, w, h).attr({
                stroke: derivedValues.lineColor,
                strokeWidth: derivedValues.lineThickness,
                fill: derivedValues.lineColor
            })
            let secondRect = firstRect.clone(true)

            MiscUnitSymbols.applyRotation(secondRect, 90)
        }

        if (svgName === 'drone') {
            let x1 = 0
            let y1 = derivedValues.height * 0.1
            let x2 = derivedValues.width * 0.5
            let y2 = -derivedValues.height * 0.09
            let x3 = derivedValues.width * 0.5
            let y3 = -derivedValues.height * 0.12
            let x4 = 0
            let y4 = -derivedValues.height * -0.01
            let x5 = -derivedValues.width * 0.5
            let y5 = -derivedValues.height * 0.12
            let x6 = -derivedValues.width * 0.5
            let y6 = -derivedValues.height * 0.09
            let d = `M ${x1} ${y1} L
            ${x2} ${y2}
            ${x3} ${y3}
            ${x4} ${y4} 
            ${x5} ${y5}
            ${x6} ${y6}
            z`
            d = Utility.roundFloatsInString(d, 2)
            g.path(d).attr({
                stroke: derivedValues.lineColor,
                strokeWidth: derivedValues.lineThickness >= 10 ? derivedValues.lineThickness - 10 : 0,
                fill: derivedValues.lineColor
            })
            //g.circle(x1,y1,5).attr({stroke: "none", fill: "red"})
        }

        if (svgName === 'repair') {
            let barGroup = g.group()
            let x1 = Utility.roundFloat(-derivedValues.width * 0.35, 2)
            let y1 = Utility.roundFloat(-derivedValues.height * 0.06 - derivedValues.lineThickness * 0.1, 2)
            let x2 = -x1
            let y2 = y1
            let x3 = x2
            let y3 = -y2
            let x4 = x1
            let y4 = y3
            let d = `M ${x1} ${y1} ${x2} ${y2} ${x3} ${y3} ${x4} ${y4} z`
            d = Utility.roundFloatsInString(d, 2)
            barGroup.path(d).attr({
                stroke: "none",
                fill: derivedValues.lineColor,
                'stroke-linejoin': derivedValues.linejoin
            });
            let cx1 = x1 - derivedValues.height * 0.1
            let cy1 = 0
            let r = y3 - y1
            barGroup.circle(cx1, cy1, r).attr({
                stroke: "none",
                fill: derivedValues.lineColor
            })
            let cx2 = -cx1
            let cy2 = 0
            barGroup.circle(cx2, cy2, r).attr({
                stroke: "none",
                fill: derivedValues.lineColor
            })
            let maskGroup = g.group()
            r = r * 0.75
            let a = 2 * Math.PI / 6;
            d = ''
            let mx = 0
            let my = 0
            let maskCoords = []
            cx1 -= r * 0.4
            for (var i = 0; i < 6; i++) {
                mx = cx1 + r * Math.cos(a * i)
                my = cy1 + r * Math.sin(a * i)
                mx = Utility.roundFloat(mx, 1)
                my = Utility.roundFloat(my, 1)
                maskCoords.push({ x: mx, y: my, o: i })
                if (d === '') {
                    d = `M ${mx} ${my} L`
                }
                else {
                    d += ` ${mx} ${my}`
                }
            }
            let maskCoordsR = []
            cx2 += r * 0.4
            let dr = ''
            for (i = 0; i < 6; i++) {
                mx = cx2 + r * Math.cos(a * i)
                my = cy2 + r * Math.sin(a * i)
                mx = Utility.roundFloat(mx, 1)
                my = Utility.roundFloat(my, 1)
                maskCoordsR.push({ x: mx, y: my, o: i })
                if (dr === '') {
                    dr = `M ${mx} ${my} L`
                }
                else {
                    dr += ` ${mx} ${my}`
                }
            }
            let maskPath = `M ${maskCoords[0].x} ${maskCoords[0].y} L
                              ${maskCoords[1].x} ${maskCoords[1].y}
                              ${maskCoords[2].x - derivedValues.width * 0.2} ${maskCoords[2].y}
                              ${maskCoords[2].x - derivedValues.width * 0.2} ${maskCoords[2].y + derivedValues.height * 0.3}
                              ${maskCoordsR[1].x + derivedValues.width * 0.2} ${maskCoordsR[1].y + derivedValues.height * 0.3}
                              ${maskCoordsR[1].x + derivedValues.width * 0.2} ${maskCoordsR[1].y}
                              ${maskCoordsR[2].x} ${maskCoordsR[2].y}
                              ${maskCoordsR[3].x} ${maskCoordsR[3].y}
                              ${maskCoordsR[4].x} ${maskCoordsR[4].y}
                              ${maskCoordsR[5].x + derivedValues.width * 0.2} ${maskCoordsR[4].y}
                              ${maskCoordsR[5].x + derivedValues.width * 0.2} ${maskCoordsR[4].y - derivedValues.height * 0.3}
                              ${maskCoords[2].x - derivedValues.width * 0.2} ${maskCoords[2].y * -1 - (derivedValues.height * 0.3)}
                              ${maskCoords[4].x - derivedValues.width * 0.2} ${maskCoords[4].y}
                              ${maskCoords[5].x} ${maskCoords[5].y} z`
            maskPath = Utility.roundFloatsInString(maskPath, 2)
            maskGroup.path(maskPath).attr({ stroke: "none", fill: "white" })
            barGroup.attr({ mask: maskGroup })
        }

        if (svgName === 'bridge') {
            let x1 = -derivedValues.width * 0.5
            let y1 = -derivedValues.height * 0.29
            let x2 = -derivedValues.width * 0.3
            let y2 = -derivedValues.height * 0.09
            let x3 = -x2
            let y3 = y2
            let x4 = -x1
            let y4 = y1
            let d = `M ${x1} ${y1} ${x2} ${y2} ${x3} ${y3} ${x4} ${y4}`
            d = Utility.roundFloatsInString(d, 2)
            g.path(d).attr({
                stroke: derivedValues.lineColor, strokeWidth: derivedValues.lineThickness, fill: "none",
                'stroke-linejoin': "bevel"
            });
            y1 = -y1
            y2 = -y2
            y3 = -y3
            y4 = -y4
            d = `M ${x1} ${y1} ${x2} ${y2} ${x3} ${y3} ${x4} ${y4}`
            g.path(d).attr({
                stroke: derivedValues.lineColor, strokeWidth: derivedValues.lineThickness, fill: "none",
                'stroke-linejoin': "bevel"
            });
        }

        if (svgName === 'fixed wing') {
            let x1 = -derivedValues.width * 0.3
            let y1 = -derivedValues.height * 0.15
            let x2 = 0
            let y2 = 0
            let x3 = -derivedValues.width * 0.3
            let y3 = derivedValues.height * 0.15

            let cx1 = -derivedValues.width * 0.6
            let cy1 = -derivedValues.height * 0.3
            let cx2 = -derivedValues.width * 0.6
            let cy2 = derivedValues.height * 0.3
            let d = `M ${x1} ${y1} L ${x2} ${y2}  ${x3} ${y3} C ${cx2} ${cy2} ${cx1} ${cy1}  ${x1} ${y1} z`
            d = Utility.roundFloatsInString(d, 2)
            g.path(d).attr({
                stroke: derivedValues.lineColor, strokeWidth: derivedValues.lineThickness,
                fill: derivedValues.lineColor
            });

            x1 = -x1
            x2 = 0
            x3 = -x3
            cx1 = -cx1
            cx2 = -cx2
            d = `M ${x1} ${y1} L ${x2} ${y2}  ${x3} ${y3} C ${cx2} ${cy2} ${cx1} ${cy1}  ${x1} ${y1} z`
            g.path(d).attr({
                stroke: derivedValues.lineColor, strokeWidth: derivedValues.lineThickness, fill: derivedValues.lineColor,
                'stroke-linejoin': derivedValues.linejoin
            });
        }



        if (svgName === 'anchor') {
            let d1 = "m 1.12 -61.08 c -6.1 0 -11.7 5.08 -11.2 11.2 c 0 6.1 5.08 10.68 11.2 10.68 c 6.1 0 11.2 -4.58 11.2 -10.68 c 0 -6.1 -5.08 -11.2 -11.2 -11.2 z m -0.5 5.6 c 3.06 0 5.6 2.54 5.6 5.08 c 0 3.06 -2.54 5.6 -5.6 5.6 c -3.06 0 -5.08 -2.54 -5.08 -5.6 c 0 -2.54 2.04 -5.08 5.08 -5.08 z"
            let d2 = "m -3.18 -41.14 c -0.54 0 0 1.06 0 4.62 v 4.62 l -11.14 -0.16 c -8.76 -0.12 -11.32 0 -11.96 0.52 c -1.04 0.86 -1.2 6.66 -0.18 6.68 l 22.78 -0.06 c 0.02 5.44 0.1 10.88 0.02 16.32 c -0.26 11.24 -0.46 22.48 -0.56 33.72 c -0.04 6.08 -0.28 11.92 -0.52 12.98 c -0.9 3.96 -3.38 4.5 -11.08 2.38 c -8 -2.2 -17.94 -7.08 -19.62 -9.62 c -1.34 -2.04 -1.54 -3.78 -0.58 -4.84 c 0.48 -0.52 1.2 -0.84 1.58 -0.68 c 0.44 0.16 0.88 -0.2 1.16 -0.92 c 0.96 -2.56 -2.26 -6.36 -8.58 -10.12 c -1.54 -0.92 -4.06 -2.88 -5.6 -4.36 c -1.62 -1.56 -3.04 -2.56 -3.36 -2.34 c -1.02 0.66 -3.12 5.38 -3.76 8.5 c -1.3 6.26 0.34 15.48 3.4 19.1 c 1.6 1.92 2.5 1.92 2.88 0.06 c 0.66 -3.3 4.78 -2.34 9.24 2.14 c 3.54 3.56 10.3 8.72 13.24 10.1 c 1.12 0.54 2.72 1.4 3.56 1.92 c 0.84 0.52 3.36 1.58 5.6 2.34 c 4.7 1.6 10.18 4.44 12.36 6.4 c 0.82 0.74 2.08 1.72 2.78 2.18 c 1.22 0.8 1.3 0.78 2.14 -0.48 c 1.72 -2.64 4.72 -4.96 9.24 -7.14 c 2.54 -1.24 5 -2.24 5.46 -2.24 c 1 0 9.36 -3.7 14.4 -6.38 c 3.96 -2.1 5.72 -3.36 10.14 -7.18 c 4.98 -4.32 7.5 -4.82 10.12 -1.96 l 1.64 1.78 l 1.04 -1.14 c 1.9 -2.08 2.6 -4.16 2.96 -8.84 c 0.28 -3.58 0.16 -5.52 -0.52 -8.64 c -1.08 -4.92 -2.92 -9.12 -4.28 -9.84 c -0.86 -0.46 -1.16 -0.32 -1.9 0.86 c -1.28 2.06 -3.08 3.7 -6.52 5.92 c -4.12 2.66 -6.62 5.42 -7.38 8.16 c -0.74 2.64 -0.4 3.6 1 2.86 c 1.54 -0.82 2.52 -0.6 3.12 0.7 c 0.84 1.82 -0.42 3.28 -5.24 6.16 c -8.24 4.9 -19.28 8.92 -24.54 8.92 c -2.48 0 -4.8 -0.96 -5.18 -2.14 c -0.18 -0.56 -0.18 -7.24 -0.02 -14.84 c 0.16 -7.6 0.44 -21.7 0.6 -31.36 c 0.16 -9.66 0.38 -17.68 0.48 -17.82 c 0.1 -0.16 5.34 -0.22 11.66 -0.16 c 13.12 0.12 12.42 0.36 12.32 -4.4 l -0.06 -2.8 c -7.82 -0.54 -15.84 0.48 -23.64 -0.58 c -0.14 -0.14 -0.3 -1.96 -0.34 -4.04 l -0.08 -4.66 z"
            let anchorGroup = g.group()
            anchorGroup.path(d1).attr({ stroke: "none", strokeWidth: 0, fill: derivedValues.lineColor })
            anchorGroup.path(d2).attr({ stroke: "none", strokeWidth: 0, fill: derivedValues.lineColor })
            MiscUnitSymbols.applyScale(anchorGroup, derivedValues.width / 120, derivedValues.height / 120)
        }

        if (opacity < 1) {
            g.attr({ "opacity": opacity })
        }

        if (rotation > 0) {
            MiscUnitSymbols.applyRotation(g, rotation)
        }
        if (xpos !== 0 || ypos !== 0) {
            MiscUnitSymbols.applyTranslation(g, xpos, ypos)
        }

    },

    drawPath: (g, d, derivedValues) => {
        g.path(d).attr({
            stroke: derivedValues.lineColor, strokeWidth: derivedValues.lineThickness, fill: derivedValues.fill,
            'stroke-linejoin': derivedValues.linejoin
        });
    },

    drawXs: (numberOfXs, g, derivedValues) => {
        let xSpread = 10 * derivedValues.width / 100
        let ySpread = 15 * derivedValues.height / 100
        let centerX = 0
        let centerY = 0
        let leftTopX = centerX - xSpread
        let leftTopY = centerY - ySpread
        let rightTopX = centerX + xSpread
        let rightTopY = leftTopY
        let rightBottomX = rightTopX
        let rightBottomY = centerY + ySpread
        let leftBottomX = leftTopX
        let leftBottomY = rightBottomY

        let widthOfX = rightTopX - leftTopX + derivedValues.lineThickness
        let distanceXtoX = widthOfX + (widthOfX * (derivedValues.symbolGap / 100))
        let widthWholeGraphic = (widthOfX + (widthOfX * (derivedValues.symbolGap / 100))) * (numberOfXs - 1) + widthOfX
        let centerIt = (widthWholeGraphic / 2) - (widthOfX / 2)
        for (let i = 0; i < numberOfXs; i++) {
            let newX1 = leftTopX + (i * distanceXtoX) - centerIt
            let newY1 = leftTopY
            let newX2 = rightBottomX + (i * distanceXtoX) - centerIt
            let newY2 = rightBottomY
            let newX3 = rightTopX + (i * distanceXtoX) - centerIt
            let newY3 = rightTopY
            let newX4 = leftBottomX + (i * distanceXtoX) - centerIt
            let newY4 = leftBottomY
            MiscUnitSymbols.drawLine(g, newX1, newY1, newX2, newY2, derivedValues)
            MiscUnitSymbols.drawLine(g, newX3, newY3, newX4, newY4, derivedValues)
        }
    },

    drawLine: (g, x1, y1, x2, y2, derivedValues) => {
        x1 = Utility.roundFloat(x1, 2)
        y1 = Utility.roundFloat(y1, 2)
        x2 = Utility.roundFloat(x2, 2)
        y2 = Utility.roundFloat(y2, 2)
        g.line(Utility.roundFloat(x1, 2), Utility.roundFloat(y1), Utility.roundFloat(x2), Utility.roundFloat(y2)).attr({
            "stroke": derivedValues.lineColor,
            "strokeWidth": derivedValues.lineThickness,
            "strokeLinecap": "round"
        })
    },

    drawBlankEllipse: (g, x, y, rx, ry, derivedValues) => {
        x = Utility.roundFloat(x, 2)
        y = Utility.roundFloat(y, 2)
        rx = Utility.roundFloat(rx, 2)
        ry = Utility.roundFloat(ry, 2)
        g.ellipse(Utility.roundFloat(x, 2), Utility.roundFloat(y, 2), Utility.roundFloat(rx, 2), Utility.roundFloat(ry, 2)).attr({
            "stroke": derivedValues.lineColor,
            "strokeWidth": derivedValues.lineThickness,
            "fill": "none"
        })
    },

    drawEllipse: (g, x, y, rx, ry, derivedValues) => {
        x = Utility.roundFloat(x, 2)
        y = Utility.roundFloat(y, 2)
        rx = Utility.roundFloat(rx, 2)
        ry = Utility.roundFloat(ry, 2)
        g.ellipse(Utility.roundFloat(x, 2), Utility.roundFloat(y, 2), Utility.roundFloat(rx, 2), Utility.roundFloat(ry, 2)).attr({
            "stroke": derivedValues.lineColor,
            "strokeWidth": derivedValues.lineThickness,
            "fill": "none"
        })
    },

    drawTriangle: (g, tx1, ty1, tx2, ty2, tx3, ty3, derivedValues) => {
        tx1 = Utility.roundFloat(tx1, 2)
        ty1 = Utility.roundFloat(ty1, 2)
        tx2 = Utility.roundFloat(tx2, 2)
        ty2 = Utility.roundFloat(ty2, 2)
        tx3 = Utility.roundFloat(tx3, 2)
        ty3 = Utility.roundFloat(ty3, 2)
        g.path(`M ${tx1} ${ty1} ${tx2} ${ty2} ${tx3} ${ty3} z`).attr({
            "stroke": "none",
            "strokeWidth": 0,
            "fill": derivedValues.lineColor,
        })
    },

    drawCircle: (g, x, y, r, derivedValues) => {
        x = Utility.roundFloat(x, 2)
        y = Utility.roundFloat(y, 2)
        r = Utility.roundFloat(r, 2)
        g.circle(x, y, r).attr({
            "stroke": "none",
            "fill": derivedValues.lineColor
        })
    },

    drawBlankCircle: (g, x, y, r, derivedValues) => {
        x = Utility.roundFloat(x, 2)
        y = Utility.roundFloat(y, 2)
        r = Utility.roundFloat(r, 2)
        g.circle(x, y, r).attr({
            "stroke": derivedValues.lineColor,
            "strokeWidth": derivedValues.lineThickness,
            "fill": "none"
        })
    },

    applyScale: (g, width, height) => {
        let currentMatrix = g.transform().localMatrix;
        let addMatrix = new Snap.Matrix()
        addMatrix.scale(width, height)
        addMatrix.add(currentMatrix)
        g.transform(addMatrix)
    },

    applyRotation: (g, rotation) => {
        let measureG = g.getBBox()
        let currentMatrix = g.transform().localMatrix;
        let addMatrix = new Snap.Matrix()
        addMatrix.rotate(rotation, measureG.cx, measureG.cy)
        addMatrix.add(currentMatrix)
        g.transform(addMatrix)
    },

    applyTranslation: (g, xpos, ypos) => {
        let currentMatrix = g.transform().localMatrix;
        let addMatrix = new Snap.Matrix()
        addMatrix.translate(xpos, ypos)
        addMatrix.add(currentMatrix)
        g.transform(addMatrix)
    },

}
export default MiscUnitSymbols