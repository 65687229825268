import React, { useState, useEffect, useContext, useRef } from 'react'
import { StoreContext } from "../../context/StoreContext"
import XButton from '../XButton/XButton'
import Utility from "../../objects/Utility"
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import './BackupMenu.scss'

const BackupMenu = () => {
    const { state, actions } = useContext(StoreContext)
    const [saveName, setSaveName] = useState('')
    const [saveResult, setSaveResult] = useState({ state: null, message: '' })
    const backupMenuRef = useRef(null)
    const nameInputRef = useRef(null)

    useEffect(() => {
        let copyOfRef = backupMenuRef.current // linter said I should copy this to a variable so the cleanup function can be reliable.
        backupMenuRef.current.addEventListener('mouseup', function (e) {
            e.stopPropagation();
        });

        return () => {
            if (copyOfRef) {
                copyOfRef.removeEventListener('mouseup', function (e) {
                    e.stopPropagation();
                });
            }
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps



    useEffect(() => {
        exitView()
    }, [state.mouseClick]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (state.installView === 'backup menu') {
            nameInputRef.current.focus()
        }
    }, [state.installView])

    const reset = () => {
        setSaveResult('')
        setSaveName('')
        setSaveResult({ state: null, message: '' })
        nameInputRef.current.value = ''
    }

    const closeAction = () => {
        exitView()
    }

    const exitView = () => {
        if (state.installView) {
            reset()
            window.scrollTo(0, 0)
        }
        actions.installView('')
    }

    const saveNameChange = () => {
        setSaveResult('')
        setSaveName(nameInputRef.current.value)
    }

    const packageMenu = () => {
        return {
            layers: state.layers,
            fonts: state.fonts,
            svgs: state.svgs
        }
    }

    const saveFile = () => {
        let packagedMenu = packageMenu()
        let fileObj = {
            fileName: saveName,
            app: 'snapCounter',
            type: 'menu',
            dateSaved: Utility.currentDate(),
            menu: packagedMenu
        }

        var zip = new JSZip();
        zip.file(saveName + ".json", JSON.stringify(fileObj));
        zip.generateAsync({ type: "blob", compression: "DEFLATE" })
            .then(function (content) {
                saveAs(content, saveName + '.zip');
                setTimeout(() => {
                    if (state.installView === 'backup menu') {
                        setSaveResult({ state: true, message: 'Backup "' + saveName + '" created.' })
                        setSaveName('')
                    }
                }, 2000)
            }).catch(function () {
                setSaveResult({ state: false, message: 'Could not save file.' })
            })

    }

    return (
        <div ref={backupMenuRef} className={state.installView === 'backup menu' ? 'backup-menu' : 'display-none'}>

          
                <div className="backup-menu-top">
                    <div className="title">Backup Menu</div>
                    <div className="close-button">
                        <XButton r="12" closeAction={closeAction} />
                    </div>
                </div>
                <div className="intro">
                    <p>
                        This will backup your menu data, needed to reconstruct your layers menu, to a file on your computer.
                    </p>
                </div>

                <div className="form-area">
                    <div>save name:</div>
                    <div><input ref={nameInputRef} type="text"
                        value={saveName} onChange={saveNameChange} /></div>
                    <div><button className={saveName ? 'action-button bluish' : 'action-button disabled'} onClick={saveName ? saveFile : null}>save</button></div>
                </div>
                {saveResult.message ? <div className={saveResult.state ? 'save-result' : 'save-result error'}>{saveResult.message}</div> : ''}
          

        </div>
    );
}
export default BackupMenu