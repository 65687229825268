import React, { useState, useEffect } from 'react'
import Snap from 'snapsvg-cjs'
import { A } from "hookrouter"
import Utility from "../../objects/Utility"
import { saveAs } from 'file-saver'
import './ModifySvg.scss'
const ModifySvg = () => {
    const [svgText, setSvgText] = useState('')
    const [svgRoundedText, setSvgRoundedText] = useState('')
    const [svgRoundedTextOnly, setSvgRoundedTextOnly] = useState('')
    const [finalSvg, setFinalSvg] = useState('')
    const [originalTextVisible, setOriginalTextVisible] = useState(true)
    const [finalWidth, setFinalWidth] = useState('')
    const [finalHeight, setFinalHeight] = useState('')
    const [originalFileName, setOriginalFileName] = useState('')
    const toggleOriginalTextVisible = () => {
        setOriginalTextVisible(!originalTextVisible)
    }

    const submitForm = () => {
        if (svgText) {
            if (svgText.includes('<svg')) {
                let _svgText = Utility.roundFloatsInFile(svgText)
                setSvgRoundedText(_svgText)
            }
            else {
                console.warn('not an svg file!')
            }
        }
    }

    const submitFormRounding = () => {
        console.log('submitFormRounding')
        if (svgText) {
            console.log('****************************************************** ok, we have svgText.', svgText.length)
            if (svgText.includes('<svg')) {
                let _svgText = Utility.roundFloatsInFile(svgText)
                console.log('*** setting setSvgRoundedTextOnly')
                setSvgRoundedTextOnly(_svgText)
            }
            else {
                console.warn('not an svg file!')
            }
        }
    }

    const showSelectedFile = () => {
        if (window.File && window.FileReader && window.FileList && window.Blob) {
            var svg_ele = document.getElementById('originalSvg')
            var file = document.querySelector('input[type=file]').files[0]
            var reader = new FileReader()
            let fileName = file.name
            setOriginalFileName(fileName)
            var textFile = /svg.*/;

            if (file.type.match(textFile)) {
                reader.onload = function (event) {
                    svg_ele.innerHTML = event.target.result;
                    let text = event.target.result
                    text = text.replace('<?xml version="1.0" encoding="UTF-8" standalone="no"?>', '')
                    text = text.replace('<!-- Created with Inkscape (http://www.inkscape.org/) -->', '')
                    text = text.replaceAll(',',' ')
                    setSvgText(text)
                }
            } else {
                svg_ele.innerHTML = "<span class='error'>It doesn't seem to be a text file!</span>";
            }
            reader.readAsText(file);
        }
    }

    const extractNativeViewBoxArray = str => {
        // find out if there is a viewBox coming in on this svg.
        let viewBoxString = ''
        let _viewBoxNativeArray = null
        let svgTag = extractTopSvgTag(str)
        if (svgTag) {
            let beginningViewboxStringIndex = svgTag.indexOf('viewBox="')
            if (beginningViewboxStringIndex > -1) {
                let endingViewboxStringIndex = svgTag.indexOf('"', beginningViewboxStringIndex + 1)
                if ((beginningViewboxStringIndex > -1 &&
                    endingViewboxStringIndex > -1) &&
                    (endingViewboxStringIndex > beginningViewboxStringIndex)) {
                    let viewboxEndIndex = svgTag.indexOf('"', beginningViewboxStringIndex + 10)
                    if (viewboxEndIndex > -1) {
                        viewBoxString = svgTag.substring(beginningViewboxStringIndex + 9, viewboxEndIndex)
                        viewBoxString = viewBoxString.replaceAll(',', ' ')
                        viewBoxString = viewBoxString.replace(/  +/g, ' ');
                        _viewBoxNativeArray = viewBoxString.split(' ').map(n => Number(n))
                        if (!_viewBoxNativeArray ||
                            !Array.isArray(_viewBoxNativeArray) ||
                            _viewBoxNativeArray.length !== 4) {
                            _viewBoxNativeArray = null
                        }
                    }
                }
            }
        }

        return _viewBoxNativeArray
    }

    const extractNativeWidthHeightStrings = str => {
        let nativeWidthString = null
        let nativeHeightString = null

        let svgTag = extractTopSvgTag(str)
        if (svgTag) {
            let splitted = Utility.strSplitOnNonEnclosedSpaces(svgTag)
            splitted.forEach(str => {
                if (str.startsWith('width=')) {
                    //nativeWidth = Utility.extractNumber(str)
                    nativeWidthString = str
                }
                if (str.startsWith('height=')) {
                    //nativeHeight = Utility.extractNumber(str)
                    nativeHeightString = str
                }
            })

        }
        if (nativeWidthString && nativeHeightString) {
            return { widthString: nativeWidthString, heightString: nativeHeightString }
        }
        return null
    }

    const extractNativeId = str => {
        let idResult = null
        let svgTag = extractTopSvgTag(str)
        if (svgTag) {
            let splitted = Utility.strSplitOnNonEnclosedSpaces(svgTag)
            for (let i = 0; i < splitted.length; i++) {
                let testStr = splitted[i]
                if (testStr.startsWith('id=') || testStr.startsWith('ID=')) {
                    let splittedId = testStr.split('=')
                    idResult = splittedId[1].replaceAll('"', '')
                    break
                }
            }
        }
        idResult = idResult.trim()
        return idResult
    }

    const extractTopSvgTag = str => {
        let startSvgTag = str.indexOf('<svg')
        if (startSvgTag > -1) {
            let endSvgTag = str.indexOf('>', startSvgTag + 1)
            if ((startSvgTag > -1 && endSvgTag > -1) && (endSvgTag > startSvgTag)) {
                return str.substring(startSvgTag, endSvgTag)
            }
        }
        return null
    }

    useEffect(() => {
        if (svgRoundedText) {
            processSvg(svgRoundedText)
        }
    }, [svgRoundedText])  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if( svgRoundedTextOnly ) {
            processSvgRounded(svgRoundedTextOnly)
        }
    }, [svgRoundedTextOnly])  // eslint-disable-line react-hooks/exhaustive-deps

    const processSvgRounded = (svgString) => {
        let nativeId = extractNativeId(svgString)
        // if there isn't an id on it, give it one.
        if (nativeId === null) {
            nativeId = 'r' + Utility.randomString(4)
            svgString = svgString.replace('<svg', '<svg id="' + nativeId + '" ')
        }


        let paper = Snap("#modifiedSvg")
        let parsed = Snap.parse(svgString)
        paper.append(parsed)
        let foundIds = Utility.distillIdsFromSnapElement(paper)
        // create a new unique string and stick it onto all the ids of the incoming svg
        let uniquePrepend = 'r' + Utility.randomString(8)
        uniquePrepend.replaceAll('_', '') // get rid of any underscores cause we need to use it as a known separator.
        uniquePrepend += '_'
        // change all the ids with their unique versions.
        svgString = Utility.replaceIdsInSvgString(foundIds, svgString, uniquePrepend)
        nativeId = uniquePrepend + nativeId
        // ok lets parse, clear the paper, and deploy the svg again
        parsed = Snap.parse(svgString)
        paper.clear()
        let se = document.getElementById('modifiedSvg')
        se.remove()
        paper = Snap("#modifiedSvg2")
        paper.append(parsed)

        let sele = paper.select('#' + nativeId)
        if( sele ) {
            let ele = sele.node
            var s = new XMLSerializer();
            let text = s.serializeToString(ele);
            setFinalSvg(text)
            setOriginalTextVisible(false)
        }
        else {
            console.log('could not locate', nativeId)
        }





    }

    const processSvg = (svgString) => {
        let paper = Snap("#modifiedSvg")
        let parsed = Snap.parse(svgString)
        paper.append(parsed)
        let foundIds = Utility.distillIdsFromSnapElement(paper)
        // create a new unique string and stick it onto all the ids of the incoming svg
        let uniquePrepend = 'r' + Utility.randomString(8)
        uniquePrepend.replaceAll('_', '') // get rid of any underscores cause we need to use it as a known separator.
        uniquePrepend += '_'
        // change all the ids with their unique versions.
        svgString = Utility.replaceIdsInSvgString(foundIds, svgString, uniquePrepend)


        // ok lets parse, clear the paper, and deploy the svg again
        parsed = Snap.parse(svgString)
        paper.clear()
        let se = document.getElementById('modifiedSvg')
        se.remove()
        paper = Snap("#modifiedSvg2")
        paper.append(parsed)



        let nativeViewBoxArray = null
        let nativeWidthHeightStrings = null
        let viewBoxString = null
        let nativeId = null
        if (svgString.includes('<svg')) {
            // if the svg has a viewBox setting, we need to know what it is.
            nativeViewBoxArray = extractNativeViewBoxArray(svgString)
            nativeWidthHeightStrings = extractNativeWidthHeightStrings(svgString)
            nativeId = extractNativeId(svgString)
            // if there isn't an id on it, give it one.
            if (nativeId === null) {
                nativeId = 'r' + Utility.randomString(4)
                svgString = svgString.replace('<svg', '<svg id="' + nativeId + '" ')
            }


            // remove the native width and height, since we are setting that at the
            // top (snap) svg tag.
            if (nativeWidthHeightStrings) {
                svgString = svgString.replace(nativeWidthHeightStrings.widthString, '')
                svgString = svgString.replace(nativeWidthHeightStrings.heightString, '')
            }
        }

        let paperWidth = 0
        let paperHeight = 0
        let viewBox_x = 0
        let viewBox_y = 0
        let nw = 0
        let nh = 0
        if (nativeViewBoxArray) {
            // figure out width and height needed
            nw = nativeViewBoxArray[2]
            nh = nativeViewBoxArray[3]
            viewBoxString = nativeViewBoxArray.join(' ')
        }
        else {
            let svgBbox = paper.getBBox({ fill: false, clipped: true })
            viewBox_x = svgBbox.x
            viewBox_y = svgBbox.y
            nw = svgBbox.width
            nh = svgBbox.height
            viewBoxString = viewBox_x + ' ' + viewBox_y + ' ' + Utility.roundFloat(nw, 1) + ' ' + Utility.roundFloat(nh, 1)
        }
        if (nw > nh) {
            paperWidth = 200
            paperHeight = (nh / nw) * 200
        }
        else {
            paperHeight = 200
            paperWidth = (nw / nh) * 200
        }
        paper.attr({ width: Utility.roundFloat(paperWidth, 1), height: Utility.roundFloat(paperHeight, 1) })
        setFinalWidth(Utility.roundFloat(paperWidth, 1))
        setFinalHeight(Utility.roundFloat(paperHeight, 1))
        var svgSource = paper.toString()
        let divEle = document.getElementById('final-svg-div')
        divEle.style.width = paperWidth + 'px'
        divEle.style.height = paperHeight + 'px'

        parsed = Snap.parse(svgSource)
        paper.clear()
        se = document.getElementById('modifiedSvg2')
        se.remove()
        paper = Snap("#modifiedSvg3")
        paper.append(parsed)


        //let ele = document.getElementById(nativeId)
        let sele = paper.select('#' + nativeId)
        if( sele ) {
            let ele = sele.node
            ele.setAttribute('viewBox', viewBoxString);
            ele.setAttribute('width', 200)
            ele.setAttribute('height', 77.6)
            var s = new XMLSerializer();
            let text = s.serializeToString(ele);
            setFinalSvg(text)
            setOriginalTextVisible(false)
        }
        else {
            console.log('could not locate', nativeId)
        }




        //let tstring = 't ' + adjustedX + ' ' + adjustedY
        //newGroup.transform(tstring)
    }

    const saveFinalSvg = () => {
        let blob = new Blob([finalSvg], { type: 'image/svg+xml' });
        saveAs(blob, originalFileName);
    }



    return (
        <div className="modify-svg">
            <div>
                <div>ModifySvg</div>
                <div><A href="/">back to app</A></div>
            </div>

            <div className="section-title">input</div>
            <div className="input-container">
                <div className="form-container">
                    <input type="file" onChange={showSelectedFile} />
                    <div className="actions">
                        <button onClick={submitForm}>submit</button>
                        <button onClick={submitFormRounding}>submit only for rounding</button>
                    </div>

                </div>

                <div className={svgText ? 'show-svg active' : 'show-svg'} id="originalSvg"></div>

            </div>


            <div className={svgText ? '' : 'display-none'}>
                <div className="section-title second">chosen svg text <span onClick={toggleOriginalTextVisible}> {originalTextVisible ? 'hide' : 'show'} </span></div>
                <div className={originalTextVisible ? 'text-display-container' : 'display-none'}>
                    <div className="text-display"><pre>{svgText}</pre></div>
                </div>
            </div>

            <div>
                <div className="section-title second">final svg</div>
                <div className="modified-display">
                    <div id="final-svg-div" className="show-modified-svg">
                        <svg id="modifiedSvg" />
                        <svg id="modifiedSvg2" />
                        <svg id="modifiedSvg3" />
                        <div className="action">width:<span>{finalWidth}</span> height:<span>{finalHeight}</span></div>
                        <div className="action"><button className="action-button cyanish" onClick={saveFinalSvg}>Save</button></div>
                    </div>
                    <div className={svgRoundedText ? 'text-modified-container' : 'display-none'}>
                        <div id="final-svg-div" className="text-modified">{finalSvg}</div>
                    </div>
                </div>
            </div>

        </div>
    );
}
export default ModifySvg
