import React, { useContext } from 'react'
import { StoreContext } from "../../context/StoreContext"
// import JSZip from 'jszip'
// import { saveAs } from 'file-saver'
// import Utility from "../../objects/Utility"
import './ClearCounter.scss'

const ClearCounter = () => {
    const { actions } = useContext(StoreContext)

    const clearCounter = () => {
        actions.counterClear(true)
    }

    return (
        <div className="clear-counter" id="clearCounter">
            <button className="action-button cyanish" onClick={clearCounter} >clear</button>
        </div>
    )
}
export default ClearCounter