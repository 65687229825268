import React from "react"
import Home from "../functions/Home/Home"
import ModifySvg from "../functions/ModifySvg/ModifySvg"

const routes = {
    '/': () => <Home />,
    '/modifysvg': () => <ModifySvg />
}
export default routes;


