import Utility from './Utility'
import Snap from 'snapsvg-cjs'

const NatoUnitSymbols = {

    draw: (g, settings, svgs) => {
        let width = 0
        let height = 0
        let xpos = 0
        let ypos = 0
        let lineThickness = 0
        let opacity = ''
        let rotation = 0
        let svgKey = ''
        let derivedValues = {}
        settings.forEach(setting => {
            switch (setting.name) {
                case 'dimensions':
                    if (!setting.value || Array.isArray(setting.value) === false) {
                        derivedValues.width = 55
                        derivedValues.height = 55
                    }
                    else {
                        derivedValues.width = Utility.roundFloat(setting.value[0], 1)
                        derivedValues.height = Utility.roundFloat(setting.value[1], 1)
                    }
                    break
                case 'round corners': derivedValues.roundCorners = Utility.roundFloat(setting.value, 2)
                    break
                case 'xy position':
                    if (Array.isArray(setting.value) === false || setting.value.length !== 2) {
                        setting.value = [1, 1]
                    }
                    if (isNaN(setting.value[0])) {
                        setting.value[0] = 1
                    }
                    if (isNaN(setting.value[1])) {
                        setting.value[1] = 1
                    }
                    derivedValues.xpos = Utility.roundFloat(setting.value[0], 1)
                    derivedValues.ypos = Utility.roundFloat(setting.value[1], 1)
                    break
                case 'line color': derivedValues.lineColor = setting.value
                    break
                case 'line thickness': derivedValues.lineThickness = Utility.roundFloat(setting.value, 2)
                    break
                case 'fill color':

                    if (setting.value.length > 9) {
                        derivedValues.fillColor = JSON.parse(setting.value)
                    }
                    else {
                        derivedValues.fillColor = { fillType: 'solid', fillColor: '#000000' }
                    }



                    break
                case 'svgKey': derivedValues.svgKey = parseInt(setting.value)
                    break
                case 'opacity': derivedValues.opacity = Utility.roundFloat(setting.value / 100, 3)
                    break
                case 'rotation': derivedValues.rotation = Utility.roundFloat(setting.value, 2)
                    break
                default: if (!setting.name.startsWith('spacer')) {
                    console.warn('we seem to have missed one: ', setting.name)
                }
            }
        })

        if (derivedValues.width === undefined || derivedValues.height === undefined) {
            derivedValues.width = 66
            derivedValues.height = 66
        }

        width = derivedValues.width
        height = derivedValues.height
        if (width === undefined) {
            width = 100
            height = 75
        }
        xpos = derivedValues.xpos
        ypos = derivedValues.ypos
        lineThickness = derivedValues.lineThickness
        rotation = derivedValues.rotation
        opacity = derivedValues.opacity
        svgKey = derivedValues.svgKey

        let svgObj = svgs.find(svg => svg.svgKey === svgKey)
        if (!svgObj) {
            console.warn('aborting, no svgObj found.')
            return
        }
        let svgName = svgObj.svgName
        if (svgName.includes('- ')) { // used to break the name into two lines in the menu.
            svgName = svgName.replace('- ', '')
        }
        let x = -width / 2
        let y = -height / 2

        ////////////////////////////////////////////////////////////////////////////////
        // default, goes with all
        if (!Utility.emptyCheck(derivedValues)) {
            NatoUnitSymbols.drawEmptyUnitSymbol(g, x, y, width, height, derivedValues)
        }
        else {
            return
        }

        if (svgName === 'nato symbol railroad artillery') {
            let x1 = -width * 0.4 + (derivedValues.lineThickness / 2)
            let y1 = -height * 0.1
            let x1a = -width * 0.25 + (derivedValues.lineThickness / 2)
            let y1a = -height * 0.1
            let x2 = -width * 0.15 + (derivedValues.lineThickness / 2)
            let y2 = height * 0.15

            let x3 = width * 0.15 - (derivedValues.lineThickness / 2)
            let y3 = height * 0.15

            let x4a = width * 0.25 - (derivedValues.lineThickness / 2)
            let y4a = -height * 0.1

            let x4 = width * 0.4 - (derivedValues.lineThickness / 2)
            let y4 = -height * 0.1

            let lineThickness = derivedValues.lineThickness
            if (lineThickness > 7) {
                lineThickness = 7
            }

            x1 = Utility.roundFloat(x1, 2)
            y1 = Utility.roundFloat(y1, 2)
            x1a = Utility.roundFloat(x1a, 2)
            y1a = Utility.roundFloat(y1a, 2)
            x2 = Utility.roundFloat(x2, 2)
            y2 = Utility.roundFloat(y2, 2)
            x3 = Utility.roundFloat(x3, 2)
            y3 = Utility.roundFloat(y3, 2)
            x4a = Utility.roundFloat(x4a, 2)
            y4a = Utility.roundFloat(y4a, 2)
            x4 = Utility.roundFloat(x4, 2)
            y4 = Utility.roundFloat(y4, 2)

            let d = `M ${x1} ${y1} L ${x1a} ${y1a} ${x2} ${y2} ${x3} ${y3} ${x4a} ${y4a} ${x4} ${y4}`
            g.path(d).attr({
                stroke: derivedValues.lineColor, strokeWidth: lineThickness,
                fill: "none", "strokeLinejoin": "square"
            })

            let c1x = 0
            let c1y = -height * 0.1
            let distance = Utility.distanceTwoPoints({ x: x3, y: y3 }, { x: c1x, y: c1y })
            let r1 = distance * 0.15 + (lineThickness * 0.2)
            r1 += height * 0.02
            c1x = Utility.roundFloat(c1x, 2)
            c1y = Utility.roundFloat(c1y, 2)
            r1 = Utility.roundFloat(r1, 2)
            g.circle(c1x, c1y, r1).attr({ stroke: "none", fill: derivedValues.lineColor })

            c1x = -width * 0.3 + (derivedValues.lineThickness / 2)
            c1y = height * 0.2
            g.circle(c1x, c1y, r1).attr({ stroke: "none", fill: derivedValues.lineColor })

            c1x = width * 0.3 - (derivedValues.lineThickness / 2)
            g.circle(c1x, c1y, r1).attr({ stroke: "none", fill: derivedValues.lineColor })
        }

        if (svgName === 'nato symbol coast artillery') {
            let x1 = 0
            let y1 = -height * 0.49 + (derivedValues.lineThickness * 1.1)
            let x2 = width * 0.49 - (derivedValues.lineThickness * 1.1)
            let y2 = 0
            let x3 = 0
            let y3 = height * 0.49 - (derivedValues.lineThickness * 1.1)
            let x4 = -width * 0.49 + (derivedValues.lineThickness * 1.1)
            let y4 = 0
            let d = `M ${x1} ${y1} ${x2} ${y2} ${x3} ${y3} ${x4} ${y4} z`
            g.path(d).attr({
                stroke: derivedValues.lineColor, strokeWidth: derivedValues.lineThickness,
                fill: "none", "strokeLinejoin": "square"
            })
        }

        if (svgName === 'nato symbol rocket') {
            let derivedCopy2 = { ...derivedValues }
            let lineWidth = derivedValues.lineThickness
            if (lineWidth > 8) {
                lineWidth = 8
                derivedCopy2.lineThickness = 8
            }
            if (lineWidth < 5) {
                lineWidth = 5
                derivedCopy2.lineThickness = 5
            }
            if (height < 50) {
                lineWidth = 5
                derivedCopy2.lineThickness = 5
            }


            //derivedCopy.lineThickness
            let x1 = -width * 0.18
            let y1 = 0
            let x2 = width * 0.1
            let y2 = 0
            NatoUnitSymbols.drawLine(g, x1, y1, x2, y2, derivedValues, 'square')

            x1 = 0
            y1 = 0
            x2 = width * 0.2
            y2 = 0
            NatoUnitSymbols.drawLine(g, x1, y1, x2, y2, derivedValues, 'round')

            let endX = width * 0.4
            let endY = y2

            x1 = width * 0.1
            y1 = -height * 0.2

            x2 = width * 0.1
            y2 = height * 0.2

            let d = `M 0 ${y1} Q ${endX} ${endY} , 0 ${y2}`
            g.path(d).attr({
                stroke: derivedValues.lineColor, strokeWidth: derivedValues.lineThickness,
                fill: "none", "strokeLinecap": "round"
            })

        }

        if (svgName === 'nato symbol ski') {
            let x1 = x + width * 0.2
            let y1 = y + height * 0.55
            let x2 = x + width * 0.35
            let y2 = y + height * 0.65
            let x3 = x + width * 0.84
            let y3 = y + height * 0.25
            let lineWidth = derivedValues.lineThickness
            if (lineWidth > 8) {
                lineWidth = 8
            }
            if (lineWidth < 5) {
                lineWidth = 5
            }
            if (height < 50) {
                lineWidth = 5
            }
            let d = `M ${x1} ${y1} L ${x2} ${y2} ${x3} ${y3}`
            g.path(d).attr({ stroke: derivedValues.lineColor, strokeWidth: lineWidth, fill: "none", strokeLinejoin: "miter" })
            x1 += -width * 0.04
            y1 += height * 0.2
            x2 += -width * 0.04
            y2 += height * 0.2
            x3 += -width * 0.04
            y3 += height * 0.2
            d = `M ${x1} ${y1} L ${x2} ${y2} ${x3} ${y3}`
            g.path(d).attr({ stroke: derivedValues.lineColor, strokeWidth: lineWidth, fill: "none", strokeLinejoin: "miter" })
        }

        if (svgName.includes('heavy')) {
            let x1 = x + width * 0.1 + (derivedValues.lineThickness * 0.5)
            let y1 = y + height * 0.01
            let x2 = x1
            let y2 = y + height * 0.99

            NatoUnitSymbols.drawLine(g, x1, y1, x2, y2, derivedValues, 'square')
        }

        if (svgName === 'nato symbol motorized infantry' ||
            svgName === 'nato symbol motorized engineer' ||
            svgName === 'nato symbol motorized anti-air') { // MOTORIZED
            let wx1 = (x + (width / 2)) - (width / 4)
            let wx2 = (x + (width / 2)) + (width / 4)
            let wy1 = y + height + (lineThickness / 2)
            let wy2 = y + height + (lineThickness / 2)
            let wr = width * 0.1
            if (wr > 7) {
                wr = 7
            }
            wy1 = (height / 2.1) + wr + (lineThickness / 2)
            wy2 = (height / 2.1) + wr + (lineThickness / 2)
            wx1 = Utility.roundFloat(wx1, 2)
            wy1 = Utility.roundFloat(wy1, 2)
            wx2 = Utility.roundFloat(wx2, 2)
            wy2 = Utility.roundFloat(wy2, 2)
            NatoUnitSymbols.drawCircle(g, wx1, wy1, wr, derivedValues)
            NatoUnitSymbols.drawCircle(g, wx2, wy2, wr, derivedValues)
        }

        if (svgName === 'nato symbol rangers') {
            let x1 = -width / 2
            let y1 = height / 2
            let x2 = width / 2
            let y2 = -height / 2
            let distance = width * 0.15
            let newXY = Utility.getPointOnLineDistance(x1, y1, x2, y2, distance)
            let newXY2 = Utility.getPointOnLineDistance(x2, y2, x1, y1, -distance)
            let redCircle = Utility.getPointOnLineDistance(x2, y2, x1, y1, -distance - (derivedValues.lineThickness * 2))
            let controlCircleRadius = 5 + derivedValues.lineThickness
            distance = width * 0.12
            let dimple = Utility.getPointOnLineDistance(x2, y2, x1, y1, -distance - (derivedValues.lineThickness * 1.5))
            let radiusFromMainLine = controlCircleRadius
            let angle2 = Utility.angle(redCircle.x, redCircle.y, 0, 0)
            let coords = Utility.polarToCartesian(redCircle.x, redCircle.y, radiusFromMainLine, angle2)
            let rightArrowPoint2x = coords.x
            let rightArrowPoint2y = coords.y
            coords = Utility.polarToCartesian(redCircle.x, redCircle.y, radiusFromMainLine, angle2 + 180)
            let rightArrowPoint3x = coords.x
            let rightArrowPoint3y = coords.y
            x1 = -width / 2
            y1 = height / 2
            x2 = width / 2
            y2 = -height / 2
            distance = derivedValues.lineThickness
            newXY = Utility.getPointOnLineDistance(x1, y1, x2, y2, distance)
            distance = derivedValues.lineThickness * 2
            if (width > 100) {
                distance += 10
            }
            newXY2 = Utility.getPointOnLineDistance(x2, y2, x1, y1, -distance)
            let distanceArrowPoint = derivedValues.lineThickness * 0.9
            let arrowPoint = Utility.getPointOnLineDistance(x2, y2, x1, y1, -distanceArrowPoint)
            NatoUnitSymbols.drawLine(g, Utility.roundFloat(newXY.x, 2), Utility.roundFloat(newXY.y, 2),
                Utility.roundFloat(newXY2.x, 2), Utility.roundFloat(newXY2.y, 2), derivedValues)
            // right side arrow
            distance = derivedValues.lineThickness / 2
            arrowPoint.x = Utility.roundFloat(arrowPoint.x, 2)
            arrowPoint.y = Utility.roundFloat(arrowPoint.y, 2)
            rightArrowPoint2x = Utility.roundFloat(rightArrowPoint2x, 2)
            rightArrowPoint2y = Utility.roundFloat(rightArrowPoint2y, 2)
            dimple.x = Utility.roundFloat(dimple.x, 2)
            rightArrowPoint3x = Utility.roundFloat(rightArrowPoint3x, 2)
            rightArrowPoint3y = Utility.roundFloat(rightArrowPoint3y, 2)

            let d = `M ${arrowPoint.x} ${arrowPoint.y} L
            ${rightArrowPoint2x} ${rightArrowPoint2y}
            ${dimple.x} ${dimple.y}
            ${rightArrowPoint3x} ${rightArrowPoint3y} z`
            let arrowG = g.group()
            arrowG.path(d).attr({ stroke: "none", fill: derivedValues.lineColor, strokeLinejoin: "miter" })
            let d2 = `M ${-arrowPoint.x} ${arrowPoint.y} L
            ${-rightArrowPoint2x} ${rightArrowPoint2y}
            ${-dimple.x} ${dimple.y}
            ${-rightArrowPoint3x} ${rightArrowPoint3y} z`
            let arrowG2 = g.group()
            arrowG2.path(d2).attr({ stroke: "none", fill: derivedValues.lineColor, strokeLinejoin: "miter" })
            // second line
            x1 = -width / 2
            y1 = -height / 2
            x2 = width / 2
            y2 = height / 2
            distance = derivedValues.lineThickness * 2
            if (width > 100) {
                distance += 10
            }
            newXY = Utility.getPointOnLineDistance(x1, y1, x2, y2, distance)
            distance = derivedValues.lineThickness
            newXY2 = Utility.getPointOnLineDistance(x2, y2, x1, y1, -distance)
            NatoUnitSymbols.drawLine(g, Utility.roundFloat(newXY.x, 2), Utility.roundFloat(newXY.y, 2), Utility.roundFloat(newXY2.x, 2), Utility.roundFloat(newXY2.y, 2), derivedValues)
        }

        if (svgName === 'nato symbol marines') {
            let d1 = "m 0.56 -30.54 c -3.05 0 -5.85 2.54 -5.6 5.6 c 0 3.05 2.54 5.34 5.6 5.34 c 3.05 0 5.6 -2.29 5.6 -5.34 c 0 -3.05 -2.54 -5.6 -5.6 -5.6 z m -0.25 2.8 c 1.53 0 2.8 1.27 2.8 2.54 c 0 1.53 -1.27 2.8 -2.8 2.8 c -1.53 0 -2.54 -1.27 -2.54 -2.8 c 0 -1.27 1.02 -2.54 2.54 -2.54 z"
            let d2 = "m -1.59 -20.57 c -0.27 0 0 0.53 0 2.31 v 2.31 l -5.57 -0.08 c -4.38 -0.06 -5.66 0 -5.98 0.26 c -0.52 0.43 -0.6 3.33 -0.09 3.34 l 11.39 -0.03 c 0.01 2.72 0.05 5.44 0.01 8.16 c -0.13 5.62 -0.23 11.24 -0.28 16.86 c -0.02 3.04 -0.14 5.96 -0.26 6.49 c -0.45 1.98 -1.69 2.25 -5.54 1.19 c -4 -1.1 -8.97 -3.54 -9.81 -4.81 c -0.67 -1.02 -0.77 -1.89 -0.29 -2.42 c 0.24 -0.26 0.6 -0.42 0.79 -0.34 c 0.22 0.08 0.44 -0.1 0.58 -0.46 c 0.48 -1.28 -1.13 -3.18 -4.29 -5.06 c -0.77 -0.46 -2.03 -1.44 -2.8 -2.18 c -0.81 -0.78 -1.52 -1.28 -1.68 -1.17 c -0.51 0.33 -1.56 2.69 -1.88 4.25 c -0.65 3.13 0.17 7.74 1.7 9.55 c 0.8 0.96 1.25 0.96 1.44 0.03 c 0.33 -1.65 2.39 -1.17 4.62 1.07 c 1.77 1.78 5.15 4.36 6.62 5.05 c 0.56 0.27 1.36 0.7 1.78 0.96 c 0.42 0.26 1.68 0.79 2.8 1.17 c 2.35 0.8 5.09 2.22 6.18 3.2 c 0.41 0.37 1.04 0.86 1.39 1.09 c 0.61 0.4 0.65 0.39 1.07 -0.24 c 0.86 -1.32 2.36 -2.48 4.62 -3.57 c 1.27 -0.62 2.5 -1.12 2.73 -1.12 c 0.5 0 4.68 -1.85 7.2 -3.19 c 1.98 -1.05 2.86 -1.68 5.07 -3.59 c 2.49 -2.16 3.75 -2.41 5.06 -0.98 l 0.82 0.89 l 0.52 -0.57 c 0.95 -1.04 1.3 -2.08 1.48 -4.42 c 0.14 -1.79 0.08 -2.76 -0.26 -4.32 c -0.54 -2.46 -1.46 -4.56 -2.14 -4.92 c -0.43 -0.23 -0.58 -0.16 -0.95 0.43 c -0.64 1.03 -1.54 1.85 -3.26 2.96 c -2.06 1.33 -3.31 2.71 -3.69 4.08 c -0.37 1.32 -0.2 1.8 0.5 1.43 c 0.77 -0.41 1.26 -0.3 1.56 0.35 c 0.42 0.91 -0.21 1.64 -2.62 3.08 c -4.12 2.45 -9.64 4.46 -12.27 4.46 c -1.24 0 -2.4 -0.48 -2.59 -1.07 c -0.09 -0.28 -0.09 -3.62 -0.01 -7.42 c 0.08 -3.8 0.22 -10.85 0.3 -15.68 c 0.08 -4.83 0.19 -8.84 0.24 -8.91 c 0.05 -0.08 2.67 -0.11 5.83 -0.08 c 6.56 0.06 6.21 0.18 6.16 -2.2 l -0.03 -1.4 c -3.91 -0.27 -7.92 0.24 -11.82 -0.29 c -0.07 -0.07 -0.15 -0.98 -0.17 -2.02 l -0.04 -2.33 z"


            let anchorGroup = g.group()
            anchorGroup.path(d1).attr({ stroke: "none", strokeWidth: 0, fill: derivedValues.lineColor })
            anchorGroup.path(d2).attr({ stroke: "none", strokeWidth: 0, fill: derivedValues.lineColor })
            let scaleXFactor = width / 130
            let scaleYFactor = height / 98
            let tstring = 's ' + scaleXFactor + ' ' + scaleYFactor
            anchorGroup.transform(tstring)
        }

        if (svgName === 'nato symbol armor marines') {
            let d1 = "m 0.56 -30.54 c -3.05 0 -5.85 2.54 -5.6 5.6 c 0 3.05 2.54 5.34 5.6 5.34 c 3.05 0 5.6 -2.29 5.6 -5.34 c 0 -3.05 -2.54 -5.6 -5.6 -5.6 z m -0.25 2.8 c 1.53 0 2.8 1.27 2.8 2.54 c 0 1.53 -1.27 2.8 -2.8 2.8 c -1.53 0 -2.54 -1.27 -2.54 -2.8 c 0 -1.27 1.02 -2.54 2.54 -2.54 z"
            let d2 = "m -1.59 -20.57 c -0.27 0 0 0.53 0 2.31 v 2.31 l -5.57 -0.08 c -4.38 -0.06 -5.66 0 -5.98 0.26 c -0.52 0.43 -0.6 3.33 -0.09 3.34 l 11.39 -0.03 c 0.01 2.72 0.05 5.44 0.01 8.16 c -0.13 5.62 -0.23 11.24 -0.28 16.86 c -0.02 3.04 -0.14 5.96 -0.26 6.49 c -0.45 1.98 -1.69 2.25 -5.54 1.19 c -4 -1.1 -8.97 -3.54 -9.81 -4.81 c -0.67 -1.02 -0.77 -1.89 -0.29 -2.42 c 0.24 -0.26 0.6 -0.42 0.79 -0.34 c 0.22 0.08 0.44 -0.1 0.58 -0.46 c 0.48 -1.28 -1.13 -3.18 -4.29 -5.06 c -0.77 -0.46 -2.03 -1.44 -2.8 -2.18 c -0.81 -0.78 -1.52 -1.28 -1.68 -1.17 c -0.51 0.33 -1.56 2.69 -1.88 4.25 c -0.65 3.13 0.17 7.74 1.7 9.55 c 0.8 0.96 1.25 0.96 1.44 0.03 c 0.33 -1.65 2.39 -1.17 4.62 1.07 c 1.77 1.78 5.15 4.36 6.62 5.05 c 0.56 0.27 1.36 0.7 1.78 0.96 c 0.42 0.26 1.68 0.79 2.8 1.17 c 2.35 0.8 5.09 2.22 6.18 3.2 c 0.41 0.37 1.04 0.86 1.39 1.09 c 0.61 0.4 0.65 0.39 1.07 -0.24 c 0.86 -1.32 2.36 -2.48 4.62 -3.57 c 1.27 -0.62 2.5 -1.12 2.73 -1.12 c 0.5 0 4.68 -1.85 7.2 -3.19 c 1.98 -1.05 2.86 -1.68 5.07 -3.59 c 2.49 -2.16 3.75 -2.41 5.06 -0.98 l 0.82 0.89 l 0.52 -0.57 c 0.95 -1.04 1.3 -2.08 1.48 -4.42 c 0.14 -1.79 0.08 -2.76 -0.26 -4.32 c -0.54 -2.46 -1.46 -4.56 -2.14 -4.92 c -0.43 -0.23 -0.58 -0.16 -0.95 0.43 c -0.64 1.03 -1.54 1.85 -3.26 2.96 c -2.06 1.33 -3.31 2.71 -3.69 4.08 c -0.37 1.32 -0.2 1.8 0.5 1.43 c 0.77 -0.41 1.26 -0.3 1.56 0.35 c 0.42 0.91 -0.21 1.64 -2.62 3.08 c -4.12 2.45 -9.64 4.46 -12.27 4.46 c -1.24 0 -2.4 -0.48 -2.59 -1.07 c -0.09 -0.28 -0.09 -3.62 -0.01 -7.42 c 0.08 -3.8 0.22 -10.85 0.3 -15.68 c 0.08 -4.83 0.19 -8.84 0.24 -8.91 c 0.05 -0.08 2.67 -0.11 5.83 -0.08 c 6.56 0.06 6.21 0.18 6.16 -2.2 l -0.03 -1.4 c -3.91 -0.27 -7.92 0.24 -11.82 -0.29 c -0.07 -0.07 -0.15 -0.98 -0.17 -2.02 l -0.04 -2.33 z"
            let anchorGroup = g.group()
            anchorGroup.path(d1).attr({ stroke: "none", strokeWidth: 0, fill: derivedValues.lineColor })
            anchorGroup.path(d2).attr({ stroke: "none", strokeWidth: 0, fill: derivedValues.lineColor })
            let scaleXFactor = (width - (derivedValues.lineThickness * 2)) / 170
            let scaleYFactor = (height - (derivedValues.lineThickness * 2)) / 128
            let tstring = 's ' + scaleXFactor + ' ' + scaleYFactor
            anchorGroup.transform(tstring)
        }

        if (svgName === 'nato symbol mortar1') {
            let x1 = x + width * 0.5
            let y1 = y + height * 0.18
            let x2 = x1
            let y2 = y + height * 0.73
            let derivedCopy = { ...derivedValues }

            let strokeWidth = 3 + ((height - 75) * 0.05)
            if (strokeWidth < 1) {
                strokeWidth = 1
            }
            derivedCopy.lineThickness = strokeWidth
            NatoUnitSymbols.drawLine(g, x1, y1, x2, y2, derivedCopy, 'square')

            let distance = Utility.distanceBetweenTwoPoints(x1, y1, x2, y2)

            x2 = x1
            y2 = y + height * 0.16
            let x3 = x1 + (distance * 0.3)
            x1 = x1 - (distance * 0.3)
            y1 = y + height * 0.38

            let y3 = y1
            let d = `M ${x1} ${y1} L ${x2} ${y2} ${x3} ${y3}`
            g.path(d).attr({ stroke: derivedValues.lineColor, strokeWidth: strokeWidth, fill: "none", strokeLinejoin: "miter" })

            x1 = x + width * 0.5
            y1 = y + height * 0.82
            let r = distance * 0.13
            derivedCopy.fillColor = 'none'
            g.circle(x1, y1, r).attr({
                strokeWidth: strokeWidth,
                stroke: derivedCopy.lineColor,
                fill: "none"
            })
        }

        if (svgName === 'nato symbol mortar2') {
            let x1 = x + width * 0.5
            let y1 = y + height * 0.18
            let x2 = x1
            let y2 = y + height * 0.73
            let derivedCopy = { ...derivedValues }

            let strokeWidth = 3 + ((height - 75) * 0.05)
            if (strokeWidth < 1) {
                strokeWidth = 1
            }
            derivedCopy.lineThickness = strokeWidth
            NatoUnitSymbols.drawLine(g, x1, y1, x2, y2, derivedCopy, 'square')

            let distance = Utility.distanceBetweenTwoPoints(x1, y1, x2, y2)

            x2 = x1
            y2 = y + height * 0.16
            let x3 = x1 + (distance * 0.3)
            x1 = x1 - (distance * 0.3)
            y1 = y + height * 0.38

            let y3 = y1
            let d = `M ${x1} ${y1} L ${x2} ${y2} ${x3} ${y3}`
            g.path(d).attr({ stroke: derivedValues.lineColor, strokeWidth: strokeWidth, fill: "none", strokeLinejoin: "miter" })

            x1 = (x + width * 0.5) - (distance * 0.1)
            y1 = y + height * 0.58
            x2 = (x + width * 0.5) + (distance * 0.1)
            y2 = y1
            NatoUnitSymbols.drawLine(g, x1, y1, x2, y2, derivedCopy, 'square')


            x1 = x + width * 0.5
            y1 = y + height * 0.82
            let r = distance * 0.13
            derivedCopy.fillColor = 'none'
            g.circle(x1, y1, r).attr({
                strokeWidth: strokeWidth,
                stroke: derivedCopy.lineColor,
                fill: "none"
            })
        }

        if (svgName === 'nato symbol mortar3') {
            let x1 = x + width * 0.5
            let y1 = y + height * 0.18
            let x2 = x1
            let y2 = y + height * 0.73
            let derivedCopy = { ...derivedValues }

            let strokeWidth = 3 + ((height - 75) * 0.05)
            if (strokeWidth < 1) {
                strokeWidth = 1
            }
            derivedCopy.lineThickness = strokeWidth
            NatoUnitSymbols.drawLine(g, x1, y1, x2, y2, derivedCopy, 'square')

            let distance = Utility.distanceBetweenTwoPoints(x1, y1, x2, y2)

            x2 = x1
            y2 = y + height * 0.16
            let x3 = x1 + (distance * 0.3)
            x1 = x1 - (distance * 0.3)
            y1 = y + height * 0.38

            let y3 = y1
            let d = `M ${x1} ${y1} L ${x2} ${y2} ${x3} ${y3}`
            g.path(d).attr({ stroke: derivedValues.lineColor, strokeWidth: strokeWidth, fill: "none", strokeLinejoin: "miter" })

            x1 = (x + width * 0.5) - (distance * 0.1)
            y1 = y + height * 0.55
            x2 = (x + width * 0.5) + (distance * 0.1)
            y2 = y1
            NatoUnitSymbols.drawLine(g, x1, y1, x2, y2, derivedCopy, 'square')
            x1 = (x + width * 0.5) - (distance * 0.1)
            y1 = y + height * 0.63
            x2 = (x + width * 0.5) + (distance * 0.1)
            y2 = y1
            NatoUnitSymbols.drawLine(g, x1, y1, x2, y2, derivedCopy, 'square')


            x1 = x + width * 0.5
            y1 = y + height * 0.82
            let r = distance * 0.13
            derivedCopy.fillColor = 'none'
            g.circle(x1, y1, r).attr({
                strokeWidth: strokeWidth,
                stroke: derivedCopy.lineColor,
                fill: "none"
            })
        }


        if (svgName === 'nato symbol mortar4') {
            let x1 = x + width * 0.5
            let y1 = y + height * 0.18
            let x2 = x1
            let y2 = y + height * 0.73
            let derivedCopy = { ...derivedValues }

            let strokeWidth = 3 + ((height - 75) * 0.05)
            if (strokeWidth < 1) {
                strokeWidth = 1
            }
            derivedCopy.lineThickness = strokeWidth
            NatoUnitSymbols.drawLine(g, x1, y1, x2, y2, derivedCopy, 'square')

            let distance = Utility.distanceBetweenTwoPoints(x1, y1, x2, y2)

            x2 = x1
            y2 = y + height * 0.16
            let x3 = x1 + (distance * 0.3)
            x1 = x1 - (distance * 0.3)
            y1 = y + height * 0.38

            let y3 = y1
            let d = `M ${x1} ${y1} L ${x2} ${y2} ${x3} ${y3}`
            g.path(d).attr({ stroke: derivedValues.lineColor, strokeWidth: strokeWidth, fill: "none", strokeLinejoin: "miter" })

            x1 = (x + width * 0.5) - (distance * 0.1)
            y1 = y + height * 0.5
            x2 = (x + width * 0.5) + (distance * 0.1)
            y2 = y1
            NatoUnitSymbols.drawLine(g, x1, y1, x2, y2, derivedCopy, 'square')
            x1 = (x + width * 0.5) - (distance * 0.1)
            y1 = y + height * 0.58
            x2 = (x + width * 0.5) + (distance * 0.1)
            y2 = y1
            NatoUnitSymbols.drawLine(g, x1, y1, x2, y2, derivedCopy, 'square')
            x1 = (x + width * 0.5) - (distance * 0.1)
            y1 = y + height * 0.66
            x2 = (x + width * 0.5) + (distance * 0.1)
            y2 = y1
            NatoUnitSymbols.drawLine(g, x1, y1, x2, y2, derivedCopy, 'square')

            x1 = x + width * 0.5
            y1 = y + height * 0.82
            let r = distance * 0.13
            derivedCopy.fillColor = 'none'
            g.circle(x1, y1, r).attr({
                strokeWidth: strokeWidth,
                stroke: derivedCopy.lineColor,
                fill: "none"
            })
        }

        if (svgName === 'nato symbol machine gun4') { // machine gun
            let x1 = x + width * 0.5
            let y1 = y + height * 0.2
            let x2 = x1
            let y2 = y + height * 0.8
            let derivedCopy = { ...derivedValues }

            let strokeWidth = 3 + ((height - 75) * 0.05)
            if (strokeWidth < 1) {
                strokeWidth = 1
            }
            derivedCopy.lineThickness = strokeWidth
            NatoUnitSymbols.drawLine(g, x1, y1, x2, y2, derivedCopy, 'square')

            let distance = Utility.distanceBetweenTwoPoints(x1, y1, x2, y2)

            x2 = x1
            y2 = y + height * 0.18
            let x3 = x1 + (distance * 0.3)
            x1 = x1 - (distance * 0.3)
            y1 = y + height * 0.4

            let y3 = y1
            let d = `M ${x1} ${y1} L ${x2} ${y2} ${x3} ${y3}`
            g.path(d).attr({ stroke: derivedValues.lineColor, strokeWidth: strokeWidth, fill: "none", strokeLinejoin: "miter" })

            x1 = (x + width * 0.5) - (distance * 0.1)
            y1 = y + height * 0.52
            x2 = (x + width * 0.5) + (distance * 0.1)
            y2 = y1
            NatoUnitSymbols.drawLine(g, x1, y1, x2, y2, derivedCopy, 'square')
            x1 = (x + width * 0.5) - (distance * 0.1)
            y1 = y + height * 0.60
            x2 = (x + width * 0.5) + (distance * 0.1)
            y2 = y1
            NatoUnitSymbols.drawLine(g, x1, y1, x2, y2, derivedCopy, 'square')
            x1 = (x + width * 0.5) - (distance * 0.1)
            y1 = y + height * 0.68
            x2 = (x + width * 0.5) + (distance * 0.1)
            y2 = y1
            NatoUnitSymbols.drawLine(g, x1, y1, x2, y2, derivedCopy, 'square')
        }

        if (svgName === 'nato symbol machine gun3') { // machine gun
            let x1 = x + width * 0.5
            let y1 = y + height * 0.2
            let x2 = x1
            let y2 = y + height * 0.8
            let derivedCopy = { ...derivedValues }

            let strokeWidth = 3 + ((height - 75) * 0.05)
            if (strokeWidth < 1) {
                strokeWidth = 1
            }
            derivedCopy.lineThickness = strokeWidth
            NatoUnitSymbols.drawLine(g, x1, y1, x2, y2, derivedCopy, 'square')

            let distance = Utility.distanceBetweenTwoPoints(x1, y1, x2, y2)

            x2 = x1
            y2 = y + height * 0.18
            let x3 = x1 + (distance * 0.3)
            x1 = x1 - (distance * 0.3)
            y1 = y + height * 0.4

            let y3 = y1
            let d = `M ${x1} ${y1} L ${x2} ${y2} ${x3} ${y3}`
            g.path(d).attr({ stroke: derivedValues.lineColor, strokeWidth: strokeWidth, fill: "none", strokeLinejoin: "miter" })

            x1 = (x + width * 0.5) - (distance * 0.1)
            y1 = y + height * 0.55
            x2 = (x + width * 0.5) + (distance * 0.1)
            y2 = y1
            NatoUnitSymbols.drawLine(g, x1, y1, x2, y2, derivedCopy, 'square')
            x1 = (x + width * 0.5) - (distance * 0.1)
            y1 = y + height * 0.65
            x2 = (x + width * 0.5) + (distance * 0.1)
            y2 = y1
            NatoUnitSymbols.drawLine(g, x1, y1, x2, y2, derivedCopy, 'square')
        }


        if (svgName === 'nato symbol machine gun2') { // machine gun
            let x1 = x + width * 0.5
            let y1 = y + height * 0.2
            let x2 = x1
            let y2 = y + height * 0.8
            let derivedCopy = { ...derivedValues }

            let strokeWidth = 3 + ((height - 75) * 0.05)
            if (strokeWidth < 1) {
                strokeWidth = 1
            }
            derivedCopy.lineThickness = strokeWidth
            NatoUnitSymbols.drawLine(g, x1, y1, x2, y2, derivedCopy, 'square')

            let distance = Utility.distanceBetweenTwoPoints(x1, y1, x2, y2)

            x2 = x1
            y2 = y + height * 0.18
            let x3 = x1 + (distance * 0.3)
            x1 = x1 - (distance * 0.3)
            y1 = y + height * 0.4

            let y3 = y1
            let d = `M ${x1} ${y1} L ${x2} ${y2} ${x3} ${y3}`
            g.path(d).attr({ stroke: derivedValues.lineColor, strokeWidth: strokeWidth, fill: "none", strokeLinejoin: "miter" })

            x1 = (x + width * 0.5) - (distance * 0.1)
            y1 = y + height * 0.6
            x2 = (x + width * 0.5) + (distance * 0.1)
            y2 = y1
            NatoUnitSymbols.drawLine(g, x1, y1, x2, y2, derivedCopy, 'square')
        }

        if (svgName === 'nato symbol machine gun1') { // machine gun
            let x1 = x + width * 0.5
            let y1 = y + height * 0.2
            let x2 = x1
            let y2 = y + height * 0.8
            let derivedCopy = { ...derivedValues }

            let strokeWidth = 3 + ((height - 75) * 0.05)
            if (strokeWidth < 1) {
                strokeWidth = 1
            }
            derivedCopy.lineThickness = strokeWidth
            NatoUnitSymbols.drawLine(g, x1, y1, x2, y2, derivedCopy, 'square')

            let distance = Utility.distanceBetweenTwoPoints(x1, y1, x2, y2)

            x2 = x1
            y2 = y + height * 0.18
            let x3 = x1 + (distance * 0.3)
            x1 = x1 - (distance * 0.3)
            y1 = y + height * 0.4

            let y3 = y1
            let d = `M ${x1} ${y1} L ${x2} ${y2} ${x3} ${y3}`
            g.path(d).attr({ stroke: derivedValues.lineColor, strokeWidth: strokeWidth, fill: "none", strokeLinejoin: "miter" })

        }

        if (svgName === 'nato symbol HQ5') { // HQ5
            let x1 = x
            let y1 = y + height * 0.2
            let x2 = x + width
            let y2 = y1
            let derivedCopy = { ...derivedValues }
            derivedCopy.lineThickness = 3
            NatoUnitSymbols.drawLine(g, x1, y1, x2, y2, derivedCopy, 'square')

            y1 = y + height * 0.8
            y2 = y1
            NatoUnitSymbols.drawLine(g, x1, y1, x2, y2, derivedCopy, 'square')

        }

        if (svgName === 'nato symbol HQ4') { // HQ3
            let x1 = x + width * 0.3
            let y1 = y + height * 0.16
            let x2 = x1
            let y2 = y + height * 0.9
            let derivedCopy = { ...derivedValues }
            derivedCopy.lineThickness = 3
            NatoUnitSymbols.drawLine(g, x1, y1, x2, y2, derivedCopy, 'square')

            x1 += 1
            x2 += 1
            y2 = y + height * 0.45
            let x3 = x + width * 0.8
            let y3 = y + height * 0.45
            let d = `M ${x1} ${y1} L ${x2} ${y2} ${x3} ${y3} z`
            g.path(d).attr({ stroke: "black", strokeWidth: 1, fill: "white", strokeLinejoin: "round" })

            y1 = y2
            x2 = x1
            y2 = y + height * 0.75
            x3 = x + width * 0.8
            y3 = y + height * 0.45

            // y1 = y + height * 0.8
            // y2 = y + height * 0.8
            d = `M ${x1} ${y1} L ${x2} ${y2} ${x3} ${y3} z`
            g.path(d).attr({ stroke: "black", strokeWidth: 1, fill: "black", strokeLinejoin: "round" })

        }

        if (svgName === 'nato symbol HQ3') { // HQ3


            let x1 = x + width * 0.22
            let y1 = y + height * 0.2
            let w = width * 0.575
            let h = height * 0.5
            g.rect(x1, y1, w, h).attr({ stroke: "black", strokeWidth: 1, fill: "white" })
            let y1b = y1 - height * 0.04
            let x2 = x1
            let y2 = y1 + height * 0.7
            let derivedCopy = { ...derivedValues }
            derivedCopy.lineThickness = 3
            NatoUnitSymbols.drawLine(g, x1, y1b, x2, y2, derivedCopy, 'square')

            x1 = x1 + w / 2
            w = w / 2
            h = h / 2
            g.rect(x1, y1, w, h).attr({ stroke: "none", strokeWidth: 0, fill: "black" })

            x1 -= w
            y1 += h
            g.rect(x1, y1, w, h).attr({ stroke: "none", strokeWidth: 0, fill: "black" })
        }

        if (svgName === 'nato symbol HQ2') { // HQ2
            let x1 = x
            let y1 = y + height / 2
            let w = width / 2
            let h = height / 2
            g.rect(x1, y1, w, h).attr({ stroke: "none", strokeWidth: 0, fill: "black" })
            x1 += width / 2
            y1 = y
            g.rect(x1, y1, w, h).attr({ stroke: "none", strokeWidth: 0, fill: "black" })
        }

        if (svgName === 'nato symbol mortar') {
            let mortarGroup = g.group()
            let derivedCopy = { ...derivedValues }
            derivedCopy.lineThickness = 10
            NatoUnitSymbols.drawLine(mortarGroup, 0, -22, 0, 13, derivedCopy, 'square')
            NatoUnitSymbols.drawCircle(mortarGroup, 0, 12.75, 13, derivedCopy)
            let scaleFactor = height / 92
            let tstring = 's ' + scaleFactor
            mortarGroup.transform(tstring)
        }

        if (svgName === 'nato symbol engineer' ||
            svgName === 'nato symbol motorized engineer') {
            let x1 = 0
            let x2 = 0
            let y1 = 0
            let y2 = 0
            x1 = (-width * 0.3)
            y1 = -height * 0.17
            x2 = (width * 0.3)
            y2 = -height * 0.17
            NatoUnitSymbols.drawLine(g, x1, y1, x2, y2, derivedValues, 'square')

            x1 = (-width * 0.3)
            y1 = -height * 0.17
            x2 = (-width * 0.3)
            y2 = height * 0.17
            NatoUnitSymbols.drawLine(g, x1, y1, x2, y2, derivedValues, 'square')

            x1 = (width * 0.3)
            y1 = -height * 0.17
            x2 = (width * 0.3)
            y2 = height * 0.17
            NatoUnitSymbols.drawLine(g, x1, y1, x2, y2, derivedValues, 'square')

            x1 = 0
            y1 = -height * 0.17
            x2 = 0
            y2 = height * 0.17
            NatoUnitSymbols.drawLine(g, x1, y1, x2, y2, derivedValues, 'square')
        }

        if (svgName === 'nato symbol HQ1') {
            // left side H line
            let x1 = (-width * 0.3)
            let y1 = (-height * 0.27)
            let x2 = (-width * 0.3)
            let y2 = (height * 0.27)
            NatoUnitSymbols.drawLine(g, x1, y1, x2, y2, derivedValues)

            // right side H line
            x1 = (-width * 0.06)
            y1 = (-height * 0.27)
            x2 = (-width * 0.06)
            y2 = (height * 0.27)
            NatoUnitSymbols.drawLine(g, x1, y1, x2, y2, derivedValues)

            // cross H line
            x1 = (-width * 0.3)
            y1 = 0
            x2 = (-width * 0.06)
            y2 = 0
            NatoUnitSymbols.drawLine(g, x1, y1, x2, y2, derivedValues, 'square')

            // Q
            let x = width * 0.2
            let y = 0
            let rx = width * 0.13
            let ry = height * 0.27
            NatoUnitSymbols.drawEllipse(g, x, y, rx, ry, derivedValues)

            // little Q line
            x1 = width * 0.26
            y1 = height * 0.14
            x2 = width * 0.345
            y2 = height * 0.25
            NatoUnitSymbols.drawLine(g, x1, y1, x2, y2, derivedValues, 'square')


        }

        if (svgName === 'nato symbol anti-air' ||
            svgName === 'nato symbol motorized anti-air') {
            // first line
            let x1 = (-width / 2) + (lineThickness / 3)
            let y1 = (height / 2) - (lineThickness / 3)
            let x2 = 0 - (lineThickness / 3)
            let y2 = (-height / 2) + (lineThickness / 2)
            NatoUnitSymbols.drawLine(g, x1, y1, x2, y2, derivedValues)

            let distance = Utility.distanceBetweenTwoPoints(x1, y1, x2, y2)
            let newXY = Utility.getPointOnLineDistance(x1, y1, x2, y2, distance / 2)

            // second line
            x1 = 0 + (lineThickness / 3)
            y1 = (-height / 2) + (lineThickness / 2)
            x2 = (width / 2) - (lineThickness / 3)
            y2 = (height / 2) - (lineThickness / 3)
            NatoUnitSymbols.drawLine(g, x1, y1, x2, y2, derivedValues)

            distance = Utility.distanceBetweenTwoPoints(x1, y1, x2, y2)
            let newXY2 = Utility.getPointOnLineDistance(x1, y1, x2, y2, distance / 2)


            // third line
            NatoUnitSymbols.drawLine(g, newXY.x + (lineThickness / 3), newXY.y, newXY2.x - (lineThickness / 3), newXY2.y, derivedValues)
        }

        if (svgName === 'nato symbol anti-tank' ||
            svgName === 'nato symbol armored anti-tank') {
            // first line
            let x1 = -width / 2
            let y1 = -height / 2
            let x2 = 0 - (lineThickness / 3)
            let y2 = (height / 2) - (lineThickness / 2)
            NatoUnitSymbols.drawLine(g, x1, y1, x2, y2, derivedValues)

            // second line
            x1 = width / 2
            y1 = -height / 2
            x2 = 0 + (lineThickness / 3)
            y2 = (height / 2) - (lineThickness / 2)
            NatoUnitSymbols.drawLine(g, x1, y1, x2, y2, derivedValues)
        }



        if (svgName === 'nato symbol infantry' ||
            svgName === 'nato symbol airborne' ||
            svgName === 'nato symbol heavy weapons' ||
            //svgName === 'nato symbol rangers' ||
            svgName === 'nato symbol marines' ||
            svgName === 'nato symbol mountain infantry' ||
            svgName === 'nato symbol mechanized' ||
            svgName === 'nato symbol motorized infantry' ||
            svgName === 'nato symbol stryker'
        ) { // first line



            let x1 = -width / 2
            let y1 = height / 2
            let x2 = width / 2
            let y2 = -height / 2
            let distance = derivedValues.lineThickness / 2
            let newXY = Utility.getPointOnLineDistance(x1, y1, x2, y2, distance)
            let newXY2 = Utility.getPointOnLineDistance(x2, y2, x1, y1, -distance)
            NatoUnitSymbols.drawLine(g, newXY.x, newXY.y, newXY2.x, newXY2.y, derivedValues)

            // second line
            x1 = -width / 2
            y1 = -height / 2
            x2 = width / 2
            y2 = height / 2
            newXY = Utility.getPointOnLineDistance(x1, y1, x2, y2, distance)
            newXY2 = Utility.getPointOnLineDistance(x2, y2, x1, y1, -distance)
            NatoUnitSymbols.drawLine(g, newXY.x, newXY.y, newXY2.x, newXY2.y, derivedValues)
        }

        if (svgName === 'nato symbol cavalry' ||
            svgName === 'nato symbol motorcycle' ||
            svgName === 'nato symbol recon') { // CAVALRY
            let x1 = -width / 2
            let y1 = height / 2
            let x2 = width / 2
            let y2 = -height / 2
            let distance = derivedValues.lineThickness / 2
            let newXY = Utility.getPointOnLineDistance(x1, y1, x2, y2, distance)
            let newXY2 = Utility.getPointOnLineDistance(x2, y2, x1, y1, -distance)
            NatoUnitSymbols.drawLine(g, newXY.x, newXY.y, newXY2.x, newXY2.y, derivedValues)
        }

        if (svgName === 'nato symbol airborne') {
            let w = 100 * (width / 200) - derivedValues.lineThickness
            let h = 26 * (height / 200)

            let x1 = -w / 2
            let y1 = h / 2
            let x2 = 0
            let y2 = y1
            let rx1 = (x2 - x1) / 2
            let ry1 = h
            let x3 = w / 2
            let y3 = y1

            y1 += height * 0.4 - derivedValues.lineThickness / 2
            y2 += height * 0.4 - derivedValues.lineThickness / 2
            y3 += height * 0.4 - derivedValues.lineThickness / 2

            x1 = Utility.roundFloat(x1, 2)
            y1 = Utility.roundFloat(y1, 2)
            x2 = Utility.roundFloat(x2, 2)
            y2 = Utility.roundFloat(y2, 2)
            rx1 = Utility.roundFloat(rx1, 2)
            ry1 = Utility.roundFloat(ry1, 2)
            x3 = Utility.roundFloat(x3, 2)
            y3 = Utility.roundFloat(y3, 2)



            let d = `M ${x1} ${y1}
                     A ${rx1} ${ry1} 0 0 1 ${x2} ${y2}
                     M ${x2} ${y2}
                     A ${rx1} ${ry1} 0 0 1 ${x3} ${y3}`

            d = d.replace(/\s+/g, ' ').trim()
            g.path(d).attr({ "stroke": derivedValues.lineColor, "strokeWidth": derivedValues.lineThickness, "fill": "none" })

        }

        if (svgName === 'nato symbol motorcycle') { // two dots lower right
            let ar = (width / 24) + (height / 40)
            if (lineThickness > 5) {
                ar -= lineThickness * 0.1
            }
            let x1 = width * 0.1
            let y1 = height * 0.3
            NatoUnitSymbols.drawCircle(g, x1, y1, ar, derivedValues)
            x1 = width * 0.3
            NatoUnitSymbols.drawCircle(g, x1, y1, ar, derivedValues)
        }

        if (svgName === 'nato symbol armor' ||
            svgName === 'nato symbol heavy armor' ||
            svgName === 'nato symbol armor marines' ||
            svgName === 'nato symbol mechanized' ||
            svgName === 'nato symbol assault gun' ||
            svgName === 'nato symbol recon' ||
            svgName === 'nato symbol armored engineer' ||
            svgName === 'nato symbol armored anti-tank' ||
            svgName === 'nato symbol stryker') { // ARMOR
            NatoUnitSymbols.drawArmorEllipse(g, width, height, derivedValues)
        }

        if (svgName === 'nato symbol armored engineer') {
            let x1 = 0
            let x2 = 0
            let y1 = 0
            let y2 = 0
            x1 = (-width * 0.15)
            y1 = -height * 0.10
            x2 = (width * 0.15)
            y2 = -height * 0.10
            NatoUnitSymbols.drawLine(g, x1, y1, x2, y2, derivedValues, 'square')

            x1 = (-width * 0.15)
            y1 = -height * 0.10
            x2 = (-width * 0.15)
            y2 = height * 0.10
            NatoUnitSymbols.drawLine(g, x1, y1, x2, y2, derivedValues, 'square')

            x1 = (width * 0.15)
            y1 = -height * 0.10
            x2 = (width * 0.15)
            y2 = height * 0.10
            NatoUnitSymbols.drawLine(g, x1, y1, x2, y2, derivedValues, 'square')

            x1 = 0
            y1 = -height * 0.10
            x2 = 0
            y2 = height * 0.10
            NatoUnitSymbols.drawLine(g, x1, y1, x2, y2, derivedValues, 'square')
        }

        if (svgName === 'nato symbol mountain infantry') { // MOUNTAIN INFANTRY
            let tx1 = (x + (width / 2)) - (width / 5)
            let tx2 = x + (width / 2)
            let tx3 = (x + (width / 2)) + (width / 5)
            let ty1 = y + height - (lineThickness / 2)
            let ty2 = y + (height / 2) + (lineThickness / 2)
            let ty3 = y + height - (lineThickness / 2)
            NatoUnitSymbols.drawTriangle(g, tx1, ty1, tx2, ty2, tx3, ty3, derivedValues)
        }

        if (svgName === 'nato symbol assault gun' ||
            svgName === 'nato symbol anti-tank' ||
            svgName === 'nato symbol armored anti-tank') { // ARMORs
            let ar = (width / 12) - (lineThickness / 5)
            NatoUnitSymbols.drawCircle(g, 0, 0, ar, derivedValues)
        }

        if (svgName === 'nato symbol artillery' ||
            svgName === 'nato symbol coast artillery' ||
            svgName === 'nato symbol heavy artillery') { // ARTILLERY
            let ar = (width / 10) - (lineThickness / 10)
            NatoUnitSymbols.drawCircle(g, 0, 0, ar, derivedValues)
        }

        if (svgName === 'nato symbol stryker') {
            let ry = height * 0.25 + lineThickness / 2
            let tb = (height / 2) - (lineThickness * 0.5)


            let wheelRadius = (tb - ry) / 2
            wheelRadius *= 0.7
            let wheel_y = ((tb - ry) / 2) + ry
            let wheelThickness = lineThickness * 0.7
            while ((wheelThickness / 2 + wheelRadius) + wheel_y > tb) {
                wheelThickness -= 0.01
                wheelRadius -= 0.001
            }
            NatoUnitSymbols.drawBlankCircle(g, 0, wheel_y, wheelRadius, derivedValues)
            NatoUnitSymbols.drawBlankCircle(g, (wheelRadius * 2.2 + lineThickness), wheel_y, wheelRadius, derivedValues)
            NatoUnitSymbols.drawBlankCircle(g, -(wheelRadius * 2.2 + lineThickness), wheel_y, wheelRadius, derivedValues)
        }


        NatoUnitSymbols.drawEmptyBoxNoFill(g, x, y, width, height, derivedValues)


        x = Utility.roundFloat(x, 2)
        y = Utility.roundFloat(y, 2)
        width = Utility.roundFloat(width, 2)
        height = Utility.roundFloat(height, 2)
        let rc = derivedValues.roundCorners
        let maskGroup = g.group()
        maskGroup.rect(x, y, width, height, rc, rc).attr({
            "stroke": "white",
            "strokeWidth": derivedValues.lineThickness,
            "fill": "white",
            "opacity": 1
        })
        maskGroup.rect(x + (width * 0.1), y + height, width - (width * 0.2), 30).attr({ fill: "white", stroke: "white" })


        g.attr({ mask: maskGroup })


        if (opacity < 1) {
            g.attr({ "opacity": opacity })
        }

        if (rotation > 0) {
            NatoUnitSymbols.applyRotation(g, rotation)
        }
        if (xpos !== 0 || ypos !== 0) {
            NatoUnitSymbols.applyTranslation(g, xpos, ypos)
        }

    },

    drawEmptyUnitSymbol: (g, x, y, width, height, derivedValues) => {
        x = Utility.roundFloat(x, 2)
        y = Utility.roundFloat(y, 2)
        width = Utility.roundFloat(width, 2)
        height = Utility.roundFloat(height, 2)
        let rc = derivedValues.roundCorners

        if (!derivedValues.fillColor) {
            return
        }
        let fillColorObj = derivedValues.fillColor
        let fillInfo = ''
        if (fillColorObj.fillType === 'solid') {
            fillInfo = fillColorObj.fillColor
        }
        else { // gradient
            let type = derivedValues.fillColor.gradientDirection === 'radial' ? 'r' : 'l'
            let pxStart = derivedValues.fillColor.xyPositionStart.split(',')[0]
            let pyStart = derivedValues.fillColor.xyPositionStart.split(',')[1]
            let pxEnd = derivedValues.fillColor.xyPositionEnd.split(',')[0]
            let pyEnd = derivedValues.fillColor.xyPositionEnd.split(',')[1]
            let color1 = derivedValues.fillColor.colorStart
            let color2 = derivedValues.fillColor.colorMid
            let color3 = derivedValues.fillColor.colorEnd
            let defg = `${type}(${pxStart}, ${pyStart}, ${pxEnd}, ${pyEnd})${color1}-${color2}-${color3}`
            let parent = g.parent()
            var cg = parent.gradient(defg);
            fillInfo = cg
        }

        g.rect(x, y, width, height, rc, rc).attr({
            stroke: derivedValues.lineColor,
            strokeWidth: derivedValues.lineThickness,
            fill: fillInfo,
        })
    },

    drawEmptyBoxNoFill: (g, x, y, width, height, settings) => {
        x = Utility.roundFloat(x, 2)
        y = Utility.roundFloat(y, 2)
        width = Utility.roundFloat(width, 2)
        height = Utility.roundFloat(height, 2)
        let rc = settings.roundCorners
        g.rect(x, y, width, height, rc, rc).attr({
            "stroke": settings.lineColor,
            "strokeWidth": settings.lineThickness,
            "fill": "none"
        })
    },

    drawLine: (g, x1, y1, x2, y2, settings, lineCapStyle = 'round') => {
        x1 = Utility.roundFloat(x1, 3)
        y1 = Utility.roundFloat(y1, 3)
        x2 = Utility.roundFloat(x2, 3)
        y2 = Utility.roundFloat(y2, 3)
        let lineGroup = g.group()
        lineGroup.line(Utility.roundFloat(x1, 3), Utility.roundFloat(y1, 3), Utility.roundFloat(x2, 3), Utility.roundFloat(y2, 3)).attr({
            "stroke": settings.lineColor,
            "strokeWidth": settings.lineThickness,
            "strokeLinecap": lineCapStyle
        })
        return lineGroup
    },

    drawEllipse: (g, x, y, rx, ry, settings) => {
        x = Utility.roundFloat(x, 2)
        y = Utility.roundFloat(y, 2)
        rx = Utility.roundFloat(rx, 2)
        ry = Utility.roundFloat(ry, 2)
        g.ellipse(Utility.roundFloat(x, 2), Utility.roundFloat(y, 2), Utility.roundFloat(rx, 2), Utility.roundFloat(ry, 2)).attr({
            "stroke": settings.lineColor,
            "strokeWidth": settings.lineThickness,
            "fill": "none"
        })
    },

    drawArmorEllipse: (g, width, height, settings) => {

        let x1 = width * 0.08
        let y1 = height * -0.25
        let x2 = width * 0.08
        let y2 = height * 0.25

        let curve_x1 = x1 + (width * 0.28)
        let curve_y1 = y1 - (height * 0.01)
        let curve_x2 = x2 + (width * 0.28)
        let curve_y2 = y2 + (height * 0.01)

        x1 = Utility.roundFloat(x1, 2)
        y1 = Utility.roundFloat(y1, 2)
        x2 = Utility.roundFloat(x2, 2)
        y2 = Utility.roundFloat(y2, 2)
        curve_x1 = Utility.roundFloat(curve_x1, 2)
        curve_y1 = Utility.roundFloat(curve_y1, 2)
        curve_x2 = Utility.roundFloat(curve_x2, 2)
        curve_y2 = Utility.roundFloat(curve_y2, 2)

        let curvePathLeft = 'M ' + -x1 + ' ' + y1 + ' ' +
            'C ' + -curve_x1 + ' ' + curve_y1 + ' ' +
            -curve_x2 + ' ' + curve_y2 + ' ' +
            -x2 + ' ' + y2

        let curvePathCombined = curvePathLeft +
            'L ' + x2 + ' ' + y2 + ' ' +
            'C ' + curve_x2 + ' ' + curve_y2 + ' ' +
            curve_x1 + ' ' + curve_y1 + ' ' +
            x1 + ' ' + y1 + ' z'
        g.path(curvePathCombined).attr({ "stroke": settings.lineColor, "strokeWidth": settings.lineThickness, "fill": "none" })

    },

    drawTriangle: (g, tx1, ty1, tx2, ty2, tx3, ty3, settings) => {
        tx1 = Utility.roundFloat(tx1, 2)
        ty1 = Utility.roundFloat(ty1, 2)
        tx2 = Utility.roundFloat(tx2, 2)
        ty2 = Utility.roundFloat(ty2, 2)
        tx3 = Utility.roundFloat(tx3, 2)
        ty3 = Utility.roundFloat(ty3, 2)
        g.path(`M ${tx1} ${ty1} ${tx2} ${ty2} ${tx3} ${ty3} z`).attr({
            "stroke": "none",
            "strokeWidth": 0,
            "fill": settings.lineColor,
        })
    },

    drawCircle: (g, x, y, r, settings) => {
        x = Utility.roundFloat(x, 2)
        y = Utility.roundFloat(y, 2)
        r = Utility.roundFloat(r, 2)
        g.circle(x, y, r).attr({
            "stroke": "none",
            "fill": settings.lineColor
        })
    },

    drawBlankCircle: (g, x, y, r, settings) => {
        x = Utility.roundFloat(x, 2)
        y = Utility.roundFloat(y, 2)
        r = Utility.roundFloat(r, 2)
        g.circle(x, y, r).attr({
            "stroke": settings.lineColor,
            "strokeWidth": settings.wheelThickness,
            "fill": "none"
        })
    },

    applyRotation: (g, rotation) => {
        let measureG = g.getBBox()
        let currentMatrix = g.transform().localMatrix;
        let addMatrix = new Snap.Matrix()
        addMatrix.rotate(rotation, measureG.cx, measureG.cy)
        addMatrix.add(currentMatrix)
        g.transform(addMatrix)
    },

    applyTranslation: (g, xpos, ypos) => {
        let currentMatrix = g.transform().localMatrix;
        let addMatrix = new Snap.Matrix()
        addMatrix.translate(xpos, ypos)
        addMatrix.add(currentMatrix)
        g.transform(addMatrix)
    },

}
export default NatoUnitSymbols