import React, { useState, useEffect, useContext } from 'react'
import { StoreContext } from "../../context/StoreContext"
// import ApiHelper from "../../objects/ApiHelper"
import LayersOptions from '../LayersOptions/LayersOptions'
import Tabs from '../Tabs/Tabs'
import CounterMenuLayerOption from '../CounterMenuLayerOption/CounterMenuLayerOption'
import CounterMenuLayersOverlay from '../CounterMenuLayersOverlay/CounterMenuLayersOverlay'
// import Utility from "../../objects/Utility"
import './CounterMenu.scss'
const CounterMenu = () => {
    const { state } = useContext(StoreContext)
    const [overlayActive, setOverlayActive] = useState(false)
    const [tabs, setTabs] = useState([])

    useEffect(() => {
        setOverlayActive(false)
        // we need to redo the tabs if state.layers gets updated, cause the layerHidden value may change.
        // Cause we construct the layer option tabs in a function, it won't get re-setup if all we're doing 
        // is changing the hidden value.
        if (state.svgs.length > 0 && state.layers.length > 0) {
            setupTabs()
        }
    }, [state.layers]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (state.svgs.length > 0) {
            setupTabs()
        }
    }, [state.svgs]) // eslint-disable-line react-hooks/exhaustive-deps

    const setupTabs = () => {
        let tabObj = { tabTitle: 'menu' }
        tabObj.tabContents = state.layers.map(layer => layer.layerHidden ? '' : <CounterMenuLayerOption key={layer.layerKey} layer={layer} setOverlayActive={setOverlayActive} />).sort((a, b) => a.layerOrder - b.layerOrder)
        let _tabs = []
        _tabs.push(tabObj)

        tabObj = { tabTitle: 'show' }
        tabObj.tabContents = <LayersOptions />
        _tabs.push(tabObj)

        setTabs(_tabs)
    }

    return (
        state.svgs.length > 0 ?
            <div className="counter-menu">
                <Tabs tabs={tabs} />
                <CounterMenuLayersOverlay active={overlayActive} />
            </div>
            : ''
    );
}
export default CounterMenu