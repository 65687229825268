//eslint-disable-next-line
import React, { useContext, useEffect } from "react"
import { useErrorBoundary } from "use-error-boundary"
import { StoreContext } from "./context/StoreContext"
import Utility from "./objects/Utility"
import { useRoutes } from "hookrouter"
import routes from "./consts/routes"


import './App.css'

const App = () => {
  const { actions } = useContext(StoreContext)
  const controller = new AbortController();
  const { signal } = controller;
  const {
    ErrorBoundary,
    didCatch
  } = useErrorBoundary()

  useEffect(() => {
    actions.windowWidthHeight({ width: window.innerWidth, height: window.innerHeight })
    let os = Utility.detectOS()
    actions.os(os)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const handleResize = () => {
      actions.windowWidthHeight({ width: window.innerWidth, height: window.innerHeight })
      let ele = document.getElementById('counter')
      if (ele) {
        actions.counterDrawWidth(ele.offsetWidth);
      }
    }

    window.addEventListener('resize', () => handleResize(), { signal });

    return () => {
      controller.abort();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      const handleBlur = e => {
        actions.blur(e)
      }
      window.addEventListener('blur', handleBlur, { signal });
      return () => {
        controller.abort();
      };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      const handleFocus = e => {
        actions.focus(e)
      }
      window.addEventListener('focus', handleFocus, { signal });
      return () => {
        controller.abort();
      };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      const handleReturnKey = e => {
        if (13 === e.keyCode) {
          actions.returnKey(e)
        }
        else {
          actions.keyUp(e.keyCode)
        }
      }
      document.addEventListener("keyup", handleReturnKey, { signal });
      return () => {
        //document.removeEventListener("keyup", handleReturnKey);
        controller.abort();
      };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      const handleMouseDown = e => {
        actions.mouseDown(e)
      }
      document.addEventListener("mousedown", handleMouseDown, { signal });

      return () => {
        //document.removeEventListener("mousedown", handleMouseDown);
        controller.abort();
      };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      const handleMouseUp = e => {
        actions.mouseClick(e)
      }
      document.addEventListener("mouseup", handleMouseUp, { signal });

      return () => {
        //document.removeEventListener("mouseup", handleMouseUp);
        controller.abort();
      };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      const handleTouchStart = e => {
        actions.mouseClick(e)
      }
      document.addEventListener("touchstart", handleTouchStart, { signal });

      return () => {
        controller.abort();
      };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
      const handleDblClick = e => {
        actions.mouseDblClick(e)
      }
      document.addEventListener("dblclick", handleDblClick, { signal });

      return () => {
        //document.removeEventListener("mouseDblClick", handleDblClick);
        controller.abort();
      };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    window.addEventListener("error", function (e) {
      console.warn("Error occurred: " + e.error.message);
      return false;
   })

    const routeResult = useRoutes(routes);


    return (
      <>
        {didCatch ? (
          <p className="error-boundary">Some unrecoverable error has occurred.<br/> 
          Sorry about that, but this app is experimental, and stuff happens.<br/>
          You can try reloading the page a few times, or even closing your browser and restarting it.<br/> 
          If that doesn't work, then go to http://snapcounter.app?restore=true to restore the app to its defaults.</p>
        ) : (
          <ErrorBoundary>
            {routeResult}
          </ErrorBoundary>
        )}
      </>
    )


    
  }

  export default App;