import React, { useEffect, useRef, useContext, useState } from 'react'
import { StoreContext } from "../../context/StoreContext";
import Utility from "../../objects/Utility";
import { HexColorPicker, HexColorInput } from "react-colorful";
import './ColorPicker.scss'
const ColorPicker = () => {
    const { actions, state } = useContext(StoreContext)
    const hexColorContainerRef = useRef(null)
    const [timer, setTimer] = useState(null)

    useEffect(() => {
        if (state.colorPicker && state.colorPicker.x > -1) {
            hexColorContainerRef.current.style["left"] = parseInt(state.colorPicker.x) + 'px'
            hexColorContainerRef.current.style["top"] = parseInt(state.colorPicker.y) + 'px'
            let viewable = Utility.isElementOffScreen(hexColorContainerRef.current)
            hexColorContainerRef.current.style["top"] = parseInt(state.colorPicker.y + viewable.yAdjust) + 'px'
            hexColorContainerRef.current.style["left"] = parseInt(state.colorPicker.x + viewable.xAdjust) + 'px'
        }
        else {
            if (timer) {
                clearTimeout(timer)
                setTimer(null)
            }
        }
    }, [state.colorPicker]) // eslint-disable-line react-hooks/exhaustive-deps

    const setColor = (hexColor) => {

        const acceptColor = () => {
            actions.colorPicker({ x: state.colorPicker.x, y: state.colorPicker.y, hexColor })
            setTimer(null)
        }

        if (timer) {
            clearTimeout(timer)
        }
        setTimer(
            setTimeout(() => {
                acceptColor()
            }, 300)
        )
    }

    const clickOnColorPickerOverlay = () => {
        let colorPicker = {
            x: -1,
            y: -1,
            hexColor: '#000000'
        }
        actions.colorPicker(colorPicker)
    }

    const stopPropagation = (evt) => {
        evt.stopPropagation()
    }

    if (state.colorPicker && state.colorPicker.x > -1) {
        return (
            <div className="color-picker" onClick={clickOnColorPickerOverlay}>
                <div ref={hexColorContainerRef} className="hex-color-container" onClick={stopPropagation}>
                    <HexColorPicker color={state.colorPicker.hexColor === 'transparent' || Utility.validateHexColor(state.colorPicker.hexColor) ? state.colorPicker.hexColor : '#000000'} onChange={setColor} />
                    <div className="manual-color">
                        <div className="manual-label">hexadecimal: #</div>
                        {state.colorPicker.hexColor === 'transparent' ?
                            <HexColorInput disabled color='' onChange={setColor} />
                            :
                            <HexColorInput color={Utility.validateHexColor(state.colorPicker.hexColor) ? state.colorPicker.hexColor : '#000000'} onChange={setColor} />
                        }
                    </div>
                </div>
            </div>
        )
    }
    else {
        return ' '
    }
}
export default ColorPicker