import React, { useState, useEffect, useContext, useRef } from 'react'
import { StoreContext } from "../../context/StoreContext"
import ApiHelper from "../../objects/ApiHelper"
import Utility from "../../objects/Utility"
import './Server.scss'
const Server = () => {
    const { state, actions } = useContext(StoreContext)
    const [opened, setOpened] = useState(false)
    const [serverDialogOpened, setServerDialogOpened] = useState(false)
    const [dialogOption, setDialogOption] = useState('restore')
    const [updateProcessMode, setUpdateProcessMode] = useState('initial')
    const [svgsToAdd, setSvgsToAdd] = useState([])
    const [layersToUpdate, setLayersToUpdate] = useState([])
    const [updateInfoSvgNames, setUpdateInfoSvgNames] = useState('')
    const [updateInfoLayerNames, setUpdateInfoLayerNames] = useState('')
    const [updateAvailableNotice, setUpdateAvailableNotice] = useState(false)

    const [updateLayerData, setUpdateLayerData] = useState([])
    const [updateSvgData, setUpdateSvgData] = useState([])

    // const [serverCheckForUpdateOpened, setCheckForUpdateDialogOpened] = useState(false)
    const dropDownRef = useRef(null)
    const serverDialogRef = useRef(null)

    const checkForUpdate = () => {
        // let clientLastSvgKey = Math.max(...state.svgs.map(s => { return s.svgKey }))
        // for ww2vehicleSvgs, I used values 700 and up (to about 735 at the point of me writing this comment)
        // Theres probably no one who has added more than 900 custom svgs or images, so this should be safe.
        // There may be one or two nuts who did though. But, oh well. They probably don't know what they're
        // doing anyways. I could have just jacked up the svgKeys to like starting at 2000, but, I'm not sure
        // what method is best. I did the 700-735, and it would be a pain to re-index everything. Alot of work
        // for something that probably is just fine the way I did it anyways.
        // An improvement here, though, is I'm check for each ww2vehicle svgKey, in case for some reason one
        // of them got knocked out somehow, or the user mucked around in the inspector and deleted entries.
        // Probably overkill, but, I already coded it in, so lets run with it.
        // Gather up all existing svgs on the client with svgKeys from 700 to 735
        let ww2vehicleSvgs = state.svgs.filter( svg => svg.svgKey > 699 && svg.svgKey < 747 )
        // and hard-coded here are the svgKeys we should have. Yes its sequencial with no gaps, but ... perhaps in the 
        // future I may have "holes" in the list of svgs.
        // SQL:
        // select STRING_AGG(cast("svgKey" as varchar), ','  order by "svgKey") 
        // from "Svg" where "svgKey" > 699 and "svgKey" < 800
        let expectedSvgs = [700,701,702,703,704,705,706,707,708,709,710,711,712,713,714,715,716,717,718,719,720,721,722,723,724,725,726,727,728,729,730,731,732,733,734,735,736,737,738,739,740,741,742,743,744,745,746]
        ww2vehicleSvgs.forEach( svg => {
           let _svgKey = svg.svgKey
           expectedSvgs = expectedSvgs.filter( esvg => Number(esvg) !== Number(_svgKey) )
        })
        if( expectedSvgs.length > 0 ) {
            setUpdateAvailableNotice(true)
        }          
        // see if the ww2vehicles layer is present at the client. No need to check for duplicates of the layer, since, if the client
        // doesnt have it, they couldnt have possibly made duplicates. And the chance they created a layer with this name is highly
        // unlikely. I'm not going to spend hours on code for the 0.001% chance someone did. My user base is like 20, not a million.
        let ww2vehiclesLayerFound = state.layers.find( sl => sl.layerName === 'ww2 vehicles' )
        if( !ww2vehiclesLayerFound ) {
             setUpdateAvailableNotice(true)
        }
        // ok, if we detected an update is available, this only turns on an indicator in the drop down. If the user actually
        // wants to update - that'll be handled when the user clicks updateCheckAtServer
    }

    useEffect(() => {
        // prevent overlay from knowing the mouse was clicked, if it was clicked inside the drop-down area.
        let copyOfRef = dropDownRef.current // linter said I should copy this to a variable so the cleanup function can be reliable.
        dropDownRef.current.addEventListener('mouseup', function (e) {
            e.stopPropagation();
        });
        let copyOfServerDialogRef = serverDialogRef.current // linter said I should copy this to a variable so the cleanup function can be reliable.
        serverDialogRef.current.addEventListener('mouseup', function (e) {
            e.stopPropagation();
        });

        checkForUpdate()

        return () => {
            if (copyOfRef) {
                copyOfRef.removeEventListener('mouseup', function (e) {
                    e.stopPropagation();
                });
            }
            if (copyOfServerDialogRef) {
                copyOfServerDialogRef.removeEventListener('mouseup', function (e) {
                    e.stopPropagation();
                });
            }
        }

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (opened) {
            if (state.mouseClick.target.id === 'server-opener') {
                return
            }
            setOpened(false)
        }
    }, [state && state.mouseClick]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleOpen = () => {
        if (serverDialogOpened) {
            setServerDialogOpened(false)
            actions.overlay(false)
            return
        }
        if (!opened) {
            actions.overlay(true)
        }
        setOpened(!opened)
    }

    useEffect(() => {
        if (state.overlay === false) {
            setTimeout(() => {
                setServerDialogOpened(false)
            }, 0)
        }
    }, [state.overlay])

    useEffect(() => {
        //actions.overlay(opened)
    }, [opened]) // eslint-disable-line react-hooks/exhaustive-deps

    const openOption = evt => {
        let targetted = null
        if (evt.target && evt.target.id) {
            targetted = evt.target.id
        }
        if (targetted === 'displayRestoreDatabase') {
            setServerDialogOpened(true)
            setDialogOption('restore')
            setOpened(false)
            return
        }
        if (targetted === 'displayCheckForUpdates') {
            setServerDialogOpened(true)
            setDialogOption('update')
            setOpened(false)
            return
        }
        actions.dataDisplay('')
        actions.installView('')
        setServerDialogOpened(false)
    }

    useEffect(() => {
        if (serverDialogOpened) {
            setOpened(false)
        }
    }, [serverDialogOpened])

    const cancel = () => {
        setServerDialogOpened(false)
        actions.overlay(false)
    }

    const cancelUpdates = () => {
        setSvgsToAdd([])
        setLayersToUpdate([])
        setUpdateProcessMode('initial')
        cancel()
    }

    const restoreDatabase = () => {
        actions.restoreDatabase(true)
        setServerDialogOpened(false)
    }

    const updateCheckAtServer = async () => {
        setUpdateProcessMode('processing')
        let defaultLayers = state.layers.filter(ly => ly.parentLayerKey === -1 && ly.layerName.startsWith('custom') === false)
        let clientLayerSvgLists = []
        defaultLayers.forEach(dl => {
            let inputs = dl.inputs
            let svgSelectInput = inputs.find(inp => inp.named === 'svgKey')
            if (svgSelectInput) {
                clientLayerSvgLists.push({ layerName: dl.layerName, list: svgSelectInput.list })
            }
        })

       // let clientLastSvgKey = Math.max(...state.svgs.map(s => { return s.svgKey }))
        let clientUpdateRequest = {
            clientLayerSvgLists,
         //   clientLastSvgKey
        }

        const checkForUpdate = async (data) => {
            let response = await ApiHelper.checkForUpdate(data)
            if (response) {
                return await response
            }
            else {
                let message = 'unknown'
                if (response) {
                    message = response
                }
                if (response && response.status) {
                    message = response.status
                }
                console.warn('error on fetch, ', message)
            }
        }

       // clientUpdateRequest looks like:
       // clientLastSvgKey: 1081
       // clientLayerSvgLists: Array(5)
       //   0: {layerName: 'NATO unit symbols', list: Array(43)}  -list is cdl of svgKeys like [2,3,4,11,12...]
       //   1: {layerName: 'unit size', list: Array(12)}
       //   2: {layerName: 'misc unit symbols', list: Array(14)}
       //   3: {layerName: 'common symbols', list: Array(10)}
       //   4: {layerName: 'ww2 vehicles', list: Array(46)}

        let response = await checkForUpdate(clientUpdateRequest)
        let layerData = response.data ? response.data : null
        let newSvgData = response.newSvgsData ? response.newSvgsData : null
        let update = response.update ? response.update : null
        if( layerData.length === 0 && newSvgData.length === 0 && update.length === 0 ) {
            setUpdateProcessMode('noUpdateAvailable')
        }
        else {
            let layerNames = update.map( upl => upl.layerName )
            let svgNames = newSvgData.map( nsd => nsd.svgName )

            setUpdateInfoLayerNames(layerNames.join(', '))
            setUpdateInfoSvgNames(svgNames.join(', '))
            setUpdateProcessMode('updateAvailable')
            setUpdateLayerData(layerData)
            setUpdateSvgData(newSvgData)
        }

        // const [svgsToAdd, setSvgsToAdd] = useState([])
        // const [layersToUpdate, setLayersToUpdate] = useState([])
        // const [updateInfoSvgNames, setUpdateInfoSvgNames] = useState('')
        // const [updateInfoLayerNames, setUpdateInfoLayerNames] = useState('')



        // let svgNames = []
        // let layerNames = []
        // let noUpdates = true
        // if (response) {
        //     if (response.newSvgs && Array.isArray(response.newSvgs) && response.newSvgs.length > 0) {
        //         response.newSvgs.forEach(ns => svgNames.push(ns.svgName))
        //         setSvgsToAdd(response.newSvgs)
        //         noUpdates = false
        //         //addSvgs(response.newSvgs)
        //     }
        //     if (response.updateLayers && Array.isArray(response.updateLayers) && response.updateLayers.length > 0) {
        //         response.updateLayers.forEach(ul => {
        //             let ly = state.layers.find(sl => sl.layerKey === ul.layerKey)
        //             if (ly) {
        //                 layerNames.push(ly.layerName)
        //             }
        //         })
        //         setLayersToUpdate(response.updateLayers)
        //         noUpdates = false
        //         //updateLayers(response.updateLayers)
        //     }
        //     if (noUpdates) {
        //         setUpdateProcessMode('noUpdateAvailable')
        //     }
        //     setUpdateInfoSvgNames(svgNames.join(', '))
        //     setUpdateInfoLayerNames(layerNames.join(', '))
        // }
    }

    useEffect(() => {
        if (svgsToAdd.length > 0 || layersToUpdate.length > 0) {
            setUpdateProcessMode('updateAvailable')
        }
    }, [svgsToAdd, layersToUpdate])

    const installUpdate = async () => {
        setUpdateProcessMode('updateInstalling')
        await addSvgs(updateSvgData)
        await updateLayers(updateLayerData)
        setUpdateInfoLayerNames('')
        setUpdateInfoSvgNames('')
        setLayersToUpdate([])
        setSvgsToAdd([])
        setUpdateProcessMode('updateInstalled')
        setUpdateAvailableNotice(false)
    }

    const addSvgs = async (svgs) => {
        for (let i = 0; i < svgs.length; i++) {
            svgs[i].svgCode = svgs[i].svgCode.replace(/\s+/g, ' ').trim()
            await Utility.dexieAddPutItem(state.dexie, "svgs", svgs[i], "svgKey")
            let found = state.svgs.find(s => s.svgKey === svgs[i].svgKey)
            if (!found) {
                actions.svgsAdd(svgs[i])
            }
        }
    }

    const updateLayers = async (layers) => {
        for (let i = 0; i < layers.length; i++) {
            let layerFromDexie = await Utility.getDexieStoreSingleItem(state.dexie, "layers", layers[i].layerKey, "layerKey")
            if (layerFromDexie) {
                let inputs = layerFromDexie.inputs
                for (let n = 0; n < inputs.length; n++) {
                    let input = inputs[n]
                    if (input.named === 'svgKey') {
                        layerFromDexie.inputs[n].list = layers[i].list
                        //await Utility.dexiePutItem(state.dexie, "layers", layerFromDexie, "layerKey")
                        await Utility.updateDexieStoreSingleItem(state.dexie, 'layers', layerFromDexie, 'layerKey')
                        let stateLayer = state.layers.find(sl => sl.layerKey === layers[i].layerKey)
                        let updatedLayer = { ...stateLayer, inputs }
                        actions.layerUpdate(JSON.parse(JSON.stringify(updatedLayer)))
                        break
                    }
                }
            }
            else { // new layer
                await Utility.dexieAddItem(state.dexie, 'layers', layers[i])
                actions.addLayer(layers[i])
            }
        }
    }

    return (
        <div className={opened ? 'server opened' : 'server'}>
            <div id="server-opener" className="opener" onClick={handleOpen}>Server<span className={updateAvailableNotice ? '' : 'display-none'}>
                <svg height="15" width="15">
                    <circle cx="8" cy="8" r="6.4" stroke="black" strokeWidth="0.48" fill="red" />
                    <path d="M8 3.6 L 8 9" stroke="white" strokeWidth="1.8" />
                    <circle cx="8" cy="11.52" r="1.1" fill="white" stroke="none" />
                </svg>
            </span></div>
            <div ref={dropDownRef} className="drop-down">
                <div id="displayRestoreDatabase" onClick={openOption}><span>restore database</span></div>
                <div id="displayCheckForUpdates" onClick={openOption}><span className={updateAvailableNotice ? 'alert' : ''}>check for updates</span></div>
            </div>

            <div ref={serverDialogRef} id="server-dialog" className={serverDialogOpened ? 'server-dialog' : 'display-none'}>
                <div id="dialog-option-restore" className={dialogOption === 'restore' ? 'restore' : 'display-none'}>
                    <div className="option-title">restore database</div>
                    <div className="warning-title">WARNING</div>
                    <ul>
                        <li>Restoring the database will erase the current Snapcounter database you have on your
                            computer.</li>
                        <li>Restoring the database will download the default database from the server, and
                            restore the default Snapcounter database to your computer.</li>
                        <li>Any custom svgs, pngs, fonts, and any other custom changes you may have made,
                            will be lost.</li>
                        <li>If you try to load any saved counters or sheets, that require data such as custom svgs,
                            pngs, fonts, or custom duplicated layers, they will not display correctly.</li>
                        <li>You probably shouldn't be doing this, unless there are some problems with the app that
                            you think may only be fixed by restoring the database back to the default.</li>
                    </ul>
                    <div className="action-buttons">
                        <button className="action-button orangish" onClick={restoreDatabase}>Restore database</button>
                        <button className="action-button yellowish" onClick={cancel}>cancel</button>
                    </div>
                </div>

                <div id="dialog-option-update" className={dialogOption === 'update' ? 'update' : 'display-none'}>

                    <div className={updateProcessMode === 'initial' ? '' : 'display-none'}>
                        <div className="option-title">check for updates</div>
                        <div className="update-text">Click "check for updates" to see if there are updates available
                            at the server.
                        </div>
                        <div className="action-buttons">
                            <button className="action-button cyanish" onClick={updateCheckAtServer}>check for updates</button>
                            <button className="action-button yellowish" onClick={cancel}>cancel</button>
                        </div>
                    </div>

                    <div className={updateProcessMode === 'processing' ? '' : 'display-none'}>
                        <div className="option-title">check for updates</div>
                        <div className="update-text">Contacting server...</div>

                        <div className="action-buttons">
                            <button className="action-button disabled">check for updates</button>
                            <button className="action-button yellowish" onClick={cancel}>cancel</button>
                        </div>
                    </div>

                    <div className={updateProcessMode === 'noUpdateAvailable' ? '' : 'display-none'}>
                        <div className="option-title">check for updates</div>
                        <div className="update-text">There is no update available</div>

                        <div className="action-buttons">
                            <button className="action-button disabled">check for updates</button>
                            <button className="action-button yellowish" onClick={cancel}>exit</button>
                        </div>
                    </div>

                    <div className={updateProcessMode === 'updateAvailable' ? '' : 'display-none'}>
                        <div className="option-title">check for updates</div>
                        <div className="updates-available">There are updates available:</div>
                        {updateInfoLayerNames ? <div className="update-info"><div>menu items:</div><div>{updateInfoLayerNames}</div></div> : ''}
                        {updateInfoSvgNames ? <div className="update-info svg"><div>svg items:</div><div>{updateInfoSvgNames}</div></div> : ''}

                        <div className="action-buttons">
                            <button className="action-button orangish" onClick={installUpdate}>install updates</button>
                            <button className="action-button yellowish" onClick={cancelUpdates}>cancel</button>
                        </div>
                    </div>

                    <div className={updateProcessMode === 'updateInstalling' ? '' : 'display-none'}>
                        <div className="option-title">check for updates</div>
                        <div className="updates-available">Installing updates..</div>

                        <div className="action-buttons">
                            <button className="action-button disabled" onClick={installUpdate}>install updates</button>
                            <button className="action-button yellowish" onClick={cancelUpdates}>cancel</button>
                        </div>
                    </div>

                    <div className={updateProcessMode === 'updateInstalled' ? '' : 'display-none'}>
                        <div className="option-title">check for updates</div>
                        <div className="updates-available">Updates have been installed!</div>

                        <div className="action-buttons">
                            <button className="action-button yellowish" onClick={cancel}>exit</button>
                        </div>
                    </div>

                </div>





            </div>
        </div>
    );
}
export default Server
