import React, { useEffect, useContext } from 'react'
import { StoreContext } from "../../context/StoreContext"
import Utility from "../../objects/Utility";
import './LayersOptions.scss'
const LayersOptions = () => {
    const { state, actions } = useContext(StoreContext)

    useEffect(() => {
        // for some reason, I couldnt setup the checkboxes with checked={ly.layerHidden}. It would just make the checkbox unresponsive.

    }, [])  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        state.layers.forEach(ly => {
            let layerKey = ly.layerKey
            let layerIsVisible = !ly.layerHidden
            let el = document.getElementById('checkbox_hide_' + layerKey)
            let isCustomSvgsLayer = Utility.isCustomSvgsLayer(layerKey, state.layers)
            if (isCustomSvgsLayer) {
                let countOfCustomItems = Utility.customItemsCount('custom svgs', state.layers, state.svgs)
                if (countOfCustomItems === 0) {
                    el.disabled = true
                }
                else {
                    el.disabled = false
                }
            }
            let isCustomImagesLayer = Utility.isCustomImagesLayer(layerKey, state.layers)
            if (isCustomImagesLayer) {
                let countOfCustomItems = Utility.customItemsCount('custom images', state.layers, state.svgs)
                if (countOfCustomItems === 0) {
                    el.disabled = true
                }
                else {
                    el.disabled = false
                }
            }
            if (layerIsVisible) {
                el.checked = true
            }
        })
    }, [state.layers])  // eslint-disable-line react-hooks/exhaustive-deps

    const onChangeHide = evt => {
        if (evt.target && evt.target.id) {
            let layerKey = evt.target.id.replace(/[^0-9]/g, '');
            if (layerKey) {
                let targetLayer = state.layers.find(ly => ly.layerKey === Number(layerKey))
                if (targetLayer) {
                    targetLayer.layerHidden = targetLayer.layerHidden === 0 ? 1 : 0
                    actions.layerUpdate(targetLayer)
                    Utility.updateDexieStoreSingleItem(state.dexie, 'layers', targetLayer, 'layerKey')
                }
            }
        }
    }

    return (
        <div className="layers-options">
            <div className="grid-table">
                <div className="grid-item header">show</div>
                <div className="grid-item header">layer name</div>
                {state.layers.map(ly => (
                    <React.Fragment key={ly.layerKey}>
                        <div className="grid-item">
                            <input id={`checkbox_hide_${ly.layerKey}`} className="show-hide-checkbox" type="checkbox" onChange={onChangeHide} />
                        </div>
                        <div className={ly.layerHidden ? 'grid-item hidden' : 'grid-item'}>{ly.layerName}</div>
                    </React.Fragment>
                ))}
            </div>
        </div>
    )
}
export default LayersOptions

/*
            <div className="grid-table">
                <div className="grid-item header">layer name</div>
                <div className="grid-item header">hidden</div>
                <div className="grid-item header">order</div>

                {sortedByOrderLayers.map(ly => {
                    return <React.Fragment key={ly.layerKey}>
                        <div className={ly.layerHidden ? 'grid-item left-align hidden' : 'grid-item left-align'}>{ly.layerName}</div>
                        <div className="grid-item">
                            {ly.layerName !== 'base counter' ?
                                <SliderComponent forKey={ly.layerKey} option1='yes' option2='no' checked={ly.layerHidden === 1} onChange={changeLayerHidden} />
                                : 'can not hide'}
                        </div>
                        <div className="grid-item">
                            <span>{ly.layerOrder}</span>
                            {ly.layerName !== 'base counter' ?
                                <select id={`orderLayerKey${ly.layerKey}`} value={ly.layerOrder} onChange={changeOrder}>
                                    {
                                        orderOptions
                                    }
                                </select>
                                : ''}
                        </div>
                    </React.Fragment>
                })}

            </div>
*/