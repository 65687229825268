import React, { useState, useEffect, useContext, useRef } from 'react'
import { StoreContext } from "../../context/StoreContext"
import XButton from '../XButton/XButton'
import Utility from "../../objects/Utility"
import './InstallFont.scss'
// admin import ApiHelper from "../../objects/ApiHelper"
const InstallFont = () => {
    const [fontFamily, setFontFamily] = useState('')
    const [fetchResult, setFetchResult] = useState('')
    const [fontUrl, setFontUrl] = useState('')
    const [fontUrlUsed, setFontUrlUsed] = useState('')
    const [fontObj, setFontObj] = useState(null)
    const { state, actions } = useContext(StoreContext)
    const [errorOnFontUrl, setErrorOnFontUrl] = useState(false)
    const [errorOnFontUrlMessage, setErrorOnFontUrlMessage] = useState('')
    const [charactersNeeded, setCharactersNeeded] = useState('')
    const [fontInstallationSuccessful, setFontInstallationSuccessful] = useState(false)

    const installFontRef = useRef(null)

    useEffect(() => {
        let copyOfRef = installFontRef.current // linter said I should copy this to a variable so the cleanup function can be reliable.
        installFontRef.current.addEventListener('mouseup', function (e) {
            e.stopPropagation();
        });

        return () => {
            if (copyOfRef) {
                copyOfRef.removeEventListener('mouseup', function (e) {
                    e.stopPropagation();
                });
            }
        }

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        closeAction()
    }, [state.mouseClick]) // eslint-disable-line react-hooks/exhaustive-deps




    let uppercaseCharacters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    let lowercaseCharacters = 'abcdefghijklmnopqrstuvwxyz'
    let numbers = '0123456789'
    let punctuationCharacters = '`~@#$%^&*()_-+={}[]|"\'<>,.?/'

    const closeAction = () => {
        exitFontInstallation()
    }

    useEffect(() => {
        if (state.installView !== 'install font') {
            setFontInstallationSuccessful(false)
            setCharactersNeeded('')
            setErrorOnFontUrlMessage('')
            setErrorOnFontUrl('')
            setFontObj(null)
            setFontUrlUsed('')
            setFontUrl('')
            setFetchResult('')
            setFontFamily('')
        }
    }, [state.installView])  // eslint-disable-line react-hooks/exhaustive-deps

    const exitFontInstallation = () => {
        if (state.installView) {
            window.scrollTo(0, 0)
            setFontInstallationSuccessful(false)
            setCharactersNeeded('')
            setErrorOnFontUrlMessage('')
            setErrorOnFontUrl('')
            setFontObj(null)
            setFontUrlUsed('')
            setFontUrl('')
            setFetchResult('')
            setFontFamily('')
            let charactersNeededArray = ['uppercase', 'lowercase', 'numbers', 'punctuation']
            charactersNeededArray.forEach(id => {
                let ele = document.getElementById(id)
                if (ele) {
                    ele.checked = false
                }
            })
        }
        actions.installView('')
    }

    const tryAgain = () => {
        if (state.installView !== '') {
            window.scrollTo(0, 0)
            setFontInstallationSuccessful(false)
            setErrorOnFontUrlMessage('')
            setErrorOnFontUrl('')
            setFontObj(null)
            setFetchResult('')
        }
    }

    const installAnother = () => {
        if (state.installView !== '') {
            window.scrollTo(0, 0)
            setFontInstallationSuccessful(false)
            setCharactersNeeded('')
            setErrorOnFontUrlMessage('')
            setErrorOnFontUrl('')
            setFontObj(null)
            setFontUrlUsed('')
            setFontUrl('')
            setFetchResult('')
            setFontFamily('')
        }
    }

    const changeFontUrl = evt => {
        setErrorOnFontUrlMessage('')
        setErrorOnFontUrl(false)
        let _fontUrl = evt.target.value
        setFontUrl(_fontUrl)
    }

    const submitFontUrl = evt => {
        if (isValidUrl(fontUrl) === false) {
            setErrorOnFontUrl(true)
            return
        }
        let _charactersNeeded = ''
        let characterRestrictedFontUrl = fontUrl
        fontUrl.replace('&display=swap', '')
        if (fontUrl.includes('family=') === false) {
            setErrorOnFontUrlMessage('The url does not seem to have a font reference.')
            setErrorOnFontUrl(true)
            return
        }

        if ((fontUrl.match(/family=/g) || []).length > 1) {
            setErrorOnFontUrlMessage('The url has more than one font reference. Please only add 1 font at a time.')
            setErrorOnFontUrl(true)
            return
        }


        let separater = '&'
        if (characterRestrictedFontUrl.includes('?') === false) {
            separater = '?'
        }
        if (charactersNeeded === '') {

            _charactersNeeded = uppercaseCharacters + lowercaseCharacters + numbers + punctuationCharacters
            setCharactersNeeded(_charactersNeeded)

            let urlEncodedCharactersNeeded = uppercaseCharacters + lowercaseCharacters + numbers + encodeURIComponent(punctuationCharacters)
            characterRestrictedFontUrl += separater + 'text=' + urlEncodedCharactersNeeded
        }
        else {
            let urlEncodedCharactersNeeded = charactersNeeded.replace(/\s+/g, '') // remove any spaces or line feeds the user may have inserted.
            urlEncodedCharactersNeeded = encodeURIComponent(urlEncodedCharactersNeeded)
            characterRestrictedFontUrl += separater + 'text=' + urlEncodedCharactersNeeded
        }


        let textCount = characterRestrictedFontUrl.match(/text=/g).length;
        if (textCount > 1) {
            characterRestrictedFontUrl = Utility.replaceLast(characterRestrictedFontUrl, '&text=', '')
        }
        if (isValidUrl(characterRestrictedFontUrl) === false) {
            setErrorOnFontUrl(true)
            return
        }
        setErrorOnFontUrl(false)
        if (characterRestrictedFontUrl === fontUrlUsed) {
            // user may just be resubmitting the same url again
            goGetIt(fontUrlUsed)
        }
        setFontUrlUsed(characterRestrictedFontUrl)
        //goGetIt(characterRestrictedFontUrl)
    }

    useEffect(() => {
        if (fontUrlUsed) {
            goGetIt(fontUrlUsed)
        }
    }, [fontUrlUsed]) // eslint-disable-line react-hooks/exhaustive-deps

    const errorHandler = (e) => {
        setFontUrlUsed('')
        if (e && e.message) {
            setErrorOnFontUrlMessage(e.message)
        }
        setErrorOnFontUrl(true)
    }

    const goGetIt = (url) => {
        fetchCSS(url)
            .then(embedFonts)
            .then(outputResult)
            .catch(errorHandler)
    }

    function fetchCSS(url) {
        return fetch(url).then(function (res) {
            return res.text()
        }, errorHandler)
    }

    function embedFonts(cssText) {
        var fontLocations = cssText.match(/https:\/\/[^)]+/g)
        var fontLoadedPromises = fontLocations.map(function (location) {
            return new Promise(function (resolve, reject) {
                fetch(location).then(function (res) {
                    return res.blob()
                }).then(function (blob) {
                    var reader = new FileReader()
                    reader.addEventListener('load', function () {
                        // Side Effect
                        cssText = cssText.replace(location, this.result)
                        resolve([location, this.result])
                    })
                    reader.readAsDataURL(blob)
                }).catch(reject)
            })
        })
        return Promise.all(fontLoadedPromises).then(function () {
            return cssText
        })
    }

    const cleanResult = (str, trimOnly = false) => {
        let strResult = str
        if (!trimOnly) {
            strResult = strResult.replace(/\s+/g, '')
        }
        else {
            strResult = strResult.trim()
        }
        strResult = strResult.replaceAll("'", '')
        strResult = strResult.replaceAll(';', '')
        return strResult
    }

    const outputResult = (cssText) => {
        let arr = cssText.split("\n")
        let _fontObj = {
            fontFamily: '',
            fontStyle: '',
            fontWeight: '',
            fontSrc: ''
        }
        arr.forEach((e, i) => {
            if (e.includes('src:')) {
                let srcString = e;
                srcString = srcString.replace('src:', '')
                srcString = srcString.trim()
                _fontObj.fontSrc = srcString
            }
            else {
                let pair = e.split(':')
                if (pair[0].includes('font-family')) {
                    let val = pair[1]
                    if (val) {
                        _fontObj.fontFamily = cleanResult(val, true)
                    }
                }
                if (pair[0].includes('font-style')) {
                    let val = pair[1]
                    if (val) {
                        _fontObj.fontStyle = cleanResult(val)
                    }
                }
                if (pair[0].includes('font-weight')) {
                    let val = pair[1]
                    if (val) {
                        _fontObj.fontWeight = cleanResult(val)
                    }
                }
            }
        })
        if (_fontObj && _fontObj.fontFamily) {
            // add the url used
            _fontObj.fontUrl = fontUrlUsed
            setFontObj(_fontObj)
        }
        else {
            setFontObj(null)
            fontUrlUsed('')
        }
    }

    const changeCharactersNeeded = evt => {
        let value = evt.target.value
        setCharactersNeeded(value)
    }

    const addGlyphs = evt => {
        let typeGlyphs = evt.target.id
        let checked = evt.target.checked
        let glyphs = ''

        if (typeGlyphs === 'uppercase') {
            glyphs = uppercaseCharacters
        }
        if (typeGlyphs === 'lowercase') {
            glyphs = lowercaseCharacters
        }
        if (typeGlyphs === 'numbers') {
            glyphs = numbers
        }
        if (typeGlyphs === 'punctuation') {
            glyphs = punctuationCharacters
        }
        let currentValue = charactersNeeded
        if (checked) {
            if (currentValue.includes(glyphs) === false) {
                currentValue += glyphs
            }
        }
        else {
            for (let char of glyphs) {
                currentValue = currentValue.replaceAll(char, '')
            }
        }

        setCharactersNeeded(currentValue)
    }

    const isValidUrl = urlString => {
        if (urlString.length < 20 || urlString.indexOf('.') < 11) {
            return false
        }
        try {
            return Boolean(new URL(urlString));
        }
        catch (e) {
            return false;
        }
    }

    const sufixes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const getBytes = (bytes) => {
        const i = Math.floor(Math.log(bytes) / Math.log(1024));
        return (!bytes && '0 Bytes') || ((bytes / Math.pow(1024, i)).toFixed(2) + " " + sufixes[i]);
    };

    const formattedSize = str => {
        if (typeof str !== 'string') {
            return '0'
        }
        return getBytes(str.length)
    }

    const doInstallFont = () => {
        if (fontObj) {
            setFontFamily(fontObj.fontFamily)
            // prepare object for what we need
            let stringToEncodeRange = charactersNeeded.replace(/\s+/g, '')
            let unicodeRanges = generateUnicodeRanges(stringToEncodeRange)
            if (unicodeRanges) {
                unicodeRanges = unicodeRanges.replaceAll(',', ', ')
            }
            let obj = {
                fontFamily: fontObj.fontFamily,
                fontStyle: fontObj.fontStyle,
                fontWeight: Number(fontObj.fontWeight),
                fontSrc: fontObj.fontSrc,
                fontUrl: fontObj.fontUrl,
                fontUnicodeRange: unicodeRanges,
                fontText: stringToEncodeRange
            }
            // only when posting to server, postFont(obj)
            processFont(obj)
        }
    }

    // this is for posting font to server
    // const postFontToServer = async (fontObj) => {
    //     let response = await ApiHelper.fontPost({ ...fontObj })
    //     if (response) {
    //         return await response
    //     }
    //     else {
    //         let message = 'unknown'
    //         if( response ) {
    //             message = response
    //         }
    //         if( response && response.status ) {
    //             message = response.status
    //         }
    //         console.warn('error on fetch, ', message)
    //     }
    // }

    const processFont = async (_fontObj) => {
        addFontToDocument(_fontObj)
        await addFontToDexie(_fontObj)
        // below - admin only 
        //await postFontToServer(_fontObj)
        addFontToState(_fontObj)
        // set example text so user can see if the font really got installed.
        let ele = document.getElementById('testFont')
        if (ele) {
            ele.style.fontFamily = _fontObj.fontFamily
            ele.style.fontStyle = _fontObj.fontStyle
            ele.style.fontWeight = _fontObj.fontWeight
        }
        setFontInstallationSuccessful(true)
    }

    const addFontToDocument = async (_fontObj) => {
        let link = document.createElement('link')
        link.href = _fontObj.fontUrl;
        link.rel = "stylesheet";
        link.type = "text/css";
        link.id = _fontObj.fontFamily.replaceAll(' ', '_') + '@' + _fontObj.fontStyle + '@' + _fontObj.fontWeight
        document.head.appendChild(link);
    }

    const addFontToDexie = async (_fontObj) => {
        // install to dexie
        let obj = {
            fontFamily: _fontObj.fontFamily,
            fontStyle: _fontObj.fontStyle,
            fontWeight: Number(_fontObj.fontWeight),
            fontSrc: _fontObj.fontSrc,
            fontUrl: _fontObj.fontUrl,
            fontUnicodeRange: _fontObj.fontUnicodeRange,
            fontText: _fontObj.fontText
        }

        await Utility.dexieAddPutFont(state.dexie, obj)
    }

    const addFontToState = _fontObj => {
        // install to state. Dont put fontSrc into state, its too big.xxx
        let obj = { ..._fontObj }
        delete obj.fontSrc
        let fonts = [...state.fonts]
        let existingIndex = fonts.findIndex(ft => ft.fontFamily === obj.fontFamily)
        if (existingIndex >= 0) {
            fonts[existingIndex] = obj
        }
        else {
            fonts.push(obj)
        }
        actions.fonts(fonts)
    }

    const convertToUnicode = int => {
        let unicode = ''
        if (int) {
            unicode = int.toString(16)
            unicode = 'U+' + unicode
        }
        return unicode
    }

    const generateUnicodeRanges = str => {
        if (!str || typeof str !== 'string') {
            return ''
        }
        let integerCodes = []
        for (let i = 0; i < str.length; i++) {
            let char = str.charCodeAt(i)
            integerCodes.push(char)
        }
        integerCodes = integerCodes.sort(function (a, b) { return a - b })
        let range = []
        let ranges = []
        for (let index = 0; index < integerCodes.length; index++) {
            let int = integerCodes[index]
            if (index === 0) {
                range.push(int)
                // if there is only one character in the array, just end it now.
                if (index === integerCodes.length - 1) {
                    range.push(int)
                    ranges.push(range)
                }
                continue
            }
            if ((int === integerCodes[index - 1] + 1)) { // if this int is one more than what we looked at previously, we are ranging.

                // if we are at the end of the array, just push the last value in.
                if (index === integerCodes.length - 1) {
                    range.push(int)
                    ranges.push(range)
                }
                continue
            }
            else {
                // we have a break in the range
                range.push(integerCodes[index - 1]) // put the previous int (that was ranging) into the range holding array.
                ranges.push(range)
                range = [] // and reset range to empty
                range.push(int) // and push the new starting for the next range in.
                // if we are at the end of the array, just push the last value in.
                if (index === integerCodes.length - 1) {
                    ranges.push(range)
                }
            }
        }
        let unicodeRanges = []
        ranges.forEach(range => {
            if (range.length === 1) {
                let rangeStart = range[0]
                let unicodeStart = convertToUnicode(rangeStart)
                unicodeRanges.push(unicodeStart)
            }
            if (range.length === 2) {
                let rangeStart = range[0]
                let unicodeStart = convertToUnicode(rangeStart)
                let rangeEnd = range[1]
                let unicodeEnd = convertToUnicode(rangeEnd)
                if (unicodeStart === unicodeEnd) {
                    unicodeRanges.push(unicodeStart)
                }
                else {
                    unicodeRanges.push(unicodeStart + '-' + unicodeEnd.replace('U+', ''))
                }
            }
        })
        let unicodeString = unicodeRanges.join(',') + ';'
        return unicodeString
    }

    /* for reference
    (11) [{…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}, {…}]
0: {fontFamily: 'ABeeZee', fontStyle: 'normal', fontText: null, fontUnicodeRange: null, fontUrl: 'https://fonts.googleapis.com/css2?family=ABeeZee', …}
1: {fontFamily: 'Anek Telugu', fontStyle: 'normal', fontText: null, fontUnicodeRange: null, fontUrl: 'https://fonts.googleapis.com/css2?family=Anek+Telu…pqrstuvwxyz0123456789`~@#$%^&*()_-+={}[]|"\'<>,.?/', …}
2: {fontFamily: 'Germania One', fontStyle: 'normal', fontWeight: 400, fontUrl: "https://fonts.googleapis.com/css2?family=Germania+…%26*()_-%2B%3D%7B%7D%5B%5D%7C%22'%3C%3E%2C.%3F%2F", fontUnicodeRange: 'U+22-39,U+3c-5b,U+5d-7e;', …}
3: {fontFamily: 'Goblin One', fontStyle: 'normal', fontText: null, fontUnicodeRange: null, fontUrl: 'https://fonts.googleapis.com/css2?family=Goblin+One', …}
4: {fontFamily: 'IM Fell English SC', fontStyle: 'normal', fontText: null, fontUnicodeRange: null, fontUrl: 'https://fonts.googleapis.com/css2?family=IM+Fell+English+SC', …}
5: {fontFamily: 'Material Icons', fontStyle: 'normal', fontText: null, fontUnicodeRange: null, fontUrl: 'https://fonts.googleapis.com/icon?family=Material+Icons', …}
6: {fontFamily: 'Pirata One', fontStyle: 'normal', fontText: null, fontUnicodeRange: null, fontUrl: 'https://fonts.googleapis.com/css2?family=Pirata+One', …}
7: {fontFamily: 'Public sans', fontStyle: 'normal', fontText: null, fontUnicodeRange: null, fontUrl: 'https://fonts.googleapis.com/css2?family=Public+Sans', …}
8: {fontFamily: 'Revalia', fontStyle: 'normal', fontWeight: 400, fontUrl: "https://fonts.googleapis.com/css2?family=Revalia&t…%26*()_-%2B%3D%7B%7D%5B%5D%7C%22'%3C%3E%2C.%3F%2F", fontUnicodeRange: 'U+22-39,U+3c-5b,U+5d-7e;', …}
9: {fontFamily: 'Source Serif Pro', fontStyle: 'normal', fontText: null, fontUnicodeRange: null, fontUrl: 'https://fonts.googleapis.com/css2?family=Source+Se…PQRSTUVWXYZabcdefghijklmnopqrst
*/

    const cancelInstall = () => {
        setFontObj(null)
    }

    return (
        <div ref={installFontRef} className={state.installView === 'install font' ? 'install-font' : 'display-none'}>
            <div className="install-font-top">
                <div className="title">Install Font</div>
                <div className="close-button">
                    <XButton r="12" closeAction={closeAction} />
                </div>
            </div>

            <div className="intro">
                <p>
                    You can install Google webfonts into this app.
                </p>
                <p>
                    Font data tends to be large, so be careful not to add too many fonts, or else this app may become slow or unresponsive.
                </p>
                <p>After you add a font, the font information and data will live on your computer, managed by your browser. If your browser decides it needs to
                    make room in its allocated memory space, it may delete your font. And it will do so without any warning or notice.
                </p>
                <p>
                    To install a font, go to <a href="https://fonts.google.com/" target="_blank" rel="noopener noreferrer">https://fonts.google.com/</a> to find a font you like.
                </p>
                <p>
                    Once you have selected a font, scroll down to where you see the various styles. On the right is a circle with a plus sign on it.
                    Click that, and it should add your font to the right panel where it says "Selected family".
                </p>
                <p>Inside of the Selected Family panel, find the text
                    for the url to the font - it should look like
                </p>
                <p className="url-example">https://fonts.googleapis.com/css2?family=Anek+Telugu:wght@200&display=swap
                </p>
                <p>
                    Then paste that into the <span>font url</span> box below, click submit, and the data will be retrieved for that font.
                </p>
            </div>





            <div className={!fontInstallationSuccessful ? 'font-options-optional' : 'display-none'}>
                <div className="title">OPTIONAL</div>
                <div className="restrict-characters-explanation">
                    <p>
                        To reduce the amount of font data you need, you can restrict the data retrieved to only
                        the characters, numbers, and punctuation characters you plan to use with this font.
                    </p><p>
                        If you don't know exactly what you'll need, you can just leave this empty, and this app will retrieve all data
                        for common modern English characters - a-z,A-Z,0-9, and punctuation characters.
                    </p><p>
                        If you want to use foreign language characters, you will need to type them in (and be sure the font
                        you chose supports that language).
                    </p>
                </div>

                <div className="font-options">
                    <label><input id="uppercase" type="checkbox" onClick={addGlyphs} />Add upper letters</label>
                    <label><input id="lowercase" type="checkbox" onClick={addGlyphs} />Add lowercase letters</label>
                    <label><input id="numbers" type="checkbox" onClick={addGlyphs} />Add numbers</label>
                    <label><input id="punctuation" type="checkbox" onClick={addGlyphs} />Add punctuation characters</label>
                </div>
                <div className="restrict-to-characters">
                    <textarea id="charactersNeeded" className="restrict-characters" onChange={changeCharactersNeeded} value={charactersNeeded}>

                    </textarea>
                </div>
            </div>

            <div className={!fontInstallationSuccessful && !(
                fontObj && fontObj.fontFamily && fontObj.fontFamily.length > 0 && fontObj.fontSrc.length > 0)
                ? 'main' : 'display-none'}>
                <div className="inputs">
                    <div className="font-url">
                        <div>font url:</div> <input type="text" className={errorOnFontUrl ? 'warning' : ''} placeholder="https://fonts.googleapis.com/css2?family=Public+Sans" value={fontUrl} onChange={changeFontUrl} />
                    </div>
                    <div className="fetch-result">
                        {fetchResult}
                    </div>
                </div>
                <div className={errorOnFontUrl ? 'show-error' : 'display-none'}>{errorOnFontUrlMessage}</div>
                <div className="action-buttons">
                    <button className={isValidUrl(fontUrl) ? 'action-button' : 'action-button disabled'} onClick={fontUrl ? submitFontUrl : null}>submit</button>
                </div>
            </div>

            <div className={fontObj && fontObj.fontFamily && fontObj.fontFamily.length > 0 && fontObj.fontSrc.length > 0 && !fontInstallationSuccessful ? 'font-obj' : 'display-none'}>

                <div className="install">
                    Font information has been retrieved successfully. Do you wish to install this font into the app?
                    <button className="action-button reddish" onClick={cancelInstall}>Cancel</button><button className="action-button" onClick={doInstallFont}>Yes install the font</button>
                </div>

                <div className="font-data">
                    <div>Font url: <span>{fontObj && fontObj.fontUrl ? fontObj.fontUrl : ''}</span></div>
                    <div>Font family: <span>{fontObj && fontObj.fontFamily ? fontObj.fontFamily : ''}</span></div>
                    <div>Font style: <span>{fontObj && fontObj.fontStyle ? fontObj.fontStyle : ''}</span></div>
                    <div>Font weight: <span>{fontObj && fontObj.fontWeight ? fontObj.fontWeight : ''}</span></div>
                    <div className="src">Font source (base64 encoded) size is: {fontObj && fontObj.fontSrc ? formattedSize(fontObj.fontSrc) : '0'}: <div>{fontObj && fontObj.fontSrc ? fontObj.fontSrc : ''}</div></div>
                </div>
            </div>


            <div className={fontInstallationSuccessful ? 'installation-successful' : 'display-none'}>
                <div className="title">Font installation appears to have been successful. The text below has been set to the installed font.</div>
                <div id="testFont" className="installed-font-example">
                    <div>Font installed: {fontFamily}</div>
                    <div>{charactersNeeded}</div>
                </div>
                <div className="actions">
                    <button className="action-button blueish" onClick={exitFontInstallation}>OK</button>
                    <button className="action-button blueish" onClick={tryAgain}>Let's try again</button>
                    <button className="action-button blueish" onClick={installAnother}>Install another font</button>
                </div>
            </div>


        </div>
    );
}
export default InstallFont