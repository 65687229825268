import React, { useState, useEffect } from 'react'
import Utility from "../../objects/Utility"
//import Snap from 'snapsvg-cjs'
import './MaterialSymbolButton.scss'
const MaterialSymbolButton = ({ symbol, selected, select }) => {
    const [hoverOnSymbol, setHoverOnSymbol] = useState(false)
    const [positionToolTip, setPositionToolTip] = useState({ left: 0, top: 0 })
    const [initialToolTipPositionSet, setInitialToolTipPositionSet] = useState(false)
    const [countHovers, setCountHovers] = useState(0)

    const handleClick = e => {
        let symbolClickedOn = e.currentTarget.id.replace('material-symbol-', '')
        select(symbolClickedOn)
    }

    const hover = hovering => {
        setHoverOnSymbol(hovering)
        setTimeout(() => {
            resetToolTipPosition()
        }, 500)
    }

    useEffect(() => {
        if (hoverOnSymbol) {
            let buttonElement = document.getElementById(`material-symbol-${symbol}`)
            let toolTipElement = document.getElementById(`tool-tip-${symbol}`)
            let buttonBox = Utility.getElementBBox(buttonElement)
            let toolTipBox = Utility.getElementBBox(toolTipElement)
            if (initialToolTipPositionSet === false) {
                if (toolTipBox.x > 0) { // put it to the right to start with.
                    setPositionToolTip({ left: buttonBox.width + 'px', top: 0 + 'px' })
                    setTimeout(() => { // doing it too fast seems to cause a rare occasional failure in detecting the tooltip's width
                        setInitialToolTipPositionSet(true)
                    }, 10)
                }
            }
            else {
                setCountHovers(countHovers + 1)
            }

        }
    }, [hoverOnSymbol]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (countHovers % 3 === 0) {
            setInitialToolTipPositionSet(false)
        }
    }, [countHovers])


    useEffect(() => {
        if (initialToolTipPositionSet && hoverOnSymbol) {
            let fromElement = document.getElementById(`material-symbol-${symbol}`)
            let toolTipElement = document.getElementById(`tool-tip-${symbol}`)
            let optionsElement = Utility.getClosestElementMatchingName(fromElement, 'options')
            let optionsBox = Utility.getElementBBox(optionsElement)
            let toolTipBox = Utility.getElementBBox(toolTipElement)

            if (toolTipBox.width > 0) {
                if (toolTipBox.right + 12 > optionsBox.right) {
                    let finalLeft = '-' + (toolTipBox.width + 1) + 'px'
                    setPositionToolTip({ left: finalLeft, top: 0 })
                }
            }

            setTimeout(() => {
                if (hoverOnSymbol) {
                    resetToolTipPosition()
                }
            }, 500)
        }
    }, [initialToolTipPositionSet]) // eslint-disable-line react-hooks/exhaustive-deps

    const resetToolTipPosition = () => {
        if (hoverOnSymbol) {
            let fromElement = document.getElementById(`material-symbol-${symbol}`)
            let toolTipElement = document.getElementById(`tool-tip-${symbol}`)
            let optionsElement = Utility.getClosestElementMatchingName(fromElement, 'options')
            let optionsBox = Utility.getElementBBox(optionsElement)
            let toolTipBox = Utility.getElementBBox(toolTipElement)

            if (toolTipBox.width > 0) {
                if (toolTipBox.right + 12 > optionsBox.right) {
                    let finalLeft = '-' + (toolTipBox.width + 1) + 'px'
                    setPositionToolTip({ left: finalLeft, top: 0 })
                }
            }
            else {
                setTimeout(() => {
                    resetToolTipPosition()
                }, 100)
            }
        }
    }



    return (
        <div className="material-symbol-button" id={`material-symbol-${symbol}`} onMouseOver={() => hover(true)} onMouseOut={() => hover(false)} onClick={handleClick}>
            <div className={ selected ? 'material-symbol-box selected' : 'material-symbol-box'}>
                <i className="material-icons">{symbol}</i>
                <div id={`tool-tip-${symbol}`} style={{ left: positionToolTip.left, top: positionToolTip.top }} className={hoverOnSymbol ? 'tool-tip' : 'display-none'}>{symbol}</div>
            </div>

        </div>
    )
}
export default MaterialSymbolButton