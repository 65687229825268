import React, { useEffect, useState } from 'react'
import './GridOptions.scss'
const GridOptions = ({ showingGrid, usingGridColor }) => {
    const [showGrid, setShowGrid] = useState(false)
    const [gridColor, setGridColor] = useState('light')

    const changeShowGrid = (evt) => {
        setShowGrid(evt.target.checked)
    }


    const changeGridColor = (evt) => {
        if (evt.target.checked) {
            setGridColor('dark')
        }
        else {
            setGridColor('light')
        }
    }

    useEffect(() => {
        showingGrid(showGrid)
    }, [showGrid]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        usingGridColor(gridColor)
    }, [gridColor]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="grid-options">

            <div className={showGrid ? 'show-grid-text active' : 'show-grid-text'}>show grid:</div>
            <div className="slider-switch">
            <label className="switch">
                <input type="checkbox"
                    onChange={changeShowGrid}
                />
                <span className="slider round"></span>
            </label>
            </div>

            <div className={showGrid ? 'slider-selection' : 'slider-selection inactive'}>
                <div className="label">grid color</div>
                <div className="slider-control-container">
                    <div className={ gridColor === 'light' ? 'option-color selected' : 'option-color'}>light</div>
                    <div className="slider-switch">

                        <label className="switch">
                            <input type="checkbox"
                                onChange={changeGridColor}
                            />
                            <span className="slider round"></span>
                        </label>

                    </div>
                    <div className={ gridColor === 'dark' ? 'option-color selected' : 'option-color'}>dark</div>
                </div>
            </div>


        </div>
    );
}
export default GridOptions