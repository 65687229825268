import React, { useState, useEffect, useContext, useRef } from 'react'
import { StoreContext } from "../../context/StoreContext"
import Utility from "../../objects/Utility"
import BaseCounter from "../../objects/BaseCounter"
import UnitSizeSymbols from "../../objects/UnitSizeSymbols"
import NatoUnitSymbols from "../../objects/NatoUnitSymbols"
import MiscUnitSymbols from "../../objects/MiscUnitSymbols"
import CommonSymbols from "../../objects/CommonSymbols"
import TextSymbols from "../../objects/TextSymbols"
import MaterialSymbols from "../../objects/MaterialSymbols"
import CombatMovement from "../../objects/CombatMovement"
import Line from "../../objects/Line"
import Rectangle from "../../objects/Rectangle"
import Ellipse from "../../objects/Ellipse"
import Triangle from "../../objects/Triangle"
import Hexagon from "../../objects/Hexagon"
import IdentifierText from "../../objects/IdentifierText"
import CustomSvgs from "../../objects/CustomSvgs"
import CustomImages from "../../objects/CustomImages"
import Ww2Vehicles from "../../objects/Ww2Vehicles"
import Snap from 'snapsvg-cjs'
import './DrawLayer.scss'
const DrawLayer = ({ layer }) => {
    const { actions, state } = useContext(StoreContext)
    const [paper, setPaper] = useState(null)
    const [hidden] = useState(0)
    const [savedValues, setSavedValues] = useState({})
    const drawWidthRef = useRef(state.counterDrawWidth)
    const [disabledInputMessages, setDisabledInputMessages] = useState([]) // experimental

    useEffect(() => {
        // this fires first (i think)
        let _paper = Snap('#drawLayer' + layer.layerKey);
        if (Utility.isCustomLayer(layer.layerKey, state.layers)
            || Utility.isRawSvgLayer(layer.layerKey, state.layers)
            ) {
            // if (layer.layerName === 'custom svgs') {
            _paper.attr({ viewBox: "0, 0, 240, 240" }); // assuming most svgs have their 0,0 origin at top left.
        }
        else {
            _paper.attr({ viewBox: "-120, -120, 240, 240" });
        }
        setPaper(_paper)
        return () => {
            if (paper) {
                paper.clear()
            }
        }
    }, [])  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (Utility.emptyCheck(state.activeLayerValues) === false) {
            drawLayer()
        }
    }, [paper]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        drawLayer()
    }, [state.activeLayerValues]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (disabledInputMessages.length > 0) {
            setTimeout(() => {
                disabledInputMessages.forEach(message => {
                    const el1 = document.querySelector('[data-message="' + message + '"]');
                    if (el1) {
                        el1.disabled = true
                    }
                })
            }, 100)
        }

    }, [disabledInputMessages])

    const manageDisables = messages => {
        if (!messages) {
            messages = []
        }
        if (disabledInputMessages.length > 0) {
            disabledInputMessages.forEach(message => {
                if (messages.includes(message) === false) {
                    const el1 = document.querySelector('[data-message="' + message + '"]');
                    if (el1) {
                        el1.disabled = false
                    }
                }
            })
        }
        if (messages) {
            setDisabledInputMessages(messages)
        }
        else {
            setDisabledInputMessages([])
        }
    }

    const drawLayer = () => {
        if (!paper) {
            return
        }
        let originalLayerName = layer.layerName
        if (layer.hasOwnProperty('parentLayerKey')) {
            let parentLayerKey = layer.parentLayerKey
            if (parentLayerKey) {
                let parentLayer = state.layers.find(ly => ly.layerKey === parentLayerKey)
                if (parentLayer) {
                    originalLayerName = parentLayer.layerName
                }
            }
        }
        // get inputKeys out of state.activeLayerValues
        let settings = []
        let inputKeysValuesForLayer = Utility.inputKeysValuesForLayer(state.activeLayerValues, layer.layerKey)
        if (JSON.stringify(savedValues) === JSON.stringify(inputKeysValuesForLayer)) {
            return
        }
        paper.clear()
        let g = paper.group()
        setSavedValues(inputKeysValuesForLayer)
        let breakAway = false
        for (const [inputKey] of Object.entries(inputKeysValuesForLayer)) {
            let foundInputData = layer.inputs.find(li => li.inputKey === parseInt(inputKey))
            if (!foundInputData) {
               // breakAway = xxx true put this back after width height for nato is done
                break;
            }
            if( !foundInputData.named.startsWith('spacer') ) {
            settings.push({
                inputKey: parseInt(inputKey),
                list: foundInputData.list,
                message: foundInputData.message,
                comment: foundInputData.comment,
                name: foundInputData.named,
                type: foundInputData.type,
                value: inputKeysValuesForLayer[inputKey]
            })
        }
        }
        if (breakAway) {
            return
        }

        if (originalLayerName === 'base counter') {
            BaseCounter.draw(g, settings)
        }
        if (originalLayerName === 'NATO unit symbols') {
            NatoUnitSymbols.draw(g, settings, state.svgs)
        }

        if (originalLayerName === 'unit size') {
            let disableInputMessages = UnitSizeSymbols.draw(g, settings, state.svgs)
            manageDisables(disableInputMessages)
        }

        if (originalLayerName === 'misc unit symbols') {
            MiscUnitSymbols.draw(g, settings, state.svgs)
        }

        if (originalLayerName === 'common symbols') {
            CommonSymbols.draw(g, settings, state.svgs)
        }

        if (originalLayerName === 'text') {
            TextSymbols.draw(g, settings)
        }

        if (originalLayerName === 'Google material symbols') {
            MaterialSymbols.draw(g, settings)
        }


        if (originalLayerName === 'combat-movement') {
            CombatMovement.draw(g, settings)
        }

        if (originalLayerName === 'line') {
            Line.draw(g, settings, state.draggingLayer)
        }

        if (originalLayerName === 'rectangle') {
            Rectangle.draw(g, settings)
        }

        if (originalLayerName === 'ellipse') {
            Ellipse.draw(g, settings)
        }

        if (originalLayerName === 'triangle') {
            let disableInputMessages = Triangle.draw(g, settings)
            manageDisables(disableInputMessages)
        }

        if (originalLayerName === 'hexagon') {
            Hexagon.draw(g, settings)
        }

        if (originalLayerName === 'identifier text') {
            IdentifierText.draw(g, settings)
        }

        if (originalLayerName === 'custom svgs') {
            CustomSvgs.draw(g, settings, state.svgs)
        }

        if (originalLayerName === 'custom images') {
            CustomImages.draw(g, settings, state.svgs)
        }
        if (originalLayerName === 'ww2 vehicles') {
            Ww2Vehicles.draw(g, settings, state.svgs)
        }

        g.attr({ id: 'layerGroup_' + layer.layerKey })


        if (originalLayerName !== 'base counter') { // can't drag the base counter
            g.attr({ pointerEvents: "visiblePainted" })
            setDragger(g)
        }

    }

    useEffect(() => {
        drawWidthRef.current = state.counterDrawWidth
    }, [state.counterDrawWidth])

    const startedDragging = tf => {
        actions.draggingLayer(tf)
    }

    const setDragger = g => {
        let { x, y } = [0, 0]
        let { cx, cy } = [0, 0]
        let rightClicked = false

        const dragTracker = (active, dx, dy) => {
            if (rightClicked) {
                return
            }
            if (!active) {
                x = Utility.roundFloat(x, 3)
                y = Utility.roundFloat(y, 3)
                let input = layer.inputs.find(li => li.named.startsWith('xy position'))
                let lineStartRef = layer.inputs.find(li => li.named.startsWith('line start'))
                let lineEndRef = layer.inputs.find(li => li.named.startsWith('line end'))
                let centerXyPositionRef = layer.inputs.find(li => li.named.startsWith('center xy position'))
                if (input) {
                    //actions.dragUpdate({ layerKey: layer.layerKey, inputKey: input.inputKey, x, y })
                    x = x + cx
                    y = y + cy
                    actions.dragUpdate({ layerKey: layer.layerKey, inputKey: input.inputKey, x, y })
                }
                if (centerXyPositionRef) {
                    actions.dragUpdate({ layerKey: layer.layerKey, inputKey: centerXyPositionRef.inputKey, x, y })
                }
                if (lineStartRef) {
                    actions.dragUpdate({ layerKey: layer.layerKey, inputKey: lineStartRef.inputKey, x, y })
                }
                if (lineEndRef) {
                    actions.dragUpdate({ layerKey: layer.layerKey, inputKey: lineEndRef.inputKey, x, y })
                }
            }
            else {
                x = dx
                y = dy
            }
        }

        var move = function (dx, dy) {
            if (rightClicked) {
                return
            }
            g.attr({ cursor: "move" })
            // get width of counter drawing area, in pixels.
            let _counterDrawWidth = 0
            if (drawWidthRef.current > 0) {
                _counterDrawWidth = drawWidthRef.current
            }
            else {
                let ele = document.getElementById('counter')
                _counterDrawWidth = ele.offsetWidth;
                actions.counterDrawWidth(_counterDrawWidth)
            }

            if (_counterDrawWidth > 0) {
                let mul = 240 / _counterDrawWidth
                //mul = mul / 2
                dx *= mul; // need to modify it cause pixels for the mouse are not a direct match to pixels on the svg
                dy *= mul;
                // orig below
                g.attr({
                    transform: this.data('origTransform') + (this.data('origTransform') ? "T" : "t") + [dx, dy]
                    //transform:  (this.data('origTransform') ? "T" : "t") + [dx, dy]
                });
                dragTracker(true, dx, dy);
            }
        }

        var start = function (x, y, evt) {
            let buttonPressed = evt.button
            if (buttonPressed !== 0) {
                rightClicked = true
                return // only operate on left clicks. Leave right click to browser's use.
            }
            startedDragging(true)
            rightClicked = false
            let currentXY_values = currentXY()
            cx = currentXY_values[0]
            cy = currentXY_values[1]

            g.data('origTransform', this.transform().local);
            g.attr({ cursor: "grabbing" })
        }
        var stop = function () {
            dragTracker(false)
            g.attr({ cursor: "pointer" })
            setTimeout(() => {
                startedDragging(false)
            }, 1000)
        }

        g.drag(move, start, stop);
    }

    const currentXY = () => {
        let xyInput = layer.inputs.find(input => input.type === 'xy_position')
        let xyInputKey = xyInput.inputKey
        let xyStartValues = state.activeLayerValues[layer.layerKey + '_' + xyInputKey]
        return xyStartValues
    }

    return (
        <div className={hidden ? 'draw-layer display-none' : 'draw-layer'}>
            <svg id={`drawLayer${layer.layerKey}`} className="counter-svg" />
        </div>
    );
}
export default DrawLayer