import React, { useRef, useContext, useEffect, useState } from 'react'
//import Dexie from 'dexie';
import Snap from 'snapsvg-cjs'
import { StoreContext } from "../../context/StoreContext"
import Utility from "../../objects/Utility"
import SheetControl from '../SheetControl/SheetControl'
import SheetCounterPopper from '../SheetCounterPopper/SheetCounterPopper'
import SheetOperations from '../SheetOperations/SheetOperations'
import './Sheet.scss'

const Sheet = ({ layer, setOverlayActive }) => {
    const { state, actions } = useContext(StoreContext)
    const [paper, setPaper] = useState(null)
    const [paperDragLayer, setPaperDragLayer] = useState(null)
    const [counterBoxesGroup, setCounterBoxesGroup] = useState(null)
    const sheetRef = useRef(null)
    const svgRef = useRef(null)
    const [initialPaint, setInitialPaint] = useState(true)
    const [guideType, setGuideType] = useState('none')
    const [workingWidth, setWorkingWidth] = useState(0)
    const [workingHeight, setWorkingHeight] = useState(0)

    const [changeSheetLoaded, setChangeSheetLoaded] = useState(null)
    const [hidePopper, setHidePopper] = useState(false)

    //const [printableMM, setPrintableMM] = useState([209.55,273.05]) // 8.25, 10.75
    //const [printableMM, setPrintableMM] = useState([203.2,260.35]) // 8, 10.25

    const [clickedSlot, _setClickedSlot] = useState(null)
    const clickedSlotRef = useRef(clickedSlot)
    const setClickedSlot = data => {
        clickedSlotRef.current = data
        _setClickedSlot(data)
    }

    const [pendingClickOnSlotNumber, _setPendingClickOnSlotNumber] = useState(-1)
    const pendingClickOnSlotNumberRef = useRef(pendingClickOnSlotNumber)
    const setPendingClickOnSlotNumber = data => {
        pendingClickOnSlotNumberRef.current = data
        _setPendingClickOnSlotNumber(data)
    }

    const [localSlots, _setLocalSlots] = useState([])
    const localSlotsRef = useRef(localSlots)
    const setLocalSlots = data => {
        localSlotsRef.current = data
        _setLocalSlots(data)
    }

    const [moveCounterFromSlotToSlot, setMoveCounterFromSlotToSlot] = useState(null)


    useEffect(() => {
        if (guideType) {
            let corners = document.querySelectorAll(`[data-type="guideCorner"]`);
            let edges = document.querySelectorAll(`[data-type="guideCornerEdge"]`)
            let lines = document.querySelectorAll(`[data-type="guideLine"]`)
            let arr = [...corners, ...edges, ...lines]
            arr.forEach(guide => guide.style.opacity = 0)

            if (guideType === 'corners') {
                let corners = document.querySelectorAll(`[data-type="guideCorner"]`);
                let edges = document.querySelectorAll(`[data-type="guideCornerEdge"]`)
                let arr = [...corners, ...edges]
                arr.forEach(guide => guide.style.opacity = 1)
            }
            if (guideType === 'edges') {
                let arr = document.querySelectorAll(`[data-type="guideCornerEdge"]`);
                arr.forEach(guide => {
                    guide.style.opacity = 1
                })
            }
            if (guideType === 'lines') {
                let arr = document.querySelectorAll(`[data-type="guideLine"]`);
                arr.forEach(guide => {
                    guide.style.opacity = 1
                })
            }
        }
    }, [guideType])

    useEffect(() => {
        if (clickedSlot !== null) {
            setClickedSlot(null)
        }
    }, [clickedSlot])

    useEffect(() => {
        if (hidePopper) {
            setHidePopper(false)
        }
    }, [hidePopper])

    useEffect(() => {
        if (moveCounterFromSlotToSlot) {
            moveCounter(moveCounterFromSlotToSlot.fromSlotNumber, moveCounterFromSlotToSlot.toSlotNumber)
        }
        setMoveCounterFromSlotToSlot(null)
    }, [moveCounterFromSlotToSlot]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let _paper = Snap("#sheetDisplay");
        //let width = eleBox.width // gotta change the way pixelsperinch settings is used in saving slot info first.
        let width = 1200 // fixed size for now.
        let height = Utility.roundFloat(width * (11 / 8.5), 0) // regular paper size is 8.5x11 (not printable, but, we'll deal with margins later)
        setWorkingWidth(width)
        setWorkingHeight(height)
        // 8.5in =  215.9mm
        // 11in  = 279.4mm
        _paper.attr({ id: "sheetDisplay", width: width, "height": height, viewBox: "0, 0, " + width + ", " + height });
        setPaper(_paper)
        actions.sheetPaper(_paper)
        _paper.rect(0, 0, width, height).attr({ fill: "white", stroke: "none" })
        let _paperDragLayer = Snap("#sheetDisplayDragLayer")
        _paperDragLayer.attr({ id: "sheetDisplayDragLayer", width: width, "height": height, viewBox: "0, 0, " + width + ", " + height });
        setPaperDragLayer(_paperDragLayer)

        // fix for container of the svgs getting too wide. One sheet may be offset from the other.
        let sd = Utility.getElementBBox('sheetDisplay')
        let dd = Utility.getElementBBox('sheetDisplayDragLayer')
        let x_difference = sd.left - dd.left
        var d = document.getElementById('sheetDisplayDragLayer');
        d.style.left = x_difference + 'px';

        setTimeout(() => {
            setGuideType(state.sheetSettings.guideType)
        }, 1000)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (paper) {
            let slots = paintSlots()
            if (state.slots.length === 0) {
                actions.slots(slots)
            }
            actions.sheetPaper(paper)
        }
    }, [paper]) // eslint-disable-line react-hooks/exhaustive-deps

    const paintSlots = () => {
        let _counterBoxesGroup = counterBoxesGroup
        if (_counterBoxesGroup) {
            _counterBoxesGroup.clear()
        }
        else {
            _counterBoxesGroup = paper.group()
            _counterBoxesGroup.attr({ id: "boxesGroup" })
        }


        var p = _counterBoxesGroup.path("M10-5-10,15M15,0,0,15M0-5-20,15").attr({
            fill: "none",
            stroke: "#dddddd",
            strokeWidth: 4
        }).toPattern(0, 0, 10, 10)
        // _counterBoxesGroup.rect(0, 0, 1200, 1553).attr({
        //     fill: p
        // });
        _counterBoxesGroup.rect(0, 0, workingWidth, workingHeight).attr({
            fill: p
        });

        let slots = []
        if (state.slots.length === 0) {
            // if Dexie had no slots info to give us to start with, we'll need to start from scratch.
            for (let i = 0; i < 600; i++) {
                slots[i] = {
                    number: i,
                    centerXy: { x: -1, y: -1 },
                    xy: { x: -1, y: -1 },
                    position: { col: -1, row: -1 },
                    counterState: null
                }
            }
        }
        else {
            slots = JSON.parse(JSON.stringify(localSlotsRef.current))
        }

        //let pixelsToMmRatio = 1200 / 215.9 // 1200 is the size in pixels we gave to the "sheet". 215.9 is mm to 8.5 inches.
        let printableArea = state.sheetSettings.printableArea
        let pixelsToMmRatio = workingWidth / printableArea[0] //215.9 // 1200 is the size in pixels we gave to the "sheet". 215.9 is mm to 8.5 inches.
        let counterSizeMM = state.sheetSettings.counterSize
        if (state.sheetSettings.useCounterCustomSize) {
            counterSizeMM = state.sheetSettings.counterCustomSize
        }
        let counterMargins = state.sheetSettings.counterMargins
        let pixelsPerCounter = Utility.roundFloat(counterSizeMM * pixelsToMmRatio, 4)
        let fontSize = Utility.roundFloat(pixelsPerCounter * 0.3, 0)
        let xy = { x: -1, y: -1 }
        let counterNumber = -1
        let countersAcross = state.sheetSettings.countersColumnsRows[0]
        let countersDown = state.sheetSettings.countersColumnsRows[1]

        let marginTopPixels = counterMargins[0] * pixelsToMmRatio
        let marginRightPixels = counterMargins[1] * pixelsToMmRatio
        let marginBottomPixels = counterMargins[2] * pixelsToMmRatio
        let marginLeftPixels = counterMargins[3] * pixelsToMmRatio

        let pageMargins = state.sheetSettings.pageMargins
        let pageTopPixels = pageMargins[0] * pixelsToMmRatio
        let pageRightPixels = pageMargins[1] * pixelsToMmRatio
        let pageBottomPixels = pageMargins[2] * pixelsToMmRatio
        let pageLeftPixels = pageMargins[3] * pixelsToMmRatio
        let drawnGuides = { corners: [], lines: [], edges: [] }
        let verticalGuideLines = []
        let horizontalGuideLines = []
        let countersToReRender = []
        let guideColor = '#999'
        let guideType = 'none'
        if (state.sheetSettings.guideType) {
            guideType = state.sheetSettings.guideType
        }

        // lets draw box outlining the page margins
        // let pageWidth = 1200 - (pageRightPixels + pageLeftPixels)
        // let pageHeight = 1553 - (pageTopPixels + pageBottomPixels)
        let pageWidth = workingWidth - (pageRightPixels + pageLeftPixels)
        let pageHeight = workingHeight - (pageTopPixels + pageBottomPixels)

        // printers and applications seem to like resizing the image being printed if there is white space it thinks it can remove.
        paper.circle(0,0,2).attr({fill: "#eeeeee"})
        paper.circle(pageWidth,0,2).attr({fill: "#eeeeee"})
        paper.circle(pageWidth,pageHeight,2).attr({fill: "#eeeeee"})
        paper.circle(0,pageHeight,2).attr({fill: "#eeeeee"})

        // boxesRect will be the rect that represents the page that will be printed out.
        // page margins around boxesRect will be removed when exporting to svg or png.
        _counterBoxesGroup.rect(Utility.roundFloat(pageLeftPixels, 2), Utility.roundFloat(pageTopPixels, 2), Utility.roundFloat(pageWidth, 2), Utility.roundFloat(pageHeight, 2))
            .attr({ stroke: "#bbb", "strokeWidth": 1, fill: "white", id: "boxesRect" })
        slots.forEach(ss => {
            ss.number = -1
            ss.position.col = -1
            ss.position.row = -1
            ss.xy = { x: -1, y: -1 }
            ss.centerXy = { x: -1, y: -1 }
        })
        for (var row = 0; row < countersDown; row++) {
            for (var col = 0; col < countersAcross; col++) {
                counterNumber = (row * countersAcross) + col
                slots[counterNumber].number = counterNumber
                slots[counterNumber].position.col = col
                slots[counterNumber].position.row = row
                slots[counterNumber].pixelsWidth = pixelsPerCounter

                xy.x = (col * pixelsPerCounter)
                // move it over by counter margin left
                xy.x += marginLeftPixels
                // and due to counters to the left, we need to add the margins they have. First one will have no
                // neighbor to the left. First one in the row won't have to take account of any to the left, cause there are none.
                xy.x += col * (marginRightPixels + marginLeftPixels)
                xy.y = (row * pixelsPerCounter)
                // move it down by margin top
                xy.y += marginTopPixels
                // and due to counters to the top, we need to add the margins they have. Top counter of each row will have
                // no neighbors above it, so nothing to add on for those.
                xy.y += row * (marginTopPixels + marginBottomPixels)
                // add page margins top and left.
                // no need to worry about page margins right and bottom
                xy.x += pageLeftPixels
                xy.y += pageTopPixels
                xy.x = Utility.roundFloat(xy.x, 4)
                xy.y = Utility.roundFloat(xy.y, 4)



                slots[counterNumber].xy = { x: xy.x, y: xy.y }

                let centerX = xy.x + (pixelsPerCounter / 2)
                let centerY = xy.y + (pixelsPerCounter / 2)
                slots[counterNumber].centerXy = { x: centerX, y: centerY }


                counterNumber = (row * countersAcross) + col
                _counterBoxesGroup.rect(xy.x, xy.y, pixelsPerCounter - 1, pixelsPerCounter - 1).attr({ stroke: "#aaa", "strokeWidth": 1, fill: "#ddd", "data-type": "holder" })

                _counterBoxesGroup.text(xy.x + (pixelsPerCounter / 2), xy.y + (pixelsPerCounter / 2), counterNumber + 1).attr(
                    {
                        "textAnchor": "middle",
                        "dominant-baseline": "central",
                        "fontSize": fontSize,
                        "fontWeight": "normal",
                        "fontFamily": "sans-serif",
                        stroke: "none",
                        fill: "#666",
                        "data-type": "holderText"
                    })

                _counterBoxesGroup.text(xy.x + (pixelsPerCounter / 2), xy.y + (pixelsPerCounter / 1.2), 'R' + (row + 1) + 'C' + (col + 1)).attr(
                    {
                        "textAnchor": "middle",
                        "dominant-baseline": "central",
                        "fontSize": fontSize / 1.5,
                        "fontWeight": "normal",
                        "fontFamily": "sans-serif",
                        stroke: "none",
                        fill: "#777",
                        "data-type": "holderText"
                    })

                // draw cutting guideline crosses
                let guidePositions = []
                let gxy = { x: xy.x, y: xy.y }
                let guideName = ''
                let tx = Utility.roundFloat(gxy.x, 2)
                let ty = Utility.roundFloat(gxy.y, 2)

                if (drawnGuides.corners.includes(tx + '_' + ty) === false) {
                    guideName = 'guideCorner'
                    if (row === 0 || col === 0) {
                        guidePositions.push({ x: tx, y: ty, guideName })
                        guideName = 'guideCornerEdge'
                    }
                    guidePositions.push({ x: tx, y: ty, guideName })
                    drawnGuides.corners.push(tx + '_' + ty)
                }
                tx = Utility.roundFloat(gxy.x + pixelsPerCounter, 2)
                ty = Utility.roundFloat(gxy.y, 2)
                if (drawnGuides.corners.includes(tx + '_' + ty) === false) {
                    guideName = 'guideCorner'
                    if (row === 0 || col === countersAcross - 1) {
                        guidePositions.push({ x: tx, y: ty, guideName })
                        guideName = 'guideCornerEdge'
                    }
                    guidePositions.push({ x: tx, y: ty, guideName })
                    drawnGuides.corners.push(tx + '_' + ty)
                }
                tx = Utility.roundFloat(gxy.x, 2)
                ty = Utility.roundFloat(gxy.y + pixelsPerCounter, 2)
                if (drawnGuides.corners.includes(tx + '_' + ty) === false) {
                    guideName = 'guideCorner'
                    if (row === countersDown - 1 || col === 0) {
                        guidePositions.push({ x: tx, y: ty, guideName })
                        guideName = 'guideCornerEdge'
                    }
                    guidePositions.push({ x: tx, y: ty, guideName })
                    drawnGuides.corners.push(tx + '_' + ty)
                }
                tx = Utility.roundFloat(gxy.x + pixelsPerCounter, 2)
                ty = Utility.roundFloat(gxy.y + pixelsPerCounter, 2)
                if (drawnGuides.corners.includes(tx + '_' + ty) === false) {
                    guideName = 'guideCorner'
                    if (row === countersDown - 1 || col === countersAcross - 1) {
                        guidePositions.push({ x: tx, y: ty, guideName })
                        guideName = 'guideCornerEdge'
                    }
                    guidePositions.push({ x: tx, y: ty, guideName })
                    drawnGuides.corners.push(tx + '_' + ty)
                }
                guidePositions.forEach(gp => {
                    // corners is same as edges, except we have to add the edges name to the data attribute.
                    _counterBoxesGroup.line(gp.x - 10, gp.y, gp.x + 10, gp.y).attr({ stroke: guideColor, strokeWidth: 1, "data-type": gp.guideName, opacity: 0 })
                    _counterBoxesGroup.line(gp.x, gp.y - 10, gp.x, gp.y + 10).attr({ stroke: guideColor, strokeWidth: 1, "data-type": gp.guideName, opacity: 0 })
                })

                let foundPoint = null
                if (row === 0) {
                    foundPoint = verticalGuideLines.find(vl => vl.start.x === Utility.roundFloat(xy.x, 2) && vl.start.y === Utility.roundFloat(xy.y, 2))
                    if (!foundPoint) {
                        verticalGuideLines.push({ start: { x: Utility.roundFloat(xy.x, 2), y: Utility.roundFloat(xy.y, 2) } })
                    }
                    foundPoint = verticalGuideLines.find(vl => vl.start.x === Utility.roundFloat(xy.x + pixelsPerCounter, 2) && vl.start.y === Utility.roundFloat(xy.y, 2))
                    if (!foundPoint) {
                        verticalGuideLines.push({ start: { x: Utility.roundFloat(xy.x + pixelsPerCounter, 2), y: Utility.roundFloat(xy.y, 2) } })
                    }
                }
                if (row === countersDown - 1) {
                    foundPoint = verticalGuideLines.find(vl => vl.start.x === Utility.roundFloat(xy.x, 2))
                    foundPoint.end = { x: foundPoint.start.x, y: Utility.roundFloat(xy.y + pixelsPerCounter, 2) }
                    foundPoint = verticalGuideLines.find(vl => vl.start.x === Utility.roundFloat(xy.x + pixelsPerCounter, 2))
                    foundPoint.end = { x: foundPoint.start.x, y: Utility.roundFloat(xy.y + pixelsPerCounter, 2) }
                }

                if (col === 0) {
                    foundPoint = horizontalGuideLines.find(hl => hl.start.x === Utility.roundFloat(xy.x, 2) && hl.start.y === Utility.roundFloat(xy.y, 2))
                    if (!foundPoint) {
                        horizontalGuideLines.push({ start: { x: Utility.roundFloat(xy.x, 2), y: Utility.roundFloat(xy.y, 2) } })
                    }
                    foundPoint = horizontalGuideLines.find(hl => hl.start.x === Utility.roundFloat(xy.x, 2) && hl.start.y === Utility.roundFloat(xy.y + pixelsPerCounter, 2))
                    if (!foundPoint) {
                        horizontalGuideLines.push({ start: { x: Utility.roundFloat(xy.x, 2), y: Utility.roundFloat(xy.y + pixelsPerCounter, 2) } })
                    }

                }
                if (col === countersAcross - 1) {
                    foundPoint = horizontalGuideLines.find(vl => vl.start.y === Utility.roundFloat(xy.y, 2))
                    foundPoint.end = { x: Utility.roundFloat(xy.x + pixelsPerCounter, 2), y: foundPoint.start.y }
                    foundPoint = horizontalGuideLines.find(vl => vl.start.y === Utility.roundFloat(xy.y + pixelsPerCounter, 2))
                    foundPoint.end = { x: Utility.roundFloat(xy.x + pixelsPerCounter, 2), y: foundPoint.start.y }
                }
                if (slots[counterNumber].counterState !== null) {
                    countersToReRender.push(slots[counterNumber])
                }
            }
        }

        verticalGuideLines.forEach(vgl => {
            if (vgl.start && vgl.end) {
                vgl.start.y -= state.sheetSettings.counterMargins[0] * pixelsToMmRatio
                vgl.end.y += state.sheetSettings.counterMargins[2] * pixelsToMmRatio
                _counterBoxesGroup.line(vgl.start.x, vgl.start.y, vgl.end.x, vgl.end.y).attr({ stroke: guideColor, strokeWidth: 1, "data-type": 'guideLine', opacity: 0 })
            }
        });
        horizontalGuideLines.forEach(hgl => {
            if (hgl.start && hgl.end) {
                hgl.start.x -= state.sheetSettings.counterMargins[3] * pixelsToMmRatio
                hgl.end.x += state.sheetSettings.counterMargins[1] * pixelsToMmRatio
                _counterBoxesGroup.line(hgl.start.x, hgl.start.y, hgl.end.x, hgl.end.y).attr({ stroke: guideColor, strokeWidth: 1, "data-type": 'guideLine', opacity: 0 })
            }
            else {
                if (hgl.start && hgl.start.x && hgl.start.y) {
                    _counterBoxesGroup.circle(hgl.start.x, hgl.start.y, 20).attr({ stroke: "red", fill: "none" })
                }
                if (hgl.end && hgl.end.x && hgl.end.y) {
                    _counterBoxesGroup.circle(hgl.end.x, hgl.end.y, 20).attr({ stroke: "red", fill: "none" })
                }
            }
        });

        countersToReRender.forEach(counter => {
            reRenderCounter(counter)//
        })

        // if user reduces number of slots that causes some counters to "fall off", we need to retain
        // their group info, (and hide the svg) so if the user increases the number of slots and it becomes available
        // again, we can show it again.
        for (let i = counterNumber + 1; i < slots.length; i++) {
            slots[i].number = i
            slots[i].pixelsWidth = pixelsPerCounter
            slots[i].position = { userLoadSlotsSheetcol: -1, row: -1 }
            slots[i].xy = { x: -1, y: -1 }
            slots[i].centerXy = { x: -1, y: -1 }
            // and any previously drawn counters in a slot that doesnt exist anymore (due to number of boxes being reduced), we need to delete the group.
            let deleteElement = null
            deleteElement = paper.select('#sheet_number_' + i)
            if (deleteElement) {
                deleteElement.remove()
            }
        }
        setCounterBoxesGroup(_counterBoxesGroup)
        let savedGuideType = guideType
        setGuideType(null)
        setTimeout(() => {
            setGuideType(savedGuideType)
        }, 100)

        return slots

    }



    useEffect(() => {
        if (state.userLoadSlotsSheet) {
            setChangeSheetLoaded(state.userLoadSlotsSheet)
        }
    }, [state.userLoadSlotsSheet]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (changeSheetLoaded) {
            // clear sheet of any previously drawn counters
            let slots_ = JSON.parse(JSON.stringify(localSlotsRef.current))
            slots_.forEach(slot => {
                if (slot.number > -1) {
                    let deleteElement = null
                    deleteElement = paper.select('#sheet_number_' + slot.number)
                    if (deleteElement) {
                        deleteElement.remove()
                    }

                }
            })

            actions.sheetSettings(changeSheetLoaded.sheetSettings)
        }
    }, [changeSheetLoaded]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (Utility.emptyCheck(state.sheetSettings) === false) {
            if (paper && counterBoxesGroup !== null) {
                if (initialPaint === true) {
                    setInitialPaint(false)
                    return
                }
                let slots = paintSlots()
                actions.slots(slots)
            }
        }
    }, [state.sheetSettings]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!state.slots) {
            return
        }

        if (state.slots && state.slots.length > 0 && state.slots[0].number === 0) {
            Utility.updateDexieStore(state.dexie, 'slots', state.slots, 'number')
        }

        if (changeSheetLoaded) {
            let loadedGuideType = ''
            if (changeSheetLoaded.sheetSettings.guideType) {
                loadedGuideType = changeSheetLoaded.sheetSettings.guideType
                guideTypeChange(loadedGuideType)
            }
            setChangeSheetLoaded(null) // end it now
            let newOccupiedSlots = []
            changeSheetLoaded.slots.forEach(slot => {
                if (slot.counterState) {
                    let newSlot = { number: slot.number, counterState: JSON.parse(JSON.stringify(slot.counterState)) } // cloneCounterToSlot(slot.number, slot.counterState)
                    newOccupiedSlots.push(newSlot)
                }
                else {
                    console.warn('loading occupied slots - why were we given a null slot?: ', changeSheetLoaded)
                }
            })
            cloneCountersToSlots(newOccupiedSlots) // even if there are no counters, we need to call this to remove previously painted counters.
            if (loadedGuideType) {
                setTimeout(() => {
                    setGuideType(loadedGuideType)
                }, 1000)
            }
        }

        setLocalSlots(state.slots)

    }, [state.slots]) // eslint-disable-line react-hooks/exhaustive-deps

    // leave for reference, in case I have to do something like this again.
    // const drawCounterAtSlotUsingSvgString = slot => {
    //     let svgstring = slot.svg
    //     let fixedStr = svgstring.replace(/data-number="\d+"/g, 'data-number="' + slot.number + '"');
    //     fixedStr = fixedStr.replace(/id="sheet_number_\d+"/g, 'id="sheet_number_' + slot.number + '"');


    //     // var defsFrag = Snap.parse(defsString)
    //     // paper.append(defsFrag)
    //     let parsed = Snap.parse(fixedStr)
    //     paper.append(parsed)
    //     let groupElement = paper.select('#sheet_number_' + slot.number)

    //     // // // reset scaling and position
    //     let currentMatrix = groupElement.transform().localMatrix;
    //     currentMatrix.a = 1
    //     currentMatrix.d = 1
    //     currentMatrix.e = 0
    //     currentMatrix.f = 0
    //     groupElement.transform(currentMatrix)

    //     currentMatrix = groupElement.transform().localMatrix;
    //     let addMatrix = new Snap.Matrix()

    //     let tx = slot.centerXy.x
    //     let ty = slot.centerXy.y

    //     tx = Utility.roundFloat(tx, 4)
    //     ty = Utility.roundFloat(ty, 4)

    //     addMatrix.translate(tx, ty)
    //     addMatrix.scale(slot.pixelsWidth / 200) // coming from the counter edit, the size of counter is 200.
    //     addMatrix.add(currentMatrix)
    //     groupElement.transform(addMatrix)
    //     groupElement.attr({ cursor: "pointer" })
    //     makeDraggable(groupElement)
    //     let newSvgstring = new XMLSerializer().serializeToString(groupElement.node) // node?
    //     return { groupElement, svg: newSvgstring }
    // }

    useEffect(() => {
        if (state.cloneCounterToSlot) {
            let slotNumber = null
            if( state.cloneCounterToSlot.slotNumber ) {
                slotNumber = state.cloneCounterToSlot.slotNumber - 1
            }
            else {
                let slot = localSlotsRef.current.find(sl => sl.counterState === null) // get the first empty slot
                slotNumber = slot.number
            }
            cloneCounterToSlot(slotNumber, JSON.parse(JSON.stringify(state.cloneCounterToSlot)))
            // clear the cloneCounterToSheet state so it can get re-triggered next time.
            actions.cloneCounterToSlot(null)
        }
    }, [state.cloneCounterToSlot]) // eslint-disable-line react-hooks/exhaustive-deps


    // batch method to use when loading in a sheet.
    const cloneCountersToSlots = (countersToClone) => {
        let _slots = JSON.parse(JSON.stringify(localSlotsRef.current))
        // clear the sheet of any previously drawn counters.
        _slots.forEach(slot => {
            if (slot.number > -1) {
                slot.counterState = null
                if (slot.svg) {
                    slot.svg = ''
                }
                let deleteElement = null
                deleteElement = paper.select('#sheet_number_' + slot.number)
                if (deleteElement) {
                    deleteElement.remove()
                }
            }
        })
        countersToClone.forEach(cc => {
            let slotNumber = cc.number
            let packagedCounterState = JSON.parse(JSON.stringify(cc.counterState))
            let tx = localSlotsRef.current[slotNumber].centerXy.x
            let ty = localSlotsRef.current[slotNumber].centerXy.y
            // dont draw the counters that may have fallen off the sheet.
            // fit the counter in, given the current parameters of sheetSettings
            //let pixelsToMmRatio = 1200 / 215.9 // 1200 is the size in pixels we gave to the "sheet". 215.9 mm equals 8.5 inches.
            // 8.25 inches = 209.55mm
            let printableArea = state.sheetSettings.printableArea
            let pixelsToMmRatio = workingWidth / printableArea[0] //215.9 // 1200 is the size in pixels we gave to the "sheet". 215.9 mm equals 8.5 inches.
            let counterSizeMM = state.sheetSettings.counterSize

            if (state.sheetSettings.useCounterCustomSize) {
                counterSizeMM = state.sheetSettings.counterCustomSize
            }
            let pixelsWidth = Utility.roundFloat(counterSizeMM * pixelsToMmRatio, 5) // the 4 decimal places is probably excessive.
            //paper.rect(0, 0, pixelsWidth, pixelsWidth).attr({ stroke: "red", strokeWidth: 1, fill: "none" })
            let newGroup = paper.group() // we are creating a new group that will be drawn into the empty slot.
            packagedCounterState.layers.forEach(csl => {
                let parsed = Snap.parse(csl.svg)
                if (tx > -1 && ty > -1) { // dont draw counters that were pushed off the sheet
                    newGroup.append(parsed)
                }
            })

            // just to make sure the previous drawn counter is gone.
            let deleteElement = null
            deleteElement = paper.select('#sheet_number_' + slotNumber)
            if (deleteElement) {
                deleteElement.remove()
            }
            let newSvgstring = ''
            if (tx > -1 && ty > -1) { // dont draw counters that were pushed off the sheet
                newGroup.attr({ 'data-number': slotNumber })
                newGroup.attr({ 'id': 'sheet_number_' + slotNumber }) // not sure why I called it sheet_number. Should be slot_number.

                let scaleFactor = pixelsWidth / 1000 // the 1000 is the natural width we gave the counter drawing area.
                let bbox = newGroup.getBBox()

                let cx = bbox.cx // 600
                let cy = bbox.cy // 776.5

                let mx = 500 + (1200 - 1000) / 2
                let my = 500 + (1553 - 1000) / 2
                let adjustedX = ((600 - cx) * (1 - scaleFactor))
                let adjustedY = ((776.5 - cy) * (1 - scaleFactor))
                adjustedX -= mx
                adjustedY -= my
                adjustedX += tx
                adjustedY += ty
                let tstring = 't ' + adjustedX + ' ' + adjustedY + ', s ' + scaleFactor
                newGroup.transform(tstring)

                newSvgstring = new XMLSerializer().serializeToString(newGroup.node)
                makeDraggable(newGroup)
            }

            _slots[slotNumber].counterState = JSON.parse(JSON.stringify(packagedCounterState))
            _slots[slotNumber].svg = newSvgstring

        })
        actions.slots(_slots)
    }
    // place
    const cloneCounterToSlot = (slotNumber, packagedCounterState) => {
        let _slots = JSON.parse(JSON.stringify(localSlotsRef.current))

        let tx = localSlotsRef.current[slotNumber].centerXy.x
        let ty = localSlotsRef.current[slotNumber].centerXy.y

        if( tx === -1 && ty === -1 ) {
            console.warn('illegal slot draw, slotNumber:', slotNumber, ' packagedCounterState:', packagedCounterState)
            return
        }

        // fit the counter in, given the current parameters of sheetSettings
        //let pixelsToMmRatio = 1200 / 215.9 // 1200 is the size in pixels we gave to the "sheet". 215.9 mm equals 8.5 inches.
        let pixelsToMmRatio = workingWidth / state.sheetSettings.printableArea[0] //215.9 // 1200 is the size in pixels we gave to the "sheet". 215.9 mm equals 8.5 inches.
        let counterSizeMM = state.sheetSettings.counterSize

        if (state.sheetSettings.useCounterCustomSize) {
            counterSizeMM = state.sheetSettings.counterCustomSize
        }
        let pixelsWidth = Utility.roundFloat(counterSizeMM * pixelsToMmRatio, 5) // the 4 decimal places is probably excessive.
        //paper.rect(0, 0, pixelsWidth, pixelsWidth).attr({ stroke: "red", strokeWidth: 1, fill: "none" })
        let newGroup = paper.group() // we are creating a new group that will be drawn into the empty slot.
        let widths = []
        let heights = []
        let cx = 0
        let cy = 0
        packagedCounterState.layers.forEach(csl => {
            if (csl.svg) {
                let parsed = Snap.parse(csl.svg)
                newGroup.append(parsed) // its being put in the center of the paper.
                let bbox = newGroup.getBBox()
                let bboxWidth = bbox.width
                let bboxHeight = bbox.height

                widths.push(bboxWidth)
                heights.push(bboxHeight)
            }
        })
        let bbox = newGroup.getBBox()
        cx = bbox.cx // 600
        cy = bbox.cy // 776.5
        // just to make sure the previous drawn counter is gone.
        let deleteElement = null
        deleteElement = paper.select('#sheet_number_' + slotNumber)
        if (deleteElement) {
            deleteElement.remove()
        }

        newGroup.attr({ 'data-number': slotNumber })
        newGroup.attr({ 'id': 'sheet_number_' + slotNumber }) // not sure why I called it sheet_number. Should be slot_number.

        let scaleFactor = pixelsWidth / 1000 // the 1000 is the natural width we gave the counter drawing area.
        // the 1200 and the 1553 are for the sheet values (8 1/2 " x 11" source dimensions)

        // the 500 is half size of the counter, 1000x1000
        // the 1200 and 1553 is the size of the parent paper (sheet).

        let mx = 500 + (1200 - 1000) / 2
        let my = 500 + (1553 - 1000) / 2
        let adjustedX = ((600 - cx) * (1 - scaleFactor))
        let adjustedY = ((776.5 - cy) * (1 - scaleFactor))
        adjustedX -= mx
        adjustedY -= my
        adjustedX += tx
        adjustedY += ty
        let tstring = 't ' + adjustedX + ' ' + adjustedY + ', s ' + scaleFactor
        newGroup.transform(tstring)

        let newSvgstring = new XMLSerializer().serializeToString(newGroup.node)
        makeDraggable(newGroup)

        _slots[slotNumber].counterState = JSON.parse(JSON.stringify(packagedCounterState))
        _slots[slotNumber].svg = newSvgstring
        actions.slots(_slots)
    }

    // const insertDefNodesToPaper = (defNodes, _paper) => {
    //     // insert any defs we need for this counter
    //     let defNodesToInsert = []
    //     let sheetRef = document.getElementById('sheetDisplay')
    //     let defs = []
    //     defNodes.forEach(defNode => {
    //         let foundDefChild = sheetRef.getElementById(defNode.id)
    //         if (!foundDefChild) {
    //             defNodesToInsert.push(defNode)
    //         }
    //         defs.push({ id: defNode.id, string: defNode.outerHTML })
    //     })
    //     let defsString = ''
    //     if (defNodesToInsert.length > 0) {
    //         defsString = '<defs>'
    //         defNodesToInsert.forEach(defNode => {
    //             defsString += defNode.outerHTML
    //         })
    //         defsString += '</defs>'
    //         var defsFrag = Snap.parse(defsString)
    //         _paper.append(defsFrag)
    //     }

    //     return defs
    // }

    // const insertDefsToPaper = (defs, _paper) => {
    //     let defsToInsert = []
    //     let sheetRef = document.getElementById('sheetDisplay')
    //     defs.forEach(def => {
    //         let foundDefChild = sheetRef.getElementById(def.id)
    //         if (!foundDefChild) {
    //             defsToInsert.push(def)
    //         }
    //     })

    //     if (defsToInsert.length > 0) {
    //         let defsString = '<defs>'
    //         defsToInsert.forEach(def => {
    //             defsString += def.string
    //         })
    //         defsString += '</defs>'
    //         var defsFrag = Snap.parse(defsString)
    //         _paper.append(defsFrag)
    //     }
    // }


    const makeDraggable = (g) => {
        g.attr({ cursor: "pointer" })
        let currentMatrix = g.transform().localMatrix;
        let dropIndicatorRect = null
        let dropIndicatorSlotNumber = -1
        let dragClone = null
        let fromSlotNumber = -1
        let paperWidth = paper.node.clientWidth
        let paperHeight = paper.node.clientHeight
        let lastCandidate = null
        let pendingSlotNumber = -1
        let maxDraggedDistance = -1
        let buttonPressed = -1
        let pixelsWidth = -1

        var dragStart = function (x, y, evt) {
            buttonPressed = evt.button
            if (buttonPressed !== 0) {
                return // only operate on left clicks. Leave right click to browser's use.
            }
            currentMatrix = g.transform().localMatrix
            fromSlotNumber = Number(g.attr('data-number'))
            setPendingClickOnSlotNumber(fromSlotNumber)
            pendingSlotNumber = fromSlotNumber
            maxDraggedDistance = -1
            //pixelsWidth = state.slots[0].pixelsWidth
            pixelsWidth = localSlotsRef.current[0].pixelsWidth
        }

        var dragMove = function (dx, dy) {
            if (buttonPressed !== 0) {
                return
            }
            setHidePopper(true)
            if (dragClone === null) {
                dragClone = g.clone()
                paperDragLayer.append(dragClone)
                g.attr({ opacity: 0 })
            }
            // dx, dy is just the delta, not
            if (dx !== 0 || dy !== 0) {
                setPendingClickOnSlotNumber(-1)
            }
            maxDraggedDistance = Utility.distanceTwoPoints({ x: 0, y: 0 }, { x: dx, y: dy })

            let addMatrix = new Snap.Matrix()
            addMatrix.translate(dx, dy)

            let scaleFactor = pixelsWidth / 1000
            addMatrix.add(currentMatrix)
            g.transform(addMatrix)

            let groupBox = g.getBBox()
            let resultMatrix = g.transform().localMatrix
            //if (groupBox.x < 0) {
            // console.log('state.sheetSettings.counterMargins[0]:', state.sheetSettings.counterMargins[0])
            // console.log('state.sheetSettings.pageMargins[0]:', state.sheetSettings.pageMargins[0])
            // let mmLeft = (state.sheetSettings.counterMargins[0] + state.sheetSettings.pageMargins[0])
            // let pixelsToMmRatio = workingWidth / 215.9 
            // console.log('pixelsToMmRatio:',pixelsToMmRatio)
            // console.log('mmLeft:', mmLeft)
            // let pixelsPaddingLeft = mmLeft * pixelsToMmRatio
            // console.log('pixelsPaddingLeft:',pixelsPaddingLeft)
            if (groupBox.x < 0) {
                resultMatrix.e -= groupBox.x
            }
            if (groupBox.y < 0) {
                resultMatrix.f -= groupBox.y
            }

            if (groupBox.x2 > paperWidth) {
                resultMatrix.e -= (groupBox.x2 - paperWidth)
            }
            if (groupBox.y2 > paperHeight) {
                resultMatrix.f -= (groupBox.y2 - paperHeight)
            }
            if (dragClone) {
                dragClone.transform(resultMatrix)
            }


            let transMatrix = g.transform().localMatrix
            let mx = (500 + (1200 - 1000) / 2) * scaleFactor
            let my = (500 + (1553 - 1000) / 2) * scaleFactor
            let tx = transMatrix.e + mx
            let ty = transMatrix.f + my

            let slotCandidate = getClosestEmptySlotToXY(tx, ty, fromSlotNumber)
            if (slotCandidate === -1) {
                return
            }
            lastCandidate = slotCandidate

            if (slotCandidate.number !== dropIndicatorSlotNumber) {
                dropIndicatorSlotNumber = slotCandidate.number
                if (dropIndicatorRect !== null) {
                    dropIndicatorRect.remove()
                    dropIndicatorRect = null
                }
                dropIndicatorRect = paper.rect(slotCandidate.xy.x, slotCandidate.xy.y, slotCandidate.pixelsWidth, slotCandidate.pixelsWidth)
                    .attr({
                        stroke: "red", strokeWidth: "2", fill: "red", fillOpacity: "0.2",
                        strokeDasharray: "2 4",
                        strokeDashoffset: 0
                    })

                Snap.animate(0, 400, function (value) {
                    if (dropIndicatorRect) {
                        dropIndicatorRect.attr({ 'strokeDashoffset': value })
                    }

                }, 5000);

            }
        }

        var dragStop = function (evt) {
            if (buttonPressed !== 0) {
                return
            }
            // in dragStop, we need to be able to figure out if the user dragged the counter to a valid destination.
            // dragging out of the sheet area will reasult in lastCandidate being -1.
            // We also need to handle of the user didnt mean to drag (by dragging just a little) and if they really meant
            // to bring up the popper. Thats why theres various (convoluted) cases below.

            if (lastCandidate === null) {
                if (pendingSlotNumber > -1) {
                    setClickedSlot(localSlotsRef.current[pendingSlotNumber]) // brings up popper (or turns it off)
                    pendingSlotNumber = -1
                }
            }
            if (lastCandidate === -1 || lastCandidate === null || lastCandidate.number === fromSlotNumber) {
                if( maxDraggedDistance > -1 ) {
                    reRenderCounter(localSlotsRef.current[fromSlotNumber])
                }
                // if distance dragged was negligible, bring up popper
                if (maxDraggedDistance > 0 && maxDraggedDistance < 3) {
                    setClickedSlot(localSlotsRef.current[fromSlotNumber])
                }
            }
            else {
                // actually moving to another slot
                setMoveCounterFromSlotToSlot({ fromSlotNumber, toSlotNumber: lastCandidate.number })
            }

            if (dropIndicatorRect !== null) {
                dropIndicatorRect.stop()
                dropIndicatorRect.remove()
                dropIndicatorRect = null
                dropIndicatorSlotNumber = -1
            }

            if (dragClone) {
                dragClone.remove()
                dragClone = null
            }

        }

        g.drag(dragMove, dragStart, dragStop);

    }

    const getClosestEmptySlotToXY = (x, y, fromSlotNumber) => {
        let closestDistance = 999
        let closestSlot = -1
        localSlotsRef.current.forEach(slot => {
            if (slot.centerXy.x > -1 && slot.centerXy.y > -1) {
                let distance = Utility.distanceBetweenTwoPoints(x, y, slot.centerXy.x, slot.centerXy.y)
                if (distance < closestDistance &&
                    (slot.counterState === null ||
                        slot.number === fromSlotNumber)

                ) {
                    closestDistance = distance;
                    closestSlot = slot
                }

            }
        })
        return closestSlot
    }

    const moveCounter = (fromSlotNumber, toSlotNumber) => {
        let _slots = [...localSlotsRef.current]
        let movedElement = paper.select('#sheet_number_' + fromSlotNumber)
        movedElement.attr({ id: 'sheet_number_' + toSlotNumber, 'data-number': toSlotNumber })
        _slots[toSlotNumber].counterState = JSON.parse(JSON.stringify(_slots[fromSlotNumber].counterState))
        _slots[fromSlotNumber].counterState = null
        _slots[fromSlotNumber].svg = null
        let newSvg = reRenderCounter(_slots[toSlotNumber])
        _slots[toSlotNumber].svg = newSvg
        setLocalSlots(_slots)
        actions.slots(_slots) // xxx
    }

    // const moveCounterOrig = (fromSlotNumber, toSlotNumber) => {
    //     let _slots = [...localSlotsRef.current]
    //     let tx = state.slots[toSlotNumber].centerXy.x
    //     let ty = state.slots[toSlotNumber].centerXy.y
    //     let fromTx = state.slots[fromSlotNumber].centerXy.x
    //     let fromTy = state.slots[fromSlotNumber].centerXy.y
    //     paper.circle(tx, ty, 20).attr({ stroke: "red", strokeWidth: 3, fill: "none" })
    //     paper.circle(fromTx, fromTy, 20).attr({ stroke: "blue", strokeWidth: 3, fill: "none" })
    //     let movedElement = paper.select('#sheet_number_' + fromSlotNumber)
    //     // let test = movedElement.select('svg:nth-child(1)');


    //     let pixelsToMmRatio = workingWidth / state.sheetSettings.printableArea[0] //215.9 // 1200 is the size in pixels we gave to the "sheet". 215.9 mm equals 8.5 inches.
    //     let counterSizeMM = state.sheetSettings.counterSize
    //     if (state.sheetSettings.useCounterCustomSize) {
    //         counterSizeMM = state.sheetSettings.counterCustomSize
    //     }
    //     let pixelsWidth = Utility.roundFloat(counterSizeMM * pixelsToMmRatio, 5)

    //     let bbox = movedElement.getBBox()
    //     let diffCenterX = tx - bbox.cx
    //     let diffCenterY = ty - bbox.cy
    //     //console.log('bbox: ', bbox)
    //     let overSize = bbox.width - pixelsWidth
    //     let slotx = tx - (pixelsWidth / 2)
    //     let sloty = ty - (pixelsWidth / 2)

    //     let currentCenterX = bbox.cx
    //     let currentCenterY = bbox.cy
    //     //paper.circle(tx + bbox.cx,ty + bbox.cy, 50).attr({stroke:"red",fill:"none"})
    //     let currentMatrix = movedElement.transform().localMatrix;
    //     let addMatrix = new Snap.Matrix()
    //     addMatrix.translate(tx - currentCenterX, ty - currentCenterY)
    //     addMatrix.add(currentMatrix)
    //     movedElement.transform(addMatrix)
    //     movedElement.attr({ id: 'sheet_number_' + toSlotNumber, 'data-number': toSlotNumber })

    //     let bbox2 = movedElement.getBBox()
    //     let offsetx = bbox2.x - slotx
    //     let offsety = bbox2.y - sloty

    //     let svg = movedElement.toString()
    //     _slots[toSlotNumber].svg = svg
    //     _slots[toSlotNumber].counterState = JSON.parse(JSON.stringify(_slots[fromSlotNumber].counterState))
    //     _slots[fromSlotNumber].counterState = null
    //     _slots[fromSlotNumber].svg = null
    //     //reRenderCounter(_slots[toSlotNumber])

    //     actions.slots(_slots)

    // }

    const reRenderCounter = slot => {
        let groupElement = null
        groupElement = paper.select('#sheet_number_' + slot.number)
        if (groupElement) {
            groupElement.remove()
        }
        slot.counterState = JSON.parse(JSON.stringify(slot.counterState))

        let tx = slot.centerXy.x
        let ty = slot.centerXy.y
        let pixelsToMmRatio = workingWidth / state.sheetSettings.printableArea[0] //215.9 // 1200 is the size in pixels we gave to the "sheet". 215.9 mm equals 8.5 inches.
        let counterSizeMM = state.sheetSettings.counterSize

        if (state.sheetSettings.useCounterCustomSize) {
            counterSizeMM = state.sheetSettings.counterCustomSize
        }
        let pixelsWidth = Utility.roundFloat(counterSizeMM * pixelsToMmRatio, 5) // the 4 decimal places is probably excessive.
        let newGroup = paper.group() // we are creating a new group that will be drawn into the empty slot.
        slot.counterState.layers.forEach(ccal => {
            let parsed = Snap.parse(ccal.svg)
            newGroup.append(parsed)
        })

        newGroup.attr({ 'data-number': slot.number })
        newGroup.attr({ 'id': 'sheet_number_' + slot.number }) // not sure why I called it sheet_number. Should be slot_number.

        let scaleFactor = pixelsWidth / 1000
        let bbox = newGroup.getBBox()
        let cx = bbox.cx // 600
        let cy = bbox.cy // 776.5

        let mx = 500 + (1200 - 1000) / 2
        let my = 500 + (1553 - 1000) / 2
        let adjustedX = ((600 - cx) * (1 - scaleFactor))
        let adjustedY = ((776.5 - cy) * (1 - scaleFactor))
        adjustedX -= mx
        adjustedY -= my
        adjustedX += tx
        adjustedY += ty
        let tstring = 't ' + adjustedX + ' ' + adjustedY + ', s ' + scaleFactor
        newGroup.transform(tstring)
        makeDraggable(newGroup)
        return newGroup.toString()
    }

    const deleteSlotNumber = number => {
        if (number < 0) {
            return
        }
        let slots_ = JSON.parse(JSON.stringify(localSlotsRef.current))
        slots_[number].counterState = null
        let deleteElement = null
        deleteElement = paper.select('#sheet_number_' + number)
        if (deleteElement) {
            deleteElement.remove()
        }

        actions.slots(slots_)
        let data = { number, counterState: null }
        Utility.updateDexieStoreSingleItem(state.dexie, 'slots', data, 'number')
    }

    const editSlotNumber = number => {
        if (number < 0) {
            return
        }
        let slot = localSlotsRef.current[number]
        if (slot && slot.counterState) {
            actions.loadCounter(slot.counterState)
        }
    }

    const guideTypeChange = _guideType => {
        setGuideType(_guideType)
    }

    return (
        <div id="sheet" className="sheet" ref={sheetRef}>
            <SheetControl />
            <SheetOperations guideTypeChange={guideTypeChange} loadedGuideType={guideType} />
            <div className="sheet-display">
                <svg ref={svgRef} id="sheetDisplay" className="sheet-display-svg" />
                <svg id="sheetDisplayDragLayer" className="drag-layer" />
                <SheetCounterPopper
                    clickedSlot={clickedSlot} hide={hidePopper} editSlotNumber={editSlotNumber} deleteSlotNumber={deleteSlotNumber}
                />
            </div>
        </div>
    )
}
export default Sheet