import React, { useState } from 'react'
import './Tabs.scss'
const Tabs = ({ tabs }) => {
    const [selectedIndex, setSelectedIndex] = useState(0)

    const tabSelect = (evt) => {
        setSelectedIndex(parseInt(evt.target.id.split(/(\d+)/)[1]))
    }

    return (
        <div className="tabs">
            <div className="tab-labels">
                {
                    tabs.map((tab, index) => {
                        return (
                            <div id={`tab_${index}`} key={index} className={selectedIndex === index ? 'tab-title selected' : 'tab-title'} onClick={tabSelect} >{tab.tabTitle}</div>
                        )
                    })
                }
            </div>
            <div className="tab-shadow" />
            <div className="tab-contents">
            {
                    tabs.map((tab, index) => {
                        return (
                            <div id={`tab_content_${index}`} key={`content${index}`} className={selectedIndex === index ? 'tab-contents' : 'display-none'} >{tab.tabContents}</div>
                        )
                    })
                }
            </div>
        </div>
    );
}
export default Tabs
