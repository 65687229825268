import React, { useState, useEffect, useContext } from 'react'
import Snap from 'snapsvg-cjs'
import { StoreContext } from "../../context/StoreContext"
import Utility from "../../objects/Utility"
import './SvgButton.scss'
const SvgButton = ({ svgKey, selectButton, selected, largeButton, layerKey }) => {
    const { state } = useContext(StoreContext)
    const [svgName, setSvgName] = useState('')
    const [svgCode, setSvgCode] = useState('')

    useEffect(() => {
        // if this is a custom svgs layer being duplicated, then the svgs have to have
        // their ids changed, cause we cant have two different layers using the same svgs
        // that have the same ids being shown in the layers menu.
        let svgData = state.svgs.find(sd => sd.svgKey === svgKey)
        if (svgData) {
            if (svgData.hasOwnProperty('svgName')) {
                let shortenedName = svgData.svgName.replace('misc unit symbol ', '')
                shortenedName = shortenedName.replace('nato symbol ', '')
                shortenedName = shortenedName.replace('common symbol ', '')
                shortenedName = shortenedName.replace('common symbols ', '')
                shortenedName = shortenedName.replace('ww2 vehicles ', '')
                setSvgName(shortenedName)
            }
            if (svgData.hasOwnProperty('svgCode')) {
                let svg = svgData.svgCode
                // seems an encoding specifier (UTF-8) needs to be on top of the svg code. Officially, its not needed. But,
                // it seems it is needed.
                if (svg.indexOf('<?xml') === -1) {
                    svg = `<?xml version="1.0" encoding="UTF-8"?>
                            ${svg}`
                }
                let isCustomSvgsLayer = Utility.isDuplicatedCustomSvgsLayer(layerKey, state.layers)
                if (isCustomSvgsLayer) {
                    // we need to replace the ids with something different.
                    let uniquePrepend = svgData.uniquePrepend
                    if (uniquePrepend) {
                        let _uniquePrepend = 'b' + Utility.randomString(8)
                        _uniquePrepend.replaceAll('_', '') // get rid of any underscores cause we need to use it as a known separator.
                        _uniquePrepend += '_'
                        svg = svg.replaceAll(uniquePrepend, _uniquePrepend)
                    }
                }
                setSvgCode(svg)
            }
        }

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const extractTopSvgTag = str => {
        let startSvgTag = str.indexOf('<svg')
        if (startSvgTag > -1) {
            let endSvgTag = str.indexOf('>', startSvgTag + 1)
            if ((startSvgTag > -1 && endSvgTag > -1) && (endSvgTag > startSvgTag)) {
                return str.substring(startSvgTag, endSvgTag)
            }
        }
        return null
    }

    const extractWidthHeight = str => {
        let foundWidth = -1
        let foundHeight = -1

        let svgTag = extractTopSvgTag(str)
        if (svgTag) {
            let splitted = Utility.strSplitOnNonEnclosedSpaces(svgTag)
            splitted.forEach(str => {
                if (str.startsWith('width=')) {
                    foundWidth = Utility.extractNumber(str)
                }
                if (str.startsWith('height=')) {
                    foundHeight = Utility.extractNumber(str)
                }
            })

        }

        if (foundWidth > -1 && foundHeight > -1) {
            return { w: foundWidth, h: foundHeight }
        }
        return null
    }

    useEffect(() => {
        if (svgCode) {
            let _paper = Snap("#svg_button_" + layerKey + '_' + svgKey)
            let parsed = Snap.parse(svgCode)
            _paper.append(parsed)

            // get images to fill up most of the button area
            if (Utility.isRawSvgsLayer(layerKey, state.layers)) {
                let test = _paper.select("path")
                if (test) {
                    let bbox = test.getBBox()
                    let newViewbox = Utility.roundFloat(bbox.x * 1.1,0) + ' ' +
                    Utility.roundFloat(bbox.y * 1.1,0) + ' ' +
                    Utility.roundFloat(bbox.width * 1.1,0) + ' ' +
                    Utility.roundFloat(bbox.height * 1.1,0)
                    let tsvg = _paper.select("svg")
                    tsvg.node.setAttribute('viewBox', newViewbox);
                }
                else {
                    console.warn('no path for ', svgKey)
                }
            }
            if (svgCode && largeButton) {
                let widthHeight = extractWidthHeight(svgCode)
                if (widthHeight) {
                    const { w, h } = widthHeight
                    let svgElement = document.getElementById("svg_button_" + layerKey + '_' + svgKey).getElementsByTagName('svg')[0];
                    if (svgElement) {
                        let useWidth = 115
                        let useHeight = 115
                        if (w && h) {
                            if (w > h) {
                                useWidth = 115
                                useHeight = (h / w) * 115
                            }
                            else {
                                useHeight = 115
                                useWidth = (w / h) * 115
                            }
                        }

                        svgElement.setAttribute('width', useWidth)
                        svgElement.setAttribute('height', useHeight)
                    }
                }
            }
        }
    }, [svgCode]) // eslint-disable-line react-hooks/exhaustive-deps

    const _selectButton = key => {
        selectButton(key)
    }

    return <div className={largeButton ? 'svg-button large-button' : 'svg-button'}>
        <div className={selected ? 'svg-code selected' : 'svg-code'} id={`svg_button_${layerKey}_${svgKey}`} onClick={() => _selectButton(svgKey)} />
        <div className={selected ? 'svg-name selected' : 'svg-name'}>{svgName}</div>
    </div>

}
export default SvgButton