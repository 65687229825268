import Utility from './Utility'
import Snap from 'snapsvg-cjs'

const UnitSizeSymbols = {

    draw: (g, settings, svgs) => {

        let xpos = 0
        let ypos = 0
        let opacity = ''
        let rotation = 0
        let svgKey = ''
        let symbolGap = 0
        let derivedValues = {}
        settings.forEach(setting => {
            switch (setting.name) {
                case 'dimensions':
                    if (!setting.value || Array.isArray(setting.value) === false) {
                        derivedValues.width = 55
                        derivedValues.height = 55
                    }
                    else {
                        derivedValues.width = Utility.roundFloat(setting.value[0], 1)
                        derivedValues.height = Utility.roundFloat(setting.value[1], 1)
                    }
                    break
                case 'xy position':
                    if (Array.isArray(setting.value) === false || setting.value.length !== 2) {
                        setting.value = [1, 1]
                    }
                    if (isNaN(setting.value[0])) {
                        setting.value[0] = 1
                    }
                    if (isNaN(setting.value[1])) {
                        setting.value[1] = 1
                    }
                    derivedValues.xpos = Utility.roundFloat(setting.value[0], 1)
                    derivedValues.ypos = Utility.roundFloat(setting.value[1], 1)
                    break
                case 'color': derivedValues.color = setting.value
                    break
                case 'line thickness': derivedValues.lineThickness = Utility.roundFloat(setting.value / 10, 2)
                    break
                case 'svgKey': derivedValues.svgKey = parseInt(setting.value)
                    break
                case 'opacity': opacity = Utility.roundFloat(setting.value / 100, 3)
                    break
                case 'rotation': derivedValues.rotation = Utility.roundFloat(setting.value, 2)
                    break
                case 'symbol gap': derivedValues.symbolGap = Utility.roundFloat(setting.value, 2)
                    break
                default: if (!setting.name.startsWith('spacer')) {
                    console.warn('we seem to have missed one: ', setting.name)
                }
            }
        })
        if (derivedValues.color === '') {
            derivedValues.color = 'transparent'
        }

        if (derivedValues.width === undefined || derivedValues.height === undefined) {
            derivedValues.width = 66
            derivedValues.height = 66
        }
        let width = derivedValues.width
        let height = derivedValues.height

        xpos = derivedValues.xpos
        ypos = derivedValues.ypos
        rotation = derivedValues.rotation
        svgKey = derivedValues.svgKey
        symbolGap = derivedValues.symbolGap
        let svgObj = svgs.find(svg => svg.svgKey === svgKey)
        if (!svgObj) {
            console.warn('no svgObj aborting')
            return
        }
        let svgName = svgObj.svgName


        if (svgName === 'team/crew') {
            width = 160 * (width / 100)
            height = 160 * (height / 100)
            derivedValues.lineThickness += 2
            let tcx = 0
            let tcy = 0
            let tcrx = width / 2
            let tcry = height / 2
            UnitSizeSymbols.drawBlankEllipse(g, tcx, tcy, tcrx, tcry, derivedValues)
            let x1 = -width / 2
            let y1 = height / 2
            let x2 = width / 2
            let y2 = -height / 2
            UnitSizeSymbols.drawLine(g, x1, y1, x2, y2, derivedValues)
        }

        if (svgName === 'squad') {
            width = 100 * (width / 20)
            height = 100 * (height / 20)
            let tcx = 0
            let tcy = 0
            let tcrx = width / 5
            let tcry = height / 5
            //UnitSizeSymbols.drawCircle(g, tcx, tcy, tcry, derivedValues)
            UnitSizeSymbols.drawEllipse(g, tcx, tcy, tcrx, tcry, derivedValues)
        }

        if (svgName === 'section') {
            width = 100 * (width / 100)
            height = 100 * (height / 100)
            let tcx = 0
            let tcy = 0
            let tcrx = width / 5
            let tcry = height / 5
            let gap = tcrx + (tcrx * (symbolGap / 100))
            //UnitSizeSymbols.drawCircle(g, tcx - gap, tcy, tcr, derivedValues)
            //UnitSizeSymbols.drawCircle(g, tcx + gap, tcy, tcr, derivedValues)
            UnitSizeSymbols.drawEllipse(g, tcx - gap, tcy, tcrx, tcry, derivedValues)
            UnitSizeSymbols.drawEllipse(g, tcx + gap, tcy, tcrx, tcry, derivedValues)
        }


        if (svgName === 'platoon') {
            width = 100 * (width / 20)
            height = 100 * (height / 20)
            let tcx = 0
            let tcy = 0
            let tcrx = width / 5
            let tcry = height / 5
            let gap = tcrx * 2 + (tcrx * 1.75 * (symbolGap / 100))
            // UnitSizeSymbols.drawCircle(g, tcx - gap, tcy, tcr, derivedValues)
            // UnitSizeSymbols.drawCircle(g, tcx, tcy, tcr, derivedValues)
            // UnitSizeSymbols.drawCircle(g, tcx + gap, tcy, tcr, derivedValues)

            UnitSizeSymbols.drawEllipse(g, tcx - gap, tcy, tcrx, tcry, derivedValues)
            UnitSizeSymbols.drawEllipse(g, tcx, tcy, tcrx, tcry, derivedValues)
            UnitSizeSymbols.drawEllipse(g, tcx + gap, tcy, tcrx, tcry, derivedValues)
        }

        if (svgName === 'company') {
            height = 90 * (height / 50)
            derivedValues.lineThickness = derivedValues.lineThickness * 3
            let x1 = 0
            let y1 = -height / 2
            let x2 = 0
            let y2 = height / 2
            UnitSizeSymbols.drawLine(g, x1, y1, x2, y2, derivedValues)
        }

        if (svgName === 'battalion') {
            width = 45 //* (width / 50)
            height = 90 * (height / 50)
            let symbolGapRatio = derivedValues.symbolGap / 200
            derivedValues.lineThickness = derivedValues.lineThickness * 3

            let x1 = width * symbolGapRatio// + derivedValues.lineThickness
            let y1 = -height / 2
            let x2 = width * symbolGapRatio// + derivedValues.lineThickness
            let y2 = height / 2
            UnitSizeSymbols.drawLine(g, x1, y1, x2, y2, derivedValues)

            x1 = -width * symbolGapRatio// - derivedValues.lineThickness
            y1 = -height / 2
            x2 = -width * symbolGapRatio// - derivedValues.lineThickness
            y2 = height / 2
            UnitSizeSymbols.drawLine(g, x1, y1, x2, y2, derivedValues)
        }


        if (svgName === 'regiment') {
            width = 90 * (width / 50)
            height = 90 * (height / 50)
            let symbolGapRatio = derivedValues.symbolGap / 200
            derivedValues.lineThickness = derivedValues.lineThickness * 3

            let x1 = width * symbolGapRatio * 3// + derivedValues.lineThickness
            let y1 = -height / 2
            let x2 = width * symbolGapRatio * 3// + derivedValues.lineThickness
            let y2 = height / 2
            UnitSizeSymbols.drawLine(g, x1, y1, x2, y2, derivedValues)

            x1 = 0
            y1 = -height / 2
            x2 = 0
            y2 = height / 2
            UnitSizeSymbols.drawLine(g, x1, y1, x2, y2, derivedValues)

            x1 = -width * symbolGapRatio * 3// - derivedValues.lineThickness
            y1 = -height / 2
            x2 = -width * symbolGapRatio * 3// - derivedValues.lineThickness
            y2 = height / 2
            UnitSizeSymbols.drawLine(g, x1, y1, x2, y2, derivedValues)
        }


        if (svgName === 'brigade') {
            derivedValues.width *= 7
            derivedValues.height *= 7
            derivedValues.lineThickness *= 3
            UnitSizeSymbols.drawXs(1, g, derivedValues)
        }

        if (svgName === 'division') {
            derivedValues.width *= 7
            derivedValues.height *= 7
            derivedValues.lineThickness *= 3
            UnitSizeSymbols.drawXs(2, g, derivedValues)
        }

        if (svgName === 'corps') {
            derivedValues.width *= 7
            derivedValues.height *= 7
            derivedValues.lineThickness *= 3
            UnitSizeSymbols.drawXs(3, g, derivedValues)
        }

        if (svgName === 'army') {
            derivedValues.width *= 7
            derivedValues.height *= 7
            derivedValues.lineThickness *= 3
            UnitSizeSymbols.drawXs(4, g, derivedValues)
        }

        if (svgName === 'army group') {
            derivedValues.width *= 7
            derivedValues.height *= 7
            derivedValues.lineThickness *= 3
            UnitSizeSymbols.drawXs(5, g, derivedValues)
        }

        if (opacity < 1) {
            g.attr({ "opacity": opacity })
        }

        if (xpos !== 0 || ypos !== 0) {
            UnitSizeSymbols.applyTranslation(g, xpos, ypos)
        }

        if (rotation > 0) {
            UnitSizeSymbols.applyRotation(g, rotation)
        }
        if (svgName === 'regiment') {
            // disable an input since it has no effect for regiment
            return ['scale width for Unit Size']
        }
        if (svgName === 'team/crew') {
            // disable an input since it has no effect for team/crew
            return ['symbol gap Unit Size']
        }
        if (svgName === 'squad') {
            return ['line thickness Unit Size', 'symbol gap Unit Size']
        }
        if (svgName === 'section') {
            return ['line thickness Unit Size']
        }
        if (svgName === 'platoon') {
            return ['line thickness Unit Size']
        }
        if (svgName === 'company') {
            return ['scale width for Unit Size', 'symbol gap Unit Size']
        }
        if (svgName === 'brigade') {
            return ['symbol gap Unit Size']
        }
        if (svgName === 'division') {
            return []
        }
        if (svgName === 'battalion') {
            return []
        }
        if (svgName === 'corps') {
            return []
        }
        if (svgName === 'army') {
            return []
        }
        if (svgName === 'army group') {
            return []
        }
        return []
    },


    drawXs: (numberOfXs, g, settings) => {
        let xSpread = 10 * settings.width / 100
        let ySpread = 15 * settings.height / 100
        let centerX = 0
        let centerY = 0
        let leftTopX = centerX - xSpread
        let leftTopY = centerY - ySpread
        let rightTopX = centerX + xSpread
        let rightTopY = leftTopY
        let rightBottomX = rightTopX
        let rightBottomY = centerY + ySpread
        let leftBottomX = leftTopX
        let leftBottomY = rightBottomY

        let widthOfX = rightTopX - leftTopX + settings.lineThickness
        let distanceXtoX = widthOfX + (widthOfX * (settings.symbolGap / 100))
        let widthWholeGraphic = (widthOfX + (widthOfX * (settings.symbolGap / 100))) * (numberOfXs - 1) + widthOfX
        let centerIt = (widthWholeGraphic / 2) - (widthOfX / 2)
        for (let i = 0; i < numberOfXs; i++) {
            let newX1 = leftTopX + (i * distanceXtoX) - centerIt
            let newY1 = leftTopY
            let newX2 = rightBottomX + (i * distanceXtoX) - centerIt
            let newY2 = rightBottomY
            let newX3 = rightTopX + (i * distanceXtoX) - centerIt
            let newY3 = rightTopY
            let newX4 = leftBottomX + (i * distanceXtoX) - centerIt
            let newY4 = leftBottomY
            UnitSizeSymbols.drawLine(g, newX1, newY1, newX2, newY2, settings)
            UnitSizeSymbols.drawLine(g, newX3, newY3, newX4, newY4, settings)
        }
    },

    drawLine: (g, x1, y1, x2, y2, settings) => {
        x1 = Utility.roundFloat(x1, 3)
        y1 = Utility.roundFloat(y1, 3)
        x2 = Utility.roundFloat(x2, 3)
        y2 = Utility.roundFloat(y2, 3)
        g.line(x1, y1, x2, y2).attr({
            "stroke": settings.color,
            "strokeWidth": settings.lineThickness,
            "strokeLinecap": "round"
        })
    },

    drawBlankEllipse: (g, x, y, rx, ry, settings) => {
        x = Utility.roundFloat(x, 2)
        y = Utility.roundFloat(y, 2)
        rx = Utility.roundFloat(rx, 2)
        ry = Utility.roundFloat(ry, 2)
        g.ellipse(Utility.roundFloat(x, 2), Utility.roundFloat(y, 2), Utility.roundFloat(rx, 2), Utility.roundFloat(ry, 2)).attr({
            "stroke": settings.color,
            "strokeWidth": settings.lineThickness,
            "fill": "none"
        })
    },



    drawTriangle: (g, tx1, ty1, tx2, ty2, tx3, ty3, settings) => {
        tx1 = Utility.roundFloat(tx1, 2)
        ty1 = Utility.roundFloat(ty1, 2)
        tx2 = Utility.roundFloat(tx2, 2)
        ty2 = Utility.roundFloat(ty2, 2)
        tx3 = Utility.roundFloat(tx3, 2)
        ty3 = Utility.roundFloat(ty3, 2)
        g.path(`M ${tx1} ${ty1} ${tx2} ${ty2} ${tx3} ${ty3} z`).attr({
            "stroke": "none",
            "strokeWidth": 0,
            "fill": settings.color,
        })
    },

    drawCircle: (g, x, y, r, settings) => {
        x = Utility.roundFloat(x, 2)
        y = Utility.roundFloat(y, 2)
        r = Utility.roundFloat(r, 2)
        g.circle(x, y, r).attr({
            "stroke": "none",
            "fill": settings.color
        })
    },

    drawEllipse: (g, x, y, rx, ry, settings) => {
        x = Utility.roundFloat(x, 2)
        y = Utility.roundFloat(y, 2)
        rx = Utility.roundFloat(rx, 2)
        ry = Utility.roundFloat(ry, 2)
        g.ellipse(Utility.roundFloat(x, 2), Utility.roundFloat(y, 2), Utility.roundFloat(rx, 2), Utility.roundFloat(ry, 2)).attr({
            "stroke": "none",
            "fill": settings.color
        })
    },

    drawBlankCircle: (g, x, y, r, settings) => {
        x = Utility.roundFloat(x, 2)
        y = Utility.roundFloat(y, 2)
        r = Utility.roundFloat(r, 2)
        g.circle(x, y, r).attr({
            "stroke": settings.color,
            "strokeWidth": settings.lineThickness,
            "fill": "none"
        })
    },

    applyRotation: (g, rotation) => {
        let measureG = g.getBBox()
        let currentMatrix = g.transform().localMatrix;
        let addMatrix = new Snap.Matrix()
        addMatrix.rotate(rotation, measureG.cx, measureG.cy)
        addMatrix.add(currentMatrix)
        g.transform(addMatrix)
    },

    applyTranslation: (g, xpos, ypos) => {
        let currentMatrix = g.transform().localMatrix;
        let addMatrix = new Snap.Matrix()
        addMatrix.translate(xpos, ypos)
        addMatrix.add(currentMatrix)
        g.transform(addMatrix)
    },

}
export default UnitSizeSymbols