import React, { useState, useEffect, useContext } from 'react'
import Utility from "../../objects/Utility";
import { StoreContext } from "../../context/StoreContext"
import './InputXyPosition.scss'
const InputXyPosition = ({ layerKey, input, setValue, useValue, delayIndex }) => {
    const { state } = useContext(StoreContext)
    // first is x, second is y, the second set are for coordinating line drags. so we can 
    //update both ends of the line at the same time.
    const [xyVal, setXyVal] = useState([Utility.roundFloat(useValue[0], 1), Utility.roundFloat(useValue[1], 1), null, null]);
    const [xyValText, setXyValText] = useState([Utility.roundFloat(useValue[0], 1), Utility.roundFloat(useValue[1], 1)]);
    const [minRange] = useState(-100)
    const [maxRange] = useState(100)
    const [initialEffect, setInitialEffect] = useState(true)
    const [timer, setTimer] = useState(null)
    const [textInputStyle] = useState('text-input-style')
    const [warningTextInputWidth, setWarningTextInputWidth] = useState('')
    const [warningTextInputHeight, setWarningTextInputHeight] = useState('')

    useEffect(() => {
        if (state.dragUpdate.layerKey === 0) {
            return
        }
        if (state.dragUpdate.hasOwnProperty('layerKey') &&
            state.dragUpdate.hasOwnProperty('inputKey') &&
            !isNaN(state.dragUpdate.x) && !isNaN(state.dragUpdate.y)) {
            if (layerKey === state.dragUpdate.layerKey
                && input.inputKey === state.dragUpdate.inputKey) {

                // special case for Line layer. There are two xy position, one for start of line, and one for end of line.
                if (input.named === 'line start' || input.named === 'line end') {
                    let currentX = xyVal[0]
                    let currentY = xyVal[1]
                    let modifiedX = Utility.roundFloat(currentX + state.dragUpdate.x, 1)
                    let modifiedY = Utility.roundFloat(currentY + state.dragUpdate.y, 1)
                    setXyVal([modifiedX, modifiedY])
                    setXyValText([modifiedX, modifiedY])
                }
                else {
                    if (input.named === 'center xy position') {
                        let currentX = xyVal[0]
                        let currentY = xyVal[1]
                        let modifiedX = Utility.roundFloat(currentX + state.dragUpdate.x, 1)
                        let modifiedY = Utility.roundFloat(currentY + state.dragUpdate.y, 1)
                        setXyVal([modifiedX, modifiedY])
                        setXyValText([modifiedX, modifiedY])
                        setWarningTextInputWidth('')
                        setWarningTextInputHeight('')
                    }
                    else {
                        setXyVal([Utility.roundFloat(state.dragUpdate.x, 1), Utility.roundFloat(state.dragUpdate.y, 1)])
                        setXyValText([Utility.roundFloat(state.dragUpdate.x, 1), Utility.roundFloat(state.dragUpdate.y, 1)])
                        setWarningTextInputWidth('')
                        setWarningTextInputHeight('')
                    }

                }
            }
        }
    }, [state.dragUpdate]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        return () => {
            if (timer) {
                clearTimeout(timer)
            }
        };
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (useValue !== null && useValue !== undefined) {
            if (state.activeLayerValues[layerKey + '_' + input.inputKey] !== undefined) {
                if (xyVal[0] !== useValue[0] || xyVal[1] !== useValue[1]) {
                    setXyVal(useValue)
                }
            }
        }
    }, [useValue]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (initialEffect) {
            setInitialEffect(false)
            return
        }
        if (xyVal === null || xyVal === undefined) {
            return
        }
        if (Array.isArray(xyVal) === false) {
            return
        }
        if (xyVal.length === 0) {
            return
        }
        if (xyVal[0] === null || xyVal[0] === undefined || xyVal[1] === null || xyVal[1] === undefined) {
            return
        }
        let x = Number(xyVal[0])
        let y = Number(xyVal[1])
        //setValue(input.inputKey, [x, y])
        if (!timer) {
            let thisTimer = setTimeout(() => {

                let existingValue = Utility.currentLayerInputValue(layerKey, input.inputKey, state.activeLayerValues)
                // if this input is already the same value as whats in state.activeLayerValues, do not re-send the same value back.
                if (!existingValue || !Array.isArray(existingValue) || Number(existingValue[0]) !== x || Number(existingValue[1]) !== y) {
                    setValue(input.inputKey, [x, y])
                }
                setTimer(null)
            }, 0)
            setTimer(thisTimer)
        }
        else {
            clearTimeout(timer)
            let thisTimer = setTimeout(() => {
                let existingValue = Utility.currentLayerInputValue(layerKey, input.inputKey, state.activeLayerValues)
                // if this input is already the same value as whats in state.activeLayerValues, do not re-send the same value back.
                if (!existingValue || !Array.isArray(existingValue) || Number(existingValue[0]) !== x || Number(existingValue[1]) !== y) {
                    setValue(input.inputKey, [x, y])
                }
                setTimer(null)
            }, 0)
            setTimer(thisTimer)
        }




    }, [xyVal]) // eslint-disable-line react-hooks/exhaustive-deps

    const changeXyVal = evt => {
        let value = evt.target.value
        // handle NaN
        if (typeof(value) !== 'string' && evt.target.id === 'input_text_width') {
            setXyValText(['', xyValText[1]])
            setWarningTextInputWidth('warning')
            return
        }
        if (typeof(value) !== 'string' && evt.target.id === 'input_text_height') {
            setXyValText([xyValText[0], ''])
            setWarningTextInputHeight('warning')
            return
        }

        // handle slider events
        if (evt.target.id === 'input_x') {
            value = Utility.roundFloat(value, 1)
            setXyVal([value, xyVal[1]])
            setXyValText([value, xyValText[1]])
            setWarningTextInputWidth('')
            return
        }
        if (evt.target.id === 'input_y') {
            value = Utility.roundFloat(value, 1)
            setXyVal([xyVal[0], value])
            setXyValText([xyValText[0], value])
            setWarningTextInputHeight('')
            return
        }

        // handle text input events, and interpret value to see if it can be sent to the slider safely.
        if (evt.target.id === 'input_text_width') {
            setXyValText([value.trim(), xyValText[1]])
        }
        if (evt.target.id === 'input_text_height') {
            setXyValText([xyValText[0], value.trim()])
        }

        // this is just for the warning background colors on the text inputs
        if (Utility.isNumeric(value)) {
            let interpretedValue = Utility.roundFloat(value, 1)
            if (interpretedValue < -100 || interpretedValue > 100) {
                if (evt.target.id === 'input_text_width') {
                    setWarningTextInputWidth('warning')
                    return
                }
                if (evt.target.id === 'input_text_height') {
                    setWarningTextInputHeight('warning')
                    return
                }
            }
            else {
                if (evt.target.id === 'input_text_width') {
                    setWarningTextInputWidth('')
                }
                if (evt.target.id === 'input_text_height') {
                    setWarningTextInputHeight('')
                }
            }
        }
        // interpret value
        if (Utility.isNumeric(value)) {
            let interpretedValue = Utility.roundFloat(value, 1)

            if (interpretedValue < -100) {
                if (evt.target.id === 'input_text_width') {
                    setXyVal([-100, xyVal[1]]) // ignore the illegally low value, set slider to max negative
                    return
                }
                if (evt.target.id === 'input_text_height') {
                    setXyVal([xyVal[1], -100]) // ignore the illegally low value, set slider to max negative
                    return
                }
            }
            if (interpretedValue > 100) {
                if (evt.target.id === 'input_text_width') { // ignore the illegally high value, set slider to max positive
                    setXyVal([100, xyVal[1]])
                    return
                }
                if (evt.target.id === 'input_text_height') {
                    setXyVal([xyVal[0], 100]) // ignore the illegally high value, set slider to max positive
                    return
                }
            }
            if (evt.target.id === 'input_text_width') { // ignore the illegally high value, set slider to max positive
                setXyVal([interpretedValue, xyVal[1]])
                setWarningTextInputWidth('')
                return
            }
            if (evt.target.id === 'input_text_height') {
                setXyVal([xyVal[1], interpretedValue]) // ignore the illegally high value, set slider to max positive
                setWarningTextInputHeight('')
                return
            }


        }
        else {
            if (evt.target.id === 'input_text_width') { // ignore the illegally high value, set slider to max positive
                setWarningTextInputWidth('warning')
                return
            }
            if (evt.target.id === 'input_text_height') {
                setWarningTextInputHeight('warning')
                return
            }
        }
    }

    useEffect(() => {
        if (state.mouseDblClick) {
            if (state.mouseDblClick.target && state.mouseDblClick.target.id) {
                if (state.mouseDblClick.target.id === 'xy_' + layerKey + '_' + input.inputKey
                    ||
                    state.mouseDblClick.target.id === 'xy_' + layerKey + '_' + input.inputKey + '_x'
                    ||
                    state.mouseDblClick.target.id === 'xy_' + layerKey + '_' + input.inputKey + '_y'
                ) {
                    let foundLayer = state.layers.find(ly => ly.layerKey === layerKey)
                    if (foundLayer) {
                        let foundInput = foundLayer.inputs.find(inp => inp.inputKey === input.inputKey)
                        if (foundInput) {
                            let xyVal_default = foundInput.defaultFloatArrayValue
                            if (xyVal_default !== null) {
                                if (typeof xyVal_default === 'string') {
                                    xyVal_default = Utility.convertPostgresArrayToArray(xyVal_default)
                                }
                                if (xyVal_default && Array.isArray(xyVal_default) && xyVal_default.length === 2) {
                                    if (state.mouseDblClick.target.id.endsWith('_x')) {
                                        setXyVal([xyVal_default[0], xyVal[1]])
                                        setXyValText([xyVal_default[0], xyVal[1]])
                                        setWarningTextInputWidth('')
                                    }
                                    else {
                                        if (state.mouseDblClick.target.id.endsWith('_y')) {
                                            setXyVal([xyVal[0], xyVal_default[1]])
                                            setXyValText([xyVal[0], xyVal_default[1]])
                                            setWarningTextInputHeight('')
                                        }
                                        else {
                                            setXyVal([xyVal_default[0], xyVal_default[1]])
                                            setXyValText([xyVal_default[0], xyVal_default[1]])
                                            setWarningTextInputWidth('')
                                            setWarningTextInputHeight('')
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }, [state.mouseDblClick]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div id={`input_xy_position_${input.inputKey}`} className="input-xy-position">
            <div id={`xy_${layerKey}_${input.inputKey}`} className="named">{input.named}</div>
            <div className="coordinate-container">
                <span id={`xy_${layerKey}_${input.inputKey}_x`}>x:</span>
                <input type="range" min={minRange} max={maxRange} className="slider" id="input_x"
                    value={xyVal[0]}
                    step="0.1"
                    onChange={changeXyVal}
                />
                <div><input type="text" className={`${textInputStyle} ${warningTextInputWidth}`} onChange={changeXyVal} id="input_text_width" value={xyValText[0]} /></div>
            </div>
            <div className="coordinate-container">
                <span id={`xy_${layerKey}_${input.inputKey}_y`}>y:</span>
                <input type="range" min={minRange} max={maxRange} className="slider" id="input_y"
                    value={xyVal[1]}
                    step="0.1"
                    onChange={changeXyVal}
                />
                <div><input type="text" className={`${textInputStyle} ${warningTextInputHeight}`} onChange={changeXyVal} id="input_text_height" value={xyValText[1]} /></div>
            </div>
        </div>
    )
}
export default InputXyPosition