import React, { useState, useEffect, useContext } from 'react'
import { StoreContext } from "../../context/StoreContext"
import Utility from "../../objects/Utility"
import './InputSlider.scss'
const InputSlider = ({ layerKey, input, setValue, useValue, delayIndex }) => {
    const [rangeval, setRangeval] = useState(useValue)
    const [rangevalText, setRangevalText] = useState(useValue)
    const [minRange, setMinRange] = useState(1)
    const [maxRange, setMaxRange] = useState(100)
    const [initialEffect, setInitialEffect] = useState(true)
    const [stepValue, setStepValue] = useState(1)
    const [timer, setTimer] = useState(null)
    const [readoutDecoration, setReadoutDecoration] = useState('')
    const [inputWidth, setInputWidth] = useState('200px')
    const [warningOnInput, setWarningOnInput] = useState(false)
    const { state } = useContext(StoreContext)

    useEffect(() => {
        if (input.comment && input.comment.includes('custom width')) {
            let findWidth = input.comment.split('custom width')
            if (findWidth.length > 1) {
                let findWidth2 = findWidth[1]
                if (findWidth2) {
                    let customWidth = findWidth2.match(/\d+/)[0]
                    setInputWidth(customWidth + 'px')
                }
            }
        }

        let arr = Utility.convertPostgresArrayToArray(input.defaultArrayMinMaxValue)
        if (input.comment && input.comment.includes('percent')) {
            setReadoutDecoration('%')
        }
        if (input.comment && input.comment.includes('degrees')) {
            setReadoutDecoration('°')
        }
        if (input.comment && input.comment.includes('divide by 10')) {
            setStepValue(0.1)
        }
        else {
            setStepValue(1)
        }
        if (input.comment && input.comment.includes('use x')) {
            setInputWidth('167px')
            setReadoutDecoration('x')
            setStepValue(0.1)
        }
        if (input.named.length > 11) {
            if (input.comment && input.comment.includes('percent')) {
                setInputWidth('155px')
            }
            else {
                setInputWidth('178px')
            }
        }
        let minVal = arr[0]
        let maxVal = arr[1]
        setMinRange(minVal)
        setMaxRange(maxVal)
        return () => {
            if (timer) {
                clearTimeout(timer)
            }
        };
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (rangeval === null || rangeval === undefined) {
            return
        }
        if (initialEffect) {
            setInitialEffect(false)
            return
        }

        if (!timer) {
            let thisTimer = setTimeout(() => {
                let existingValuePicked = Utility.currentLayerInputValue(layerKey, input.inputKey, state.activeLayerValues)
                // if this input is already the same value as whats in state.activeLayerValues, do not re-send the same value back.
                if (existingValuePicked !== rangeval) {
                    setValue(input.inputKey, rangeval)
                }
                setTimer(null)
            }, 10)
            setTimer(thisTimer)
        }
        else {
            clearTimeout(timer)
            let thisTimer = setTimeout(() => {
                let existingValuePicked = Utility.currentLayerInputValue(layerKey, input.inputKey, state.activeLayerValues)
                // if this input is already the same value as whats in state.activeLayerValues, do not re-send the same value back.
                if (existingValuePicked !== rangeval) {
                    setValue(input.inputKey, rangeval)
                }
                setTimer(null)
            }, 10)
            setTimer(thisTimer)
        }

    }, [rangeval])  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (useValue !== null && useValue !== undefined) {
            if (state.activeLayerValues[layerKey + '_' + input.inputKey] !== undefined) {
                if (rangeval !== useValue) {
                    let value = useValue
                    if (stepValue === 1) {
                        value = parseInt(value)
                    }
                    setRangeval(value)
                }
            }
        }
    }, [useValue]) // eslint-disable-line react-hooks/exhaustive-deps

    const changeRangeVal = evt => {
        setRangevalText(evt.target.value)
        if (Utility.isNumeric(evt.target.value)) {
            let value = Utility.roundFloat(evt.target.value, 1)
            if( stepValue === 1 ) {
                value = parseInt(evt.target.value)
            }
            if (value < minRange || value > maxRange) {
                setWarningOnInput(true)
            }
            else {
                setWarningOnInput(false)
                setRangeval(value)
            }
        }
        else {
            setWarningOnInput(true)
        }
    }

    useEffect(() => {
        if (state.mouseDblClick) {
            if (state.mouseDblClick.target && state.mouseDblClick.target.id) {
                if (state.mouseDblClick.target.id === 'slider_' + layerKey + '_' + input.inputKey) {
                    let foundLayer = state.layers.find(ly => ly.layerKey === layerKey)
                    if (foundLayer) {
                        let foundInput = foundLayer.inputs.find(inp => inp.inputKey === input.inputKey)
                        if (foundInput) {
                            let intVal = foundInput.defaultIntValue
                            if (intVal !== null) {
                                setRangeval(intVal)
                            }
                        }
                    }
                }
            }
        }
    }, [state.mouseDblClick]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div id={`input_slider_${input.inputKey}`} className="input-slider">
            <span id={`slider_${layerKey}_${input.inputKey}`} className="named">{input.named}:</span>
            <input type="range" min={minRange} max={maxRange} className="slider" style={{ width: inputWidth }} id={`input_${input.inputKey}`}
                step={stepValue}
                value={rangeval}
                onChange={changeRangeVal}
            />
            {/* <div
                className={
                    input.comment &&
                        input.comment.includes('percentage') ? 'value-readout percent' :
                        useMinWidthValueReadout && !input.comment.includes('use x') ? 'value-readout min-width'
                            :

                            (input.comment && input.comment.includes('use x')) ? 'value-readout use-x'
                                : 'value-readout'

                }
            >{rangeval}{readoutDecoration}</div> */}
            <div className="text-readout"><input className={warningOnInput ? 'warning' : ''} type="text" id={`input_text_${input.inputKey}`} onChange={changeRangeVal} value={rangevalText} />{readoutDecoration ? <span className="decoration">{readoutDecoration}</span> : ''}</div>
        </div>

    )
}
export default InputSlider