import React, { useContext, useState, useEffect } from 'react'
// import { compress } from 'lz-string'
// import { decompress } from 'lz-string'
import Utility from "../../objects/Utility"
import Dexie from 'dexie';
import ApiHelper from "../../objects/ApiHelper"
import TopMenu from "../TopMenu/TopMenu"
import CounterMenu from "../CounterMenu/CounterMenu"
import CounterDraw from "../CounterDraw/CounterDraw"
import Overlay from "../Overlay/Overlay"
import ColorPicker from "../ColorPicker/ColorPicker"
import DataDisplay from "../DataDisplay/DataDisplay"
import InstallFont from "../InstallFont/InstallFont"
import InstallSvg from "../InstallSvg/InstallSvg"
import InstallImage from "../InstallImage/InstallImage"
import BackupMenu from "../BackupMenu/BackupMenu"
import RestoreMenu from "../RestoreMenu/RestoreMenu"
import BackupWorkspace from "../BackupWorkspace/BackupWorkspace"
import RestoreWorkspace from "../RestoreWorkspace/RestoreWorkspace"
import ErrorMessage from "../ErrorMessage/ErrorMessage"
import Help from "../Help/Help"
import Sheet from "../Sheet/Sheet"
import './Home.scss'
import WaitingOnSetup from "../WaitingOnSetup/WaitingOnSetup"
import { StoreContext } from "../../context/StoreContext";
const Home = () => {
    const { state, actions } = useContext(StoreContext)
    const [waitingOnSetup, setWaitingOnSetup] = useState(true)
    const [waitingOnSetupMessage, setWaitingOnSetupMessage] = useState('')
    const [dexieLoaded, setDexieLoaded] = useState({ layers: false, svgs: false, sheetSettings: false, slots: false, fonts: false })
    const [loaded, setLoaded] = useState(false)
    // activeLayerValues is required when loading from dexie. Not from api (doesnt exist at api)
    const [passActiveLayerValues, setPassActiveLayerValues] = useState(false)
    const [passSlots, setPassSlots] = useState(false)
    const [forceApi, setForceApi] = useState(false)
    const [clearDexieMode, setClearDexieMode] = useState(false)
    //const [completedRestore, setCompletedRestore] = useState(false)

    let loadSequence = [
        { dataName: "activeLayerValues", api: false, loaded: false },
        { dataName: "savedCounters", api: false, loaded: false },
        { dataName: "savedSheets", api: false, loaded: false },
        { dataName: "slots", api: false, loaded: false },
        { dataName: "layers", api: true, loaded: false },
        { dataName: "svgs", api: true, loaded: false, },
        { dataName: "sheetSettings", api: true, loaded: false },
        { dataName: "fonts", api: true, loaded: false }
    ]

    const setupDexie = () => {
        var db = new Dexie('snapcounter');
        db.version(9).stores({
            layers: 'layerKey, layerName, layerHidden, layerOpacity, inputs, layerInputKeys, layerOrder, parentLayerKey, layerActive, layerActiveRequiredInputKey',
            svgs: 'svgKey, svgName, svgCode, uniquePrepend',
            sheetSettings: '++index, counterSize, counterCustomSize, counterMargins, countersColumnsRows, pageMargins, useCounterCustomSize, guideType, printableArea',
            slots: 'number, xy, centerXy, pixelsWidth, position, counterState',
            activeLayerValues: 'lik, value',
            savedCounters: 'hash, name, layers, customLayers, layerKeysOrdered, svg, dateSaved',
            savedSheets: 'hash, name, slots, sheetSettings, compressedSvg, dateSaved',
            fonts: '[fontFamily+fontStyle+fontWeight], fontSrc, fontUrl, fontUnicodeRange, fontText',
            // slots: 'number, xy, centerXy, layers, customLayers, layerKeysOrdered, pixelsWidth, position, defs, svgString',
            // savedCounters: 'counterName, layers, dateSaved, dateUpdated',
            // savedSheets: 'name, counters, dateSaved, dateUpdated',
            // currentSheetSettings: '++index, countersColumnsRows, counterSize, counterCustomSize, useCounterCustomSize, counterMargins, pageMargins',
            // currentSlots: 'number, layers',
        })
        actions.dexie(db)
    }

    const processRestoreDatabase = async() => {
        if (state.restoreDatabase) {
            setForceApi(true)
            setWaitingOnSetup(true)
            await clearDexie()
            window.location.reload()
            //setWaitingOnSetup(true)
            //setWaitingOnSetupMessage('')
            //setDexieLoaded({ layers: false, svgs: false, sheetSettings: false, slots: false, fonts: false })
            //setLoaded(false)
            //setPassActiveLayerValues(false)
            //setPassSlots(false)
            //setForceApi(false)
            //await clearDexie()
            //setupDexie()
        }
    }

    useEffect(() => {
        processRestoreDatabase()
        actions.restoreDatabase(false)
    }, [state.restoreDatabase]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        async function kickOff() {
            if (state.restoreWorkspace) {
                setWaitingOnSetup(true)
                setWaitingOnSetupMessage('')
                setLoaded(false)
                setPassActiveLayerValues(false)
                setPassSlots(false)
                setForceApi(false)
                await processLoadSequence() // we need to wait for the whole load process to be done.
            }
            actions.restoreWorkspace(false) // setting this back to false will allow the app to setup.
        }
        if (state.restoreWorkspace) {
            kickOff()
        }
        checkLoadedStatus() // check loaded status since restoreWorkspace if true, would have prevent the app from stating up, unless restoreWorkspace is false.
    }, [state.restoreWorkspace]) // eslint-disable-line react-hooks/exhaustive-deps

    const clearDexie = async () => {
        await Utility.dexieClearTable(state.dexie, 'layers')
        await Utility.dexieClearTable(state.dexie, 'svgs')
        await Utility.dexieClearTable(state.dexie, 'sheetSettings')
        await Utility.dexieClearTable(state.dexie, 'slots')
        await Utility.dexieClearTable(state.dexie, 'activeLayerValues')
        await Utility.dexieClearTable(state.dexie, 'savedCounters')
        await Utility.dexieClearTable(state.dexie, 'savedSheets')
        await Utility.dexieClearTable(state.dexie, 'fonts')
    }

    useEffect(() => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let forceApi = params.get('forceApi');
        let restore = params.get('restore');
        if (forceApi === 'true') {
            console.warn('forceApi is in effect.')
            setForceApi(true)
        }
        if (restore === 'true') {
            setClearDexieMode(true)
            setForceApi(true)
            params.delete('restore')
            window.history.replaceState({}, 'snapCounter', '?restore=false')
        }
        
        let detectedOs = Utility.detectOS()
        let detectedBrowser = Utility.detectBrowser()
        console.log('detectedOs:',detectedOs)
        console.log('detectedBrowser:',detectedBrowser)
        actions.browser(detectedBrowser)
        actions.os(detectedOs)

        setupDexie()

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const processLoadSequence = async () => {
        // console.log('************************************************************************')
        // console.log('                      processLoadSequence')
        // console.log('************************************************************************')

        // upgrade info for upgrading from width, height controls, to the unified dimensions control.
        let deprecatedWidths = JSON.parse(JSON.stringify(Utility.deprecatedWidths))

        if (clearDexieMode) {
            await clearDexie()
            setClearDexieMode(false)
        }

        for (let i = 0; i < loadSequence.length; i++) {
            let seq = loadSequence[i]
            let dataName = seq.dataName
            //console.log('loadSequence[' + i + ']: ', loadSequence[i])

            ///////////////////////////////////////////////////////////////////////////////////////////////////
            //see if data is in dexie storage
            ///////////////////////////////////////////////////////////////////////////////////////////////////
            let data = await state.dexie[dataName].toArray()
            let specialAbort = false
            if( dataName === 'layers' && data.length < 16 ) {
                specialAbort = true
            }
            if (data && forceApi === false && !specialAbort) {
                if (data && data.length > 0) {
                    setWaitingOnSetupMessage('Loading ' + dataName + ' from IndexedDb')

                    // send data to state
                    if (dataName === 'sheetSettings') {
                        data = data[0] // it should just be an object, so we put it into an array.
                    }
                    if (dataName === 'activeLayerValues' && Array.isArray(data)) {
                        // we need to upgrade deprecated width height values to new dimensions control.
                        // include any duplicated layers with the affected width height components
                        deprecatedWidths.forEach(dw => {
                            let inputWidthKey = dw.width
                            let deprecatedActiveLayerWidthObjs = data.filter(alv => alv.lik.endsWith('_' + inputWidthKey))
                            if (deprecatedActiveLayerWidthObjs) {
                                deprecatedActiveLayerWidthObjs.forEach(obj => {
                                    let layerKey = Number(obj.lik.split('_')[0])
                                    if (!dw.layerKeys.includes(layerKey)) {
                                        dw.layerKeys.push(layerKey)
                                    }
                                })
                            }

                        })

                        let deleteAlvInputFromDexieArray = []
                        deprecatedWidths.forEach(dw => {
                            dw.layerKeys.forEach(lk => {
                                let height = null
                                let width = null
                                let obj = data.find(kp => kp.lik === lk + '_' + dw.width)
                                if (obj) {
                                    width = obj.value
                                }
                                obj = data.find(kp => kp.lik === lk + '_' + dw.height)
                                if (obj) {
                                    height = obj.value
                                }
                                if (width && height) {
                                    // delete deprecated controls
                                    data = data.filter(dt => dt.lik !== lk + '_' + dw.width)
                                    data = data.filter(dt => dt.lik !== lk + '_' + dw.height)
                                    // delete deprecated inputs from dexie.activeLayerValues
                                    deleteAlvInputFromDexieArray.push(lk + '_' + dw.width)
                                    deleteAlvInputFromDexieArray.push(lk + '_' + dw.height)
                                    // insert new control
                                    let existingDimension = data.find(dt => dt.lik === lk + '_' + dw.dimensions)
                                    if (existingDimension) {
                                        existingDimension.value = [Number(width), Number(height)]
                                    }
                                    else {
                                        data.push({ lik: lk + '_' + dw.dimensions, value: [Number(width), Number(height)] })
                                    }
                                }
                            })
                        })
                        for(let d=0; d<deleteAlvInputFromDexieArray.length; d++) {
                            await state.dexie.activeLayerValues.where('lik').equals(deleteAlvInputFromDexieArray[d]).delete()
                        }

                        let arrToObject = {}
                        data.forEach(item => {
                            arrToObject[item.lik] = item.value
                        })
                        data = arrToObject
                    }

                    if (dataName === 'savedCounters' && Array.isArray(data)) {
                        let arrToObject = {}
                        data.forEach(item => {
                            arrToObject[item.hash] = item
                        })
                        data = arrToObject
                    }

                    if (dataName === 'savedSheets' && Array.isArray(data)) {
                        let arrToObject = {}
                        data.forEach(item => {
                            arrToObject[item.hash] = item
                        })
                        data = arrToObject
                    }

                    // layers needs to be ordered by layerOrder. Dexie enforces ordering by the key, which is layerKey.
                    if (dataName === 'layers' && Array.isArray(data)) {
                        data.sort((a, b) => a.layerOrder - b.layerOrder)
                        let upgradedInputKeys = null
                        let upgradedInputs = null
                        let upgradedLayers = []
                        let layerIsUpgraded = false
                        deprecatedWidths.forEach(dw => {
                            dw.layerKeys.forEach(lk => {
                                let layer = data.find(dt => dt.layerKey === lk)
                                if (layer) {
                                    layerIsUpgraded = false
                                    // upgrade layer.layerInputKeys
                                    if (layer.layerInputKeys.find(ik => ik === dw.width)) {
                                        let startDeleteIndex = layer.layerInputKeys.findIndex(ik => ik === dw.width)
                                        if (startDeleteIndex > -1) {
                                            upgradedInputKeys = JSON.parse(JSON.stringify(layer.layerInputKeys))
                                            upgradedInputKeys = upgradedInputKeys.filter((data, idx) => idx !== startDeleteIndex);
                                            upgradedInputKeys = upgradedInputKeys.filter((data, idx) => idx !== startDeleteIndex);
                                            upgradedInputKeys = upgradedInputKeys.filter((data, idx) => idx !== startDeleteIndex);
                                            upgradedInputKeys = Utility.arrayInsertAtIndexItem(upgradedInputKeys, startDeleteIndex, dw.dimensions)
                                            layer.layerInputKeys = upgradedInputKeys
                                            layerIsUpgraded = true
                                        }
                                    }

                                    // upgrade layer.inputs
                                    if (layer.inputs.find(inp => inp.inputKey === dw.width)) {
                                        let startDeleteIndex = layer.inputs.findIndex(inp => inp.inputKey === dw.width)
                                        if (startDeleteIndex > -1) {
                                            let newLayerInput = { "defaultArrayMinMaxValue": dw.defaultArrayMinMaxValue, comment: dw.comment, "defaultFloatArrayValue": dw.defaultFloatArrayValue, "defaultFloatValue": null, "defaultIntValue": null, "defaultStrValue": null, "inputKey": dw.dimensions, "list": null, "message": "width height for " + dw.name, "named": "dimensions", "stringForArray": null, "type": "width_height", "typeKey": 16, "valueClass": "array" }
                                            let deleteIndex = layer.inputs.findIndex(lin => lin.inputKey === dw.width)
                                            upgradedInputs = JSON.parse(JSON.stringify(layer.inputs))
                                            upgradedInputs = upgradedInputs.filter((data, idx) => idx !== deleteIndex);
                                            upgradedInputs = upgradedInputs.filter((data, idx) => idx !== deleteIndex);
                                            upgradedInputs = upgradedInputs.filter((data, idx) => idx !== deleteIndex);
                                            upgradedInputs = Utility.arrayInsertAtIndexItem(upgradedInputs, deleteIndex, newLayerInput)
                                            layer.inputs = upgradedInputs
                                            layerIsUpgraded = true
                                        }
                                    }
                                    if( layerIsUpgraded ) {
                                        upgradedLayers.push(layer)
                                    }
                                }
                            })
                        })
                       // update dexie data with upgraded layers
                        for(let u=0; u<upgradedLayers.length; u++) {
                            await state.dexie.layers.where("layerKey").equals(upgradedLayers[u].layerKey).modify( upgradedLayers[u] );
                        }
                    }

                    if (dataName === 'fonts') {
                        data = JSON.parse(JSON.stringify(data))
                        data.forEach(dt => delete dt.fontSrc)
                    }

                    actions[dataName](data)
                    seq.loaded = true

                }
            }

            if (seq.loaded === false) {
                ///////////////////////////////////////////////////////////////////////////////////////////////////
                // if no dexie data, then the option is to call api for the data.
                ///////////////////////////////////////////////////////////////////////////////////////////////////
                if (seq.api === false) {
                    if (dataName === 'activeLayerValues') {
                        setPassActiveLayerValues(true)
                    }
                    if (dataName === 'slots') {
                        setPassSlots(true)
                    }
                }
                if (seq.api === true) {
                    setWaitingOnSetupMessage('Loading ' + dataName + ' from server')
                    let data = await getDataFromApi(dataName)
                    if ((data && data.length > 0) || (Utility.emptyCheck(data) === false)) {


                        // sheetSettings we want as a single object, not an array. There won't be versions of sheetSettings (of itself)
                        // and we have some data transmutations to deal with
                        if (dataName === 'sheetSettings') {
                            let sheetSettings = {}
                            sheetSettings.counterCustomSize = parseFloat(data[0].counterCustomSize)
                            if (sheetSettings.counterCustomSize === -1) {
                                sheetSettings.counterCustomSize = ''
                            }
                            sheetSettings.counterSize = parseFloat(data[0].counterSize)
                            sheetSettings.counterMargins = Utility.convertPostgresArrayToArray(data[0].counterMargins)
                            sheetSettings.pageMargins = Utility.convertPostgresArrayToArray(data[0].pageMargins)
                            sheetSettings.countersColumnsRows = Utility.convertPostgresArrayToArray(data[0].countersColumnsRows)
                            sheetSettings.useCounterCustomSize = data[0].useCounterCustomSize
                            sheetSettings.printableArea = Utility.convertPostgresArrayToArray(data[0].printableArea)
                            if (data[0].guideType) {
                                sheetSettings.guideType = data[0].guideType
                            }
                            data = [sheetSettings]
                        }

                        ////////////////////////////////////////////////////////////
                        // we have data from api. Load to dexie, and state
                        ////////////////////////////////////////////////////////////


                        let count = await state.dexie[dataName].count()
                        if (count === 0 || forceApi) { // if we are doing a forceApi, put the results into dexie even if it has stuff
                            if (forceApi) {
                                await state.dexie[dataName].clear()
                            }
                            // send data to dexie
                            populateDexieTable(dataName, data)
                        }

                        // send data to state

                        // only do this when loading state with data for sheetSettings. Yea I should have never made it a single object.
                        if (dataName === 'sheetSettings') {
                            data = data[0]
                        }

                        if (dataName === 'fonts') {
                            data = JSON.parse(JSON.stringify(data))
                            data.forEach(dt => delete dt.fontSrc)
                        }

                        actions[dataName](data)
                        seq.loaded = true

                    }
                }

            }

            if (seq.loaded === false) {

            }
        }

    }

    useEffect(() => {
        if (state.activeLayerValues && Utility.emptyCheck(state.activeLayerValues) === false && waitingOnSetup) {
            checkLoadedStatus()
        }
    }, [state.activeLayerValues]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (state.layers && state.layers.length > 0 && waitingOnSetup) {
            checkLoadedStatus()
        }
    }, [state.layers]) // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (state.svgs && state.svgs.length > 0 && waitingOnSetup) {
            checkLoadedStatus()
        }
    }, [state.svgs]) // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (state.sheetSettings && Utility.emptyCheck(state.sheetSettings) === false && waitingOnSetup) {
            checkLoadedStatus()
        }
    }, [state.sheetSettings]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (state.fonts && state.fonts.length > 0 && waitingOnSetup) {


            // document.fonts.ready.then(() => {
            //     // Any operation that needs to be done only after all the fonts
            //     // have finished loading can go here.


            //     let arr = Array.from(document.fonts);
            //     let installedFonts = []
            //     arr.forEach(font => {
            //         let obj = {}
            //         obj.fontFamily = font.family
            //         obj.fontStyle = font.style
            //         obj.fontWeight = Number(font.weight)
            //         obj.fontUnicodeRange = font.unicodeRange
            //         installedFonts.push(obj)
            //     })

            //     // create a stylesheet entry for each font. Each font gets a unique id, so the
            //     // user can optionally remove it. Although we dont want them removing the Google icon
            //     // fonts, since thats used in a layer.
            //     let fontReport = []
            state.fonts.forEach(font => {
                let fontFamily = font.fontFamily
                let link = document.createElement('link')
                link.href = font.fontUrl;
                link.rel = "stylesheet";
                link.type = "text/css";
                link.id = fontFamily.replaceAll(' ', '_') + '@' + font.fontStyle + '@' + font.fontWeight
                document.head.appendChild(link);
            })

            checkLoadedStatus()

            // });
        }
    }, [state.fonts]) // eslint-disable-line react-hooks/exhaustive-deps

    const checkLoadedStatus = () => {
        // console.log('checkLoadedStatus')
        // console.log('waitingOnSetup: ', waitingOnSetup)
        // console.log('state.fonts.length: ', state.fonts.length)
        // console.log('state.sheetSettings: ', state.sheetSettings)
        // console.log('state.svgs.length: ', state.svgs.length)
        // console.log('state.layers.length: ', state.layers.length)
        // console.log('state.activeLayerValues: ', state.activeLayerValues ? state.activeLayerValues : 'null')
        // console.log('state.slots.length: ', state.slots.length)
        // console.log('checks: waitingOnSetup: ', waitingOnSetup)
        // console.log('checks: is fonts length > 0? ', state.fonts.length)
        // console.log('checks: is sheetSettings filled? ', state.sheetSettings)
        // console.log('checks: is svgs length > 0? ', state.svgs.length)
        // console.log('checks: is layers length> 0? ', state.layers.length)
        // console.log('checks: is activaLayerValues got stuff? ', state.activeLayerValues, ' or is passActiveLayers true? ', passActiveLayerValues)
        // console.log('checks: is slots length > 0? ', state.slots.length, ' or is passSlots ', passSlots)
        if (waitingOnSetup === true &&
            state.restoreWorkspace === false &&
            state.fonts.length > 0 &&
            Utility.emptyCheck(state.sheetSettings) === false &&
            state.svgs.length > 0 &&
            state.layers.length > 0 &&

            (Utility.emptyCheck(state.activeLayerValues) === false
                ||
                passActiveLayerValues === true)

            &&

            (state.slots.length > 0
                ||
                passSlots === true)

        ) {
            setWaitingOnSetupMessage('')
            //console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> Go!')
            setWaitingOnSetup(false)
            setTimeout(() => {
                setLoaded(true)
            }, 100)
        }
    }

    useEffect(() => {
        if (loaded) {
            if (navigator.storage && navigator.storage.persist) {
                navigator.storage.persist().then(persistent => {
                    if (persistent) {
                        console.warn("Storage will not be cleared except by explicit user action");
                    } else {
                        if (navigator.storage.persisted) {
                            navigator.storage.persisted().then(function (persistent) {
                                if (persistent)
                                    console.warn("Storage will not be cleared except by explicit user action.");
                                else
                                    console.warn("Storage may be cleared by the UA under storage pressure.");
                            });
                        }
                    }
                });
            }
        }

    }, [loaded])

    useEffect(() => {
        if (state.dexie) {
            setWaitingOnSetupMessage('looking for data sources...')
            processLoadSequence()
        }
    }, [state.dexie]) // eslint-disable-line react-hooks/exhaustive-deps

    const populateDexieTable = async (tableName, data) => {
        let db = state.dexie
        if (Array.isArray(data) === false) {
            // dexie needs arrays with the key in them
            if (tableName === 'sheetSettings' || tableName === 'savedCounters' || tableName === 'savedSheets') {
                data = Object.values(data)
            }
            if (tableName === 'activeLayerValues') {
                let newArray = []
                for (const [key, value] of Object.entries(data)) {
                    newArray.push({ lik: key, value })
                }
                data = newArray
            }
            if (!data) {
                // not object with keys:values? Maybe its just a string
                data = [data]
            }
        }

        // lets not do bulkAdd. There seems to be some bug that wipes out the data in dexie.
        // Lets change it to do one record at a time. At least that way any problems in the data can be pinpointed.
        let key = db[tableName].schema.primKey.name
        for (let i = 0; i < data.length; i++) {
            let dt = data[i]
            if (tableName === 'sheetSettings') {
                dt.index = 1 // artificially insert the index
            }
            db[tableName].get(db[tableName].schema.primKey.name, function (entity) {
                db[tableName].put(dt, key).then(() => {
                    let _dexieLoaded = Object.assign({}, dexieLoaded);
                    _dexieLoaded[tableName] = true
                    setDexieLoaded(_dexieLoaded)
                })
            }).catch(function () {
                db[tableName].add(dt, key).then(() => {
                    let _dexieLoaded = Object.assign({}, dexieLoaded);
                    _dexieLoaded[tableName] = true
                    setDexieLoaded(_dexieLoaded)
                })
            });

        }

    }

    const getDataFromApi = async (dataName) => {
        let response = null
        if (dataName === 'layers') {
            try {
                response = await ApiHelper.getLayers()
            }
            catch (error) {
                console.warn('exception on getLayers: ', error)
            }
            if (response) {
                let modifiedLayers = response.map(ly => {
                    if (ly.inputs === null) {
                        ly.inputs = []
                    }
                    if (ly.layerInputKeys === null) {
                        ly.layerInputKeys = []
                    }
                    ly.parentLayerKey = -1
                    return ly
                })
                return modifiedLayers
            }
        }
        if (dataName === 'svgs') {
            response = await ApiHelper.getSvgs()
        }
        if (dataName === 'fonts') {
            response = await ApiHelper.getFonts()
        }
        if (dataName === 'sheetSettings') {
            response = await ApiHelper.getSheetSettings()
        }
        if (response) {
            return await response
        }
        else {
            let message = 'unknown'
            if (response) {
                message = response
            }
            if (response && response.status) {
                message = response.status
            }
            console.warn('error on fetch, ', message)
            // processLoadSequence()
            //throw new Error(`HTTP error! status: ${message}`)
        }
    }

    useEffect(() => {
        if (state.keyUp) {
            // if (state.keyUp === 49) {
            //     console.log('state.layers: ', state.layers)
            // }
            // if (state.keyUp === 51) {
            //     console.log('state.svgs: ', state.svgs)
            // }
            // if (state.keyUp === 53) {
            //     console.log('state.activeLayerValues: ', state.activeLayerValues)
            // }
            // if (state.keyUp === 52) {
            //     console.log('state.sheetSettings: ', state.sheetSettings)
            // }
            // if (state.keyUp === 53) {
            //     console.log('state.slots: ', state.slots)
            // }
            // if (state.keyUp === 54) {
            //     console.log('state.savedSheets: ', state.savedSheets)
            // }
            // if (state.keyUp === 56) {
            //     console.log('state.savedCounters: ', state.savedCounters)
            // }
        }
    }, [state.keyUp]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        waitingOnSetup ?

            <div className="home2">
                <WaitingOnSetup message={waitingOnSetupMessage} />
            </div>
            :

            <div id="home" className={loaded ? 'home loaded' : 'home'}>
                <TopMenu />
                <div className={state.help || state.dataDisplay || state.installView ? 'display-none' : 'flex-container'}>
                    <div className="main-box-1">
                        <CounterMenu />
                        <CounterDraw />
                    </div>
                    <div className="main-box-2"><Sheet /></div>
                </div>
                <Overlay />
                <ColorPicker />
                <DataDisplay />
                <InstallFont />
                <InstallSvg />
                <InstallImage />
                <BackupMenu />
                <RestoreMenu />
                <BackupWorkspace />
                <RestoreWorkspace />
                <ErrorMessage />
                <Help />
            </div>

    )
}
export default Home;