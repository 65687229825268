import React, { useState, useEffect, useContext, useRef } from 'react'
//import { compress } from 'lz-string'
import { StoreContext } from "../../context/StoreContext"
import Utility from "../../objects/Utility"
import InputText from "../InputText/InputText"
import InputColor from "../InputColor/InputColor"
import InputSlider from "../InputSlider/InputSlider"
import InputWidthHeight from "../InputWidthHeight/InputWidthHeight"
import InputXyPosition from "../InputXyPosition/InputXyPosition"
import InputFont from "../InputFont/InputFont"
import SvgGroup from "../SvgGroup/SvgGroup"
import InputCounterShapes from "../InputCounterShapes/InputCounterShapes"
import InputRadioGroup from "../InputRadioGroup/InputRadioGroup"
import InputMaterialSymbol from "../InputMaterialSymbol/InputMaterialSymbol"
import InputFill from "../InputFill/InputFill"
import './CounterMenuLayerOptionsMenu.scss'
const CounterMenuLayerOptionsMenu = ({ layer, menuOpened, canGoActive }) => {
    const { state, actions } = useContext(StoreContext)
    const [values, _setValues] = useState({})
    const [setupValuesCompleted, setSetupValuesCompleted] = useState(false)
    const [safeLayerName, setSafeLayerName] = useState('')
    const [useLargeButtons, setUseLargeButtons] = useState(false)
    const [minMaxRanges, setMinMaxRanges] = useState([]) // only relevant to InputWidthHeight and sliders (xy is hardcoded to -100 100)
    const valuesRef = useRef(values)

    const setValues = data => {
        valuesRef.current = data
        _setValues(data)
    }

    useEffect(() => {
        createSafeLayerName()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const createSafeLayerName = () => {
        let originalLayerName = layer.layerName
        if (layer.hasOwnProperty('parentLayerKey')) {
            let parentLayerKey = layer.parentLayerKey
            if (parentLayerKey > -1) {
                let parentLayer = state.layers.find(ly => ly.layerKey === parentLayerKey)
                if (parentLayer) {
                    originalLayerName = parentLayer.layerName
                }
            }
        }
        if (originalLayerName === 'custom svgs' || originalLayerName === 'custom images') {
            setUseLargeButtons(true)
        }
        if (typeof originalLayerName === 'string' && originalLayerName.length > 0) {
            originalLayerName = originalLayerName.replaceAll(' ', '_').toLowerCase()
            setSafeLayerName(originalLayerName)
        }
    }

    useEffect(() => {
        if (setupValuesCompleted) {
            return
        }

        const updateAsync = async (updatedLayer) => {
            await Utility.dexieRemoveLayersFromActive(state.dexie, updatedLayer.layerKey)
            await Utility.updateDexieStoreSingleItem(state.dexie, 'layers', updatedLayer, 'layerKey')
            actions.layerUpdate(updatedLayer)
        }

        let alvKeys = Object.keys(state.activeLayerValues)
        let layerSpecificKeys = alvKeys.filter(ak => parseInt(ak.split('_')[0]) === layer.layerKey)
        let originalName = null
        if (layer.parentLayerKey > -1) { // meaning its a dupe
            let originalLayer = state.layers.find(ly => ly.layerKey === layer.parentLayerKey)
            if (originalLayer) {
                originalName = originalLayer.layerName
            }
        }
        if (layer.layerName === 'custom svgs' || originalName === 'custom svgs' ||
            layer.layerName === 'custom images' || originalName === 'custom images') {
            //////////////////////////////////////////////////////////
            // check the svgKeys to make sure nothing is missing.
            //////////////////////////////////////////////////////////
            let validSvgKeys = []
            let missingSvgKeys = []
            let input = layer.inputs.find(inp => inp.named === 'svgKey')
            // input is obj like {named: 'svgKey', list: [1000,1001,...] ... }

            if (input) {
                input.list.forEach(svgKey => {
                    //note - this only runs if there is at least 1 svg in the list.
                    let found = state.svgs.find(ss => Number(ss.svgKey) === Number(svgKey))
                    if (found) {
                        validSvgKeys.push(svgKey)
                    }
                    else {
                        missingSvgKeys.push(svgKey)
                    }
                })
            }

            if (validSvgKeys.length === 0) {
                // layerActive should be 0, and layerHidden should be 1, and list should be empty. If not, update state.
                if (layer.layerActive !== 0 || layer.layerHidden !== 1 || input.list.length !== 0) {
                    input.list = []
                    let updatedLayer = { ...layer, layerActive: 0, layerHidden: 1, inputs: layer.inputs }
                    updateAsync(updatedLayer)
                }
            }
            else {
                if (missingSvgKeys.length > 0) {
                    input.list = validSvgKeys
                    let updatedLayer = { ...layer, inputs: layer.inputs }
                    updateAsync(updatedLayer)
                }
            }

        }

        if (layer && layer.hasOwnProperty('inputs') && Array.isArray(layer.inputs) && layer.inputs.length > 0) {
            let _values = {}
            let _minMaxRanges = []
            let hasBeenSet = false
            layer.inputs.forEach(li => {
                if (layerSpecificKeys.length > 0) {
                    let compoundKey = layer.layerKey + '_' + li.inputKey
                    let currentValue = state.activeLayerValues[compoundKey]
                    if (currentValue !== undefined) {
                        _values[li.inputKey] = currentValue
                    }
                    // check for minmaxvalues
                    if (li.defaultArrayMinMaxValue !== null) {
                        let str = li.defaultArrayMinMaxValue.replace("{", "[");
                        str = str.replace("}", "]");
                        _minMaxRanges[li.inputKey] = JSON.parse(str)
                    }
                    if (li.defaultFloatArrayValue !== null && _values[li.inputKey] === undefined) {
                        let str = li.defaultFloatArrayValue.replace("{", "[");
                        str = str.replace("}", "]");
                        let arr = JSON.parse(str)
                        _values[li.inputKey] = arr
                    }
                    hasBeenSet = true
                }
                else {
                    hasBeenSet = false
                    if (li.stringForArray !== null && li.named === 'fill color') {
                        let str = li.stringForArray
                        _values[li.inputKey] = str
                        hasBeenSet = true
                    }
                    if (li.defaultArrayMinMaxValue !== null) {
                        let str = li.defaultArrayMinMaxValue.replace("{", "[");
                        str = str.replace("}", "]");
                        _minMaxRanges[li.inputKey] = JSON.parse(str)
                        // hasBeenSet = true
                    }
                    if (li.defaultFloatArrayValue !== null) {
                        let str = li.defaultFloatArrayValue.replace("{", "[");
                        str = str.replace("}", "]");
                        let arr = JSON.parse(str)
                        _values[li.inputKey] = arr
                        hasBeenSet = true
                    }
                    if (li.defaultFloatValue !== null) {
                        _values[li.inputKey] = li.defaultFloatValue
                        hasBeenSet = true
                    }
                    if (li.defaultIntValue !== null) {
                        _values[li.inputKey] = li.defaultIntValue
                        if (li.named === 'svgKey') {
                            if (li.defaultIntValue === 0) { // 0 will indicate to always select the first available
                                let list = li.list
                                if (list.length > li.defaultIntValue) {
                                    _values[li.inputKey] = list[li.defaultIntValue]
                                }
                            }
                            else {
                                _values[li.inputKey] = li.defaultIntValue
                            }
                        }
                        hasBeenSet = true
                    }

                    // defaulString comes through as null since I cant send empty string in postgress if (li.defaultStrValue !== null) {
                    if (!hasBeenSet) {
                        _values[li.inputKey] = li.defaultStrValue

                    }
                }
            })
            if (Utility.emptyCheck(_values) === false) {
                setValues(_values)
                setMinMaxRanges(_minMaxRanges)
            }
        }
    }, [layer]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (setupValuesCompleted) {
            // presumably, the layerApply cannot be called unless the layer is active, which requires the "canGoActive" to be true.
            if (layer.layerActive === 1) {
                layerApply()
            }
        }
    }, [layer])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let valuesKeys = Object.keys(values)
        let updateObj = {}
        valuesKeys.forEach(inputKey => {
            if (state.activeLayerValues[layer.layerKey + '_' + inputKey] !== undefined) {
                if (state.activeLayerValues[layer.layerKey + '_' + inputKey] !== values[inputKey]) {
                    updateObj[inputKey] = state.activeLayerValues[layer.layerKey + '_' + inputKey]
                }
            }
        })
        if (Utility.emptyCheck(updateObj) === false) {
            setValues({ ...values, ...updateObj })
        }

    }, [state.activeLayerValues]) // eslint-disable-line react-hooks/exhaustive-deps

    // const layerRemove = () => {
    //     if (!Utility.emptyCheck(values)) {
    //         if (layer.layerKey !== 1) {
    //             actions.activeLayerValuesRemove(layer.layerKey)
    //             Utility.dexieRemoveLayersFromActive(state.dexie, layer.layerKey)
    //         }
    //     }
    // }

    // comes from the individual Input* functions
    const setValue = (inputKey, value) => {
        let cloneValues = {}
        Object.assign(cloneValues, valuesRef.current)
        cloneValues[inputKey] = value
        if (cloneValues !== null) {
            setValues(cloneValues)
        }
    }

    const layerApply = () => {
        let cloneActiveLayerValues = {}
        let dexieCompatible = []
        Object.assign(cloneActiveLayerValues, state.activeLayerValues)

        for (const [inputKey, value] of Object.entries(values)) {
            cloneActiveLayerValues[layer.layerKey + '_' + inputKey] = value
            dexieCompatible.push({ lik: layer.layerKey + '_' + inputKey, value })
        }
        if (Utility.objectsEqual(state.activeLayerValues, cloneActiveLayerValues) === false) {
            actions.activeLayerValues(cloneActiveLayerValues)
            Utility.updateDexieStore(state.dexie, 'activeLayerValues', dexieCompatible, 'lik')
        }

        // if (dexieCompatible.length > 0) {
        //     let existingData = await Utility.dexieGetData(state.dexie, 'activeLayerValues')
        //     if (Utility.objectsEqual(existingData, dexieCompatible) === false) {
        //         Utility.updateDexieStore(state.dexie, 'activeLayerValues', dexieCompatible, 'lik')
        //     }
        // }

    }

    useEffect(() => {
        if (Utility.emptyCheck(values) === false) {
            let requiredValue = null
            let _canGoActive = false
            if (layer.layerActiveRequiredInputKey) {
                requiredValue = values[layer.layerActiveRequiredInputKey]
                _canGoActive = requiredValue !== undefined && requiredValue !== null && requiredValue !== ''
                canGoActive(_canGoActive)
            }
            else {
                _canGoActive = true
                canGoActive(true)
            }

            // layer base counter is always live.
            if (layer.layerName === 'base counter') {
                layerApply()
            }

            // if layer is active, or 
            if (layer.layerActive === 1) {
                if (_canGoActive) {
                    layerApply()
                }
                else {
                    let updatedLayer = { ...layer, layerActive: 0 }


                    // actions.layerUpdate(updatedLayer)
                    // // shouldnt we be removing layer from activeLayerValues?
                    // actions.activeLayerValuesRemove(layer.layerKey)
                    // Utility.dexieRemoveLayersFromActive(state.dexie, layer.layerKey)
                    // Utility.updateDexieStoreSingleItem(state.dexie, 'layers', updatedLayer, 'layerKey')


                    const updateRemoveAsync = async () => {
                        await Utility.dexieRemoveLayersFromActive(state.dexie, layer.layerKey)
                        await Utility.updateDexieStoreSingleItem(state.dexie, 'layers', updatedLayer, 'layerKey')
                        actions.layerUpdate(updatedLayer)
                        actions.activeLayerValuesRemove(layer.layerKey)
                    }
                    updateRemoveAsync()
                }
            }

            if (layer.layerActive === 0) {
                // if this layer is not active
                if (requiredValue !== undefined && requiredValue !== null && requiredValue !== 'none') {
                    // but selecting this value makes it active-atable, then auto-turn it on.
                    let updatedLayer = { ...layer, layerActive: 1 }
                    // actions.layerUpdate(updatedLayer)
                    // Utility.updateDexieStoreSingleItem(state.dexie, 'layers', updatedLayer, 'layerKey')

                    const updateAsync = async () => {
                        if (!state.restoreCounterState) {
                            Utility.dexieRemoveLayersFromActive(state.dexie, layer.layerKey)
                            Utility.updateDexieStoreSingleItem(state.dexie, 'layers', updatedLayer, 'layerKey')
                        }
                        actions.layerUpdate(updatedLayer)
                    }
                    updateAsync()


                }
            }
            //setTimeout(() => {
            setSetupValuesCompleted(true)
            //}, 100)
        }
    }, [values]) // eslint-disable-line react-hooks/exhaustive-deps

    const setValid = () => {
    }

    const safeJsonParse = str => {
        if( typeof(str) === 'string' ) {
            if( str === '' ) {
                return {}
            }
            let obj = {}
            try {
                obj = JSON.parse(str);
            } catch (e) {
                obj = {}
            }
            return obj
        }
        if( typeof(str) === 'object' ) {
            return str
        }
    }

    // const deleteLayerFromActiveLayerValues = async (deleteLayerKey) => {
    //     await new Promise(resolve => setTimeout(async () => {
    //         actions.activeLayerValuesRemove(deleteLayerKey)
    //         await Utility.dexieRemoveLayersFromActive(state.dexie, deleteLayerKey)
    //     }, 1000));
    // }

    return (
        <div className={`counter-menu-layer-options-menu ${menuOpened ? 'menu-opened' : ''}`}>
            <div className={`options-menu ${safeLayerName}`} name="options">
                {layer.hasOwnProperty('inputs') && setupValuesCompleted && values !== null ? layer.inputs.map((input, index) => {
                    switch (input.type) {
                        case "text": return <InputText key={index} layerKey={layer.layerKey} input={input} setValue={setValue} useValue={values[input.inputKey]} setValid={setValid} delayIndex={index} />
                        case "color": return <InputColor key={index} layerKey={layer.layerKey} input={input} setValue={setValue} useValue={values[input.inputKey]} setValid={setValid} delayIndex={index} />
                        case "fill": return <InputFill key={index} layerKey={layer.layerKey} input={input} setValue={setValue} useValue={safeJsonParse(values[input.inputKey])} setValid={setValid} delayIndex={index} />
                        case "slider": return <InputSlider key={index} layerKey={layer.layerKey} input={input} setValue={setValue} useValue={values[input.inputKey]} setValid={setValid} delayIndex={index} />

                        case "width_height": return <InputWidthHeight key={index} layerKey={layer.layerKey} input={input} setValue={setValue} useValue={values[input.inputKey]} minMaxRanges={minMaxRanges[input.inputKey]} setValid={setValid} delayIndex={index} />

                        case "spacer": return <div id={`input_spacer_${input.inputKey}`} key={index} className="spacer" />
                        case "spacer3": return <div id={`input_spacer3_${input.inputKey}`} key={index} className="spacer3" />
                        case "variable_spacer": return <div id={`input_variable_spacer_${input.inputKey}`} key={index} className="variable-spacer" style={{ height: input.defaultIntValue + 'px' }} />
                        case "label": return <div id="label" key={index} className="label">{input.message}</div>
                        case "button_svg_group": return <SvgGroup key={index} layerKey={layer.layerKey} input={input} setValue={setValue} useValue={values[input.inputKey]} setValid={setValid} delayIndex={index} largeButtons={useLargeButtons} />

                        case "xy_position": return <InputXyPosition key={index} layerKey={layer.layerKey} input={input} setValue={setValue} useValue={values[input.inputKey]} setValid={setValid} delayIndex={index} />

                        case "font": return <InputFont key={index} input={input} layerKey={layer.layerKey} setValue={setValue} useValue={values[input.inputKey]} setValid={setValid} delayIndex={index} />
                        case "counter shapes": return <InputCounterShapes key={index} layerKey={layer.layerKey} input={input} setValue={setValue} useValue={values[input.inputKey]} setValid={setValid} delayIndex={index} />
                        case "radio_group": return <InputRadioGroup key={index} layerKey={layer.layerKey} input={input} setValue={setValue} useValue={values[input.inputKey]} setValid={setValid} delayIndex={index} />
                        case "material symbol": return <InputMaterialSymbol key={index} layerKey={layer.layerKey} input={input} setValue={setValue} useValue={values[input.inputKey]} setValid={setValid} delayIndex={index} />
                        default: return ''
                    }
                }) : ''}
            </div>

        </div>
    );
}
export default CounterMenuLayerOptionsMenu