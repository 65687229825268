import { config } from '../consts/config';
import { compress, decompress } from 'lz-string'

let Utility = {

    arrayHasAllNumbers: (arr) => arr.every(function(element) {return typeof element === 'number'}),

    packageCounterState: (state) => {
        let activeLayerKeys = Utility.getVisibleLayerKeysOrdered(state.activeLayerValues, state.layers)
        let customSvgsArray = []
        let counterStateWithSvg = state.layers.map(sl => {
            let serializedSvgForLayer = ''
            let uniquePrepend = ''
            // we'll know which layers need a svg created for them if they have their layerKeys in activeLayerValues
            if (activeLayerKeys.includes(sl.layerKey)) {
                // generate the svg for this layer
                let svgEle = document.getElementById(`drawLayer${sl.layerKey}`)
                if (svgEle) {
                    // make the ids in the svg document all unique, by using a random string to prepend them with.
                    uniquePrepend = 'l' + sl.layerKey + 'l' + Utility.randomString(6)
                    uniquePrepend.replaceAll('_', '') // get rid of any underscores cause we need to use it as a known separator.
                    uniquePrepend += '_'
                    let foundIds = Utility.distillIdsFromSvgElement(svgEle)
                    serializedSvgForLayer = new XMLSerializer().serializeToString(svgEle)
                    serializedSvgForLayer = Utility.replaceIdsInSvgString(foundIds, serializedSvgForLayer, uniquePrepend)
                    //serializedSvgForLayer = serializedSvgForLayer.replaceAll("drawLayer" + sl.layerKey, uniquePrepend + "drawLayer" + sl.layerKey)
                    // is this a custom svg layer?
                    if (Utility.isCustomSvgsLayer(sl.layerKey, state.layers)) {

                        let layer = state.layers.find(ly => ly.layerKey === sl.layerKey)
                        if (layer && layer.inputs) {
                            layer.inputs.forEach(li => {
                                if (li.comment === 'svgKey for Custom Svg' || li.comment === 'svgKey for Custom Image') {
                                    let combinedKey = layer.layerKey + '_' + li.inputKey
                                    let svgKey = state.activeLayerValues[combinedKey]
                                    let svgItem = state.svgs.find(svg => svg.svgKey === svgKey)
                                    if (svgItem) {
                                        customSvgsArray.push(svgItem)
                                    }
                                }
                            })
                        }
                    }
                }
                else {
                    console.warn('cant find element for drawLayer' + sl.layerKey)
                }
            }

            sl.svg = serializedSvgForLayer
            sl.svgIdPrepend = uniquePrepend

            return sl
        })

        return {
            layers: counterStateWithSvg,
            activeLayerValues: JSON.parse(JSON.stringify(state.activeLayerValues)),
            customSvgs: customSvgsArray
        }
    },

    hashCode: s => {
        return [...s].reduce(
            (hash, c) => (Math.imul(31, hash) + c.charCodeAt(0)) | 0,
            0
        );
    },

    deprecatedWidths: [
        { layerKeys: [2], width: 25, height: 26, dimensions: 204, defaultArrayMinMaxValue: '{1,240}', name: 'NATO unit symbols', comment: '', defaultFloatArrayValue: '{100,75}' },
        { layerKeys: [3], width: 171, height: 172, dimensions: 205, defaultArrayMinMaxValue: '{1,240}', name: 'unit size', comment: 'percent', defaultFloatArrayValue: '{10,10}' },
        { layerKeys: [4], width: 29, height: 30, dimensions: 206, defaultArrayMinMaxValue: '{1,240}', name: 'misc unit symbols', comment: 'percent', defaultFloatArrayValue: '{100,100}' },
        { layerKeys: [5], width: 29, height: 30, dimensions: 207, defaultArrayMinMaxValue: '{1,240}', name: 'common symbols', comment: '', defaultFloatArrayValue: '{100,75}' },
        { layerKeys: [12], width: 46, height: 47, dimensions: 208, defaultArrayMinMaxValue: '{1,240}', name: 'text', comment: 'percent', defaultFloatArrayValue: '{100,100}' },
        { layerKeys: [14], width: 113, height: 114, dimensions: 209, defaultArrayMinMaxValue: '{1,240}', name: 'rectangle', comment: '', defaultFloatArrayValue: '{50,25}' },
        { layerKeys: [15], width: 123, height: 124, dimensions: 210, defaultArrayMinMaxValue: '{1,240}', name: 'ellipse', comment: '', defaultFloatArrayValue: '{50,50}' },
        { layerKeys: [18], width: 160, height: 161, dimensions: 211, defaultArrayMinMaxValue: '{1,300}', name: 'identifier text', comment: 'percent', defaultFloatArrayValue: '{100,100}' },
        { layerKeys: [19], width: 183, height: 184, dimensions: 212, defaultArrayMinMaxValue: '{1,240}', name: 'custom svgs', comment: 'percent', defaultFloatArrayValue: '{100,100}' },
        { layerKeys: [20], width: 188, height: 189, dimensions: 213, defaultArrayMinMaxValue: '{1,240}', name: 'custom images', comment: 'percent', defaultFloatArrayValue: '{100,100}' }],

    arrayInsertAtIndexItem: (arr, index, newItem) => [
        // part of the array before the specified index
        ...arr.slice(0, index),
        // inserted item
        newItem,
        // part of the array after the specified index
        ...arr.slice(index)
    ],

    arrayRemoveItemAtIndex: (arr, index) => {
        if (index > arr.length || index < 0) {
            return arr
        }
        if (index > -1) {
            arr.splice(index, 1);
        }
        return arr;
    },

    condenceLayersToKeysNames: (layers) => {
        return layers.map(ly => {
            let originalName = ly.layerName
            if (ly.parentLayerKey > -1) {
                let foundOriginalLayer = layers.find(ply => ply.layerKey === ly.parentLayerKey)
                if (foundOriginalLayer) {
                    originalName = foundOriginalLayer.layerName
                }
            }
            return {
                layerKey: ly.layerKey, parentLayerKey: ly.parentLayerKey, layerName: ly.layerName,
                originalLayerKey: ly.parentLayerKey > -1 ? ly.parentLayerKey : ly.layerKey,
                originalName
            }
        })
    },

    deepEqual: (object1, object2) => {
        const isObject = (object) => {
            return object != null && typeof object === "object";
        }
        const objKeys1 = Object.keys(object1);
        const objKeys2 = Object.keys(object2);

        if (objKeys1.length !== objKeys2.length) return false;

        for (var key of objKeys1) {
            if (object2.hasOwnProperty(key) === false) {
                return false
            }
        }
        for (var key2 of objKeys2) {
            if (object1.hasOwnProperty(key2) === false) {
                return false
            }
        }

        for (var key3 of objKeys1) {
            const value1 = object1[key3];
            const value2 = object2[key3];

            const isObjects = isObject(value1) && isObject(value2);

            if ((isObjects && !Utility.deepEqual(value1, value2)) ||
                (!isObjects && value1 !== value2)
            ) {
                return false;
            }
        }
        return true;
    },



    strSplitOnNonEnclosedSpaces: str => {
        return str.split(/ +(?=(?:(?:[^"]*"){2})*[^"]*$)/g);
    },

    arrContainsCaseInsensitiveStr: (arr, str) => {
        return arr.filter(astr => {
            return astr.toLowerCase() === str.toLowerCase();
        }).length > 0 ? true : false
    },

    compressString: str => {
        return compress(str)
    },

    decompressString: cstr => {
        return decompress(cstr)
    },

    isCustomLayer: (layerKey, layers) => {
        return Utility.isCustomImagesLayer(layerKey, layers) || Utility.isCustomSvgsLayer(layerKey, layers)
    },

    isRawSvgLayer: (layerKey, layers) => {
        return Utility.isRawSvgsLayer(layerKey, layers)
    },

    isNatoSymbolLayer: (layerKey, layers) => {
        let natoSymbolLayer = layers.find(ly => ly.layerName === 'NATO unit symbols')
        if (natoSymbolLayer) {
            let natoSymbolLayerKey = natoSymbolLayer.layerKey
            let natoSymbolLayerKeys = []
            layers.forEach(ly => {
                if (Number(ly.layerKey) === Number(natoSymbolLayerKey) ||
                    Number(ly.parentLayerKey) === Number(natoSymbolLayerKey)) {
                    natoSymbolLayerKeys.push(Number(ly.layerKey))
                }
            })

            return natoSymbolLayerKeys.includes(Number(layerKey))
        }
        return false
    },

    customItemsCount: (layerName, layers, svgs) => {
        let count = 0
        let layer = null
        if (layerName === 'custom svgs') {
            layer = layers.find(ly => ly.layerName === layerName)
        }
        if (layerName === 'custom images') {
            layer = layers.find(ly => ly.layerName === layerName)
        }
        if (layer) {
            //   let requiredInputKey = layer.layerActiveRequiredInputKey
            let inputs = layer.inputs
            let customSvgsInput = inputs.find(input => input.named === 'svgKey')
            if (customSvgsInput) {
                count = customSvgsInput.list.length
                if (customSvgsInput.list.length > 0) {
                    customSvgsInput.list.forEach(svgKey => {
                        let found = svgs.find(svg => svg.svgKey === svgKey)
                        if (found) {
                            count++
                        }
                    })
                }
            }
        }

        return count
    },

    customItemsCountByLayerKey: (layerKey, layers, svgs) => {
        let count = 0
        let layer = layers.find(ly => ly.layerKey === layerKey)
        if (layer) {
            //   let requiredInputKey = layer.layerActiveRequiredInputKey
            let inputs = layer.inputs
            let customSvgsInput = inputs.find(input => input.named === 'svgKey')
            if (customSvgsInput) {
                count = customSvgsInput.list.length
                if (customSvgsInput.list.length > 0) {
                    customSvgsInput.list.forEach(svgKey => {
                        let found = svgs.find(svg => svg.svgKey === svgKey)
                        if (found) {
                            count++
                        }
                        else {
                            console.warn('could not find ', layer.layerName, ' svgKey: ', svgKey)
                        }
                    })
                }
            }
        }

        return count
    },

    isCustomImagesLayer: (layerKey, layers) => {
        let customLayer = layers.find(ly => ly.layerName === 'custom images')
        if (customLayer) {
            let customLayerKey = customLayer.layerKey
            let customImagesLayerKeys = []
            layers.forEach(ly => {
                if (Number(ly.layerKey) === Number(customLayerKey) ||
                    Number(ly.parentLayerKey) === Number(customLayerKey)) {
                    customImagesLayerKeys.push(Number(ly.layerKey))
                }
            })

            return customImagesLayerKeys.includes(Number(layerKey))
        }
        return false
    },

    isCustomSvgsLayer: (layerKey, layers) => {
        let customLayer = layers.find(ly => ly.layerName === 'custom svgs')
        if (customLayer) {
            let customLayerKey = customLayer.layerKey
            let customSvgsLayerKeys = []
            layers.forEach(ly => {
                if (Number(ly.layerKey) === Number(customLayerKey) ||
                    Number(ly.parentLayerKey) === Number(customLayerKey)) {
                    customSvgsLayerKeys.push(Number(ly.layerKey))
                }
            })

            return customSvgsLayerKeys.includes(Number(layerKey))
        } return false
    },

    isRawSvgsLayer: (layerKey, layers) => {
        let rawSvgLayer = layers.find(ly => ly.layerName === 'ww2 vehicles')
        if (rawSvgLayer) {
            let rawSvgLayerKey = rawSvgLayer.layerKey
            let rawSvgsLayerKeys = []
            layers.forEach(ly => {
                if (Number(ly.layerKey) === Number(rawSvgLayerKey) ||
                    Number(ly.parentLayerKey) === Number(rawSvgLayerKey)) {
                    rawSvgsLayerKeys.push(Number(ly.layerKey))
                }
            })

            return rawSvgsLayerKeys.includes(Number(layerKey))
        } return false
    },

    isDuplicatedCustomSvgsLayer: (layerKey, layers) => {
        let originalCustomSvgsLayer = layers.find(ly => ly.layerName === 'custom svgs')
        if (originalCustomSvgsLayer) {
            let checkLayer = layers.find(ly => Number(ly.layerKey) === Number(layerKey))
            if (checkLayer) {
                if (Number(checkLayer.parentLayerKey) === Number(originalCustomSvgsLayer.layerKey)) {
                    return true
                }
            }
        }
        return false
    },

    isDuplicatedCustomImagesLayer: (layerKey, layers) => {
        let originalCustomImagesLayer = layers.find(ly => ly.layerName === 'custom images')
        if (originalCustomImagesLayer) {
            let checkLayer = layers.find(ly => Number(ly.layerKey) === Number(layerKey))
            if (checkLayer) {
                if (Number(checkLayer.parentLayerKey) === Number(originalCustomImagesLayer.layerKey)) {
                    return true
                }
            }
        }
        return false
    },

    distillIdsFromSvgString: svgString => {
        let searchFrom = 0
        let foundIds = []
        let max = 1000000 // prevent runaway
        while (searchFrom < svgString.length && max > 0) {
            max--
            let ind = svgString.indexOf('id="', searchFrom)
            if (ind > -1) {
                let indEnd = svgString.indexOf('"', ind + 4)
                if (indEnd > -1) {
                    searchFrom = indEnd + 1
                    let id = svgString.substring(ind + 4, indEnd)
                    if (id.length > 0 && id.length < 32) {
                        if (foundIds.includes(id) === false) {
                            foundIds.push(id)
                        }
                    }
                    else {
                        break
                    }
                }
                else {
                    break
                }
            }
            else {
                break
            }
        }

        return foundIds
    },

    distillIdsFromSvgElement: svgEle => {
        let foundIds = []
        if (svgEle) {
            let svgString = new XMLSerializer().serializeToString(svgEle)
            if (svgString) {
                foundIds = Utility.distillIdsFromSvgString(svgString)
                let allIds = svgEle.querySelectorAll('*[id]')
                allIds.forEach(aid => {
                    if (foundIds.includes(aid.id) === false) {
                        foundIds.push(aid.id)
                    }
                })
            }
        }
        return foundIds
    },

    distillIdsFromSnapElement: snapEle => {
        let foundIds = []
        if (snapEle) {
            let svgString = snapEle.toString()
            if (svgString) {
                foundIds = Utility.distillIdsFromSvgString(svgString)

                let allIds = snapEle.selectAll('*[id]')
                allIds.forEach(aid => {
                    if (foundIds.includes(aid.id) === false) {
                        foundIds.push(aid.id)
                    }
                })
            }
        }
        return foundIds
    },

    replaceIdsInSvgString: (idsArray, svgString, uniquePrepend) => {
        let newId = ''
        let replacedSvg = svgString
        idsArray.forEach(search => {
            newId = uniquePrepend + search
            replacedSvg = replacedSvg.replaceAll('"' + search + '"', '"' + newId + '"')
            replacedSvg = replacedSvg.replaceAll('#' + search, '#' + newId)
        })
        return replacedSvg
    },

    extractInteger: (str) => {
        if (typeof str === 'number') {
            return parseInt(str)
        }
        if (typeof str !== 'string') {
            return -1
        }
        let myString = str.replace(/\D/g, '');
        let r = myString.match(/[0-9]+/g)
        if (r) {
            return parseInt(r)
        }
        return null
    },

    extractNumber: (str) => {
        if (typeof str === 'number') {
            return parseFloat(str)
        }
        if (typeof str !== 'string' || str === '') {
            return null
        }
        var regex = /[+-]?\d+(\.\d+)?/g;
        let foundArray = str.match(regex).map(function (v) { return parseFloat(v); });
        if (foundArray.length > 0) {
            return foundArray[0]
        }
        return null
    },

    getBytes: (bytes) => {
        const sufixes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(1024));
        return (!bytes && '0 Bytes') || ((bytes / Math.pow(1024, i)).toFixed(2) + " " + sufixes[i]);
    },

    replaceLast: (text, searchValue, replaceValue) => {
        const lastOccurrenceIndex = text.lastIndexOf(searchValue)
        return `${text.slice(0, lastOccurrenceIndex)
            }${replaceValue
            }${text.slice(lastOccurrenceIndex + searchValue.length)
            }`
    },

    getLayerKeysOrdered: (layers) => {
        return layers.map(ly => ly.layerKey)
    },

    getVisibleLayerKeysOrdered: (activeLayerValues, layers) => {
        let visibleOrders = Utility.extractLayerKeys(activeLayerValues)
        let visibleLayersInfo = []
        visibleOrders.forEach(vo => {
            let foundLayer = layers.find(ly => ly.layerKey === vo)
            if (foundLayer) {
                let order = foundLayer.layerOrder
                visibleLayersInfo.push({ layerKey: vo, order: parseInt(order) })
            }
        })
        visibleLayersInfo.sort((a, b) => a.order - b.order)
        let visibleOrdered = visibleLayersInfo.map(vl => vl.layerKey)
        return visibleOrdered
    },

    getVisibleLayerKeyValuesOrdered: (activeLayerValues, layers) => {
        let visibleOrders = Utility.extractLayerKeys(activeLayerValues)
        let visibleLayersInfo = []
        visibleOrders.forEach(vo => {
            let foundLayer = layers.find(ly => ly.layerKey === vo)
            if (foundLayer) {
                let order = foundLayer.layerOrder
                visibleLayersInfo.push({ layerKey: vo, order: parseInt(order) })
            }
        })
        visibleLayersInfo.sort((a, b) => a.order - b.order)
        let visibleOrdered = visibleLayersInfo.map(vl => vl.layerKey)
        let keyValuesOrdered = []
        visibleOrdered.forEach(vo => {
            let testForString = vo + '_'
            for (const [key, value] of Object.entries(activeLayerValues)) {
                if (key.startsWith(testForString)) {
                    keyValuesOrdered[key] = value
                }
            }
        })
        return keyValuesOrdered
    },

    polarToCartesian: (centerX, centerY, radius, angleInDegrees) => {
        var angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

        return {
            x: centerX + (radius * Math.cos(angleInRadians)),
            y: centerY + (radius * Math.sin(angleInRadians))
        };
    },

    describeArc: (x, y, radius, startAngle, endAngle) => {
        var start = Utility.polarToCartesian(x, y, radius, endAngle);
        var end = Utility.polarToCartesian(x, y, radius, startAngle);

        var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

        var d = [
            "M", start.x, start.y,
            "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
        ].join(" ");

        return d;
    },

    polygonCentroid: (pts) => {
        if (!pts) {
            return null
        }
        if (Array.isArray(pts) === false) {
            return null
        }
        if (pts.length < 3) {
            return null
        }
        var first = pts[0], last = pts[pts.length - 1];
        if (first.x !== last.x || first.y !== last.y) pts.push(first);
        var twicearea = 0,
            x = 0, y = 0,
            nPts = pts.length,
            p1, p2, f;
        for (var i = 0, j = nPts - 1; i < nPts; j = i++) {
            p1 = pts[i]; p2 = pts[j];
            f = p1.x * p2.y - p2.x * p1.y;
            twicearea += f;
            x += (p1.x + p2.x) * f;
            y += (p1.y + p2.y) * f;
        }
        f = twicearea * 3;
        return { x: x / f, y: y / f };
    },

    compressDataToLocalStorage: (name, data) => {
        if (!name || !data) {
            console.warn('invalid arguments, name: ', name)
            return
        }
        let str = null
        if (typeof data === 'string') {
            str = data
        }
        else {
            try {
                str = JSON.stringify(data)
            }
            catch (err) {
                console.warn('JSON.stringify error: ', err)
            }
        }
        // sanity check
        if (str.length < 3) {
            console.warn('no apparent data!: ', str)
            return
        }
        let compressedData = null
        try {
            compressedData = compress(str)
        }
        catch (err) {
            console.warn('compress error on str: ', str, ' err: ', err)
        }
        if (compressedData) {
            try {
                localStorage.setItem(name, compressedData)
            } catch (e) {
                console.warn('error from localStorage: ', e)
            }
        }
    },

    cyrb53: (str, seed = 0) => {
        let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
        for (let i = 0, ch; i < str.length; i++) {
            ch = str.charCodeAt(i);
            h1 = Math.imul(h1 ^ ch, 2654435761);
            h2 = Math.imul(h2 ^ ch, 1597334677);
        }
        h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
        h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);
        return 4294967296 * (2097151 & h2) + (h1 >>> 0);
    },

    distanceTwoPoints: (p1, p2) => {
        let t1 = Math.pow((p2.x - p1.x), 2)
        let t2 = Math.pow((p2.y - p1.y), 2)
        let t3 = t1 + t2
        return Math.sqrt(t3)
    },

    currentLayerInputValue: (layerKey, inputKey, activeLayers) => {
        if (activeLayers.hasOwnProperty(layerKey + '_' + inputKey)) {
            return activeLayers[layerKey + '_' + inputKey]
        }
        return null
    },

    layerIsActive: (layerKey, activeLayers) => {
        if (layerKey === null || layerKey === undefined || activeLayers === null || activeLayers === undefined) {
            return false
        }
        let alks = Utility.activeLayerKeys(activeLayers)
        return alks.includes(layerKey)
    },

    activeLayerKeys: (obj) => {
        if (!obj || Utility.emptyCheck(obj)) {
            return []
        }
        let layerKeys = []
        let activeLayerValues = Object.keys(obj)
        activeLayerValues.forEach(ki => {
            let arr = ki.split('_')
            if (arr.length === 2) {
                let layerKey = parseInt(arr[0])
                if (layerKeys.includes(layerKey) === false) {
                    layerKeys.push(parseInt(arr[0]))
                }
            }
        })
        return layerKeys
    },

    inputKeysValuesForLayer: (obj, layerKey) => {
        let inputKeyValues = {}
        for (const [key, value] of Object.entries(obj)) {
            if (key.startsWith(layerKey + '_')) {
                let inputKey = key.replace(layerKey + '_', '')
                inputKeyValues[inputKey] = value
            }
        }
        return inputKeyValues
    },

    copyDixieStoreToLocalStorage: (dexie, storeName) => {
        dexie[storeName].toArray().then(res => {
            Utility.compressDataToLocalStorage(storeName, res)
        })
    },

    dexieClearTable: async (dexie, storeName) => {
        if (storeName) {
            try {
                await dexie[storeName].clear()
            }
            catch (e) {
                console.warn('error: ', e)
            }
        }
    },

    dexieGetData: async (dexie, storeName) => {
        let data = await dexie[storeName].toArray()
        return data
    },

    //dexieGetItem: async (dexie, storeName, primaryKey, primaryKeyName

    dexieAddItem: async (dexie, storeName, data) => {
        await dexie[storeName].add(data)
            .catch('Error', err => {
                console.error(err);
            });
    },

    dexiePutItem: async (dexie, storeName, data) => {
        dexie[storeName].put(data)
            .catch('Error', err => {
                console.error(err);
            });
    },

    dexieAddPutItem: async (dexie, storeName, data, primaryKeyName) => {
        let key = data[primaryKeyName]
        let queryObj = {}
        queryObj[primaryKeyName] = key
        let record = await dexie[storeName].get(queryObj)
            .catch('Error', err => {
                console.error(err);
            });
        if (!record) {
            await Utility.dexieAddItem(dexie, storeName, data)
        }
        else {
            await Utility.dexiePutItem(dexie, storeName, data)
        }
    },

    dexieAddPutFont: async (dexie, data) => {
        let existingData = await dexie.fonts.get({
            fontFamily: data.fontFamily,
            fontStyle: data.fontStyle,
            fontWeight: data.fontWeight
        })

        if (existingData) {
            Utility.dexiePutItem(dexie, "fonts", data).then(() => {
                // is ok
            }).catch(console.error);
        }
        else {
            Utility.dexieAddItem(dexie, "fonts", data).then(() => {
                // is ok
            }).catch(console.error);
        }
    },

    dexieDeleteFont: async (dexie, fontFamily, fontStyle, fontWeight) => {
        if (!dexie || !fontFamily || !fontStyle || !fontWeight) {
            console.warn('dexieDeleteFont got invalid params:', dexie, 'fontFamily', fontFamily, 'fontStyle', fontStyle, 'fontWeight', fontWeight)
            return
        }
        try {
            await dexie['fonts'].delete([fontFamily, fontStyle, Number(fontWeight)])
        } catch (e) {
            console.warn('error deleting font:', e)
        }
    },

    dexieRemoveLayersFromActive: async (dexie, layerKeysToDelete) => {
        let layerKeys = []
        if (!layerKeysToDelete) {
            return
        }
        if (Array.isArray(layerKeysToDelete)) {
            layerKeys = layerKeysToDelete
        }
        else {
            layerKeys.push(layerKeysToDelete)// assume theres one discrete value
        }
        let dexieKeysToRemove = []
        await dexie.activeLayerValues.orderBy('lik').uniqueKeys('lik').then(async (lkvs) => {
            lkvs.forEach(lkv => {
                layerKeys.forEach(layerKey => {
                    if (lkv.startsWith(layerKey + '_') && parseInt(layerKey) !== 1) {
                        dexieKeysToRemove.push(lkv)
                    }
                })
            })
            if (dexieKeysToRemove.length > 0) {
                await dexie.activeLayerValues.bulkDelete(dexieKeysToRemove).catch("BulkError", function (e) {
                    // Explicitly catching the bulkAdd() operation makes those successful
                    // additions commit despite that there were errors.
                    console.error("Some activeLayerValues records did not succeed in bulkDelete. e: ", e)
                });
            }
        })
    },

    getDexieStoreSingleItem: async (dexie, storeName, primaryKeyValue, primaryKeyName) => {
        if (!dexie || !storeName || !primaryKeyValue || !primaryKeyName) {
            return
        }
        let queryObj = {}
        queryObj[primaryKeyName] = primaryKeyValue
        let record = await dexie[storeName].get(queryObj)
            .catch('Error', err => {
                console.error(err);
            });
        if (!record) {
            console.warn('no record found for ', storeName, ' primaryKeyValue: ', primaryKeyValue, ' primaryKeyName: ', primaryKeyName)
            return null
        }
        return record
    },

    updateDexieStoreSingleItem: async (dexie, storeName, data, primaryKeyName) => {
        if (!dexie || !storeName || !data || !primaryKeyName) {
            console.warn('invalid updateDexieStoreSingleItem dexie:', dexie, 'storeName:', storeName, 'data:', data, 'primaryKeyName:', primaryKeyName)
            return
        }
        let key = data[primaryKeyName]
        let queryObj = {}
        queryObj[primaryKeyName] = key

        let record = await dexie[storeName].get(queryObj)
            .catch('Error', err => {
                console.error(err);
            });
        if (!record) {

            // try again
            record = await dexie[storeName].get(queryObj)
                .catch('Error', err => {
                    console.error(err);
                });
            if (!record) {
                console.warn('no record found for ', storeName, ' data: ', data, ' primaryKeyName: ', primaryKeyName)
                return
            }


        }
        if (record) {
            record = { ...record, ...data }
            //await Utility.dexieDeleteItem(dexie, storeName, primaryKeyName, record[primaryKeyName])
            dexie[storeName].put(record)
                .catch('Error', err => {
                    console.error(err);
                });
        }
    },

    dexieBulkAdd: (dexie, storeName, data) => {
        if (!dexie || !storeName || !data) {
            console.warn('invalid dexieBulkAdd dexie:', dexie, 'storeName:', storeName, 'data:', data)
            return
        }
        if (!Array.isArray(data) || data.length === 0) {
            console.warn('storeName:', storeName, 'dexieBulkAdd, data is not array:', data)
            return
        }
        dexie[storeName].bulkPut(data).then(function (lastKey) {
            // is ok
        })
            .catch('BulkError', err => {
                err.failures.forEach(failure => {
                    console.error(failure.message);
                });
                // If on dexie@>3.1.0-alpha.6:
                for (const [pos, error] of Object.entries(err.failuresByPos)) {
                    console.error(`Operation ${pos} failed with ${error}`);
                }
            });
    },

    dexieDeleteItem: async (dexie, storeName, primaryKeyName, primaryKey) => {
        if (!dexie || !storeName || !primaryKeyName || !primaryKey) {
            console.warn('dexieDeleteItem got invalid dexie:', dexie, 'storeName', storeName, 'primaryKeyName', primaryKeyName, 'primaryKey', primaryKey)
            return
        }
        await dexie[storeName].where(primaryKeyName).equals(primaryKey).delete();
    },

    updateDexieStore: (dexie, storeName, data, primaryKeyName) => {
        if (!dexie || !storeName || !data || !primaryKeyName) {
            return
        }
        if (Array.isArray(data) === false && Utility.isObject(data)) {
            if (storeName === 'sheetSettings') {
                data.index = 1
                dexie[storeName].where(primaryKeyName).equals(1).modify(data);
            }
            else {
                console.warn('what do we have here? storeName:', storeName, 'data:', data)
            }

        }
        if (Array.isArray(data)) {
            if (data.length === 0) {
                // no point
                return
            }
            if (data[0].hasOwnProperty(primaryKeyName) === false) {
                console.warn('data, ', data, ', given to updateDexieStore does not have the supplied primaryKeyName ', primaryKeyName)
                return
            }

            dexie[storeName].bulkPut(data)
                .catch('BulkError', err => {
                    err.failures.forEach(failure => {
                        console.error(failure.message);
                    });
                    // If on dexie@>3.1.0-alpha.6:
                    for (const [pos, error] of Object.entries(err.failuresByPos)) {
                        console.error(`Operation ${pos} failed with ${error}`);
                    }
                });



            //dexie[storeName].count(cnt => {

            // if there is no data in the dexie for this store, just bulkAdd what we got.
            // if (cnt === 0) {
            //     dexie[storeName].bulkPut(data)
            //         .catch('BulkError', err => {
            //             err.failures.forEach(failure => {
            //                 console.error(failure.message);
            //             });
            //             // If on dexie@>3.1.0-alpha.6:
            //             for (const [pos, error] of Object.entries(err.failuresByPos)) {
            //                 console.error(`Operation ${pos} failed with ${error}`);
            //             }
            //         });
            // }
            // else {

            //     // there is data currently in dexie for this store. Lets try updating it.
            //     dexie[storeName].bulkPut(data)
            //         .catch('BulkError', err => {
            //             err.failures.forEach(failure => {
            //                 console.error(failure.message);
            //             });
            //             // If on dexie@>3.1.0-alpha.6:
            //             for (const [pos, error] of Object.entries(err.failuresByPos)) {
            //                 console.error(`Operation ${pos} failed with ${error}`);
            //             }
            //         });
            // }


            //   })
        }





    },

    extractLayerKeys: data => {
        let keys = Object.keys(data)
        let layerKeys = keys.map(k => parseInt(k.split('_')[0])).filter((value, index, self) => self.indexOf(value) === index)
        return layerKeys
    },

    extractInputKeys: data => {
        let keys = Object.keys(data)
        let inputKeys = keys.map(k => parseInt(k.split('_')[1])).filter((value, index, self) => self.indexOf(value) === index)
        return inputKeys
    },

    isObject: object => {
        return object != null && typeof object === 'object';
    },

    objectsEqual: (object1, object2) => {
        function isObject(object) {
            return object != null && typeof object === 'object';
        }
        if (isObject(object1) === false || isObject(object2) === false) {
            return false
        }
        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);
        if (keys1.length !== keys2.length) {
            return false;
        }
        for (const key of keys1) {
            const val1 = object1[key];
            const val2 = object2[key];
            const areObjects = isObject(val1) && isObject(val2);
            if (
                (areObjects && !Utility.objectsEqual(val1, val2)) ||
                (!areObjects && val1 !== val2)
            ) {
                return false;
            }
        }
        return true;
    },

    strHasSpecialCharacters: (str) => {
        const alphanumeric = /^[ \-\p{L}\p{N}]*$/u;
        return !str.match(alphanumeric)
    },

    scaleDPath: (d, sw, sh) => {
        function scaleNumbers(arr) {
            let isX = true
            return arr.map(item => {
                if (Utility.isNumeric(item)) {
                    let pathNumber = item
                    pathNumber = Utility.roundFloat(parseFloat(pathNumber), 1)
                    if (isX) {
                        pathNumber *= sw
                    }
                    else {
                        pathNumber *= sh
                    }
                    isX = !isX

                    pathNumber = Utility.roundFloat(pathNumber, 1)
                    return pathNumber
                }
                return item
            })
        }

        let d_arr = d.split(' ')

        // send the array to the function that will step through the items and modify the qualifying numbers
        let marr = scaleNumbers(d_arr)
        let mt = marr.join(' ')
        return mt
    },

    toPlainString: (num) => {
        return ('' + +num).replace(/(-?)(\d*)\.?(\d*)e([+-]\d+)/,
            function (a, b, c, d, e) {
                return e < 0
                    ? b + '0.' + Array(1 - e - c.length).join(0) + c + d
                    : b + c + d + Array(e - d.length + 1).join(0);
            });
    },

    roundFloatsInString: (str, precision) => {
        let strTemp = str.replaceAll(',', ' ')
        strTemp = strTemp.replaceAll('-', ' -')
        strTemp = strTemp.replace(/\s\s+/g, ' ')
        let strArray = strTemp.split(' ')
        for (let i = 0; i < strArray.length; i++) {
            let s = strArray[i]
            if (parseFloat(s)) {
                strArray[i] = Utility.roundFloat(s, 2)
            }
        }
        let roundedString = strArray.join(' ')
        return roundedString
    },

    roundFloatsInFile: (str, precision) => {
        let str2 = str.replaceAll(',', ' ')
        let str2arr = str2.split(' ')
        for (let e = 0; e < str2arr.length; e++) {
            let etoken = str2arr[e]
            if (etoken.includes('e-') || etoken.includes('e+')) {
                let r = Utility.toPlainString(etoken)
                if (r && !isNaN(r)) {
                    str2arr[e] = r
                }
            }
        }
        str = str2arr.join(' ')
        //let earr = str.split(' 
        let run = str.length
        let candidate = { numStr: '', start: -1, end: -1 }
        let ch = ''
        let numRoundArray = []
        for (let i = 0; i < run; i++) {
            ch = str.charAt(i)
            if (ch === '#') {
                i += 3
                candidate = { numStr: '', start: -1, end: -1 }
            }
            if (
                (ch === '-' && candidate.numStr === '') ||
                (ch === '.' && candidate.numStr.length > 0 && candidate.numStr.includes('.') === false) ||
                Utility.isInt(ch)
            ) {
                if (candidate.numStr === '') {
                    candidate.numStr = ch
                    candidate.start = i
                }
                else {
                    candidate.numStr += ch
                }
            }
            else {
                if (candidate.numStr.length > 5) {
                    // number chars after dot
                    let dotIndex = candidate.numStr.indexOf('.')
                    if (dotIndex > 0) {
                        let fakeNum = candidate.numStr * 100
                        let roundFakeNum = Utility.roundFloat(fakeNum, 2)
                        let roundedNum = roundFakeNum / 100
                        if (candidate.numStr.startsWith('0.')) {
                            roundedNum = Utility.roundFloat(roundedNum, 4)
                        }
                        else {
                            roundedNum = Utility.roundFloat(roundedNum, 2)
                        }
                        numRoundArray.push({ orig: candidate.numStr + '', rep: roundedNum + '' })

                    }
                }
                candidate = { numStr: '', start: -1, end: -1 }
            }
        }
        let roundedStr = str
        for (var r = 0; r < numRoundArray.length; r++) {
            let search = String(numRoundArray[r].orig)
            let replaceWith = String(numRoundArray[r].rep)
            roundedStr = roundedStr.split(search).join(replaceWith);
        }
        return roundedStr
    },

    distanceBetweenTwoPoints: (x1, y1, x2, y2) => {
        return Math.hypot(x2 - x1, y2 - y1)
    },

    radiansToDegrees: (radians) => {
        var pi = Math.PI;
        return radians * (180 / pi);
    },

    degreesToRadians: (degrees) => {
        var pi = Math.PI
        return degrees * (pi / 180)
    },

    findNewPointOnSlope: (x, y, angle, distance) => {
        var result = {};
        result.x = Math.round(Math.cos(angle * Math.PI / 180) * distance + x);
        result.y = Math.round(Math.sin(angle * Math.PI / 180) * distance + y);
        return result;
    },

    getPointOnLineDistance: (x1, y1, x2, y2, distance) => {
        var A1 = {
            x: x2,
            y: y2
        };

        var A2 = {
            x: x1,
            y: y1
        };

        // Distance
        var d = distance;

        // Find Slope of the line
        var slope = (A2.y - A1.y) / (A2.x - A1.x);

        // Find angle of line
        var theta = Math.atan(slope);

        // the coordinates of the A3 Point
        var A3x = A2.x + d * Math.cos(theta);
        var A3y = A2.y + d * Math.sin(theta);
        return { x: Utility.roundFloat(A3x, 3), y: Utility.roundFloat(A3y, 2) }
    },

    angle: (cx, cy, ex, ey) => {
        var dy = ey - cy
        var dx = ex - cx
        var theta = Math.atan2(dy, dx) // range (-PI, PI]
        theta *= 180 / Math.PI // rads to degs, range (-180, 180]
        //if (theta < 0) theta = 360 + theta; // range [0, 360)
        return theta
    },

    convertPostgresArrayToArray: (str) => {
        if (!str) {
            return []
        }
        if (typeof str !== 'string') {
            return []
        }
        let fixed = str.replace('{', '[')
        fixed = fixed.replace('}', ']')
        return JSON.parse(fixed)
    },

    isElementOffScreen: (el) => {


        var top = el.offsetTop;
        var left = el.offsetLeft;
        var width = el.offsetWidth;
        var height = el.offsetHeight;

        while (el.offsetParent) {
            el = el.offsetParent;
            top += el.offsetTop;
            left += el.offsetLeft;
        }

        let viewableList = {
            top: false,
            left: false,
            bottom: false,
            right: false,
            yAdjust: 0,
            xAdjust: 0,
        }

        if (top >= window.pageYOffset) {
            viewableList.top = true
        }
        else {
            viewableList.yAdjust = window.pageYOffset - top
        }
        if (left >= window.pageXOffset) {
            viewableList.left = true
        }
        else {
            viewableList.xAdjust = window.pageXOffset - left
        }
        if ((top + height) <= (window.pageYOffset + window.innerHeight)) {
            viewableList.bottom = true
        }
        else {
            viewableList.yAdjust = (window.pageYOffset + window.innerHeight) - (top + height)
        }
        if ((left + width) <= (window.pageXOffset + window.innerWidth)) {
            viewableList.right = true
        }
        else {
            viewableList.xAdjust = (window.pageXOffset + window.innerWidth) - (left + width)
        }

        return viewableList
    },

    isJsonParsable: (str) => {
        if (!str) {
            return false
        }
        if (typeof str !== 'string') {
            return false
        }
        try {
            JSON.parse(str);
        } catch (e) {
            return false
        }
        return true
    },

    parseJson: (str) => {
        if (!str) {
            return false
        }
        if (typeof str !== 'string') {
            return false
        }
        try {
            return JSON.parse(str);
        } catch (e) {
            return false;
        }
    },

    // the json.dumps method on the server does something to the date string that changes it to a flat string. So I had to do a special conversion fn for it.
    formatDate: (dateString) => {
        if (dateString) {
            if (dateString[3] === ',') { // if its of format Sat, 26 Jam 2019 00:00:00 GMT
                let date_arr = dateString.split(' ')
                let formattedDate = date_arr[2] + ' ' + date_arr[1] + ', ' + date_arr[3]
                return formattedDate
            }
            else {
                let date_arr1 = dateString.split(' ')[0]
                let date_arr2 = date_arr1.split('-')
                let mth = ''
                switch (parseInt(date_arr2[1])) {
                    case 1: mth = 'Jan'
                        break
                    case 2: mth = 'Feb'
                        break
                    case 3: mth = 'Mar'
                        break
                    case 4: mth = 'Apr'
                        break
                    case 5: mth = 'May'
                        break
                    case 6: mth = 'Jun'
                        break
                    case 7: mth = 'Jul'
                        break
                    case 8: mth = 'Aug'
                        break
                    case 9: mth = 'Sep'
                        break
                    case 10: mth = 'Oct'
                        break
                    case 11: mth = 'Nov'
                        break
                    case 12: mth = 'Dec'
                        break
                    default: mth = '0'
                }

                let formattedDate = mth + ' ' + date_arr2[2] + ', ' + date_arr2[0]
                return formattedDate
            }
        }
    },

    formatDateYMD: (dateString) => {
        if (dateString) {
            let strArr = dateString.substring(5).replace(',', '').split(' ')
            let mthStr = strArr[1]
            let dayStr = strArr[0]
            let yearStr = strArr[2]
            switch (mthStr) {
                case 'Jan': mthStr = '01'; break
                case 'Feb': mthStr = '02'; break
                case 'Mar': mthStr = '03'; break
                case 'Apr': mthStr = '04'; break
                case 'May': mthStr = '05'; break
                case 'Jun': mthStr = '06'; break
                case 'Jul': mthStr = '07'; break
                case 'Aug': mthStr = '08'; break
                case 'Sep': mthStr = '09'; break
                case 'Oct': mthStr = '10'; break
                case 'Nov': mthStr = '11'; break
                case 'Dec': mthStr = '12'; break
                default: mthStr = '00'
            }
            return yearStr + '-' + mthStr + '-' + dayStr
        }
        return ''
    },

    currentDate: () => {
        var date = new Date();
        return new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
            .toISOString()
            .split("T")[0];
    },

    clone: (items) => items.map(item => (Array.isArray(item) ? Utility.clone(item) : item)),

    getClosestElementMatchingName: (elem, name) => {
        if (!elem || !elem.parentNode) {
            return null
        }
        if (!name || typeof name !== 'string') {
            return null
        }
        for (; elem && elem !== document; elem = elem.parentNode) {
            let testname = elem.getAttribute('name')
            if (testname === name) {
                return elem
            }
        }

        return null
    },

    getClosestElementNameMatchingName: (elem, name) => {
        if (!elem || !elem.parentNode) {
            return null
        }
        if (!name || typeof name !== 'string') {
            return null
        }
        for (; elem && elem !== document; elem = elem.parentNode) {
            let testname = elem.getAttribute('name')
            if (testname === name) {
                return name
            }
        }

        return null
    },

    validateHexColor: (val) => {
        if (!val) {
            return false
        }
        if (typeof (val) !== "string") {
            return false
        }
        if (val.length > 7) {
            return false
        }
        if (val.startsWith('#') === false) {
            return false
        }
        var pattern = new RegExp("^#([a-fA-F0-9]){3}$|[a-fA-F0-9]{6}$");
        return pattern.test(val)
    },

    rgbToHex: (r, g, b) => {
        if (typeof r === 'string') {
            r = parseInt(r)
        }
        if (typeof g === 'string') {
            g = parseInt(g)
        }
        if (typeof b === 'string') {
            b = parseInt(b)
        }
        if (Utility.isInt(r) && r >= 0 && r <= 255 &&
            Utility.isInt(g) && g >= 0 && g <= 255 &&
            Utility.isInt(b) && b >= 0 && b <= 255) {
            return '#' + [r, g, b]
                .map(x => x.toString(16).padStart(2, '0')).join('').toUpperCase()
        }
        return '#000000'
    },

    isInt: (n) => !isNaN(parseInt(n, 10)) && isFinite(n),

    isNumeric: (n) => !isNaN(parseFloat(n)) && isFinite(n),

    requestId: () => Math.floor(Math.random() * ((99999 - 11111) + 1) + 11111),

    randomString: (len) => [...Array(len)].map(() => Math.random().toString(36)[2]).join(''),

    getConfig: () => config,

    lowerCase: str => {
        if (str) {
            if (typeof str === 'string') {
                return str.toLowerCase()
            }
        }
        return str
    },

    intVal: (n) => {
        if (!n) {
            return 0
        }
        if (Utility.isNumeric(n) === false) {
            return parseInt(n)
        }
        return n
    },

    roundFloat: (nbr, dec_places = 0) => {
        var mult = Math.pow(10, dec_places);
        if (Array.isArray(nbr)) {
            return nbr.map(num => Math.round(parseFloat(num) * mult) / mult)
        }
        return Math.round(parseFloat(nbr) * mult) / mult;
    },

    sluggify: (txt) => {
        return txt.replaceAll(' ', + '_')
    },

    deSluggify: (txt) => {
        return txt.replaceAll('_', + ' ')
    },

    getIpAddress: () => {
        let existingIpAddress = window.localStorage.getItem('ip')
        if (existingIpAddress) {
            return existingIpAddress
        }
        return new Promise((resolve, reject) => {
            fetch("https://extreme-ip-lookup.com/json").then((response) => {
                // console.log('response: ', response)
                response.json().then(obj => {
                    //console.log('obj: ', obj)
                })
            },
                (error) => {
                    resolve()
                })
        }).catch(error => { })
    },

    emptyCheck: (data) => {

        if (typeof (data) == 'number' || typeof (data) == 'boolean') {
            return false;
        }
        if (typeof (data) == 'undefined' || data === null) {
            return true;
        }
        if (typeof (data.length) != 'undefined') {
            return data.length === 0;
        }
        var count = 0;
        for (var i in data) {
            if (data.hasOwnProperty(i)) {
                count++;
            }
        }

        return count === 0;
    },

    isObjectAndNotEmpty: (obj) => Object.entries(obj).length > 0 && obj.constructor === Object,

    escapeRegExp(str) {
        return str.replace(/([.*+?^=!:${}()|[]\/\\])/g, "\\$1");
    },

    detectOS: () => {
        var userAgent = window.navigator.userAgent,
            platform = window.navigator.platform,
            macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
            windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
            iosPlatforms = ['iPhone', 'iPad', 'iPod'],
            os = null;

        if (macosPlatforms.indexOf(platform) !== -1) {
            os = 'mac';
        } else if (iosPlatforms.indexOf(platform) !== -1) {
            os = 'ios';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
            os = 'windows';
        } else if (/Android/.test(userAgent)) {
            os = 'android';
        } else if (!os && /Linux/.test(platform)) {
            os = 'linux';
        }

        return os;
    },

    detectBrowser: () => {
        var sBrowser, sUsrAg = navigator.userAgent;

        if (sUsrAg.indexOf("Firefox") > -1) {
            sBrowser = "Mozilla Firefox";
            // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
        } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
            sBrowser = "Opera";
            //"Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
        } else if (sUsrAg.indexOf("Trident") > -1) {
            sBrowser = "Microsoft Internet Explorer";
            // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
        } else if (sUsrAg.indexOf("Edge") > -1) {
            sBrowser = "Microsoft Edge";
            // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
        } else if (sUsrAg.indexOf("Chrome") > -1) {
            sBrowser = "Google Chrome or Chromium";
            // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
        } else if (sUsrAg.indexOf("Safari") > -1) {
            sBrowser = "Apple Safari";
            // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
        } else {
            sBrowser = "unknown";
        }

        if (document.documentMode || /Edge/.test(navigator.userAgent) || /Edg/.test(navigator.userAgent)) {
            sBrowser = 'Microsoft Edge'
        }

        return sBrowser;
    },

    getElementBBox: (ele) => {
        // ele could be a string for an id, a name, a string for a className, or a element itself.
        if (!ele) {
            return null
        }
        let element = ele

        // if a string, try to get the element by id or className
        if (typeof ele === 'string') {
            element = document.getElementById(ele)
            if (!element) {
                element = document.getElementsByName(ele)[0]; // names come back as an array, since many elements may have the same className.
                if (!element) {
                    element = document.getElementsByClassName(ele)[0]; // classnames come back as an array, since many elements may have the same className.
                }
            }
        }
        if (!element) {
            return null
        }
        let positionInfo = null
        try {
            positionInfo = element.getBoundingClientRect();
        }
        catch (e) {
            console.warn('could not find element for ', ele, ' error: ', e)
        }
        if (!positionInfo) {
            return null
        }
        return positionInfo
    },

    getElementWidth: (ident) => {
        let bbox = Utility.getElementBBox(ident)
        if (!bbox) {
            return 0
        }

        return bbox.width;
    },

    getElementHeight: (ident) => {
        let bbox = Utility.getElementBBox(ident)
        if (!bbox) {
            return 0
        }

        return bbox.height;
    },

    getCombinedElementsWidth: (className) => {
        let elements = null
        if (className) {
            elements = document.getElementsByClassName(className)
        }
        if (!elements) {
            return 0
        }
        let elementsWidth = 0;
        Array.from(elements).forEach((element) => {
            let elementRect = element.getBoundingClientRect()
            if (elementRect) {
                elementsWidth += elementRect.width
            }
        })

        return elementsWidth
    },

    cleanUrlText: (text) => {
        //text = text.replace(/\&/g, ' ')
        //text = text.replace(/\%/g, ' ')
        text = text.replace(/&/g, ' ')
        text = text.replace(/%/g, ' ')
        text = text.replace(/[0-9]/g, ' ');
        text = text.replace(/\s\s+/g, ' ');
        return text
    },

    markdown: (text) => {

        // turn an < and > characters the user may have typed-in into html entities (to prevent them from writing html directly)
        text = text.replace(/</g, '&lt;')
        text = text.replace(/>/g, '&gt;')

        // simple tags
        let tags = [{ name: 'bold', htmlTag: 'b' },
        { name: 'italic', htmlTag: 'i' },
        { name: 'underline', htmlTag: 'u' },
        { name: 'quote', htmlTag: 'blockquote' }]
        var pattern1 = "\\["
        var pattern2 = "\\]"
        var pattern3 = "\\[/"
        var pattern4 = "\\]"
        tags.forEach(tag => {
            var regExp1 = new RegExp(`${pattern1}${tag.name}${pattern2}`, "g")
            var regExp2 = new RegExp(`${pattern3}${tag.name}${pattern4}`, "g")
            text = text.replace(regExp1, '<' + tag.htmlTag + '>')
            text = text.replace(regExp2, '</' + tag.htmlTag + '>')
            // text = text.replace(/\[b\]/g, '<b>')
            // text = text.replace(/\[\/b\]/g, '</b>')
        })

        let smallFontSize = '8px'
        let mediumFontSize = '16px'
        let largeFontSize = '26px'
        let superLargeFontSize = '40px'
        text = text.replace(/\[size small\]/g, `<span style="font-size: ${smallFontSize}">`)//   
        text = text.replace(/\[size medium\]/g, `<span style="font-size: ${mediumFontSize}">`)
        text = text.replace(/\[size large\]/g, `<span style="font-size: ${largeFontSize}">`)
        text = text.replace(/\[size super-large\]/g, `<span style="font-size: ${superLargeFontSize}">`)
        text = text.replace(/\[\/size\]/g, '</span>')

        var match = text.match(/\[color([^<]+)/i)

        while (match && match.length > 0) {

            let bracketpos = match[1].indexOf(']')
            if (bracketpos > 0) {
                let color = match[1].substring(1, bracketpos)
                if (!color) {
                    break;
                }
                let testTag = '[color ' + color + ']'
                let tagStartStartPos = text.indexOf(testTag)

                if (tagStartStartPos === -1) {
                    break;
                }
                let tagStartEndPos = text.indexOf(']', tagStartStartPos)
                if (tagStartEndPos === -1) {
                    break;
                }
                let tagEndStartPos = text.indexOf('[/color]', tagStartEndPos)
                if (tagEndStartPos === -1) {
                    break;
                }
                let tagEndEndPos = tagEndStartPos + '[/color]'.length
                if (tagEndEndPos === -1) {
                    break;
                }
                let taggedText = text.substring(tagStartEndPos + 1, tagEndStartPos)
                if (!taggedText) {
                    break;
                }
                let textLeftSide = text.substring(0, tagStartStartPos)
                let textRightSide = text.substring(tagEndEndPos)
                text = textLeftSide
                text += `<span style="color: ${color}">${taggedText}</span>`
                text += textRightSide
            }
            else {
                break
            }
            match = text.match(/\[color([^<]+)/i)
        }

        match = text.match(/\[link([^<]+)/i)

        while (match && match.length > 0) {
            let bracketpos = match[1].indexOf(']')
            if (bracketpos > 0) {
                let link = match[1].substring(1, bracketpos)
                if (!link) {
                    break;
                }
                let testTag = '[link ' + link + ']'
                let tagStartStartPos = text.indexOf(testTag)

                if (tagStartStartPos === -1) {
                    break;
                }
                let tagStartEndPos = text.indexOf(']', tagStartStartPos)
                if (tagStartEndPos === -1) {
                    break;
                }
                let tagEndStartPos = text.indexOf('[/link]', tagStartEndPos)
                if (tagEndStartPos === -1) {
                    break;
                }
                let tagEndEndPos = tagEndStartPos + '[/link]'.length
                if (tagEndEndPos === -1) {
                    break;
                }
                let taggedText = text.substring(tagStartEndPos + 1, tagEndStartPos)
                if (!taggedText) {
                    // break;
                    taggedText = link
                }
                let textLeftSide = text.substring(0, tagStartStartPos)
                let textRightSide = text.substring(tagEndEndPos)
                text = textLeftSide
                text += `<a target="_blank" rel="noopener noreferrer" href="${link}">${taggedText}</a>`
                text += textRightSide
            }
            else {
                break
            }
            match = text.match(/\[link([^<]+)/i)
        }



        match = text.match(/\[image([^<]+)/i)

        while (match && match.length > 0) {

            let bracketpos = match[1].indexOf(']')
            if (bracketpos > 0) {
                let image = match[1].substring(1, bracketpos)
                if (!image) {
                    break;
                }
                let testTag = '[image ' + image + ']'
                let tagStartStartPos = text.indexOf(testTag)

                if (tagStartStartPos === -1) {
                    break;
                }
                let tagStartEndPos = text.indexOf(']', tagStartStartPos)
                if (tagStartEndPos === -1) {
                    break;
                }
                let tagEndStartPos = text.indexOf('[/image]', tagStartEndPos)
                if (tagEndStartPos === -1) {
                    break;
                }
                let tagEndEndPos = tagEndStartPos + '[/image]'.length
                if (tagEndEndPos === -1) {
                    break;
                }
                let taggedText = text.substring(tagStartEndPos + 1, tagEndStartPos)

                let textLeftSide = text.substring(0, tagStartStartPos)
                let textRightSide = text.substring(tagEndEndPos)
                text = textLeftSide

                if (taggedText) {
                    text += `<figure style="text-align: center">
                                 <img alt="" src="${image}"/>
                                 <figcaption>${taggedText}</figcaption>
                            </figure>`
                }
                else {

                    text += `<img alt="" src="${image}"/>`
                }
                text += textRightSide
            }
            else {
                break
            }
            match = text.match(/\[image([^<]+)/i)
        }

        // convert linefeeds
        text = text.replace(/(?:\r\n|\r|\n)/g, '<br>');

        return text
    },

    compressor: () => {

    }



}


export default Utility