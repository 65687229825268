import ApiConnector from './ApiConnector';

let ApiHelper = {

    getSheetSettings: (params) => {
        return new Promise((resolve, reject) => {
            ApiConnector.call('post', 'wargalleyservice/sheetSettings/get', params, null).then((response) => {
                if (response.hasOwnProperty('error')) {
                    reject(response.error)
                }
                else {
                    if (response.hasOwnProperty('success') && response.success.hasOwnProperty('data')) {
                        resolve(response.success.data)
                    }
                    else {
                        console.log('reject t1')
                        reject({ message: 'unknown error' })
                    }
                }
            },
                (error) => {
                    console.warn('got error: ', error)
                    console.log('reject t2')
                    reject(error)
                })
        })

    },

    getLayerKeys: (params) => {
        return new Promise((resolve, reject) => {
            ApiConnector.call('post', 'wargalleyservice/layer/get/keys', params, null).then((response) => {
                if (response.hasOwnProperty('error')) {
                    console.log('reject t3')
                    reject(response.error)
                }
                else {
                    if (response.hasOwnProperty('success') && response.success.hasOwnProperty('data')) {
                        resolve(response.success.data)
                    }
                    else {
                        console.warn('no internet')
                        console.log('reject t4')
                        reject({ message: 'unknown error' })
                    }
                }
            },
                (error) => {
                    console.warn('got error: ', error)
                    console.log('reject t5')
                    reject(error)
                })
        })
    },

    getLayers: (params) => {
        return new Promise((resolve, reject) => {
            ApiConnector.call('post', 'wargalleyservice/layer/cache', params, null).then((response) => {
                if (response.hasOwnProperty('error')) {
                    reject(response.error)
                }
                else {
                    if (response.hasOwnProperty('success') && response.success.hasOwnProperty('data')) {
                        resolve(response.success.data)
                    }
                    else {
                        console.log('reject t6')
                        reject({ message: 'unknown error' })
                    }
                }
            },
                (error) => {
                    console.warn('got error: ', error)
                    console.log('reject t7')
                    reject(error)
                })
        })

    },

    getInputTypes: (params) => {
        return new Promise((resolve, reject) => {
            ApiConnector.call('post', 'wargalleyservice/inputType/get', params, null).then((response) => {
                if (response.hasOwnProperty('error')) {
                    console.warn('response.error: ', response.error)
                    console.log('reject t9')
                    reject(response.error)
                }
                else {
                    if (response.hasOwnProperty('success') && response.success.hasOwnProperty('data')) {
                        resolve(response.success.data)
                    }
                    else {
                        console.warn('unknown error on getInputTypes')
                        console.log('reject t10')
                        reject({ message: 'unknown error' })
                    }
                }
            },
                (error) => {
                    console.warn('ApiHelper got error on getInputTypes: ', error)
                    console.log('reject t11')
                    reject(error)
                })
        })

    },

    getSvg: (params) => {
        return new Promise((resolve, reject) => {
            ApiConnector.call('post', 'wargalleyservice/svg/get', params, null).then((response) => {
                if (response.hasOwnProperty('error')) {
                    reject(response.error)
                }
                else {
                    if (response.hasOwnProperty('success') && response.success.hasOwnProperty('data')) {
                        console.log('reject t12')
                        resolve(response.success.data)
                    }
                    else {
                        console.log('reject t13')
                        reject({ message: 'unknown error' })
                    }
                }
            },
                (error) => {
                    console.log('reject t14')
                    reject(error)
                })
        })

    },

    getSvgs: () => {
        return new Promise((resolve, reject) => {
            ApiConnector.call('post', 'wargalleyservice/svg/get', {}, null).then((response) => {
                if (response.hasOwnProperty('error')) {
                    console.log('reject t15')
                    reject(response.error)
                }
                else {
                    if (response.hasOwnProperty('success') && response.success.hasOwnProperty('data')) {
                        resolve(response.success.data)
                    }
                    else {
                        console.log('reject t16')
                        reject({ message: 'unknown error' })
                    }
                }
            },
                (error) => {
                    reject(error)
                })
        })

    },

    // getInputNames: (inputKeys) => {
    //     return new Promise((resolve, reject) => {
    //         ApiConnector.call('post', 'wargalleyservice/inputNames/get', {inputKeys}, null).then((response) => {
    //             if (response.hasOwnProperty('error')) {
    //                 reject(response.error)
    //             }admin
    //             else {
    //                 if (response.hasOwnProperty('success') && response.success.hasOwnProperty('data')) {
    //                     resolve(response.data)
    //                 }
    //                 else {
    //                     reject({ message: 'unknown error' })
    //                 }
    //             }
    //         },
    //             (error) => {
    //                 reject(error)
    //             })
    //     })
    // },

    // getInputs: (inputs) => {
    //     return new Promise((resolve, reject) => {
    //         ApiConnector.call('post', 'wargalleyservice/inputs/get', {inputs}, null).then((response) => {
    //             if (response.hasOwnProperty('error')) {
    //                 reject(response.error)
    //             }
    //             else {
    //                 if (response.hasOwnProperty('success') && response.success.hasOwnProperty('data')) {
    //                     resolve(response.data)
    //                 }
    //                 else {
    //                     reject({ message: 'unknown error' })
    //                 }
    //             }
    //         },
    //             (error) => {
    //                 reject(error)
    //             })
    //     })
    // },


    getFonts: () => {
        return new Promise((resolve, reject) => {
            ApiConnector.call('post', 'wargalleyservice/font/get', {}, null).then((response) => {
                if (response.hasOwnProperty('error')) {
                    reject(response.error)
                }
                else {
                    if (response.hasOwnProperty('success') && response.success.hasOwnProperty('data')) {
                        resolve(response.success.data)
                    }
                    else {
                        reject({ message: 'unknown error' })
                    }
                }
            },
                (error) => {
                    reject(error)
                })
        })
    },

    getFontSrc: (params) => {
        return new Promise((resolve, reject) => {
            ApiConnector.call('post', 'wargalleyservice/font/src/get', params, null).then((response) => {
                if (response.hasOwnProperty('error')) {
                    reject(response.error)
                }
                else {
                    if (response.hasOwnProperty('success') && response.success.hasOwnProperty('data')) {
                        resolve(response.success.data)
                    }
                    else {
                        reject({ message: 'unknown error' })
                    }
                }
            },
                (error) => {
                    reject(error)
                })
        })
    },







    fontPost: (params) => {
        return new Promise((resolve, reject) => {
            ApiConnector.call('post', 'wargalleyservice/font/post', params, null).then((response) => {
                if (response.hasOwnProperty('error')) {
                    reject(response.error)
                }
                else {
                    if (response.hasOwnProperty('success') && response.success.hasOwnProperty('data')) {
                        resolve(response.success.data)
                    }
                    else {
                        reject({ message: 'unknown error' })
                    }
                }
            },
                (error) => {
                    reject(error)
                })
        })

    },

    checkForUpdate: (params) => {
        return new Promise((resolve, reject) => {
            ApiConnector.call('post', 'wargalleyservice/update/post', params, null).then((response) => {
                if (response.hasOwnProperty('error')) {
                    reject(response.error)
                }
                else {
                    if (response.hasOwnProperty('success')) {
                        resolve(response.success)
                    }
                    else {
                        reject({ message: 'unknown error' })
                    }
                }
            },
                (error) => {
                    reject(error)
                })
        })

    },

}

export default ApiHelper