import React, { useEffect, useState } from 'react'
import Snap from 'snapsvg-cjs'
import './GridDisplay.scss'
const GridDisplay = ({ showingGrid, usingGridColor }) => {
    const [paper, setPaper] = useState(null)

    useEffect(() => {
        // drawOverlay()
        let _paper = Snap("#grid_display_svg");
        _paper.attr({ id: "grid_display_svg", viewBox: "-120, -120, 240, 240" });
        setPaper(_paper)
    }, [])

    useEffect(() => {
        if (showingGrid) {
          drawOverlay()
        }
        else {
            if (paper) {
                paper.clear()
            }
        }
    }, [showingGrid]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if(showingGrid) {
            if(paper) {
                paper.clear()
                drawOverlay()
            }
        }
    }, [usingGridColor]) // eslint-disable-line react-hooks/exhaustive-deps

    const drawOverlay = () => {
        // default coloring is the light theme.
        let colorX = ['#6cf','#fef']
        let colorY = ['#755', '#a55']
        let circleBiggerColor = '#f71'
        let circleSmallerColor = '#5ff'
        let textColor = '#fff'
        if( usingGridColor === 'dark' ) {
            colorX = ['#000', '#229']
            colorY = ['#600', '#022']
            circleBiggerColor = '#840'
            circleSmallerColor = '#02a'
            textColor = '#aaa'
        }



        for (var x = -100; x <= 100; x += 5) {
            if (x % 10) {
                paper.line(x, -100, x, 100).attr({ "strokeWidth": 0.5, stroke: colorX[0], opacity: 0.4 })
            }
            else {
                if (x === 0) {
                    paper.line(x, -100, x, 100).attr({ "strokeWidth": 0.8, stroke: colorX[0], opacity: 0.8 })
                }
                else {
                    paper.line(x, -100, x, 100).attr({ "strokeWidth": 0.5, stroke: colorX[0], opacity: 0.6 })
                }
                paper.text(x - 0.1, -106.5, x ? x : '0').attr({ "textAnchor": "middle", "dominant-baseline": "central", "fontSize": "5", "fontWeight": "normal", "fontFamily": "sans-serif", stroke: "none", fill: textColor })
            }
        }

        for (var y = -100; y <= 100; y += 5) {
            if (y % 10) {
                paper.line(-100, y, 100, y).attr({ "strokeWidth": 0.7, stroke: colorY[0], opacity: 0.6 })
            }
            else {
                if (y === 0) {
                    paper.line(-100, y, 100, y).attr({ "strokeWidth": 0.8, stroke: colorY[1], opacity: 1 })
                }
                else{
                    paper.line(-100, y, 100, y).attr({ "strokeWidth": 0.7, stroke: colorY[0], opacity: 0.7 })
                }
                paper.text(-105, y - 0.2, y ? y : '0').attr({ "textAnchor": "end", "dominant-baseline": "central", "fontSize": "5", "fontWeight": "normal", "fontFamily": "sans-serif", stroke: "none", fill: textColor })
            }
        }

        paper.circle(0, 0, 10).attr({ "strokeWidth": 0.4, stroke: circleBiggerColor, fill: "none" })
        paper.circle(0, 0, 5).attr({ "strokeWidth": 0.4, stroke: circleSmallerColor, fill: "none" })
    }


    return (
        <div className={showingGrid ? 'grid-display' : 'grid-display display-none'}>
            <svg id="grid_display_svg" className="grid-display-svg" />
        </div>
    );
}
export default GridDisplay