const initialState = {
    restoreCounterState: false,
    browser: '',
    os: '',
    help: false,
    restoreDatabase: false,
    restoreWorkspace: false,
    sheetPaper: null,
    loadCounter: null,
    blur: null,
    focus: null,
    layers: [],
    layersOrder: [],
    svgs: [],
    sheetSettings: {},
    dexie: null,
    fonts: [],
    mouseDown: [],
    mouseClick: [],
    mouseDblClick: [],
    returnKey: [],
    keyUp: [],
    windowWidthHeight: { width: 0, height: 0 },
    counterDrawWidth: 0,
    errorMessages: [],
    colorPicker: { x: -1, y: -1, hexColor: null },
    overlay: false,
    dataDisplay: null,
    installView: '',

    savedCounters: {},
    savedSheets: {},

    activeLayerValues: {},
    inputs: [],


    draggingLayer: false,
    dragUpdate: { layerKey: 0, inputKey: 0, x: 0, y: 0, inputKey2: 0, x2: 0, y2: 0 },
    duplicateLayers: [],
    moveCounterToSheet: null,
    cloneCounterToSlot: null,

    slots: [],
    counterClear: false,
    sheetSave: null,
    sheetSaved: null,
    activateLayer: { layerKey: null, active: false },
    userLoadSlotsSheet: null,
};
const types = {
    RESTORE_COUNTER_STATE: "RESTORE_COUNTER_STATE",
    BROWSER: "BROWSER",
    OS: "OS",
    HELP: "HELP",
    RESTORE_DATABASE: "RESTORE_DATABASE",
    RESTORE_WORKSPACE: "RESTORE_WORKSPACE",
    SHEET_PAPER: "SHEET_PAPER",
    PAUSE_STATE_EFFECTS: "PAUSE_STATE_EFFECTS",
    LOAD_COUNTER: "LOAD_COUNTER",
    BLUR: "BLUR",
    FOCUS: "FOCUS",
    MOUSE_DOWN: "MOUSE_DOWN",
    MOUSE_CLICK: "MOUSE_CLICK",
    MOUSE_DOUBLE_CLICK: "MOUSE_DOUBLE_CLICK",
    RETURN_KEY: "RETURN_KEY",
    KEY_UP: "KEY_UP",
    WINDOW_WIDTH_HEIGHT: "WINDOW_WIDTH_HEIGHT",
    COUNTER_DRAW_WIDTH: "COUNTER_DRAW_WIDTH",
    ERROR_MESSAGES: "ERROR_MESSAGES",
    CLEAR_ERROR_MESSAGES: "CLEAR_ERROR_MESSAGES",
    COUNTER_MENU_LAYER_OPTION: "COUNTER_MENU_LAYER_OPTION",
    COLOR_PICKER: "COLOR_PICKER",
    OVERLAY: "OVERLAY",
    DATA_DISPLAY: "DATA_DISPLAY",
    INSTALL_VIEW: "INSTALL_VIEW",
    LAYERS: "LAYERS",
    ADD_LAYER: "ADD_LAYER",
    LAYERS_ORDER: "LAYERS_ORDER",
    SAVED_COUNTERS: "SAVED_COUNTERS",
    SAVED_COUNTERS_REMOVE: "SAVED_COUNTERS_REMOVE",
    SAVED_SHEETS: "SAVED_SHEETS",
    SAVED_SHEETS_REMOVE: "SAVED_SHEETS_REMOVE",
    LAYER_UPDATE: "LAYER_UPDATE",
    LAYERS_UPDATE: "LAYERS_UPDATE",
    INPUT_VALUES: "INPUT_VALUES",
    INPUT_VALUES_REMOVE: "INPUT_VALUES_REMOVE",
    ACTIVE_LAYERS: "ACTIVE_LAYERS",
    ACTIVE_LAYER_VALUES: "ACTIVE_LAYER_VALUES",
    ACTIVE_LAYER_VALUES_REMOVE: "ACTIVE_LAYER_VALUES_REMOVE",
    ACTIVE_LAYER_VALUES_RESET: "ACTIVE_LAYER_VALUES_RESET",
    INPUT_TYPES: "INPUT_TYPES",
    CHANGED_INPUT_VALUES: "CHANGED_INPUT_VALUES",
    CURRENT_INPUT_VALUES: "CURRENT_INPUT_VALUES",
    INPUTS: "INPUTS",
    SVGS: "SVGS",
    SVGS_ADD: "SVGS_ADD",
    SVGS_REMOVE: "SVGS_REMOVE",
    FONTS: "FONTS",
    DEXIE: "DEXIE",
    DRAGGING_LAYER: "DRAGGING_LAYER",
    DRAG_UPDATE: "DRAG_UPDATE",
    DUPLICATE_LAYERS: "DUPLICATE_LAYERS",
    MOVE_COUNTER_TO_SHEET: "MOVE_COUNTER_TO_SHEET",
    CLONE_COUNTER_TO_SLOT: "CLONE_COUNTER_TO_SLOT",
    SHEET_SETTINGS: "SHEET_SETTINGS",
    SLOTS: "SLOTS",
    COUNTER_CLEAR: "COUNTER_CLEAR",
    SHEET_SAVE: "SHEET_SAVE",
    SHEET_SAVED: "SHEET_SAVED",
    LOAD_LAYERS_TO_ACTIVE: "LOAD_LAYERS_TO_ACTIVE",
    ACTIVATE_LAYER: "ACTIVATE_LAYER",
    USER_LOAD_SLOTS_SHEET: "USER_LOAD_SLOTS_SHEET",
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.RESTORE_COUNTER_STATE:
            return {
                ...state,
                restoreCounterState: action.payload
            };
        case types.OS:
            return {
                ...state,
                os: action.payload
            };
        case types.BROWSER:
            return {
                ...state,
                browser: action.payload
            };
        case types.HELP:
            return {
                ...state,
                help: action.payload
            };
        case types.RESTORE_DATABASE:
            return {
                ...state,
                restoreDatabase: action.payload,
                layers: [],
                svgs: [],
                sheetSettings: null,
                slots: [],
                activeLayerValues: {},
                savedCounters: {},
                savedSheets: {},
                fonts: [],
                layersOrder: []
            };
        case types.RESTORE_WORKSPACE:
            return {
                ...state,
                restoreWorkspace: action.payload
            };
        case types.DISABLE_INPUT:
            return {
                ...state,
                disableInput: action.payload
            };
        case types.SHEET_PAPER:
            return {
                ...state,
                sheetPaper: action.payload
            };
        case types.LOAD_COUNTER:
            return {
                ...state,
                loadCounter: action.payload
            };
        case types.BLUR:
            return {
                ...state,
                blur: action.payload
            };
        case types.FOCUS:
            return {
                ...state,
                focus: action.payload
            };

        case types.MOUSE_DOWN:
            return {
                ...state,
                mouseDown: action.payload
            };
        case types.MOUSE_CLICK:
            return {
                ...state,
                mouseClick: action.payload
            };
        case types.MOUSE_DOUBLE_CLICK:
            return {
                ...state,
                mouseDblClick: action.payload
            };
        case types.RETURN_KEY:
            return {
                ...state,
                returnKey: action.payload
            };
        case types.KEY_UP:
            return {
                ...state,
                keyUp: action.payload
            };
        case types.WINDOW_WIDTH_HEIGHT:
            return {
                ...state,
                windowWidthHeight: action.payload
            };
        case types.COUNTER_DRAW_WIDTH:
            return {
                ...state,
                counterDrawWidth: action.payload
            };
        case types.ERROR_MESSAGES:
            if (typeof action.payload === 'string') {
                action.payload = [action.payload]
            }
            // if message already exists, ignore
            if (state.errorMessages.indexOf(action.payload[0]) > -1) {
                return state
            }
            return {
                ...state,
                errorMessages: [...state.errorMessages, ...action.payload]
            };
        case types.CLEAR_ERROR_MESSAGES:
            return {
                ...state,
                errorMessages: []
            };
        case types.COLOR_PICKER:
            return {
                ...state,
                colorPicker: action.payload
            };
        case types.OVERLAY:
            return {
                ...state,
                overlay: action.payload
            };
        case types.DATA_DISPLAY:
            return {
                ...state,
                dataDisplay: action.payload
            };
        case types.INSTALL_VIEW:
            return {
                ...state,
                installView: action.payload
            };
        case types.LAYERS:
            if (JSON.stringify(state.layers) === JSON.stringify(action.payload)) {
                //    return can't do this - system is expected a return of state
                return state
            }
            return {
                ...state,
                layers: action.payload
            }
        case types.ADD_LAYER:
            return {
                ...state,
                layers: [...state.layers, action.payload]
            }
        case types.LAYERS_ORDER:
            return {
                ...state,
                layersOrder: [...state.layersOrder, ...action.payload]
            }
        case types.LAYER_UPDATE:
            let _layers = state.layers.map(ly => {
                if (ly.layerKey === action.payload.layerKey) {
                    return action.payload
                }
                return ly
            })
            return {
                ...state,
                layers: [..._layers]
            }
        case types.LAYERS_UPDATE:
            let _layers2 = state.layers.map(ly => {
                let updatedLayer = action.payload.find(apl => apl.layerKey === ly.layerKey)
                if (updatedLayer) {
                    return updatedLayer
                }
                return ly
            })
            return {
                ...state,
                layers: [..._layers2]
            }
        case types.SAVED_COUNTERS:
            return {
                ...state,
                savedCounters: { ...state.savedCounters, ...action.payload }
            }
        case types.SAVED_COUNTERS_REMOVE:
            let savedCountersClone = { ...state.savedCounters }
            delete savedCountersClone[action.payload]
            return {
                ...state,
                savedCounters: { ...savedCountersClone }
            }
        case types.SAVED_SHEETS:
            return {
                ...state,
                savedSheets: { ...state.savedSheets, ...action.payload }
            }
        case types.SAVED_SHEETS_REMOVE:
            let savedSheetsClone = { ...state.savedSheets }
            delete savedSheetsClone[action.payload]
            return {
                ...state,
                savedSheets: { ...savedSheetsClone }
            }
        case types.ACTIVE_LAYER_VALUES:
            return {
                ...state,
                activeLayerValues: { ...state.activeLayerValues, ...action.payload }
            }
        case types.ACTIVE_LAYER_VALUES_REMOVE:
            let removeLayerKeys = []
            if (Array.isArray(action.payload)) {
                removeLayerKeys = action.payload
            }
            else {
                removeLayerKeys.push(action.payload)
            }
            let reducedActiveLayerValues = JSON.parse(JSON.stringify(state.activeLayerValues))
            // cant remove layer 1, the base layer
            removeLayerKeys = removeLayerKeys.filter(layerKey => layerKey !== 1)
            if (removeLayerKeys.length === 0) { // if none of the keys were actually in activeLayerValues
                return {
                    ...state,
                    activeLayerValues: reducedActiveLayerValues
                }
            }


            let combined_keys = Object.keys(state.activeLayerValues)

            removeLayerKeys.forEach(removeLayerKey => {
                let deleteList = combined_keys.filter(ck => {
                    return ck.startsWith(removeLayerKey + '_')
                })
                deleteList.forEach(removeKey => delete reducedActiveLayerValues[removeKey])
            })

            return {
                ...state,
                activeLayerValues: reducedActiveLayerValues
            }
        case types.ACTIVE_LAYER_VALUES_RESET:
            return {
                ...state,
                activeLayerValues: action.payload
            }
        case types.SVGS:
            return {
                ...state,
                svgs: action.payload
            }
        case types.SVGS_ADD:
            return {
                ...state,
                svgs: [...state.svgs, action.payload]
            }
        case types.SVGS_REMOVE:
            let _svgs = state.svgs.filter(svg => svg.svgKey !== action.payload.svgKey)
            return {
                ...state,
                svgs: _svgs
            }
        case types.FONTS:
            return {
                ...state,
                fonts: action.payload
            }
        case types.DEXIE:
            return {
                ...state,
                dexie: action.payload
            }
        case types.DRAGGING_LAYER:
            return {
                ...state,
                draggingLayer: action.payload
            }
        case types.DRAG_UPDATE:
            return {
                ...state,
                dragUpdate: action.payload
            }
        case types.DUPLICATE_LAYERS:
            return {
                ...state,
                duplicateLayers: action.payload
            }
        case types.MOVE_COUNTER_TO_SHEET:
            return {
                ...state,
                moveCounterToSheet: action.payload
            }
        case types.CLONE_COUNTER_TO_SLOT:
            return {
                ...state,
                cloneCounterToSlot: action.payload
            }
        case types.SHEET_SETTINGS:
            return {
                ...state,
                sheetSettings: { ...state.sheetSettings, ...action.payload }
            }
        case types.SLOTS:
            return {
                ...state,
                slots: action.payload
            }
        case types.COUNTER_CLEAR:
            return {
                ...state,
                counterClear: action.payload
            }
        case types.SHEET_SAVE:
            return {
                ...state,
                sheetSave: action.payload
            }
        case types.SHEET_SAVED:
            return {
                ...state,
                sheetSaved: action.payload
            }
        case types.ACTIVATE_LAYER:
            return {
                ...state,
                activateLayer: action.payload
            }
        case types.USER_LOAD_SLOTS_SHEET:
            return {
                ...state,
                userLoadSlotsSheet: action.payload
            }
        default:
            throw new Error("Unexpected action")
    }
};
export { initialState, types, reducer }