import React, { useContext } from "react";
import { StoreContext } from "../../context/StoreContext";
import XButton from '../XButton/XButton'
import './Help.scss'

const Help = () => {
    const { actions, state } = useContext(StoreContext);
    const closeHelp = () => {
        actions.help(false)
    }

    return (
        <div className={state.help ? 'help' : 'display-none'}>
            <div className="help-top">
                <div className="help-title">HELP <span>* this help file is not complete, and will get many revisions and additions over time.</span></div>
                <div className="close-button">
                    <XButton r="12" closeAction={closeHelp} />
                </div>
            </div>
            <div>
                <p>
                    This app provides the ability to create designs that can be printed out and applied to square, round, or rectangular game pieces,
                    typically called "counters" in the gaming community.
                </p>
                <div className="disclaimer">
                <p>
                    This app is provided as a free, experimental project that probably has bugs and weird behaviors hidden in places. Don't depend
                    on this app to provide you with a bug-free experience. You may lose hours of work due to some bug.
                </p><p>
                    This app is a "internet" app, which means it can be accessed under a huge variety of browsers and browser versions, and
                    operating systems and versions, and computers, tablets, chromebooks, and laptops. Theres no way I can test every possible situation. 
                    I'm limited to testing on Ubuntu and Windows on desktop, with the browsers Edge, Firefox, Chrome, and Opera. If the app doesn't work
                    on your computer/browser/operating system, all I can say is "try another browser or operating system maybe?".
                </p><p>
                    This app does not transfer any information from its users back to the server. It does not track users, and does not use cookies.
                    The hosting provider and the server software does store ips in its logs as its running, but that is out of my hands. And I have no
                    interest in looking at server logs. If you use this app to make counters, I will have no idea what you are making - unless you send
                    me your counters or counter sheets for whatever reason, or you post online what you are creating.
                </p>
                <p>This app is designed for desktop computers with a mouse, with a decent computer monitor (preferably 1600x900 resolution or above). It will "run" on tablets and phones, but that will probably be a horrible experience.</p>
                </div>
                <p>
                    To start creating a design, you will select from a menu of options on the left side of the app (or top left side).
                    This area is called the "Menu", since you can select from a variety of design elements that can be place onto your counter.
                </p><p>
                    The main area, which is to the right of the menus, is the current state of your design, called the "Counter Design" area.
                    As you select, or unselect, items
                    from the menu, you will see the results of your actions reflected in the counter design area.
                </p><p>
                    The Menu has expandable options that will allow you to choose various elements and options to control size, color, rotation, and opacity.
                </p><p>
                    After you have chosen a menu element and set the options, you can click the round button to the left of the name, and click
                    it to make it "live". This means it should be applied to your counter in the Counter Design area. Once you have made a
                    menu option live, it will become a "layer" that is on your counter. These layers can be moved up and down, by clicking the
                    hamburger menu to the right side of the menu option and selecting "change order". This will control which layers are written
                    above other layers.
                </p><p>
                    The first layer (on top of the menu order) is the "base counter", and this cannot be removed from the counter, and must be
                    ordered as the first layer to be applied. All other layers can be moved up and down in layer order as you please.
                </p>
                <p>The "App options" has drop down selections you can chose from, and let you select from App level operations, such as
                    restoring the app to default settings, seeing what counters and sheets you have saved in the app, and backing up and
                    restoring the app states.
                </p>
                <img src="https://wargalley.com/static/media/misc/help_01.png" alt="counter app help 01" width="1172" height="705" />
                <br /><br />
                <p>
                    The large area to the bottom (or possibly the right side if you have a wide monitor) is the "Sheet Display" area.
                    As you create counters, you can copy them to the sheet, where they will be laid out in a regular grid pattern on
                    the "Counters Layout" area, which is in the shape of a A4 "letter" size paper (typical printer paper size) in portrait mode.
                </p><p>
                    Above the Counters Layout area are the "Sheet Controls" and the "Sheet Operations" areas. The Sheet Controls will control how the
                    counters are placed in the layout area. The Sheet Operations has the options for saving, loading, and selecting a cutting
                    guide to be applied to the sheet. The cutting guide is for helping you keep your cuts straight when you have printed out
                    your sheet and are cutting the counters out of it.
                </p>
                <img src="https://wargalley.com/static/media/misc/help_02.png" alt="counter app help 02" width="1027" height="578" />
                <p>
                    <b>Printing out your sheet</b>
                </p><p>
                    Since this app (being a browser app) can only export png images to 72dpi or 96dpi (poor quality when printed out), the app will
                    create the image as being much bigger than your standard printer paper is. Since most printers will "shrink to fit"
                    images sent to it (by default), it will hopefully come out to decent quality on your printer. I may in the future provide 
                    a feature to let the user change the size of the png image being exported.
                </p><p>
                    If you export your sheet out to svg format, the app will include any font data you have in your sheet. However, most
                    applications that let you load and edit svg files will ignore the font data (something about security/copyright concerns),
                    which unfortunately limits the usefulness of exporting to svg. You *could* download the Google fonts you are using, and install
                    them into your operating system, though that is a bit of a pain to do. Once the fonts are accepted by your operating system, then
                    your exported svg sheets should show up correctly when you import them into a svg editor.
                    </p><p>
                    If you do want to use a svg-capable graphics editor, you can try
                    using one of the following excellent applications. They can print out svgs directly, or you can export them to png format
                    and choose the size and dpi of the resulting png image.
                </p><p>
                    <b>Inkspace</b> is a free app for Windows, Mac, and Linux.<br />
                    Available at <a href="https://inkscape.org/" target="_blank" rel="noopener noreferrer">inkscape.org</a>
                </p><p>
                   <b>Boxy SVG</b> is a free app for Windows, Mac, and Linux.<br />
                    Available at <a href="https://boxy-svg.com/" target="_blank" rel="noopener noreferrer">boxy-svg.com</a>
                </p><p>
                    <b>Importing your own svgs, images, and fonts</b>
                </p>

                <img className="float-left" src="https://wargalley.com/static/media/misc/help_03.png" alt="counter app help 03" width="240" height="368" />
                <p>
                    You can insert into the app your own svg, image, and font resources for putting on your counter. Those svgs and images will become
                    available in menu items labeled "custom svgs" and "custom images" (these menus are not visible with the default settings because
                    they have no resources in them yet).
                </p><p>
                    To install your own svgs, images, and fonts, click on the "Client" option in the "App options" bar. There should be a drop-down
                    selection control. Click on "install font", or "install svg", or "install image".
                </p><p>
                    If the resource you installed was a svg or image, it should be available in the menu option "custom svgs" or "custom images".
                </p><p>
                    If the resource you installed was a font, then the font should be one of the fonts you can select from any
                    menu items that has a font selection control.
                </p>
                <p>
                    <span className="note">NOTE:</span> If you import your own resources into the app, and use them for your counters - you need to
                    be careful that you don't then delete those resources. If later <i>you remove those resources</i> from the app, then any counters you created that were made depending
                    on those resources to exist, will no longer display correctly. This app does not have the "intelligence" to detect
                    when you are making a mistake. If you screw up and remove your resources that you were using, then your counters
                    will be missing those resources.
                </p>
                <p>
                    The app does give you the ability to backup your modifications to the app, so if you mess something up, or the app
                    has a bug and loses your resources, you should have the ability to restore your modifications back to the app.
                </p>
                <p className="clear-both-pad-top">
                    <b>Designing your counter</b>
                </p>
                <p>
                    When you initially load the app, the Counter Design area will be a square shape with a default color.
                    This square shape and the color is a "layer" that is always active, and cannot be removed. Its necessary because
                    it provides, basically, the "canvas" on which you "paint" on. You can chose to have a different shape (circle, rectangle),
                    and you can change its color.
                </p>
                <img className="float-left" src="https://wargalley.com/static/media/misc/help_04.png" alt="counter app help 04" width="300" height="501" />

                <p>
                    To select a new layer to put on your counter, select one of the menu items.
                </p>
                <p>For example, to put a standard wargame NATO symbol on your counter, click on the option with the
                    text "NATO unit symbols". This should open the options for the "NATO unit symbols" menu.
                </p>
                <p>Under where it says "click to select symbol", click on one of the items. For example, I have selected the
                    "infantry" item. Selecting this will make the menu "active", which creates a layer and puts it on your counter.
                </p>
                <p>The round green indicator to the left of the text "NATO unit symbols" will turn bright cyan color. This means
                    its active and painted into a layer on your counter. If you wish to remove it from your counter, click
                    the active indicator to turn it off.
                </p>
                <p>With this menu option, you can see there are controls you use to position, scale, color, etc the layer.
                    All menu items will have some of these controls.
                </p>
                <p>To select a color, you can click on the colored pie-chart looking circle to bring up the color selector popup.
                    In the color popup, you can choose a color, and it will be applied to the layer. You can also select no color, by
                    typing in the word "transparent".
                </p>
                <p>
                   For controls that have a slider component, you can return it to the default by double-clicking on the label (the label
                   will say something like "width", "height" "x", "y", "xy position", etc.
                </p>
                <p>You can also adjust the position of the layers in the Counter Design area itself, by clicking and holding down and dragging the mouse,
                    on any "painted" area of a layer.
                </p>

            </div>
        </div>
    );

}
export default Help;