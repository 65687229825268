import React, { useState, useEffect, useContext, useRef } from 'react'
import Snap from 'snapsvg-cjs'
import { StoreContext } from "../../context/StoreContext"
import XButton from '../XButton/XButton'
import Utility from "../../objects/Utility"
import './InstallImage.scss'

const InstallImage = () => {
    const { state, actions } = useContext(StoreContext)
    const [imageName, setImageName] = useState('')
    const [imageInstalled, setImageInstalled] = useState(false)
    const [importPaper, setImportPaper] = useState(null)
    const [acceptedSvg, setAcceptedSvg] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [imageNameTaken, setImageNameTaken] = useState(false)
    const [imageNameInvalid, setImageNameInvalid] = useState(false)
    const installImageRef = useRef(null)

    useEffect(() => {
        let copyOfRef = installImageRef.current // linter said I should copy this to a variable so the cleanup function can be reliable.
        installImageRef.current.addEventListener('mouseup', function (e) {
            e.stopPropagation();
        });

        return () => {
            if (copyOfRef) {
                copyOfRef.removeEventListener('mouseup', function (e) {
                    e.stopPropagation();
                });
            }
        }

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        exitView()
    }, [state.mouseClick]) // eslint-disable-line react-hooks/exhaustive-deps

    const reset = () => {
        setImageName('')
        setImageInstalled(false)
        setAcceptedSvg('')
        setImageNameTaken(false)
        setImageNameInvalid(false)
        setErrorMessage('')
        let fileInputElement = document.getElementById('installImageFile')
        if (fileInputElement) {
            fileInputElement.value = ''
        }
        if (importPaper) {
            importPaper.clear()
            importPaper.attr({ width: "200", height: "200" })
            importPaper.attr({ viewBox: '0,0,200,200' });
        }
    }

    const closeAction = () => {
        exitView()
    }

    const exitView = () => {
        if (state.installView) {
            reset()
            window.scrollTo(0, 0)
        }
        actions.installView('')
    }

    const readImageWidthHeight = (data) => {
        return new Promise((resolve, reject) => {
            let img = new Image()
            img.addEventListener("load", function () {
                resolve({ width: this.naturalWidth, height: this.naturalHeight })
            });

            img.src = data
        })
    }

    const loadFromFileOnChange = (evt) => {
        let imageBase64 = ''
        var file = evt.target.files[0]
        let testString = file.name.toLowerCase()
        let fileName = file.name
        if (testString.endsWith('.jpg')) {
            fileName = testString.substring(0, testString.length - 4)
        }
        if (testString.endsWith('.jpeg')) {
            fileName = testString.substring(0, testString.length - 5)
        }
        if (testString.endsWith('.png')) {
            fileName = testString.substring(0, testString.length - 4)
        }
        setImageName(fileName)
        var reader = new FileReader(); // Creating reader instance from FileReader() API
        reader.addEventListener("load", function () { // Setting up base64 URL on image
            imageBase64 = reader.result;
            readImageWidthHeight(imageBase64).then(dimensions => {
                constructSvgWithImageData(dimensions, imageBase64)
            })

        }, false);
        reader.readAsDataURL(file); // Converting file into data URL

        /*
        <svg xmlns="http://www.w3.org/2000/svg" 
       xmlns:xlink="http://www.w3.org/1999/xlink"> 
    <image width="{IMAGE_WIDTH}" height="{IMAGE_HEIGHT}" 
           xlink:href="data:image/png;base64,{IMAGE_DATA}" 
    /> 
  </svg> 
  */
        // var blob = imageItem.getAsFile();
        // var blobUrl = window.URL.createObjectURL(blob);
        // var img = new Image();
        // img.onload = function () {
        //     //Draw the image on canvas
        //     var context = canvas.getContext("2d");
        //     context.drawImage(img, 0, 0);
        // };
        // img.src = blobUrl;
    }


    // useEffect(() => {
    //     if (svgLoadedResult) {
    //         let _paper = importPaper
    //         if (_paper === null) {
    //             _paper = Snap("#importPng_") // snap completely stopped working when I changed the id to importSvg. Seems using the name importSvg was reserved cause I used it as a state name. So leave the underscore there.
    //             setImportPaper(_paper)
    //         }
    //         else {
    //             _paper.clear()
    //         }

    //         let svgString = svgLoadedResult.trim()

    //         // check to see if its really a valid svg file
    //         let valid = false
    //         if (svgString.includes('<svg') && svgString.endsWith('</svg>')) {
    //             // we need to replace all the ids with unique ids so that they don't conflict with each other
    //             let parsed = Snap.parse(svgString)
    //             // parse the svg string into svg existance, then read all the ids, then
    //             // delete the parsed svg, replace all the ids in the svg string, then parse it back in again.
    //             // yes this could be more straightforward with just doing regex on the svg string initially,
    //             // but this way works fine, and it may be more dependable on picking up all ids in the svg.
    //             _paper.append(parsed)


    //             let foundIds = Utility.distillIdsFromSnapElement(_paper)
    //             let _uniquePrepend = Utility.randomString(8)
    //             _uniquePrepend.replaceAll('_', '') // get rid of any underscores cause we need to use it as a known separator.
    //             _uniquePrepend += '_'
    //             svgString = Utility.replaceIdsInSvgString(foundIds, svgString, _uniquePrepend)
    //             setUniquePrepend(_uniquePrepend)
    //             parsed = Snap.parse(svgString)
    //             _paper.clear()
    //             _paper.append(parsed)





    //             // seems to be a real svg file.
    //             valid = true
    //             // Get the bounds of the SVG visible content
    //             let svgBbox = null

    //             svgBbox = _paper.getBBox({ fill: false, clipped: true })
    //             svgBbox.x = 0 // not sure why the getBBox wants to cut into the svg from the left.
    //             let box_width = 0
    //             let box_height = 0
    //             svgBbox.x = Number(svgBbox.x)
    //             svgBbox.y = Number(svgBbox.y)
    //             svgBbox.width = Number(svgBbox.width)
    //             svgBbox.height = Number(svgBbox.height)
    //             if (svgBbox.width > 200 && svgBbox.height > 200) {
    //                 box_width = svgBbox.width
    //                 box_height = svgBbox.height
    //                 if (svgBbox.height > svgBbox.width) {
    //                     box_width = svgBbox.height
    //                 }
    //                 else {
    //                     box_height = svgBbox.width
    //                 }
    //             }
    //             else {
    //                 box_width = svgBbox.width + 2
    //                 box_height = svgBbox.height + 2
    //                 if (box_height > box_width) {
    //                     box_width = box_height
    //                 }
    //                 else {
    //                     box_height = box_width
    //                 }
    //             }

    //             let viewboxString = ''
    //             viewboxString = Math.round(svgBbox.x) + ',' + Math.round(svgBbox.y) + ',' +
    //                 Math.round(svgBbox.width) + ',' +
    //                 Math.round(svgBbox.height)

    //             _paper.attr({ viewBox: viewboxString });
    //             let viewboxArray = viewboxString.split(',')
    //             let viewboxWidth = viewboxArray[2]
    //             let viewboxHeight = viewboxArray[3]

    //             // figure box to fit inside 200x200 dimensions max
    //             let useWidth = 200
    //             let useHeight = 200
    //             if (viewboxWidth >= viewboxHeight) {
    //                 useWidth = 200
    //                 useHeight = (viewboxHeight / viewboxWidth) * 200
    //             }
    //             else {
    //                 useHeight = 200
    //                 useWidth = (viewboxWidth / viewboxHeight) * 200
    //             }

    //             _paper.attr({ width: Utility.roundFloat(useWidth, 1), height: Utility.roundFloat(useHeight, 1) })
    //             setOriginalViewbox(viewboxString)
    //             setViewboxArray(viewboxString.split(','))
    //             // and we show the reduced, duplicated, and modified svg.
    //             var svgSource = _paper.toString()
    //             setAcceptedSvg(svgSource)
    //         }

    //         setSvgLoaded(valid)
    //         if (!valid) {
    //             setSvgLoadError(true)
    //         }
    //     }

    // }, [svgLoadedResult])  // eslint-disable-line react-hooks/exhaustive-deps

    const constructSvgWithImageData = (dimensions, imageBase64) => {
        let svgWidth = 200
        let svgHeight = 200
        if (dimensions.width > dimensions.height) {
            svgWidth = 200
            svgHeight = (dimensions.height / dimensions.width) * 200
        }
        else {
            svgHeight = 200
            svgWidth = (dimensions.width / dimensions.height) * 200
        }
        let svgString = `<image width="${dimensions.width}" height="${dimensions.height}" xlink:href="${imageBase64}" />`
        deploySvg(svgString, svgWidth, svgHeight, dimensions.width, dimensions.height)
    }

    const deploySvg = async (svgString, svgWidth, svgHeight, imageWidth, imageHeight) => {
        let _paper = importPaper
        if (_paper === null) {
            _paper = Snap("#importImage_") // snap completely stopped working when I changed the id to importSvg. Seems using the name importSvg was reserved cause I used it as a state name. So leave the underscore there.
            setImportPaper(_paper)
        }
        else {
            _paper.clear()
        }
        let parsed = Snap.parse(svgString)
        //_paper.circle(25,25,100).attr({fill:"red"})
        _paper.append(parsed)
        _paper.attr({ width: Utility.roundFloat(svgWidth, 1), height: Utility.roundFloat(svgHeight, 1) })
        _paper.attr({ viewBox: "0,0," + imageWidth + "," + imageHeight });

        // and we show the reduced, duplicated, and modified svg.
        var svgSource = _paper.toString()

        if (svgSource) {
            svgSource = svgSource.replace('<svg ', '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" ')
            svgSource = svgSource.replace('id="importImage_"', 'id="custom_image_' + Utility.randomString(8) + '"')
            setAcceptedSvg(svgSource)
            // check file name to see if it conflicts to svg name that is already in the db
            //importedFileName
            const names = await state.dexie.svgs.orderBy('svgName').keys();
            let isContained = Utility.arrContainsCaseInsensitiveStr(names, imageName)
            if (isContained) {
                // name is already present in db
                let count = 0;
                let tryName = imageName
                while (true) {
                    tryName = imageName + count
                    if (Utility.arrContainsCaseInsensitiveStr(names, tryName)) {
                        count++
                        if (count > 1000) {
                            break;
                        }
                    }
                    else {
                        break
                    }
                }
                setImageName(tryName)
            }
        }
        else {
            setErrorMessage('Could not read image data.')
        }
    }

    useEffect(() => {

    }, [acceptedSvg])

    const installCustomImage = async () => {

        ///////////////////////////////////////////////////////////////////////
        // insert new svg into dexie svgs data structure
        ///////////////////////////////////////////////////////////////////////
        let last = await state.dexie.svgs.orderBy('svgKey').last()
        let nextKey = last.svgKey + 1
        let svgItem = {
            svgKey: nextKey,
            svgCode: acceptedSvg,
            svgName: imageName,
            uniquePrepend: ''
        }
        await Utility.dexiePutItem(state.dexie, 'svgs', svgItem)

        ///////////////////////////////////////////////////////////////////////
        // insert new svg into state.svgs data structure
        ///////////////////////////////////////////////////////////////////////
        actions.svgsAdd(svgItem)

        ///////////////////////////////////////////////////////////////////////
        // insert new svg into layers svg_group list array
        ///////////////////////////////////////////////////////////////////////
        let foundLayer = state.layers.find(ly => ly.layerName === 'custom images')
        let customImagesLayerKey = foundLayer.layerKey
        let customImagesLayers = state.layers.filter(ly => ly.layerKey === customImagesLayerKey ||
            ly.parentLayerKey === customImagesLayerKey)
        customImagesLayers.forEach(_layer => {
            let input = _layer.inputs.find(li => li.named === 'svgKey')
            if (input) {
                if (input.list.includes(nextKey) === false) {
                    input.list.push(nextKey)
                }
            }
            // lets also set hidden to 0, since the user probably wants to be able to see if it inserted correctly.
            _layer.layerHidden = 0
            actions.layerUpdate(JSON.parse(JSON.stringify(_layer)))
        })

        for (let i = 0; i < customImagesLayers.length; i++) {
            let layer = customImagesLayers[i]
            ///////////////////////////////////////////////////////////////////////
            // insert new svg into dexie custom svgs layers' svg_group list array
            ///////////////////////////////////////////////////////////////////////
            await Utility.updateDexieStoreSingleItem(state.dexie, 'layers', layer, 'layerKey')
        }

        setImageInstalled(true)
    }

    const changeNewImageName = evt => {
        let imageName = evt.target.value
        setImageName(imageName)
    }

    useEffect(() => {
        if (imageName === '') {
            setImageNameInvalid(true)
            return
        }

        async function checkName() {
            const names = await state.dexie.svgs.orderBy('svgName').keys();
            let isContained = Utility.arrContainsCaseInsensitiveStr(names, imageName)
            if (isContained) {
                setImageNameTaken(true)
                setImageNameInvalid(true)
            }
            else {
                setImageNameTaken(false)
                setImageNameInvalid(false)
            }
        }
        checkName()

    }, [imageName]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div ref={installImageRef} className={state.installView === 'install image' ? 'install-image' : 'display-none'}>
            <div className="install-image-top">
                <div className="title">Install Image</div>
                <div className="close-button">
                    <XButton r="12" closeAction={closeAction} />
                </div>
            </div>
            <div className="intro">
                <p>
                    You can install your own images into this app.<br />
                    They will become selectable in the "Custom Images" layer menu option.
                </p>
            </div>

            <div className="install-contain" >
                <div className="install">

                    <div id="importImageContainer" className="image-container">
                        <div className="background-for-image">
                            <svg id="importImage_" width="200" height="200" />
                        </div>
                    </div>



                    <div className={acceptedSvg ? "display-none" : "text-area"}>
                        <div className="input-image-select">
                            <div>Choose a png or jpg image file from your computer -</div>
                            <input type="file" id="installImageFile" accept=".jpg,.png" onChange={loadFromFileOnChange} />
                        </div>

                        <div className={errorMessage ? 'error-message' : 'display-none'}>
                            <p>
                                There was an error loading your image.
                            </p>
                            <p>{errorMessage}</p>
                            <p>Is it perhaps in zip format? If so please unzip it first.</p>
                            <p>
                                If it is a valid image file, then it may be too complicated, or too large, for the app to use.
                            </p>
                            <p>
                                <button className="action-button" onClick={reset}>try again</button>
                            </p>
                        </div>
                    </div>

                    <div className={acceptedSvg && !imageInstalled ? "text-area" : "display-none"}>
                        <div className="success">Your image has been loaded.</div>
                        <div>
                            Give it a name, then click "install image" to add it to your Custom Images layer.
                        </div>
                        <div className="input-items">
                            <input id="newImageNameInput" className={imageNameInvalid ? 'invalid' : ''} type="text" placeholder="no name" value={imageName} onChange={changeNewImageName} />

                            {imageNameTaken ?
                                <button className="action-button disabled" disabled onClick={null}>install image</button>
                                :
                                <button className="action-button blueish" onClick={installCustomImage}>install image</button>
                            }

                        </div>
                        <div className={imageNameTaken ? 'name-taken' : 'display-none'}>
                            That name is already taken. Please choose another.
                        </div>
                        <div className="actions">
                            <button className="action-button yellowish" onClick={exitView}>Exit</button>
                            <button className="action-button greenish" onClick={reset}>try again</button>
                        </div>
                    </div>

                    <div className={imageInstalled ? 'text-area' : 'display-none'}>
                        Your image has been installed.
                        <div className="actions">
                            <button className="action-button blueish" onClick={exitView}>Exit</button>
                            <button className="action-button blueish" onClick={reset}>install another</button>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    );
}
export default InstallImage