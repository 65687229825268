import Utility from './Utility'
import Snap from 'snapsvg-cjs'

const CustomImages = {

    draw: (g, settings, svgs) => {
        let scaleWidth = 0
        let scaleHeight = 0
        let xpos = 0
        let ypos = 0
        let opacity = ''
        let rotation = 0
        let svgKey = ''
        let derivedValues = {}

        settings.forEach(setting => {
            switch (setting.name) {
                case 'xy position':
                    if (Array.isArray(setting.value) === false || setting.value.length !== 2) {
                        setting.value = [1, 1]
                    }
                    if (isNaN(setting.value[0])) {
                        setting.value[0] = 1
                    }
                    if (isNaN(setting.value[1])) {
                        setting.value[1] = 1
                    }
                    derivedValues.xpos = Utility.roundFloat(setting.value[0], 2)
                    derivedValues.ypos = Utility.roundFloat(setting.value[1], 2)
                    break
                case 'dimensions':
                    if (!setting.value || Array.isArray(setting.value) === false) {
                        derivedValues.width = 55
                        derivedValues.height = 55
                    }
                    else {
                        derivedValues.width = Utility.roundFloat(setting.value[0], 1)
                        derivedValues.height = Utility.roundFloat(setting.value[1], 1)
                    }
                    break
                case 'svgKey': derivedValues.svgKey = parseInt(setting.value)
                    break
                case 'opacity': derivedValues.opacity = Utility.roundFloat(setting.value / 100, 3)
                    break
                case 'rotation': derivedValues.rotation = Utility.roundFloat(setting.value, 2)
                    break
                default: console.warn('we seem to have missed one: ', setting.name)
            }
        })
        if (derivedValues.width === undefined || derivedValues.height === undefined) {
            derivedValues.width = 66
            derivedValues.height = 66
        }
        scaleWidth = derivedValues.width
        scaleHeight = derivedValues.height
        xpos = derivedValues.xpos
        ypos = derivedValues.ypos
        rotation = derivedValues.rotation
        svgKey = derivedValues.svgKey
        let svgObj = svgs.find(svg => svg.svgKey === svgKey)
        if (!svgObj) {
            console.warn('could not find custom image svg key : ', svgKey)
            return
        }
        let svgCode = svgObj.svgCode
        opacity = derivedValues.opacity
        let tadjusts = [0, 0]
        if (svgCode) {
            tadjusts = CustomImages.drawSvg(g, svgCode)
        }

        if (opacity < 1) {
            g.attr({ "opacity": opacity })
        }
        CustomImages.applyScale(g, scaleWidth, scaleHeight)
        CustomImages.applyRotation(g, rotation)
        CustomImages.applyTranslation(g, xpos + tadjusts[0], ypos + tadjusts[1])

    },

    drawSvg: (g, svgString) => {
        // gotta deal with ids
        let parsed = Snap.parse(svgString)
        g.append(parsed)
        let foundIds = Utility.distillIdsFromSnapElement(g)
        let uniquePrepend = 'a' + Utility.randomString(6)
        uniquePrepend.replaceAll('_', '') // get rid of any underscores cause we need to use it as a known separator.
        uniquePrepend += '_'
        svgString = Utility.replaceIdsInSvgString(foundIds, svgString, uniquePrepend)
        g.clear()
        parsed = Snap.parse(svgString)
        g.append(parsed)

        let measureG = g.getBBox()
        let adjustX = 20 + ((200 - measureG.width) / 2) - measureG.x
        let adjustY = 20 + ((200 - measureG.height) / 2) - measureG.y
        return [adjustX, adjustY]
    },

    applyScale: (g, width, height) => {
        let measureG = g.getBBox()
        let currentMatrix = g.transform().localMatrix;
        let addMatrix = new Snap.Matrix()
        //addMatrix.scale(width / 100, height / 100, 0, 0);
        addMatrix.scale(width / 100, height / 100, measureG.cx, measureG.cy); //scale object
        addMatrix.add(currentMatrix)
        g.transform(addMatrix)
    },

    applyRotation: (g, rotation) => {
        let measureG = g.getBBox()
        //g.circle(measureG.cx, measureG.cy, 10).attr({fill: "red", opacity: 0.5})
        let currentMatrix = g.transform().localMatrix;
        let addMatrix = new Snap.Matrix()
        // this is right on the left top box - addMatrix.rotate(rotation, measureG.cx - (measureG.width / 2), measureG.cy - (measureG.height / 2))
        addMatrix.rotate(rotation, measureG.cx, measureG.cy)

        addMatrix.add(currentMatrix)
        g.transform(addMatrix)
    },

    applyTranslation: (g, xpos, ypos) => {
        let currentMatrix = g.transform().localMatrix;
        let addMatrix = new Snap.Matrix()
        addMatrix.translate(xpos, ypos)
        addMatrix.add(currentMatrix)
        g.transform(addMatrix)
    },

}
export default CustomImages