import React, { useState, useEffect, useContext, useRef } from 'react'
import { StoreContext } from "../../context/StoreContext"
import Utility from "../../objects/Utility"
import CounterMenuLayerCommonOptions from '../CounterMenuLayerCommonOptions/CounterMenuLayerCommonOptions'
import CounterMenuLayerOptionsMenu from '../CounterMenuLayerOptionsMenu/CounterMenuLayerOptionsMenu'
import './CounterMenuLayerOption.scss'

const CounterMenuLayerOption = ({ layer, setOverlayActive }) => {
    const { state, actions } = useContext(StoreContext)
    const [optionMenuActive, setOptionMenuActive] = useState(false)
    const [menuOpened, setMenuOpened] = useState(false)
    const [tempHilite] = useState(false)
    const counterMenuLayerOptionRef = useRef(null)
    const [layerCanBeActive, setLayerCanBeActive] = useState(true) // layers may be active from dexie. Let the subcontrol to tell us if it should be false.

    const canGoActive = tf => {
        setLayerCanBeActive(tf)
    }

    useEffect(() => {
        if (layerCanBeActive === false) {
            // if the control says we can not have this layer active, then we neet to deactivate the layer (if its on) and 
            // remove the layer values from active (if any are there).
            deactivateLayer()
        }
    }, [layerCanBeActive]) // eslint-disable-line react-hooks/exhaustive-deps

    const toggleOptionMenu = () => {
        setOptionMenuActive(!optionMenuActive)
    }

    useEffect(() => {
        setOverlayActive(optionMenuActive)
    }, [optionMenuActive])  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (optionMenuActive) {
            setTimeout(() => {
                setOptionMenuActive(false)
            }, 100)
        }
    }, [state.mouseClick]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // any change (I think) on state.layers means an action has been completed on optionMenuActive if it is active. So close it.
        setOptionMenuActive(false)
    }, [state.layers])

    const activateLayer = async () => {
        if (layer && layerCanBeActive) {
            if (layer.layerActive === 0) {
                let updatedLayer = { ...layer, layerActive: 1 }
                await Utility.dexieRemoveLayersFromActive(state.dexie, layer.layerKey)
                await Utility.updateDexieStoreSingleItem(state.dexie, 'layers', updatedLayer, 'layerKey')
                actions.layerUpdate(updatedLayer)
                // actions.activeLayerValuesRemove(layer.layerKey)
                // populating activeLayerValues is responsiblity of CounterMenuLayerOptionsMenu
            }
        }
    }

    const deactivateLayer = async () => {
        if (layer.layerKey !== 1 && layer.layerActive === 1 ) {
            let updatedLayer = { ...layer, layerActive: 0 }
            actions.layerUpdate(updatedLayer)
            actions.activeLayerValuesRemove(layer.layerKey)
            // we remove all layer references from state now.
            await Utility.dexieRemoveLayersFromActive(state.dexie, layer.layerKey)
            //  setTimeout(async() => {
            await Utility.updateDexieStoreSingleItem(state.dexie, 'layers', updatedLayer, 'layerKey')

            // },400)
        }
    }


    // useEffect(() => {
    //     if (state.mouseDblClick) {
    //         if (state.mouseDblClick.target && state.mouseDblClick.target.id) {
    //             if (state.mouseDblClick.target.id === 'layer_name_' + layer.layerKey) {
    //                 activateLayer()
    //                 // let foundLayer = state.layers.find(ly => ly.layerKey === layerKey)
    //                 // if (foundLayer) {
    //                 //     let foundInput = foundLayer.inputs.find(inp => inp.inputKey === input.inputKey)
    //                 //     if (foundInput) {
    //                 //         let intVal = foundInput.defaultIntValue
    //                 //         if( intVal !== null ) {
    //                 //             setRangeval(intVal)
    //                 //         }
    //                 //     }
    //                 // }
    //             }
    //         }
    //     }
    // }, [state.mouseDblClick])

    const _setMenuOpened = (onOrOff) => {
        setMenuOpened(onOrOff)
    }

    return (
        <div ref={counterMenuLayerOptionRef} id={'counterMenuLayerOption_' + layer.layerKey}
            className="counter-menu-layer-option">
            <div className={menuOpened ? 'command-line menu-opened' : 'command-line'}>
                {layer.layerKey === 1 ?
                    <div className="activate-button active not-allowed"></div>
                    : <div className={layer.layerActive ? 'activate-button active' : layerCanBeActive ? 'activate-button' : 'activate-button not-allowed'} onClick={layer.layerActive === 1 ? deactivateLayer : activateLayer}></div>
                }
                <div className={menuOpened ? 'option-name currently-active' : 'option-name'}>
                    <div id={`layer_name_${layer.layerKey}`} className={layer.layerActive ?
                        tempHilite ? 'hilite' :
                            'layer-active'
                        : ''} onClick={() => _setMenuOpened(!menuOpened)}>
                        {layer.layerName}
                    </div>

                    <div className={`${optionMenuActive ? 'option-menu active' : 'option-menu'} ${menuOpened ? 'layer-active' : ''}`} onClick={toggleOptionMenu}>
                        &equiv;
                    </div>

                </div>
                <CounterMenuLayerCommonOptions layer={layer} active={optionMenuActive} />
            </div>
            <CounterMenuLayerOptionsMenu layer={layer} menuOpened={menuOpened} canGoActive={canGoActive} />
        </div>
    )
}
export default CounterMenuLayerOption