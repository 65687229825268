import React, { useContext, useEffect, useState } from 'react'
import { StoreContext } from "../../context/StoreContext"
import DrawLayer from "../DrawLayer/DrawLayer"
import Utility from "../../objects/Utility"
import './Counter.scss'
const Counter = () => {
    const { state, actions } = useContext(StoreContext)
    const [mxy, setMxy] = useState({ x: 0, y: 0 })
    const [coordinatesInContainer, setCoordinatesInContainer] = useState(false)
    const [timer, setTimer] = useState(null)
    const [counterShape, setCounterShape] = useState('square')
    const controller = new AbortController();
    const { signal } = controller;

    useEffect(() => {
        if (state.activeLayerValues) {
            // should be key 1_38, but lets programmatically get it, in case I change input keys for some reason.
            let baseCounterLayer = state.layers.find(ly => ly.layerName === 'base counter')
            if (baseCounterLayer) {
                let counterShapeInput = baseCounterLayer.inputs.find(inp => inp.named === 'counter shape')
                if (counterShapeInput) {
                    let valueForCounterShape = state.activeLayerValues[baseCounterLayer.layerKey + '_' + counterShapeInput.inputKey]
                    valueForCounterShape = parseInt(valueForCounterShape)
                    if (valueForCounterShape > -1) {
                        let _counterShape = counterShape
                        switch (valueForCounterShape) {
                            case 0: _counterShape = 'square'
                                break
                            case 1: _counterShape = 'circle'
                                break
                            case 2: _counterShape = 'rectangle'
                                break
                            default: break
                        }
                        setCounterShape(_counterShape)
                    }
                }
            }
        }
    }, [state.activeLayerValues]) // eslint-disable-line react-hooks/exhaustive-deps

    const activeLayerKeys = () => {
        let activeLayerKeys = Utility.activeLayerKeys(state.activeLayerValues)
        let activeLayers = []
        activeLayerKeys.forEach(layerKey => {
            let layer = state.layers.find(ly => ly.layerKey === layerKey)
            if (layer) {
                activeLayers.push(layer)
            }
        })
        return activeLayers.sort((a, b) => a.layerOrder - b.layerOrder)
    }

    useEffect(() => {
        if (state.counterClear) {
            let layerKeys = Utility.extractLayerKeys(state.activeLayerValues)
            layerKeys = layerKeys.map(layerKey => parseInt(layerKey))
            layerKeys.forEach(layerKey => {
                if (layerKey > 1) {
                    actions.activeLayerValuesRemove(layerKey)
                    let layer = state.layers.find(ly => ly.layerKey === layerKey)
                    if (layer) {
                        let updatedLayer = { ...layer, layerActive: 0 }
                        const updateAsync = async () => {
                            await Utility.dexieRemoveLayersFromActive(state.dexie, layer.layerKey)
                            await Utility.updateDexieStoreSingleItem(state.dexie, 'layers', updatedLayer, 'layerKey')
                            actions.layerUpdate(updatedLayer)
                            actions.activeLayerValuesRemove(layer.layerKey)
                        }
                        updateAsync()
                    }
                }
            })
        }
        actions.counterClear(false)


    }, [state.counterClear]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const update = (e) => {
            let ele = document.getElementById('counter')
            if (ele) {
                let rect = ele.getBoundingClientRect();
                if (e.x >= rect.left && e.x < rect.right &&
                    e.y >= rect.top && e.y < rect.bottom) {
                    setCoordinatesInContainer(true)
                    let x = e.x - rect.left; //x position within the element.
                    let y = e.y - rect.top;  //y position within the element.

                    let counterDrawWidth = ele.offsetWidth;
                    let mul = 240 / counterDrawWidth
                    x *= mul; // need to modify it cause pixels for the mouse are not a direct match to pixels on the svg
                    y *= mul;
                    x -= 120
                    y -= 120
                    x = Math.round(x)
                    y = Math.round(y)

                    if (x !== mxy.x || y !== mxy.y) {
                        setMxy({ x, y })
                    }
                }
                else {
                    setCoordinatesInContainer(false)
                }
            }
        }
        window.addEventListener('mousemove', update, { signal })
        window.addEventListener('touchmove', update, { signal })
        return () => {
            controller.abort();
            if (timer) {
                clearTimeout(timer)
            }
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // for showing some kind of visual effect to indicate counter is being cloned to the sheet.
        if (state.cloneCounterToSlot) {
            let svgElements = document.getElementById('counter').getElementsByTagName('svg') //[0];
            let topSvgElement = svgElements[0]
            var rectElement = document.createElementNS("http://www.w3.org/2000/svg", 'rect'); //Create a path in SVG's namespace
            rectElement.setAttribute("x", "-120");
            rectElement.setAttribute("y", "-120");
            rectElement.setAttribute("width", "240");
            rectElement.setAttribute("height", "240");
            rectElement.style.stroke = "#0df"; //Set stroke colour
            rectElement.style.strokeWidth = "3px"; //Set stroke width
            rectElement.style.fill = '#aff'
            rectElement.style.opacity = 1
            rectElement.id = 'rectAnimate'
            topSvgElement.appendChild(rectElement);

            let reduceOpacity = val => {
                let _timer = setTimeout(() => {
                    rectElement.style.opacity = val
                    val -= 0.1
                    if (val > 0) {
                        reduceOpacity(val)
                    }
                    else {
                        rectElement.remove()
                        rectElement = null
                        setTimer(null)
                    }
                }, 20)
                setTimer(_timer)
            }
            reduceOpacity(1)
            setTimeout(() => {
                if (rectElement) {
                    rectElement.remove()
                    setTimer(null)
                }
            }, 110)


        }

    }, [state.cloneCounterToSlot])


    return (
        <div className="counter" id="counter">
            {
                state.svgs.length > 0 ?
                    activeLayerKeys().map((layer) => {
                        return <div id={'layerKey' + layer.layerKey} className="draw-layer-container" key={layer.layerKey}><DrawLayer layer={layer} /></div>
                    })
                    : ''
            }
            <div className={`out-of-bounds-indicator ${counterShape}`} />
            <div className={coordinatesInContainer ? 'xy-display' : 'display-none'}>
                <div>
                    <span>x:</span>
                    <span>{mxy.x}</span>
                </div>
                <div>
                    <span>y:</span>
                    <span>{mxy.y}</span>
                </div>
            </div>
        </div>
    );
}
export default Counter
