import React, { useContext, useEffect, useState } from 'react'
import { StoreContext } from "../../context/StoreContext"
import './SheetCounterPopper.scss'

const SheetCounterPopper = ({ clickedSlot, hide, editSlotNumber, deleteSlotNumber }) => {
    const { state } = useContext(StoreContext)
    const [showPopper, setShowPopper] = useState(false)
    const [slotNumberActive, setSlotNumberActive] = useState(-1)
    const [position, setPosition] = useState({ x: -1, y: -1 })

    useEffect(() => {
        if (clickedSlot !== null) {
            let clickedSlotNumber = clickedSlot.number
            if( clickedSlotNumber === slotNumberActive ) {
                // clicked on same counter again
                setShowPopper(false)
                setSlotNumberActive(-1)
            }
            else {
                positionPopper(clickedSlot)
            }
        }

    }, [clickedSlot]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if( hide ) {
            setShowPopper(false)
            setSlotNumberActive(-1)
        }
    }, [hide])

    const positionPopper = (clickedSlot) => {
        let {x, y} = clickedSlot.xy
        let widthCounter = parseInt(clickedSlot.pixelsWidth) + (parseInt(clickedSlot.pixelsWidth * 0.05))
        x += widthCounter
        y -= 10
        setPosition({ x, y })
        setSlotNumberActive(clickedSlot.number)
        setShowPopper(true)
    }

    useEffect(() => {
        let elem = state.mouseClick.target
        let ignore = false
        for (; elem && elem !== document; elem = elem.parentNode) {
            if (elem.id && elem.id.includes('sheet_number_')) {
                if( elem.id === 'sheet_number_' + slotNumberActive ) {
                    ignore = true
                    break
                }
            }
            if (elem.id && elem.id.includes('popper')) {
                ignore = true
                break
            }
        }
        // any click anywhere, other than on an active slot or the popper itself, should be a signal to hide the popper.
        if( ignore === false ) {
            setShowPopper(false)
            setSlotNumberActive(-1)
        }

    }, [state.mouseClick]) // eslint-disable-line react-hooks/exhaustive-deps

    const callbackControl = (type) => {
        if( type === 'edit' && slotNumberActive > -1 ) {
            editSlotNumber(slotNumberActive)
        }
        if( type === 'delete' && slotNumberActive > -1 ) {
            deleteSlotNumber(slotNumberActive)
        }
        setShowPopper(false)
        setSlotNumberActive(-1)
    }


    if (showPopper) {
        return (
            <div id="popper" className="sheet-counter-popper" style={{ left: position.x, top: position.y }}>
                <div className="sheet-counter-buttons">
                    <button className="action-button yellowish" onClick={() => callbackControl('edit')}>edit</button>
                    <button className="action-button reddish" onClick={() => callbackControl('delete')}>delete</button>
                </div>
            </div>
        )
    }
    else {
        return null
    }


}
export default SheetCounterPopper