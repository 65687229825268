import React, { useContext, useState, useEffect, useRef } from 'react'
import Snap from 'snapsvg-cjs'
import { StoreContext } from "../../context/StoreContext"
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import Utility from "../../objects/Utility"
import './SheetOperations.scss'

const SheetOperations = ({ guideTypeChange, loadedGuideType }) => {
    const { state, actions } = useContext(StoreContext)
    const [saveSheetMethod, setSaveSheetMethod] = useState('app')
    const [sheetName, setSheetName] = useState('')
    const [sheetSavedMessage, setSheetSavedMessage] = useState(null)
   // const [saveSheetButtonActive, setSaveSheetButtonActive] = useState(false) someone has having problems not being able to save sheets.
    const [savedSheets, setSavedSheets] = useState([])
    const [sheetOperationsOpen, setSheetOperationsOpen] = useState(true)
    const [guideType, setGuideType] = useState('none')
    const [feedbackOnFocus, setFeedbackOnFocus] = useState('')
    const [feedback, setFeedback] = useState('')
    const [slotsOccupiedCount, setSlotsOccupiedCount] = useState(0)
    const [exportPaper, setExportPaper] = useState(null)
    const [printableAreaWidth, setPrintableAreaWidth] = useState(203.2)
    const [printableAreaHeight, setPrintableAreaHeight] = useState(260.35)
    //const [csvLoadSlots, setCsvLoadSlots] = useState([])
    // const [renderCounterToSlotNumber, setRenderCounterToSlotNumber] = useState(0)
    // const [savedActiveLayerValues, setSavedActiveLayerValues] = useState(null)
    const printableAreaWidthRef = useRef(null)
    const printableAreaHeightRef = useRef(null)
    const [inputWarningArray, setInputWarningArray] = useState([])


    const [countAlvUpdates, _setCountAlvUpdates] = useState(-1)
    const countAlvUpdatesRef = useRef(countAlvUpdates)
    const setCountAlvUpdates = data => {
        countAlvUpdatesRef.current = data
        _setCountAlvUpdates(data)
    }

    // const inputTypes = [
    //     { typeKey: 1, typeClass: 'string' },
    //     { typeKey: 2, typeClass: 'numeric' },
    //     { typeKey: 3, typeClass: 'numeric' },
    //     { typeKey: 4, typeClass: 'numeric' },
    //     { typeKey: 5, typeClass: 'none' },
    //     { typeKey: 6, typeClass: 'array' },
    //     { typeKey: 7, typeClass: 'string' },
    //     { typeKey: 8, typeClass: 'array' },
    //     { typeKey: 9, typeClass: 'string' },
    //     { typeKey: 10, typeClass: 'string' },
    //     { typeKey: 11, typeClass: 'string' },
    //     { typeKey: 12, typeClass: 'string' },
    //     { typeKey: 13, typeClass: 'numeric' },
    //     { typeKey: 14, typeClass: 'numeric' },
    //     { typeKey: 15, typeClass: 'json' },
    //     { typeKey: 16, typeClass: 'array' }]

    useEffect(() => {
        setCountAlvUpdates(-1)
    }, [])

    // const renderCounterToSlot = slotNumber => {
    //     let packagedCounterState = Utility.packageCounterState(state)
    //     packagedCounterState.slotNumber = slotNumber
    //     actions.cloneCounterToSlot(packagedCounterState)
    // }

    const setWarningOnInput = (inputRef, trueOrFalse) => {
        let _inputWarningArray = [...inputWarningArray]
        if (trueOrFalse === true) {
            if (!inputWarningArray.includes(inputRef)) {
                _inputWarningArray.push(inputRef)
            }
        }
        else {
            _inputWarningArray = _inputWarningArray.filter(wa => wa !== inputRef)
        }
        setInputWarningArray(_inputWarningArray)
    }

    const inputWarning = (refHandle) => {
        if (inputWarningArray.includes(refHandle)) {
            return 'warning'
        }
        else {
            return ''
        }
    }

    const isValidNumeric = (value, low, high) => {
        if (Utility.isNumeric(value)) {
            if (Number(value) < low || Number(value) > high) {
                return false
            }
        }
        else {
            return false
        }
        return true
    }


    useEffect(() => {
        if (state.focus) {
            if (feedbackOnFocus && feedback === '') {
                setFeedback(feedbackOnFocus)
                setFeedbackOnFocus('')
                setTimeout(() => setFeedback(''), 1700)
            }
            actions.focus(null)
        }
    }, [state.focus]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (Utility.emptyCheck(state.savedSheets) === false) {
            let values = Object.values(state.savedSheets)
            setSavedSheets(values)
        }
    }, [state.savedSheets]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (Utility.emptyCheck(state.savedSheets) === false) {
            let values = Object.values(state.savedSheets)
            setSavedSheets(values)
        }
        let ls_guideType = loadedGuideType
        if (ls_guideType === '') {
            ls_guideType = state.sheetSettings.guideType
            if (ls_guideType === '') {
                ls_guideType = 'none'
            }
        }
        if (ls_guideType) {
            setTimeout(() => {
                setGuideType(ls_guideType) // the slots drawing process takes a bit of time.
            }, 1000)
        }
        let printableArea = state.sheetSettings.printableArea
        setPrintableAreaWidth(printableArea[0])
        setPrintableAreaHeight(printableArea[1])
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // if (state.slots.length > 0) {
        //     let occupiedSlotsCount = state.slots.reduce((acc, cur) => cur.counterState !== null ? ++acc : acc, 0)
        //    // setSaveSheetButtonActive(occupiedSlotsCount > 0 ? true : false)
        // }
        countSlotsOccupied()
    }, [state.slots]) // eslint-disable-line react-hooks/exhaustive-deps

    const countSlotsOccupied = () => {
        let numOccupiedCount = state.slots.filter(slot => slot.counterState !== null).length
        setSlotsOccupiedCount(numOccupiedCount)
        return numOccupiedCount
    }

    const changeSaveSheetMethod = evt => {
        setSaveSheetMethod(evt.target.value)
    }

    const onChangeSheetName = evt => {
        let sheetName = evt.target.value
        setSheetName(sheetName)
    }

    const saveSheetInApp = async () => {
        // we need to save both state.slots and state.sheetSettings
        let useSheetName = sheetName
        if (sheetName.length < 1) {
            useSheetName = 'untitled_' + '_' + Utility.randomString(4) // eslint-disable-line no-useless-concat
        }

        // create a hash to uniquely identify this saved sheet (user may change name)
        let hash = Utility.cyrb53(useSheetName, 'snapcounter_sheet')

        let svg = await combineSvgs(false)
        svg = svg.replace('export_temp', useSheetName.replaceAll(' ', '_').replaceAll('"', ''))
        svg = svg.replace('class="export-temp" ', '')
        let compressedSvg = Utility.compressString(svg)

        // only save slots with counterState data
        let occupiedSlots = state.slots.filter(sl => sl.counterState !== null)
        occupiedSlots = occupiedSlots.map(oc => {
            let duped = { ...oc }
            return duped
        })

        let saveSheetObj = {}
        saveSheetObj[hash] = {
            hash: hash,
            name: useSheetName,
            slots: occupiedSlots, //state.slots,
            sheetSettings: { ...state.sheetSettings },
            compressedSvg: compressedSvg,
            svg,
            dateSaved: Utility.currentDate()
        }
        actions.savedSheets(saveSheetObj)

        if (state.dexie) {
            state.dexie.savedSheets.put(saveSheetObj[hash])
        }

        setFeedback('saved ' + useSheetName + ' in app.')
        setTimeout(() => setFeedback(''), 1000)
    }

    // const saveSheetInCsv = () => {
    //     console.log('saveSheetInCsv')
    //     let occupiedSlots = state.slots.filter(sl => sl.counterState !== null)
    //     console.log('occupiedSlots:', occupiedSlots)
    //     let countersColumnsRows = state.sheetSettings.countersColumnsRows
    //     let counterMargins = state.sheetSettings.counterMargins
    //     let counterSize = state.sheetSettings.counterSize
    //     let guideType = state.sheetSettings.guideType
    //     let pageMargins = state.sheetSettings.pageMargins
    //     let printableArea = state.sheetSettings.printableArea
    //     let useCounterCustomSize = state.sheetSettings.useCounterCustomSize
    //     let text = '"countersColumnsRows: ' + countersColumnsRows + '",' +
    //         '"counterMargins: ' + counterMargins + '",' +
    //         '"counterSize: ' + counterSize + '",' +
    //         '"guideType: ' + guideType + '",' +
    //         '"pageMargins: ' + pageMargins + '",' +
    //         '"printableArea: ' + printableArea + '",' +
    //         '"useCounterCustomSize:  ' + useCounterCustomSize + '",' + "\r\n" // eslint-disable-line no-useless-concat
    //     console.log('sheetSettings read in:', text)
    //     console.log('')
    //     occupiedSlots.forEach(os => {
    //         let slotNumber = os.number + 1 // change from zero-based to human-based
    //         console.log('slotNumber:', slotNumber)
    //         let slotPosition = 'col:' + os.position.col + ' row:' + os.position.row
    //         console.log('slotPosition:', slotPosition)
    //         let counterActiveLayerValues = os.counterState.activeLayerValues
    //         console.log('.counterState.activeLayerValues:', os.counterState.activeLayerValues)


    //         let keys = Object.keys(counterActiveLayerValues)
    //         let activeLayerKeys = []
    //         keys.forEach(key => {
    //             let activeLayerKey = parseInt(key.split('_')[0])
    //             if (activeLayerKeys.includes(activeLayerKey) === false) {
    //                 activeLayerKeys.push(parseInt(activeLayerKey))
    //             }
    //         })
    //         let layers = os.counterState.layers
    //         layers.forEach(ly => activeLayerKeys.includes(parseInt(ly.layerKey)) ? ly.layerActive = 1 : ly.layerActive = 0)
    //         layers = layers.filter(ly => ly.layerActive === 1)


    //         text += '"slot #' + slotNumber + ' ' + slotPosition
    //             + '",Layer Key,Layer Name,Input Key,Input Name,Input Type Key, value' + "\r\n" // eslint-disable-line no-useless-concat
    //         console.log('prepare header for slot ************************************************')
    //         console.log('"slot #' + slotNumber + ' ' + slotPosition + '",Layer Key,Layer Name,Input Key,Input Name,Input Type Key, value')
    //         console.log('end prepare ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^')
    //         layers.forEach(uly => {
    //             console.log('active layer ly.layerKey:', uly.layerKey)
    //             text += "," + uly.layerKey + "," + uly.layerName
    //             console.log('adding to text: ' + uly.layerKey + "," + uly.layerName)
    //             uly.inputs.forEach((inp, index) => {
    //                 // let inputKey = inp.inputKey
    //                 // let inputMessage = inp.message
    //                 // let inputNamed = inp.named
    //                 let inputTypeKey = inp.typeKey
    //                 if (!inp.named.includes('spacer') && !inp.named.includes('separator') && !inp.named.includes('label')) {
    //                     let value = counterActiveLayerValues[uly.layerKey + '_' + inp.inputKey]
    //                     if (value !== undefined) {
    //                         if (index === 0) {
    //                             text += ','
    //                         }
    //                         else {
    //                             text += ',,,'
    //                         }
    //                         if (inputTypeKey === 15) { // color object
    //                             let stringRep = value.replaceAll('"', 'D#Q')
    //                             stringRep = stringRep.replaceAll(',', 'C#M')
    //                             text += inp.inputKey + ',' +
    //                                 inp.named + ',' +
    //                                 inp.typeKey + ',"' +
    //                                 stringRep + '"' + "\r\n" // eslint-disable-line no-useless-concat
    //                             console.log('appending color object')
    //                         }
    //                         else {
    //                             if (parseInt(inputTypeKey) !== 3 && parseInt(inputTypeKey) !== 5 && parseInt(inputTypeKey) !== 13) { // dont include spacers
    //                                 text += inp.inputKey + ',' +
    //                                     inp.named + ',' +
    //                                     inp.typeKey + ',"' +
    //                                     value + '"' + "\r\n" // eslint-disable-line no-useless-concat
    //                                 console.log('appending input info')
    //                             }
    //                             // text += "1,2,3,Input Key, Input Name, Input Value\r\n"
    //                         }
    //                     }
    //                 }

    //             })
    //         })
    //     })
    //     var zip = new JSZip();
    //     zip.file(sheetName + ".csv", text);
    //     zip.generateAsync({ type: "blob", compression: "DEFLATE" })
    //         .then(function (content) {
    //             // see FileSaver.js
    //             saveAs(content, sheetName + '.scz');
    //         });

    //     if (feedbackOnFocus === '') {
    //         setFeedbackOnFocus('saved ' + sheetName + ' to compressed csv file.')
    //     }

    // }

    const saveSheetInFile = () => {
        let occupiedSlots = state.slots.filter(sl => sl.counterState !== null)
        let useSheetName = sheetName
        if (sheetName.length < 1) {
            useSheetName = 'untitled_' + '_' + Utility.randomString(4) // eslint-disable-line no-useless-concat
        }
        let fileSheetObj = {
            name: useSheetName,
            app: 'snapCounter',
            type: 'sheet',
            dateSaved: Utility.currentDate(),
            slots: occupiedSlots,
            sheetSettings: state.sheetSettings,
        }
        var zip = new JSZip();
        zip.file(useSheetName + ".json", JSON.stringify(fileSheetObj));
        zip.generateAsync({ type: "blob", compression: "DEFLATE" })
            .then(function (content) {
                // see FileSaver.js
                saveAs(content, useSheetName + '.scz');
            });

        if (feedbackOnFocus === '') {
            setFeedbackOnFocus('saved ' + useSheetName + ' to file.')
        }
    }

    const exportSheetToSvg = async () => {
        // step through each drawn counters to get the svgs from them.
        // then put them into groups, and pile them into one svg.
        // then save the file as <name>.svg

        let svgSource = await combineSvgs()
        let useSheetName = sheetName
        if (sheetName.length < 1) {
            useSheetName = 'untitled_' + '_' + Utility.randomString(4) // eslint-disable-line no-useless-concat
        }
        // not sure why the main envelope of the svg has matrix 
        let blob = new Blob([svgSource], { type: 'image/svg+xml' });
        saveAs(blob, useSheetName + '.svg');
        if (feedbackOnFocus === '') {
            setFeedbackOnFocus('exporting ' + useSheetName + ' to svg file.')
        }
    }

    useEffect(() => {
        if (state.keyUp) {
            if (state.keyUp === 48) {
                detectFonts()
            }
        }
    }, [state.keyUp]) // eslint-disable-line react-hooks/exhaustive-deps

    const detectFonts = () => {
        let layerKeys = []
        let detectedFonts = []
        state.slots.forEach(sl => {
            if (sl.layers) {
                let keys = Object.keys(sl.layers)
                keys.forEach(key => {
                    let layerKey = key.split('_')[0]
                    if (layerKeys.includes(layerKey) === false) {
                        layerKeys.push(parseInt(layerKey))
                    }
                })
            }
        })

        let fontInputKeys = []
        state.layers.forEach(ly => {
            if (layerKeys.includes(parseInt(ly.layerKey))) {
                if (ly.layerName === 'Google material symbols') {
                    let fontFamily = 'Material Icons'
                    if (detectedFonts.includes(fontFamily) === false) {
                        detectedFonts.push(fontFamily)
                    }
                }
                ly.inputs.forEach(ip => {
                    if (ip.named === 'font') {
                        if (fontInputKeys.includes(ip.inputKey) === false) {
                            fontInputKeys.push(ip.inputKey)
                        }
                    }
                })
            }
        })
        state.slots.forEach(sl => {
            if (sl.layers) {
                for (const [key, value] of Object.entries(sl.layers)) {
                    let inputKey = key.split('_')[1]
                    if (fontInputKeys.includes(parseInt(inputKey))) {
                        if (detectedFonts.includes(value) === false) {
                            detectedFonts.push(value)
                        }
                    }
                }
            }
        })
        return detectedFonts
    }

    const combineSvgs = async () => {
        let wholeSheetSvgElement = document.getElementById("sheetDisplay");
        var serializer = new XMLSerializer();
        var svgSource = serializer.serializeToString(wholeSheetSvgElement);

        let _paper = null
        if (exportPaper) {
            _paper = exportPaper
        }
        else {
            _paper = Snap("#export_temp")
            setExportPaper(_paper)
        }
        let parsed = Snap.parse(svgSource)
        _paper.append(parsed)
        let deleteElements = _paper.selectAll(`[data-type="holder"]`)
        deleteElements.forEach(deleteMe => {
            if (deleteMe) {
                deleteMe.remove()
            }
        })
        let holderTexts = _paper.selectAll(`[data-type="holderText"]`);
        holderTexts.forEach(holderText => {
            if (holderText) {
                holderText.remove()
            }
        })
        let guideTypes = ['guideCorner', 'guideCornerEdge', 'guideLine']
        let guideName = 'none'
        switch (guideType) {
            case 'edges': guideName = 'guideCornerEdge'
                break
            case 'corners': guideName = 'guideCorner'
                break
            case 'lines': guideName = 'guideLine'
                break
            default: guideName = 'none'
        }
        guideTypes = guideTypes.filter(gt => gt !== guideName)
        guideTypes.forEach(gt => {
            let targettedGuideTypes = _paper.selectAll(`[data-type="${gt}"]`);
            targettedGuideTypes.forEach(tgt => {
                if (tgt) {
                    tgt.remove()
                }
            })
        })

        let fontFamilies = selectAllFonts(_paper)
        if (fontFamilies.length > 0) {
            let styleString = '<defs><style type="text/css">'
            for (let i = 0; i < fontFamilies.length; i++) {
                let fontFamily = fontFamilies[i]
                let fontData = await Utility.getDexieStoreSingleItem(state.dexie, 'fonts', fontFamily, 'fontFamily')
                if (fontData) {
                    styleString += "@font-face { font-family: '" + fontFamily + "'; "
                    styleString += 'font-weight: ' + fontData.fontWeight + '; '
                    if (fontData.fontSrc.includes("format('woff2')") === false) {
                        styleString += 'src: ' + fontData.fontSrc + " format('woff2');"
                    }
                    else {
                        styleString += 'src: ' + fontData.fontSrc + ';'
                    }
                    styleString += '}'
                    styleString = styleString.replaceAll(';;', ';')
                }
            }
            styleString += '</style></defs>'

            let fontG = _paper.group()
            let snapFont = Snap.parse(styleString)
            fontG.add(snapFont)
            fontG.toDefs()
        }

        // bump up the resolution so it gets to about 300ppi, effectively
        let useSheetName = sheetName
        if (sheetName.length < 1) {
            useSheetName = 'untitled_' + '_' + Utility.randomString(4) // eslint-disable-line no-useless-concat
        }
        _paper.attr({ width: 2400, height: 3106, id: useSheetName.replaceAll(' ', '_').trim() })
        let sheetDisplayRef = _paper.select('#sheetDisplay')
        sheetDisplayRef.attr({ width: 2400, height: 3106 })

        svgSource = _paper.toString()
        svgSource = svgSource.replace('class="export-temp"', '')
        _paper.clear()
        _paper.remove()

        return svgSource
    }

    const selectAllFonts = (snapPaper) => {

        let fonts = []
        Array.from(snapPaper.selectAll("text")).forEach(el => {
            if (el.node && el.node.attributes && el.node.attributes.style && el.node.attributes.style.textContent) {
                let str = el.node.attributes.style.textContent
                let arr = str.split(';')
                arr.forEach(item => {
                    if (item.startsWith('font-family')) {
                        let fsplit = item.split(':')
                        if (fsplit.length === 2) {
                            let fontFamily = fsplit[1]
                            if (fontFamily) {
                                fontFamily = fontFamily.trim()
                                fontFamily = fontFamily.replaceAll('"', '')
                                if (fonts.includes(fontFamily) === false) {
                                    fonts.push(fontFamily)
                                }
                            }
                        }
                    }
                })
            }
        })

        return fonts
    }

    const exportSheetToPng = async () => {

        const getImageDataURL = (image, type = 'png') => {
            if (type === 'jpg') type = 'jpeg';
            // const { width, height } = image;
            let width = 2400
            let height = 3106
            const canvas = document.createElement('canvas');

            const context = canvas.getContext('2d');
            canvas.width = width;
            canvas.height = height;

            if (type === 'jpeg') {
                context.fillStyle = 'white';
                context.fillRect(0, 0, width, height);
            }

            context.drawImage(image, 0, 0, width, height);

            return canvas.toDataURL(`image/${type}`);
        }

        const convertSvg = (svgString, type = 'png') => new Promise((res, rej) => {
            const image = new Image();
            image.onload = () => res(getImageDataURL(image, type));
            image.src = `data:image/svg+xml,${encodeURIComponent(svgString)}`;
        });

        let source = await combineSvgs()
        let useSheetName = sheetName
        if (sheetName.length < 1) {
            useSheetName = 'untitled_' + '_' + Utility.randomString(4) // eslint-disable-line no-useless-concat
        }
        convertSvg(source, 'png').then(dataURL => {
            saveAs(dataURL, useSheetName + '.png');
        })

        if (feedbackOnFocus === '') {
            setFeedbackOnFocus('exporting ' + useSheetName + ' to png file.')
        }


    }

    const sheetSave = () => {

        switch (saveSheetMethod) {
            case 'app':
                saveSheetInApp()
                break
            case 'file':
                saveSheetInFile()
                break
            // case 'csv':
            //     saveSheetInCsv()
            //     break
            case 'svg':
                exportSheetToSvg()
                break
            case 'png':
                exportSheetToPng()
                break
            default: console.warn('dont know what we are saving as.')
        }

    }

    // data format: {
    // hash: ewfewfew,
    // dateSaved: "2022-10-24",
    // name: "some sheet",
    // sheetSettings: {...},
    // slots: [{slotThatHasCounterData...}, {slotTheHasCounterData...}],
    // }

    // load sheet from file
    const loadSheet = (data) => {
        actions.userLoadSlotsSheet(data)
    }

    // change sheet coming from load from app select dropdown.
    const changeSavedSheet = (evt) => {
        let hash = evt.target.value
        let sheetObj = state.savedSheets[hash]
        actions.userLoadSlotsSheet(sheetObj)
    }

    const onClickChangeSavedSheet = hash => {
        let sheetObj = state.savedSheets[hash]
        actions.userLoadSlotsSheet(sheetObj)
    }

    const changeGuideType = (evt) => {
        let type = evt.target.value
        setGuideType(type)
        //localStorage.setItem('guideType', type)
    }

    const changePrintableAreaWidth = (evt) => {
        let value = evt.target.value
        setPrintableAreaWidth(value)

        setWarningOnInput(printableAreaWidthRef.current, false)
        if (isValidNumeric(evt.target.value, 150, 250) === false) {
            setWarningOnInput(printableAreaWidthRef.current, true)
        }
    }


    const changePrintableAreaHeight = (evt) => {
        let value = evt.target.value
        setPrintableAreaHeight(value)

        setWarningOnInput(printableAreaHeightRef.current, false)
        if (isValidNumeric(evt.target.value, 200, 300) === false) {
            setWarningOnInput(printableAreaHeightRef.current, true)
        }
    }

    const printableAreaDefaults = () => {
        let sheetSettings = JSON.parse(JSON.stringify(state.sheetSettings))
        sheetSettings.printableArea = [203.2, 260.35]
        actions.sheetSettings(sheetSettings)
        state.dexie.table('sheetSettings').orderBy('index').first().then(first => {
            if (JSON.stringify(first) !== JSON.stringify(sheetSettings)) {
                Utility.updateDexieStore(state.dexie, 'sheetSettings', sheetSettings, 'index')
            }
        })
        setPrintableAreaWidth(203.2)
        setPrintableAreaHeight(260.35)
    }

    useEffect(() => {
        if (isValidNumeric(printableAreaWidth, 150, 250) === true) {
            let sheetSettings = JSON.parse(JSON.stringify(state.sheetSettings))
            sheetSettings.printableArea = [Number(printableAreaWidth), sheetSettings.printableArea[1]]
            actions.sheetSettings(sheetSettings)
            state.dexie.table('sheetSettings').orderBy('index').first().then(first => {
                if (JSON.stringify(first) !== JSON.stringify(sheetSettings)) {
                    Utility.updateDexieStore(state.dexie, 'sheetSettings', sheetSettings, 'index')
                }
            })
        }
    }, [printableAreaWidth]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isValidNumeric(printableAreaHeight, 150, 250) === true) {
            let sheetSettings = JSON.parse(JSON.stringify(state.sheetSettings))
            sheetSettings.printableArea = [sheetSettings.printableArea[0], Number(printableAreaHeight),]
            actions.sheetSettings(sheetSettings)
            state.dexie.table('sheetSettings').orderBy('index').first().then(first => {
                if (JSON.stringify(first) !== JSON.stringify(sheetSettings)) {
                    Utility.updateDexieStore(state.dexie, 'sheetSettings', sheetSettings, 'index')
                }
            })
        }
    }, [printableAreaHeight]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (guideType) {
            guideTypeChange(guideType)
            let sheetSettings = JSON.parse(JSON.stringify(state.sheetSettings))
            sheetSettings.guideType = guideType
            actions.sheetSettings(sheetSettings)
            state.dexie.table('sheetSettings').orderBy('index').first().then(first => {
                if (JSON.stringify(first) !== JSON.stringify(sheetSettings)) {
                    Utility.updateDexieStore(state.dexie, 'sheetSettings', sheetSettings, 'index')
                }
            })
        }
    }, [guideType]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (loadedGuideType) {
            setGuideType(loadedGuideType)
        }
    }, [loadedGuideType])

    // const extractArrayValues = (arr, tag) => {
    //     if (!tag || !arr || !Array.isArray(arr)) {
    //         return null
    //     }
    //     let v = arr.find(sp => sp.includes(tag))
    //     if (!v) {
    //         return null
    //     }
    //     v = v.replace(tag, '')
    //     v = v.replace(':', '')
    //     v = v.replaceAll('"', '')
    //     v = v.trim()
    //     v = v.replace(/\s/g, '')
    //     v = v.split(',')
    //     v = v.map(cm => Utility.roundFloat(cm, 1))
    //     if (!v || !Array.isArray(v) || v.length === 0) {
    //         return null
    //     }
    //     return v
    // }

    // const extractFloatValue = (arr, tag) => {
    //     if (!tag || !arr || !Array.isArray(arr)) {
    //         return null
    //     }
    //     let v = arr.find(sp => sp.includes(tag))
    //     if (!v) {
    //         return null
    //     }
    //     v = v.replace(tag, '')
    //     v = v.replace(':', '')
    //     v = v.replaceAll('"', '')
    //     v = v.trim()
    //     v = v.replace(/\s/g, '')
    //     v = Utility.roundFloat(v, 1)
    //     if (isNaN(v)) {
    //         return null
    //     }
    //     return v
    // }

    // const extractStringValue = (arr, tag) => {
    //     if (!tag || !arr || !Array.isArray(arr)) {
    //         return null
    //     }
    //     let v = arr.find(sp => sp.includes(tag))
    //     if (!v) {
    //         return null
    //     }
    //     v = v.replace(tag, '')
    //     v = v.replace(':', '')
    //     v = v.replaceAll('"', '')
    //     v = v.trim()
    //     v = v.replace(/\s/g, '')
    //     if (!v) {
    //         return null
    //     }
    //     return v
    // }

    // const splitCsv = (str) => {
    //     return str.split(',').reduce((accum, curr) => {
    //         if (accum.isConcatting) {
    //             accum.soFar[accum.soFar.length - 1] += ',' + curr
    //         } else {
    //             accum.soFar.push(curr)
    //         }
    //         if (curr.split('"').length % 2 === 0) {
    //             accum.isConcatting = !accum.isConcatting
    //         }
    //         return accum;
    //     }, { soFar: [], isConcatting: false }).soFar
    // }

    // loadcsv
    // const processCsvLoad = fileData => {
    //     let sheetSettings = {}
    //     let slots = []
    //     let layerKey = ''
    //     let layerName = ''
    //     let abortLoad = false
    //     fileData.split(/\r?\n/).forEach((line, index) => {
    //         if (index === 0) {
    //             var arr = splitCsv(line)

    //             let splitted = arr.map(str => str.replaceAll('\\"', ''))
    //             splitted = splitted.map(str => str.replaceAll('"', ''))




    //             let countersColumnsRows = extractArrayValues(splitted, 'countersColumnsRows:')
    //             if (countersColumnsRows !== null) {
    //                 if (countersColumnsRows.length !== 2 || Utility.arrayHasAllNumbers(countersColumnsRows) === false) {
    //                     actions.addErrorMessages(['countersColumnsRows value is invalid!'])
    //                     abortLoad = true
    //                 }
    //                 sheetSettings.countersColumnsRows = countersColumnsRows

    //                 let counterMargins = extractArrayValues(splitted, 'counterMargins:')
    //                 if (counterMargins !== null) {
    //                     if (counterMargins.length !== 4 || Utility.arrayHasAllNumbers(counterMargins) === false) {
    //                         actions.addErrorMessages(['counterMargins value is invalid!'])
    //                         abortLoad = true
    //                     }
    //                     sheetSettings.counterMargins = counterMargins

    //                     let counterSize = extractFloatValue(splitted, 'counterSize:')
    //                     if (counterSize !== null) {
    //                         sheetSettings.counterSize = counterSize

    //                         let guideType = extractStringValue(splitted, 'guideType')
    //                         if (guideType !== null) {
    //                             sheetSettings.guideType = guideType

    //                             let pageMargins = extractArrayValues(splitted, 'pageMargins:')
    //                             if (pageMargins !== null) {
    //                                 if (pageMargins.length !== 4 || Utility.arrayHasAllNumbers(pageMargins) === false) {
    //                                     actions.addErrorMessages(['pageMargins value is invalid!'])
    //                                     abortLoad = true
    //                                 }
    //                                 sheetSettings.pageMargins = pageMargins

    //                                 let printableArea = extractArrayValues(splitted, 'printableArea:')
    //                                 if (printableArea !== null) {
    //                                     if (printableArea.length !== 2 || Utility.arrayHasAllNumbers(printableArea) === false) {
    //                                         actions.addErrorMessages(['printableArea value is invalid!'])
    //                                         abortLoad = true
    //                                     }
    //                                     sheetSettings.printableArea = printableArea

    //                                     let useCounterCustomSize = extractStringValue(splitted, 'useCounterCustomSize')
    //                                     if (useCounterCustomSize !== null) {
    //                                         useCounterCustomSize = useCounterCustomSize === 'true' ? true : false
    //                                         sheetSettings.useCounterCustomSize = useCounterCustomSize
    //                                     }
    //                                 }
    //                             }
    //                         }
    //                     }
    //                 }
    //             }
    //         }
    //         else {
    //             if (line) {
    //                 line = line.replace('"slot #', 'slot #')
    //                 line = line.replace('\\"slot #', 'slot #') // im not sure how the lines are being interpreted when its imported.
    //                 if (line.startsWith('slot #')) {
    //                     let splitted = line.split('slot #')
    //                     let numSplitted = splitted[1].split(' ')
    //                     let slotNumber = numSplitted[0]
    //                     slots.push({ slotNumber, layers: {} })
    //                 }
    //                 else {
    //                     let inputKey = ''
    //                     let inputName = ''
    //                     let inputTypeKey = ''
    //                     let inputValue = ''
    //                     let arr = null
    //                     if (line.startsWith(',,,')) {
    //                         arr = csvSplit(line)
    //                         if (arr && Array.isArray(arr) && arr.length > 1) {
    //                             inputKey = arr[3]
    //                             inputName = arr[4]
    //                             inputTypeKey = arr[5]
    //                             inputValue = arr[6]
    //                             if (parseInt(inputTypeKey) !== 3 && parseInt(inputTypeKey) !== 5 && parseInt(inputTypeKey) !== 13) {
    //                                 slots[slots.length - 1].layers[layerKey].inputs.push({ inputKey, inputName, inputTypeKey, inputValue })
    //                             }
    //                         }
    //                     }
    //                     else {
    //                         arr = csvSplit(line)
    //                         if (arr && Array.isArray(arr) && arr.length > 5) {
    //                             layerKey = arr[1]
    //                             layerName = arr[2]
    //                             inputKey = arr[3]
    //                             inputName = arr[4]
    //                             inputTypeKey = arr[5]
    //                             inputValue = arr[6]
    //                             if (parseInt(inputTypeKey) !== 3 && parseInt(inputTypeKey) !== 5 && parseInt(inputTypeKey) !== 13) {
    //                                 slots[slots.length - 1].layers[layerKey] = {}
    //                                 slots[slots.length - 1].layers[layerKey].layerName = layerName
    //                                 slots[slots.length - 1].layers[layerKey].inputs = []
    //                                 slots[slots.length - 1].layers[layerKey].inputs.push({ inputKey, inputName, inputTypeKey, inputValue })
    //                             }
    //                         }
    //                     }
    //                 }
    //             }
    //         }
    //     });
    //     if (abortLoad === true) {
    //         actions.addErrorMessages(['csv file is invalid!'])
    //         return
    //     }

    //     // create activeLayerValues
    //     let inputNames = []
    //     slots.forEach(slot => {
    //         slot.slotNumber = parseInt(slot.slotNumber)
    //         let alv = {}
    //         for (const [key, value] of Object.entries(slot.layers)) {
    //             let layerKey = key
    //             let inputs = value.inputs
    //             inputs.forEach(inp => {
    //                 let inputKey = inp.inputKey
    //                 let inputName = inp.inputName
    //                 let inputTypeKey = parseInt(inp.inputTypeKey)
    //                 if (inputNames.includes(inputName) === false) {
    //                     inputNames.push(inputName)
    //                 }
    //                 let inputValue = inp.inputValue
    //                 let foundType = inputTypes.find(it => it.typeKey === inputTypeKey)
    //                 let typeClass = foundType.typeClass


    //                 switch (typeClass) {
    //                     case 'json':
    //                         inputValue = inputValue.replaceAll('D#Q', '"')
    //                         inputValue = inputValue.replaceAll('C#M', ',')
    //                         break
    //                     case 'string':
    //                         break
    //                     case 'numeric':
    //                         if (Utility.isNumeric(inputValue)) {
    //                             inputValue = Utility.roundFloat(inputValue, 2)
    //                         }
    //                         break
    //                     case 'array':
    //                         let arr = inputValue.split(',')

    //                         arr = arr.map(it => {
    //                             if (Utility.isNumeric(it)) {
    //                                 return Utility.roundFloat(it, 2)
    //                             }
    //                             return it
    //                         })
    //                         inputValue = arr
    //                         break
    //                     default: break
    //                 }
    //                 alv[layerKey + '_' + inputKey] = inputValue
    //             })
    //         }
    //         slot.activeLayerValues = alv
    //     })
    //     slots.forEach((slot, ind) => {
    //         slot.activeLayerValues['1_5'] = slot.slotNumber
    //         slot.processed = false
    //     })
    //     if (slots.length > 0) {
    //         actions.sheetSettings(sheetSettings)
    //         setCsvLoadSlots(slots)
    //     }
    //     else {
    //         actions.addErrorMessages(['there is a format error in the csv!'])
    //     }


    //     // // go through each slot, and activate its activeLayerValues, so we can grab the svg from the counter area.
    //     // for(let s=0; s<slots.length; s++) {
    //     //     sheetClear()
    //     //     actions.activeLayerValuesReset(slots[s].activeLayerValues)
    //     // }
    // }

    // useEffect(() => {
    //     if (csvLoadSlots.length > 0) {
    //         console.log('useEffect csvLoadSlots: ', csvLoadSlots)
    //         let processed = csvLoadSlots.filter(sl => sl.processed === true)
    //         if (processed.length === 0) {
    //             // initial start of csv import.
    //             sheetClear()
    //             setSavedActiveLayerValues(state.activeLayerValues)
    //             counterClear()
    //             actions.overlay(true)
    //         }
    //         if (processed.length === csvLoadSlots.length) {
    //             // csv import has been all processed.
    //             console.log('quit')
    //             setCsvLoadSlots([])
    //             setRenderCounterToSlotNumber(0)
    //             actions.overlay(false)
    //             //setRestoreCounterState(true)
    //             actions.restoreCounterState(true)
    //         }
    //         else {
    //             processNextCsvSlot()
    //         }
    //     }
    // }, [csvLoadSlots]) // eslint-disable-line react-hooks/exhaustive-deps

    // useEffect(() => {
    //     if (state.restoreCounterState) {
    //         console.log('*************************************************************************')
    //         console.log('restoreCounterState ******************************************************')
    //         console.log('*************************************************************************')
    //         restoreCounter()


    //         setTimeout(() => {
    //             console.log('after timeout, calling restoreCounter')
    //             restoreCounter()
    //         }, 3000)

    //         actions.restoreCounterState(false)
    //     }
    // }, [state.restoreCounterState]) // eslint-disable-line react-hooks/exhaustive-deps

    // const processNextCsvSlot = async () => {
    //     let slot = null
    //     for (let i = 0; i < csvLoadSlots.length; i++) {
    //         if (csvLoadSlots[i].processed === false) {
    //             slot = csvLoadSlots[i]
    //             break
    //         }
    //     }
    //     if (slot && slot.activeLayerValues) {
    //         actions.activeLayerValues(slot.activeLayerValues)
    //     }
    // }

    // useEffect(() => {
    //     if (state.activeLayerValues && processingCsvLoadSlots()) {
    //         let slotNumber = state.activeLayerValues['1_5']
    //         if (slotNumber) {
    //             setRenderCounterToSlotNumber(slotNumber)
    //         }
    //     }
    // }, [state.activeLayerValues]) // eslint-disable-line react-hooks/exhaustive-deps

    // useEffect(() => {
    //     if (renderCounterToSlotNumber) {
    //         renderCsvCounterToSlot(renderCounterToSlotNumber)
    //     }
    // }, [renderCounterToSlotNumber]) // eslint-disable-line react-hooks/exhaustive-deps

    // const processingCsvLoadSlots = () => {
    //     if (csvLoadSlots.length > 0) {
    //         let processing = csvLoadSlots.filter(sl => sl.processed === false)
    //         if (processing.length > 0) {
    //             return true
    //         }
    //     }
    //     return false
    // }

    // const renderCsvCounterToSlot = slotNumber => {
    //     if (slotNumber) {
    //         let packagedCounterState = Utility.packageCounterState(state)
    //         packagedCounterState.slotNumber = slotNumber
    //         console.log('actions.cloneCounterToSlot number:', slotNumber)
    //         actions.cloneCounterToSlot(packagedCounterState)
    //     }
    // }

    // useEffect(() => {
    //     let slots = [...csvLoadSlots]
    //     let clonedSlot = slots.find(sl => sl.slotNumber === renderCounterToSlotNumber)
    //     if (clonedSlot) {
    //         if (clonedSlot.processed === false) {
    //             clonedSlot.processed = true
    //             setCsvLoadSlots(slots)
    //         }
    //     }
    // }, [state.cloneCounterToSlot]) // eslint-disable-line react-hooks/exhaustive-deps

    // const restoreCounter = async () => {
    //     console.log('restoreCounter')
    //     if (savedActiveLayerValues) {
    //         console.log('savedActiveLayerValues:', savedActiveLayerValues)
    //         actions.activeLayerValuesReset(savedActiveLayerValues)
    //         setSavedActiveLayerValues(null)
    //     }

    //     let keys = Object.keys(savedActiveLayerValues)
    //     let activeLayerKeys = []
    //     keys.forEach(key => {
    //         let activeLayerKey = parseInt(key.split('_')[0])
    //         if (activeLayerKeys.includes(activeLayerKey) === false) {
    //             activeLayerKeys.push(parseInt(activeLayerKey))
    //         }
    //     })

    //     console.log('according to savedActiveLayerValues, these are the layes that should be active:', activeLayerKeys)
    //     let layers = [...state.layers]
    //     layers.forEach( ly => activeLayerKeys.includes( parseInt(ly.layerKey) ) ? ly.layerActive = 1 : ly.layerActive = 0 )
    //     console.log('setting layers to', layers)
    //     actions.layers(layers)
    // }

    // const numberSlotsOccupied = () => {
    //     let cnt = 0
    //     state.slots.forEach(sl => {
    //         if (sl.counterState !== null) {
    //             cnt++
    //         }
    //     })
    //     return cnt
    // }

    // const counterClear = async () => {
    //     let alv = { ...state.activeLayerValues }
    //     for (const [key] of Object.entries(state.activeLayerValues)) {
    //         if (!key.startsWith('1_')) {
    //             delete alv[key]
    //         }
    //     }
    //     actions.activeLayerValuesReset(alv)
    // }

    // const csvSplit = (line) => {
    //     let splitLine = [];

    //     var quotesplit = line.split('"');
    //     var lastindex = quotesplit.length - 1;
    //     // split evens removing outside quotes, push odds
    //     quotesplit.forEach((val, index) => {
    //         if (index % 2 === 0) {
    //             var firstchar = (index === 0) ? 0 : 1;
    //             var trimmed = (index === lastindex)
    //                 ? val.substring(firstchar)
    //                 : val.slice(firstchar, -1);
    //             trimmed.split(",").forEach(v => splitLine.push(v));
    //         } else {
    //             splitLine.push(val);
    //         }
    //     });
    //     return splitLine;
    // }

    const loadFromFileOnChange = evt => {
        let file = evt.target.files[0]

        // if (file.name.endsWith('.csv')) {
        //     const reader = new FileReader()
        //     reader.onload = async (file) => {
        //         const text = (file.target.result)
        //         // check to see if this seems to be a sheet csv
        //         if (text.includes('counterMargins') &&
        //             text.includes('pageMargins') &&
        //             text.includes('base counter') &&
        //             text.includes('slot #')) {
        //             processCsvLoad(text)
        //         }
        //         else {
        //             actions.addErrorMessages(['This file does not seem to be a snapcounter sheet csv file.'])
        //         }
        //     };
        //     reader.readAsText(file)
        //     return
        // }

        JSZip.loadAsync(file).then((zip) => {
            setTimeout(() => {
                document.getElementById("sheet_file").value = ""
            }, 3000)
            Object.keys(zip.files).forEach((filename) => {
                if (filename.endsWith('.csv')) {
                    // zip.files[filename].async('string').then(async (fileData) => {
                    //     // check to see if this seems to be a sheet csv
                    //     if (fileData.includes('counterMargins') &&
                    //         fileData.includes('pageMargins') &&
                    //         fileData.includes('base counter') &&
                    //         fileData.includes('slot #')) {
                    //         processCsvLoad(fileData)
                    //     }
                    //     else {
                    //         actions.addErrorMessages(['This file does not seem to be a snapcounter sheet csv file.'])
                    //     }
                    // })
                }
                else {
                    zip.files[filename].async('string').then(async (fileData) => {
                        let data = null
                        try {
                            data = JSON.parse(fileData)
                        }
                        catch (e) {
                            console.warn('error loading file')
                            actions.addErrorMessages(['error loading file'])
                            return
                        }
                        if (data.app === undefined || data.app !== 'snapCounter') {
                            actions.addErrorMessages(['this file does not appear to be a snapCounter file'])
                            return
                        }
                        if (data.type === undefined || data.type !== 'sheet') {
                            actions.addErrorMessages(['this file does not have sheet data'])
                            return
                        }
                        if (data.slots === undefined) {
                            actions.addErrorMessages(['there is no slots data to reconstruct a sheet.'])
                            return
                        }
                        if (data.sheetSettings === undefined) {
                            actions.addErrorMessages(['there is no sheetSettings data to reconstruct a sheet.'])
                            return
                        }
                        if (data.hasOwnProperty('slots') && data.hasOwnProperty('sheetSettings')) {
                            // we have data
                            loadSheet(data)
                        }

                    })
                }
            })

        })


    }

    useEffect(() => {
        if (state.sheetSaved) {
            setSheetSavedMessage('Sheet "' + state.sheetSaved + '" saved.')
            actions.sheetSaved(null)
            setTimeout(() => setSheetSavedMessage(null), 3500)
        }
    }, [state.sheetSaved]) // eslint-disable-line react-hooks/exhaustive-deps

    const clickOnSheetOperationsOpen = () => {
        setSheetOperationsOpen(!sheetOperationsOpen)
    }

    const sheetClear = async () => {

        let slots_ = JSON.parse(JSON.stringify(state.slots))
        for (let s = 0; s < slots_.length; s++) {
            let slot = slots_[s]

            let deleteElement = document.getElementById('sheet_number_' + slot.number)
            if (deleteElement) {
                deleteElement.remove()
            }

            if (slot.counterState !== null) {
                slot.counterState = null
                slot.svg = ''
                let data = { number: slot.number, counterState: null }
                await Utility.updateDexieStoreSingleItem(state.dexie, 'slots', data, 'number')
            }
        }
        actions.slots(slots_)
    }

    // useEffect(() => {
    //     if (numberSlotsOccupied() === 0 && csvLoadSlots.length > 0) {
    //         //processCsvLoadSlots()
    //         // renderSlotsToSheet()
    //     }
    //     Utility.updateDexieStore(state.dexie, 'slots', state.slots, 'number')
    // }, [state.slots]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div id="sheetOperations" className="sheet-operations">
            <div className={feedback ? 'saved-sheet-feedback' : 'saved-sheet-feedback display-none'}>{feedback}</div>
            <div className="sheet-operations-opener" onClick={clickOnSheetOperationsOpen}>sheet operations <div className={sheetOperationsOpen ?
                'sheet-operations-control open' :
                'sheet-operations-control closed'}
            >{sheetOperationsOpen ? '-' : '+'}</div></div>

            <div className={sheetOperationsOpen ? 'controls' : 'display-none'}>
                <div className="control">
                    <div className="control-title"><span>SHEET SAVE</span></div>
                    <div className="options-container">
                        <div className="options">


                            <div className="save-radio-options">
                                <input type="radio" id="sheetSaveInApp" name="sheetSave" value="app"
                                    checked={saveSheetMethod === 'app'}
                                    onChange={changeSaveSheetMethod}
                                    onClick={changeSaveSheetMethod} />
                                <label htmlFor="sheetSaveInApp" >save in app</label>

                                <input type="radio" id="sheetSaveToFile" name="sheetSave" value="file"
                                    checked={saveSheetMethod === 'file'}
                                    onChange={changeSaveSheetMethod}
                                    onClick={changeSaveSheetMethod} />
                                <label htmlFor="sheetSaveToFile">save to file</label>


                                {/* <input type="radio" id="sheetSaveToCsv" name="sheetSave" value="csv"
                                    checked={saveSheetMethod === 'csv'}
                                    onChange={changeSaveSheetMethod}
                                    onClick={changeSaveSheetMethod} />
                                <label htmlFor="sheetSaveToCsv">save to CSV</label> */}

                            </div>
                            <div className="save-radio-options">
                                <input type="radio" id="sheetExportToSvg" name="sheetSave" value="svg"
                                    checked={saveSheetMethod === 'svg'}
                                    onChange={changeSaveSheetMethod}
                                    onClick={changeSaveSheetMethod} />
                                <label htmlFor="sheetExportToSvg">export to svg</label>

                                <input type="radio" id="sheetExportToPng" name="sheetSave" value="png"
                                    checked={saveSheetMethod === 'png'}
                                    onChange={changeSaveSheetMethod}
                                    onClick={changeSaveSheetMethod} />
                                <label htmlFor="sheetExportToPng">export to png</label>
                            </div>

                            <div className="save-sheet-input-container">
                                name: <input type="text" value={sheetName} onChange={onChangeSheetName} className="save-sheet-input" />
                            </div>
                            <div className="save-sheet-submit-container">
                                {sheetSavedMessage ? <div className="sheet-saved">{sheetSavedMessage}</div> :
                                    <button className="action-button" onClick={sheetSave}>submit</button>
                                }
                            </div>

                        </div>
                    </div>
                </div>

                <div className="control sheet-load">
                    <div className="control-title"><span>SHEET LOAD</span></div>
                    <div className="options-container">
                        <div className="options">


                            <div className="load-section app">
                                <div className="load-section-title">Load from App: </div>
                                <div className="load-input-inline">

                                    <select id="savedSheet" value="" onChange={changeSavedSheet}>
                                        <option key="none" value="">choose sheet</option>
                                        {
                                            savedSheets.map((ss, index) => <option onClick={() => onClickChangeSavedSheet(ss.hash)} key={index} value={ss.hash}>{ss.name}</option>)
                                        }
                                    </select>

                                </div>

                            </div>



                            <div className="load-section load-from-file">
                                <div className="load-section-title">Load from file: </div>
                                <input type="file" id="sheet_file" accept=".scz" onChange={loadFromFileOnChange} />
                            </div>

                            <div className="load-section clear-sheet">
                                <button className={slotsOccupiedCount ? 'action-button yellowish' : 'action-button disabled'} onClick={sheetClear}>clear sheet</button>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="control">
                    <div className="control-title"><span>CUTTING GUIDES</span></div>
                    <div className="options-container">
                        <div className="options">


                            <div className="save-radio-options">

                                <input type="radio" id="guideTypeNone" name="guideType" value="none"
                                    checked={guideType === 'none'}
                                    onChange={changeGuideType}
                                    onClick={changeGuideType} />
                                <label htmlFor="guideTypeNone" >none</label>

                                <input type="radio" id="guideTypeEdges" name="guideType" value="edges"
                                    checked={guideType === 'edges'}
                                    onChange={changeGuideType}
                                    onClick={changeGuideType} />
                                <label htmlFor="guideTypeEdges">page edges</label>

                            </div>
                            <div className="save-radio-options guides2">

                                <input type="radio" id="guideTypeCorners" name="guideType" value="corners"
                                    checked={guideType === 'corners'}
                                    onChange={changeGuideType}
                                    onClick={changeGuideType} />
                                <label htmlFor="guideTypeCorners" >counter corners</label>

                                <input type="radio" id="guideTypeLines" name="guideType" value="lines"
                                    checked={guideType === 'lines'}
                                    onChange={changeGuideType}
                                    onClick={changeGuideType} />
                                <label htmlFor="guideTypeLines">lines</label>

                            </div>

                        </div>
                    </div>
                </div>


                <div className="control">
                    <div className="control-title"><span>PRINTABLE AREA</span></div>
                    <div className="options-container">
                        <div className="options">

                            <div className='column'>
                                <span className="option-title">width:</span>
                                <input ref={printableAreaWidthRef} type="text" id="printableAreaWidth" className={`mm ${inputWarning(printableAreaWidthRef.current)}`} onChange={changePrintableAreaWidth} value={printableAreaWidth} /><span className="subscript">mm</span>
                            </div>
                            <div className='column'>
                                <span className="option-title">height:</span>
                                <input ref={printableAreaHeightRef} type="text" id="printableAreaHeight" className={`mm ${inputWarning(printableAreaHeightRef.current)}`} onChange={changePrintableAreaHeight} value={printableAreaHeight} /><span className="subscript">mm</span>
                            </div>
                            <div className='column'>
                                <button className="action-button cyanish" onClick={printableAreaDefaults}>set defaults</button>
                            </div>

                        </div>
                    </div>
                </div>



            </div>
            <svg id="export_temp" className="export-temp" width="1200" height="1553" />
        </div>
    )
}
export default SheetOperations