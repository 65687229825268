import React, { useContext, useState, useEffect, useRef } from 'react'
import Utility from "../../objects/Utility";
import { StoreContext } from "../../context/StoreContext"
import './InputColor.scss'
const InputColor = ({ layerKey, input, setValue, useValue, setValid, delayIndex }) => {
    const { actions, state } = useContext(StoreContext)
    const launchColorPickerRef = useRef(null)
    const [colorPicked, setColorPicked] = useState(useValue)
    const [colorPickerOwned, setColorPickerOwned] = useState(false)
    const [isValidHexValue, setIsValidHexValue] = useState(true)
    const [multicolorButton, setMulticolorButton] = useState([])
    const [initialEffect, setInitialEffect] = useState(true)
    const [timer, setTimer] = useState(null)
    const inputColorRef = useRef(null)

    useEffect(() => {
        if (useValue !== null && useValue !== undefined) {
            if (state.activeLayerValues[layerKey + '_' + input.inputKey] !== undefined) {
                if (colorPicked !== useValue) {
                    setColorPicked(useValue)
                }
            }
        }
    }, [useValue]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (colorPicked === null || colorPicked === undefined) {
            return
        }
        if (initialEffect) {
            setInitialEffect(false)
            return
        }
        const regex = new RegExp("^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$");
        if (colorPicked !== 'transparent' && !regex.test(colorPicked)) {
            setValid(false)
            return
        }
        let existingColorPicked = null
        if (Utility.layerIsActive(layerKey, state.activeLayerValues)) {
            existingColorPicked = Utility.currentLayerInputValue(layerKey, input.inputKey, state.activeLayerValues)
        }
        if (colorPicked !== existingColorPicked) {
            //setValue(input.inputKey, colorPicked)
            // debounce a ton of color changes coming from the color picker in rapid succession if the user is dragging around.

            if (timer) {
                clearTimeout(timer)
            }
            let thisTimer = setTimeout(() => {
                setValue(input.inputKey, colorPicked)
                setTimer(null)
            }, 10)
            setTimer(thisTimer)


            //setValue(input.inputKey, colorPicked)
        }

    }, [colorPicked]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let _timer = setTimeout(() => {
            if (useValue !== null && useValue !== undefined) {
                setColorPicked(useValue)
            }
            else {
                setColorPicked(input.defaultStrValue)
            }
            setTimer(null)
        }, delayIndex * 10);
        setTimer(_timer)

        executeCode()
        return () => {
            if (timer) {
                clearTimeout(timer)
            }
        };
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const colorPick = (evt) => {
        let _colorPicked = evt.target.value

        if (_colorPicked === 'transparent') {
            setIsValidHexValue(true)
        }
        else {

            const regex = new RegExp("^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$");
            if (regex.test(_colorPicked)) {
                setIsValidHexValue(true)
            }
            else {
                setIsValidHexValue(false)
            }
        }
        if (_colorPicked !== null && _colorPicked !== undefined) {
            setColorPicked(_colorPicked)
        }
    }


    const openColorPicker = () => {
        setColorPickerOwned(true)
    }

    useEffect(() => {
        if (colorPickerOwned) {
            let launchColorPickerRefClientRef = launchColorPickerRef.current.getBoundingClientRect()
            let x = launchColorPickerRefClientRef.left + launchColorPickerRefClientRef.width + 10 // the 10 is to give in some spacing
            let y = launchColorPickerRefClientRef.top
            let hexColor = colorPicked
            actions.colorPicker({ x, y, hexColor })

        }
    }, [colorPickerOwned]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (state.colorPicker.x === -1) {
            if (timer) {
                clearTimeout(timer)
            }
            setTimer(null)
            setColorPickerOwned(false)
        }
        else {
            if (colorPickerOwned) {
                setColorPicked(state.colorPicker.hexColor)
                setIsValidHexValue(Utility.validateHexColor(state.colorPicker.hexColor))
            }
        }

    }, [state.colorPicker]) // eslint-disable-line react-hooks/exhaustive-deps


    const executeCode = () => {

        function hslToHex(h, s, l) {
            l /= 100;
            const a = s * Math.min(l, 1 - l) / 100;
            const f = n => {
                const k = (n + h / 30) % 12;
                const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
                return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
            };
            return `#${f(0)}${f(8)}${f(4)}`;
        }

        let paths = []
        let colors = []
        for (var x = 0; x < 360; x = x + 12) {
            let h = hslToHex(x, 81, 50)
            colors.push(h)
        }
        let arr = [0, 12, 24, 36, 48, 60, 72, 84, 96, 108, 120, 132, 144, 156, 168, 180, 192, 204, 216, 228, 240, 252, 264, 276, 288, 300, 312, 324, 336, 348]


        arr.forEach((a, index) => {
            let d = drawPieSlice(0, 0, 19, a, a + 12)
            paths.push({ d, color: colors[index] })
        })
        setMulticolorButton(paths)
    }

    const drawPieSlice = (centerX, centerY, radius, startAngle, endAngle) => {

        var startRad = startAngle * Math.PI / 180.0;
        let x1 = radius * Math.sin(startRad)
        let y1 = radius * Math.cos(startRad)

        var endRad = endAngle * Math.PI / 180.0;
        let x2 = radius * Math.sin(endRad)
        let y2 = radius * Math.cos(endRad)

        //  var startArc = polarToCartesian(centerX, centerY, radius, endAngle);
        //  var endArc = polarToCartesian(centerX, centerY, radius, startAngle);

        //  var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

        let d = 'M ' + centerX + ', ' + centerY + ' ' +
            'L ' + x1 + ',' + y1 + ','
            + x2 + ',' + y2 + ' Z'
        return d

    }

    return (
        <div ref={inputColorRef} id={`input_color_${input.inputKey}_${layerKey}`} className="input-color">
            <div className="named">{input.named}:</div>

            <div><input type="text" spellCheck="false" className={isValidHexValue ? 'input-color-pick' : 'input-color-pick invalid'} value={colorPicked} onChange={colorPick} /></div>




            <div className="color-sample" style={{ "background": colorPicked }} onClick={openColorPicker} />
            <div ref={launchColorPickerRef}

                className="multicolor-button"

                onClick={openColorPicker}>
                <svg id={`colorPickerButton${input.inputKey}_${layerKey}`} width="28" height="28" viewBox="-14, -14, 28, 28">
                    {multicolorButton.map((dc, index) => {
                        return <path key={index} d={`${dc.d}`} fill={`${dc.color}`} />
                    })}
                    <circle cx="0" cy="0" r="14" stroke="white" strokeWidth="2px" fill="none" />
                    <circle cx="0" cy="0" r="13" stroke="white" strokeWidth="2px" fill="none" opacity="0.5" />
                </svg>

            </div>
        </div>
    )
}
export default InputColor