import React, { useState, useEffect, useContext, useRef } from 'react'
import Snap from 'snapsvg-cjs'
import { StoreContext } from "../../context/StoreContext"
import Utility from "../../objects/Utility"
import XButton from '../XButton/XButton'
import './DataDisplay.scss'
const DataDisplay = () => {
    const { state, actions } = useContext(StoreContext)
    const dataDisplayRef = useRef(null)
    const [data, setData] = useState([])
    const [keys, setKeys] = useState(null)
    const [prepareKeysData, setPrepareKeysData] = useState(null)
    const [timer, setTimer] = useState(null)
    const renderRefs = useRef([])
    const rowAdjusterRefs = useRef([])
    const [fontsCustomSettings, setFontsCustomSettings] = useState({})
    const [changeSvgNames, setChangeSvgNames] = useState({})
    const [svgNameUpdatedKey, setSvgNameUpdatedKey] = useState(-1)
    const [noItemsMessage, setNoItemsMessage] = useState('Gathering data...')

    useEffect(() => {
        setData([])
        setKeys(null)
        if (state.dataDisplay === 'saved counters') {
            setPrepareKeysData('savedCounters')
        }
        if (state.dataDisplay === 'saved sheets') {
            setPrepareKeysData('savedSheets')
        }
        if (state.dataDisplay === 'fonts') {
            setPrepareKeysData('fonts')
        }
        if (state.dataDisplay === 'installed svgs') {
            setPrepareKeysData('installedSvgs')
        }
        if (state.dataDisplay === 'installed images') {
            setPrepareKeysData('installedImages')
        }
        actions.help(false)
    }, [state.dataDisplay, state.savedCounters, state.savedSheets]) // eslint-disable-line react-hooks/exhaustive-deps

    const closeAction = () => {
        setNoItemsMessage('')
        setData([])
        setKeys(null)
        setFontsCustomSettings({})
        if (timer) {
            clearTimeout(timer)
            setTimer(null)
        }
        actions.dataDisplay(null)
    }

    useEffect(() => {
        if (prepareKeysData) {
            setupKeysData(prepareKeysData)
        }
        setPrepareKeysData(null)
    }, [prepareKeysData])  // eslint-disable-line react-hooks/exhaustive-deps

    const setupKeysData = async (dataName) => {
        setNoItemsMessage('Gathering data...')
        let data = []
        if (state.dataDisplay === 'saved counters' ||
            state.dataDisplay === 'saved sheets') {
            data = await Utility.dexieGetData(state.dexie, dataName)
            if (!data) {
                data = []
            }
            else {
                setNoItemsMessage('')
                data.forEach(dt => {
                    if (dt.compressedSvg) {
                        let svg = Utility.decompressString(dt.compressedSvg)
                        delete dt.compressedSvg
                        dt.svg = svg
                    }
                })
            }
        }

        if (state.dataDisplay === 'installed svgs') {
            // get custom svg names
            let layer = state.layers.find(ly => ly.layerName === 'custom svgs')
            if (layer) {
                let inputs = layer.inputs
                let customSvgsInput = inputs.find(input => input.named === 'svgKey')
                if (customSvgsInput) {
                    let svgList = customSvgsInput.list
                    if (svgList) {
                        data = state.svgs.filter(svg => svgList.includes(svg.svgKey))
                        if (!data) {
                            data = []
                        }
                    }
                }
            }
        }

        if (state.dataDisplay === 'installed images') {
            // get custom svg names
            let layer = state.layers.find(ly => ly.layerName === 'custom images')
            if (layer) {
                let inputs = layer.inputs
                let customSvgsInput = inputs.find(input => input.named === 'svgKey')
                if (customSvgsInput) {
                    let svgList = customSvgsInput.list
                    if (svgList) {
                        data = state.svgs.filter(svg => svgList.includes(svg.svgKey))
                        if (!data) {
                            data = []
                        }
                    }
                }
            }
        }

        if (state.dataDisplay === 'fonts') {
            data = await Utility.dexieGetData(state.dexie, dataName)
            data = data.filter(font => font.fontFamily !== 'Material Icons')
            if (!data) {
                data = []
            }
            let fontSettings = {}
            data.forEach(dt => {
                if (dt.fontSrc) {
                    let fontSrcLength = Utility.getBytes(dt.fontSrc.length)
                    dt.fontSrc = 'font source is base64 encoded, size: ' + fontSrcLength
                }
                else {
                    dt.fontSrc = '0 bytes'
                }
                let identifier = dt.fontFamily.replaceAll(' ', '_') + '@' + dt.fontStyle + '@' + dt.fontWeight
                fontSettings[identifier] = {}
                fontSettings[identifier].fontFamily = dt.fontFamily
                fontSettings[identifier].boldFont = dt.fontWeight > 500 ? true : false
                fontSettings[identifier].italicFont = dt.fontStyle === 'italic' ? true : false
                fontSettings[identifier].fontSize = 22
                fontSettings[identifier].sampleText = 'abcdefghijklmnopqrstuvwxyz01234567890'
                if (dt.fontWeight > 500) {
                    setTimeout(() => {
                        let ele = document.getElementById('boldFont_' + dt.fontFamily.replaceAll(' ', '_') + '@' + dt.fontStyle + '@' + dt.fontWeight)
                        if (ele) {
                            ele.checked = true
                        }
                    }, 1000)
                }
                if (dt.fontStyle === 'italic') {
                    setTimeout(() => {
                        let ele = document.getElementById('italicFont_' + dt.fontFamily.replaceAll(' ', '_') + '@' + dt.fontStyle + '@' + dt.fontWeight)
                        if (ele) {
                            ele.checked = true
                        }
                    }, 1000)
                }
            })
            setFontsCustomSettings(fontSettings)
        }

        if (data && data.length > 0) {
            setData(data)
            let keys = Object.keys(data[0])
            if (Array.isArray(keys)) {
                setKeys(keys)
            }
            else {
                setNoItemsMessage('there are no ' + state.dataDisplay)
                setData([])
                setKeys(null)
            }
        }
        else {
            setNoItemsMessage('there are no ' + state.dataDisplay)
            setData([])
            setKeys(null)
        }
    }

    useEffect(() => {
        if (state.dataDisplay === 'saved counters' || state.dataDisplay === 'saved sheets') {
            if (keys && keys.length > 0 && data && data.length > 0) {
                data.forEach(savedItem => {
                    let hash = savedItem.hash
                    let _paper = Snap("#svg_" + hash); //  this will add a svg element under the element specified by the argument. 
                    let svg = savedItem.svg
                    if (svg) {
                        svg = svg.replaceAll('<!--?xml version="1.0" encoding="UTF-8" standalone="yes" ?-->', '')
                        // make sure it isnt present already. And if so, just remove it so we dont get duplicates.
                        let parent = document.getElementById('svg_' + hash)
                        var child = parent.lastElementChild;
                        while (child) {
                            parent.removeChild(child);
                            child = parent.lastElementChild;
                        }
                        parent.innerHTML = ''
                        let parsed = Snap.parse(svg)
                        _paper.append(parsed)
                        // let foundIds = Utility.distillIdsFromSnapElement(_paper)
                        // let uniquePrepend = Utility.randomString(8)
                        // uniquePrepend.replaceAll('_', '')
                        // uniquePrepend += '_'
                        // svg = Utility.replaceIdsInSvgString(foundIds, svg, uniquePrepend)
                        // parsed = Snap.parse(svg)
                        // _paper.clear()
                        // _paper.append(parsed)
                        if (state.dataDisplay === 'saved sheets') {
                            _paper.attr({ width: "90%", height: "100%", viewBox: "0, 0, 1200, 1553" })
                        }
                        if (state.dataDisplay === 'saved counters') {
                            _paper.attr({ width: "90%", height: "90%", viewBox: "0, 0, 1200, 1200" })
                        }
                        // parent.innerHTML = parent.innerHTML.replaceAll('<!--?xml version="1.0" encoding="UTF-8" standalone="yes" ?-->', '')
                    }


                })

            }
        }

        if (state.dataDisplay === 'installed svgs') {
            if (keys && keys.length > 0 && data && data.length > 0) {
                data.forEach((row, index) => {
                    let svg = row['svgCode']
                    let svgKey = row['svgKey']
                    svg = svg.replaceAll('<!--?xml version="1.0" encoding="UTF-8" standalone="yes" ?-->', '')
                    // // ids should not have spaces, so we gotta sluggify the name.
                    // // and def ids (gradients, masks, etc) will need to be modified a bit so it doesnt clash with an existing id on the counter divs.
                    let safeName = row.svgName
                    if (safeName && safeName.includes(' ')) {
                        safeName = safeName.replace(/ /g, "_");
                    }
                    // svg = svg.replaceAll('id="', 'id="' + row.hash + '_')
                    // svg = svg.replaceAll("url('#", "url('#" + row.hash + '_')
                    // // make sure it isnt present already. And if so, just remove it so we dont get duplicates.
                    // let parent = document.getElementById('svg_' + safeName)
                    // var child = parent.lastElementChild;
                    // while (child) {
                    //     parent.removeChild(child);
                    //     child = parent.lastElementChild;
                    // }
                    // parent.innerHTML = ''
                    let _paper = Snap("#svg_custom_" + svgKey); //  this will add a svg element under the element specified by the argument. 
                    // //  Kinda weird, but thats what it does if there isn't a svg element already with that id.
                    let parsed = Snap.parse(svg)           // and since we want to just append the whole svg, doing it this away avoids a svg inside a svg.
                    _paper.append(parsed)

                    let foundIds = Utility.distillIdsFromSnapElement(_paper)
                    let uniquePrepend = 'i' + Utility.randomString(8)
                    uniquePrepend.replaceAll('_', '')
                    uniquePrepend += '_'
                    svg = Utility.replaceIdsInSvgString(foundIds, svg, uniquePrepend)
                    parsed = Snap.parse(svg)
                    _paper.clear()
                    _paper.append(parsed)

                    let svgElement = document.getElementById("svg_custom_" + svgKey).getElementsByTagName('svg')[0];
                    if (svgElement) {
                        svgElement.setAttribute('width', '100%')
                        svgElement.setAttribute('height', '100%')
                    }
                    // parent.innerHTML = parent.innerHTML.replaceAll('<!--?xml version="1.0" encoding="UTF-8" standalone="yes" ?-->', '')
                })
            }
        }

        if (state.dataDisplay === 'installed images') {
            if (keys && keys.length > 0 && data && data.length > 0) {
                data.forEach((row, index) => {
                    let svg = row['svgCode']
                    let svgKey = row['svgKey']
                    svg = svg.replaceAll('<!--?xml version="1.0" encoding="UTF-8" standalone="yes" ?-->', '')
                    // // ids should not have spaces, so we gotta sluggify the name.
                    // // and def ids (gradients, masks, etc) will need to be modified a bit so it doesnt clash with an existing id on the counter divs.
                    let safeName = row.svgName
                    if (safeName && safeName.includes(' ')) {
                        safeName = safeName.replace(/ /g, "_");
                    }
                    let _paper = Snap("#svg_custom_image_" + svgKey); //  this will add a svg element under the element specified by the argument. 
                    // //  Kinda weird, but thats what it does if there isn't a svg element already with that id.
                    let parsed = Snap.parse(svg)           // and since we want to just append the whole svg, doing it this away avoids a svg inside a svg.
                    _paper.append(parsed)

                    let foundIds = Utility.distillIdsFromSnapElement(_paper)
                    let uniquePrepend = 'i' + Utility.randomString(8)
                    uniquePrepend.replaceAll('_', '')
                    uniquePrepend += '_'
                    svg = Utility.replaceIdsInSvgString(foundIds, svg, uniquePrepend)
                    parsed = Snap.parse(svg)
                    _paper.clear()
                    _paper.append(parsed)

                    let svgElement = document.getElementById("svg_custom_image_" + svgKey).getElementsByTagName('svg')[0];
                    if (svgElement) {
                        svgElement.setAttribute('width', '100%')
                        svgElement.setAttribute('height', '100%')
                    }
                    // parent.innerHTML = parent.innerHTML.replaceAll('<!--?xml version="1.0" encoding="UTF-8" standalone="yes" ?-->', '')
                })
            }
        }

    }, [keys,data]) // eslint-disable-line react-hooks/exhaustive-deps

    const loadSaved = async (hash) => {
        if (state.dataDisplay === 'saved counters') {
            let counterObj = state.savedCounters[hash]
            actions.loadCounter(counterObj.counterState)
            window.scrollTo(0, 0)
            actions.dataDisplay(null)
        }
        if (state.dataDisplay === 'saved sheets') {
            let sheetObj = state.savedSheets[hash]
            actions.userLoadSlotsSheet(sheetObj)
        }
    }
    const deleteSaved = (hash) => {
        // delete from state

        // delete from dexie
        if (state.dataDisplay === 'saved counters') {
            actions.savedCountersRemove(hash)
            Utility.dexieDeleteItem(state.dexie, 'savedCounters', 'hash', hash)
        }
        if (state.dataDisplay === 'saved sheets') {
            actions.savedSheetsRemove(hash)
            Utility.dexieDeleteItem(state.dexie, 'savedSheets', 'hash', hash)
        }
    }

    const formatXml = (xml, tab) => { // tab = optional indent value, default is tab (\t)
        var formatted = '', indent = '';
        tab = tab || '  ';
        xml.split(/>\s*</).forEach(function (node) {
            if (node.match(/^\/\w/)) indent = indent.substring(tab.length); // decrease indent by one 'tab'
            formatted += indent + '<' + node + '>\r\n';
            if (node.match(/^<?\w[^>]*[^/]$/)) indent += tab;              // increase indent
        });
        let str = formatted.substring(1, formatted.length - 3);
        return str
    }

    const attachDrag = evt => {
        let tdElement = null
        let tdAdjustHeightArray = []
        let startingTdHeight = -1
        let startCoords = { x: -1, y: -1 }
        let rowIndex = -1

        const adjustDataHeight = ({ startY, y }) => {
            let diff = y - startY
            let newHeight = startingTdHeight + diff
            if (newHeight < 0) {
                return
            }


            if (!timer) {

                Array.from(tdAdjustHeightArray).forEach(function (item) {
                    item.style.height = newHeight + 'px'
                });

                // let thisTimer = setTimeout(() => {
                //     Array.from(tdAdjustHeightArray).forEach(function (item) {
                //         item.style.height = newHeight + 'px'
                //     });
                //     setTimer(null)
                // }, 30)
                // setTimer(thisTimer)
            }
            else {
                clearTimeout(timer)
                let thisTimer = setTimeout(() => {
                    Array.from(tdAdjustHeightArray).forEach(function (item) {
                        item.style.height = newHeight + 'px'
                    });
                    setTimer(null)
                }, 300)
                setTimer(thisTimer)
            }



        }

        const dragMove = evt => {
            adjustDataHeight({ startY: startCoords.y, y: evt.clientY })
        }
        const dragStart = evt => {
            tdAdjustHeightArray = document.getElementsByClassName('td-adjust-' + rowIndex)
            startingTdHeight = tdAdjustHeightArray[0].clientHeight;

            startCoords = { x: evt.clientX, y: evt.clientY }
            var crt = tdElement.cloneNode(true);
            evt.dataTransfer.setDragImage(crt, 0, 0);
        }
        const dragStop = evt => {
            // needed for Firefox since Firefox doesn't update evt.clientY during a drag, only on dragStop.
            adjustDataHeight({ startY: startCoords.y, y: evt.clientY })

            detachDrag(null)
            tdElement = null
            tdAdjustHeightArray = []
            startingTdHeight = -1
            startCoords = { x: -1, y: -1 }
            rowIndex = -1
        }

        rowIndex = evt.target.id.replace(/^\D+/g, '');
        tdElement = document.getElementById('td_adjust_' + rowIndex)
        tdElement.draggable = true
        tdElement.ondrag = dragMove
        tdElement.ondragstart = dragStart
        tdElement.ondragend = dragStop


        const detachDrag = evt => {
            let id = -1
            if (!evt) {
                id = rowIndex
            }
            else {
                id = evt.target.id.replace(/^\D+/g, '');
            }

            let tdElement = document.getElementById('td_adjust_' + id)
            tdElement.draggable = false
        }
    }

    const removeDraggable = evt => {
        let id = evt.target.id.replace(/^\D+/g, '');
        let tdElement = document.getElementById('td_adjust_' + id)
        tdElement.draggable = false
    }

    const changeFontSampleText = evt => {
        let targetFont = evt.target.id.replace('fontSampleText_', '')
        let _fontObj = fontsCustomSettings[targetFont]
        _fontObj.sampleText = evt.target.value
        setFontsCustomSettings({ ...fontsCustomSettings, ...{ [targetFont]: _fontObj } })
    }
    const changeBoldFont = evt => {
        let targetFont = evt.target.id.replace('boldFont_', '')
        let _fontObj = fontsCustomSettings[targetFont]
        _fontObj.boldFont = evt.target.checked
        setFontsCustomSettings({ ...fontsCustomSettings, ...{ [targetFont]: _fontObj } })
    }
    const changeItalicFont = evt => {
        let targetFont = evt.target.id.replace('italicFont_', '')
        let _fontObj = fontsCustomSettings[targetFont]
        _fontObj.italicFont = evt.target.checked
        setFontsCustomSettings({ ...fontsCustomSettings, ...{ [targetFont]: _fontObj } })
    }
    const changeFontSize = evt => {
        let targetFont = evt.target.id.replace('fontSize_', '')
        let _fontObj = fontsCustomSettings[targetFont]
        _fontObj.fontSize = Number(evt.target.value)
        setFontsCustomSettings({ ...fontsCustomSettings, ...{ [targetFont]: _fontObj } })
    }

    const clickDeleteFont = async (fontId) => {
        let fontFamily = fontsCustomSettings[fontId].fontFamily
        let splitted = fontId.split('@')
        if (splitted.length === 3) {
            let fontStyle = splitted[1]
            let fontWeight = splitted[2]
            // delete from dexie
            Utility.dexieDeleteFont(state.dexie, fontFamily, fontStyle, fontWeight)
            // delete from state
            let _fonts = [...state.fonts]
            //_fonts = _fonts.filter(ft => ft.fontFamily !== fontFamily && ft.fontStyle !== fontStyle && ft.fontWeight !== fontWeight)
            _fonts = _fonts.filter(ft => ft.fontFamily !== fontFamily)
            actions.fonts(_fonts)
            // delete from Dom
            let ele = document.getElementById(fontId)
            if (ele) {
                ele.remove()
            }
            ele = document.getElementById('tr_font_' + fontFamily.replaceAll(' ', '_'))
            if (ele) {
                ele.remove()
            }
            ele = document.getElementById('tr_font_adjuster_' + fontFamily.replaceAll(' ', '_'))
            if (ele) {
                ele.remove()
            }
        }
    }

    const changeSvgName = evt => {
        let svgKey = Utility.extractInteger(evt.target.id)
        if (svgKey > -1) {
            let changedSvgName = evt.target.value
            if (changedSvgName) {
                let _changeSvgNames = JSON.parse(JSON.stringify(changeSvgNames))
                _changeSvgNames[svgKey] = {}
                _changeSvgNames[svgKey].name = changedSvgName
                _changeSvgNames[svgKey].valid = true
                setChangeSvgNames(_changeSvgNames)
            }
        }
    }

    const arrContainsCaseInsensitiveStr = (arr, str) => {
        return arr.filter(astr => {
            return astr.toLowerCase() === str.toLowerCase();
        }).length > 0 ? true : false
    }

    const submitSvgNameChange = async (evt) => {
        let svgKey = Utility.extractInteger(evt.target.id)
        let newSvgName = changeSvgNames[svgKey].name
        const names = await state.dexie.svgs.orderBy('svgName').keys();
        let isContained = arrContainsCaseInsensitiveStr(names, newSvgName.trim())
        if (isContained) {
            let _changeSvgNames = JSON.parse(JSON.stringify(changeSvgNames))
            _changeSvgNames[svgKey] = {}
            _changeSvgNames[svgKey].name = newSvgName
            _changeSvgNames[svgKey].valid = false
            setChangeSvgNames(_changeSvgNames)
            return
        }


        ///////////////////////////////////////////////////////////////////////
        // update svg with new name into dexie svgs data structure
        ///////////////////////////////////////////////////////////////////////
        let svgItem = await state.dexie.svgs.get(Number(svgKey))
        svgItem.svgName = newSvgName.trim()
        await Utility.dexiePutItem(state.dexie, 'svgs', svgItem)

        // ///////////////////////////////////////////////////////////////////////
        // // insert new svg into state.svgs data structure
        // ///////////////////////////////////////////////////////////////////////
        let _svgs = [...state.svgs]
        _svgs = _svgs.map(svgItem => {
            if (Number(svgItem.svgKey) === Number(svgKey)) {
                return { ...svgItem, svgName: newSvgName.trim() }
            }
            return svgItem
        })
        actions.svgs(_svgs)

        // ///////////////////////////////////////////////////////////////////////
        // // insert new svg into dexie layers svg_group list array
        // ///////////////////////////////////////////////////////////////////////
        let _data = data.map(dt => {
            if (Number(dt.svgKey) === Number(svgKey)) {
                dt.svgName = newSvgName.trim()
                return dt
            }
            return dt
        })
        if (!_data) {
            _data = []
        }
        setData(_data)
        setSvgNameUpdatedKey(Number(svgKey))
    }

    useEffect(() => {
        if (svgNameUpdatedKey > -1) {
            if (timer) {
                clearTimeout(timer)
            }
            let thisTimer = setTimeout(() => {
                setSvgNameUpdatedKey(-1)
                setTimer(null)
            }, 700)
            setTimer(thisTimer)
        }
    }, [svgNameUpdatedKey]) // eslint-disable-line react-hooks/exhaustive-deps

    const deleteSvg = async (svgKeyToDelete) => {
        // make sure svgKeyToDelete is really there (not sure why it wouldnt, but, just make really really sure.
        // get list of layers that are of type Custom svgs
        // get layerKey of original Custom svgs
        let customSvgsLayersFromState = []
        let customSvgsLayersFromDexie = []
        let originalCustomSvgsLayer = state.layers.find(ly => ly.layerName === 'custom svgs')
        if (originalCustomSvgsLayer) {
            let originalCustomSvgsLayerKey = originalCustomSvgsLayer.layerKey
            // now that we have the layerKey of the original custom Svgs layer, look for any with parentLayerKey that matches
            customSvgsLayersFromState = state.layers.filter(ly => ly.parentLayerKey === originalCustomSvgsLayerKey)
            if (!customSvgsLayersFromState) {
                customSvgsLayersFromState = []
            }
            // and add the original too
            customSvgsLayersFromState.push(originalCustomSvgsLayer)
            // clone cause we dont want to affect state directly
            customSvgsLayersFromState = customSvgsLayersFromState.map(ly => JSON.parse(JSON.stringify(ly)))
        }
        else {
            console.warn('could not find custom svgs layer in state!')
        }

        let originalCustomSvgsLayerFromDexie = await state.dexie.layers.get({ layerName: 'custom svgs' })
            .catch('Error', err => {
                console.error(err);
            });
        if (originalCustomSvgsLayerFromDexie) {
            let originalCustomSvgsLayerKey = originalCustomSvgsLayerFromDexie.layerKey
            customSvgsLayersFromDexie = await state.dexie.layers.where({ parentLayerKey: originalCustomSvgsLayerKey }).toArray()
            if (!customSvgsLayersFromDexie) {
                customSvgsLayersFromDexie = []
            }
            customSvgsLayersFromDexie.push(originalCustomSvgsLayerFromDexie)
        }
        else {
            return
        }

        if (customSvgsLayersFromDexie.length !== customSvgsLayersFromState.length) {
            console.warn('there is a mismatch between state and dexie as to custom svgs layers!')
            return
        }

        let layerKeysToRemoveFromActiveLayerValues = []
        // remove the svgKeyToBeDeleted from the layer inputs svg list array
        customSvgsLayersFromState.forEach(customSvgsLayer => {
            let inputs = customSvgsLayer.inputs
            let customSvgsInput = inputs.find(input => input.named === 'svgKey')
            if (customSvgsInput) {
                // return the same list, except for where the key equals the key we are deleting.
                customSvgsInput.list = customSvgsInput.list.filter(_svgKey => Number(_svgKey) !== Number(svgKeyToDelete))
                if (customSvgsInput.list.length === 0) {
                    // if the list is now empty, we need to ensure the layer is hidden
                    customSvgsLayer.layerHidden = 1
                    customSvgsLayer.layerActive = 0
                }
            }

            // ok. We removed the svgKeyToDelete from the list. Now - did this layer have this svgKey set and was it active?
            let requiredSvgKey = customSvgsLayer.layerActiveRequiredInputKey
            // did it point to the svgKeyToDelete?
            if (state.activeLayerValues[customSvgsLayer.layerKey + '_' + requiredSvgKey] === svgKeyToDelete) {
                // remove references to this layer from activeLayerValues
                layerKeysToRemoveFromActiveLayerValues.push(customSvgsLayer.layerKey)
                // and the layer will not be active.
                customSvgsLayer.layerActive = 0
            }

        })

        // go ahead and delete the svgKeyToBeDelete
        await state.dexie.svgs.where('svgKey').equals(svgKeyToDelete).delete();
        let _svgs = state.svgs.filter(svg => Number(svg.svgKey) !== Number(svgKeyToDelete))
        actions.svgs(_svgs)

        // so now we (may) have entries in state.activeLayerValues that need to be removed
        // and we now will have one or more layers that will need to have their svg list updated, and possibly
        // their layerHidden and layerActive values updated.
        await Utility.dexieRemoveLayersFromActive(state.dexie, layerKeysToRemoveFromActiveLayerValues)
        actions.activeLayerValuesRemove(layerKeysToRemoveFromActiveLayerValues)
        for (let i = 0; i < customSvgsLayersFromState.length; i++) {
            await Utility.updateDexieStoreSingleItem(state.dexie, 'layers', customSvgsLayersFromState[i], 'layerKey')
        }
        actions.layersUpdate(customSvgsLayersFromState)

        // clear drawn svg
        let parent = document.getElementById('svg_custom_' + svgKeyToDelete)
        if (parent && parent.lastElementChild) {
            var child = parent.lastElementChild;
            while (child) {
                parent.removeChild(child);
                child = parent.lastElementChild;
            }
            parent.innerHTML = ''
        }

        let _data = data.filter(dt => dt.svgKey !== svgKeyToDelete)
        if (_data && _data.length > 0) {
            setData(_data)
        }
        else {
            _data = []
            setNoItemsMessage('there are no ' + state.dataDisplay)
            setData([])
            setKeys(null)
        }
    }

    const deleteImage = async (svgKeyToDelete) => {
        // make sure svgKeyToDelete is really there (not sure why it wouldnt, but, just make really really sure.
        // get list of layers that are of type Custom Images
        // get layerKey of original Custom Images
        let customImagesLayersFromState = []
        let customImagesLayersFromDexie = []
        let originalCustomImagesLayer = state.layers.find(ly => ly.layerName === 'custom images')
        if (originalCustomImagesLayer) {
            let originalCustomImagesLayerKey = originalCustomImagesLayer.layerKey
            // now that we have the layerKey of the original custom images layer, look for any with parentLayerKey that matches
            customImagesLayersFromState = state.layers.filter(ly => ly.parentLayerKey === originalCustomImagesLayerKey)
            if (!customImagesLayersFromState) {
                customImagesLayersFromState = []
            }
            // and add the original too
            customImagesLayersFromState.push(originalCustomImagesLayer)
            // clone cause we dont want to affect state directly
            customImagesLayersFromState = customImagesLayersFromState.map(ly => JSON.parse(JSON.stringify(ly)))
        }
        else {
            console.warn('could not find custom images layer in state!')
        }

        let originalCustomImagesLayerFromDexie = await state.dexie.layers.get({ layerName: 'custom images' })
            .catch('Error', err => {
                console.error(err);
            });
        if (originalCustomImagesLayerFromDexie) {
            let originalCustomImagesLayerKey = originalCustomImagesLayerFromDexie.layerKey
            customImagesLayersFromDexie = await state.dexie.layers.where({ parentLayerKey: originalCustomImagesLayerKey }).toArray()
            if (!customImagesLayersFromDexie) {
                customImagesLayersFromDexie = []
            }
            customImagesLayersFromDexie.push(originalCustomImagesLayerFromDexie)
        }
        else {
            console.warn('could not find original custom images layer from Dexie!')
            return
        }

        if (customImagesLayersFromDexie.length !== customImagesLayersFromState.length) {
            console.warn('there is a mismatch between state and dexie as to custom images layers!')
            return
        }

        let layerKeysToRemoveFromActiveLayerValues = []
        // remove the svgKeyToBeDeleted from the layer inputs svg list array
        customImagesLayersFromState.forEach(customImagesLayer => {
            let inputs = customImagesLayer.inputs
            let customSvgsInput = inputs.find(input => input.named === 'svgKey')
            if (customSvgsInput) {
                // return the same list, except for where the key equals the key we are deleting.
                customSvgsInput.list = customSvgsInput.list.filter(_svgKey => Number(_svgKey) !== Number(svgKeyToDelete))
                if (customSvgsInput.list.length === 0) {
                    // if the list is now empty, we need to ensure the layer is hidden
                    customImagesLayer.layerHidden = 1
                    customImagesLayer.layerActive = 0
                }
            }

            // ok. We removed the svgKeyToDelete from the list. Now - did this layer have this svgKey set and was it active?
            let requiredSvgKey = customImagesLayer.layerActiveRequiredInputKey
            // did it point to the svgKeyToDelete?
            if (state.activeLayerValues[customImagesLayer.layerKey + '_' + requiredSvgKey] === svgKeyToDelete) {
                // remove references to this layer from activeLayerValues
                layerKeysToRemoveFromActiveLayerValues.push(customImagesLayer.layerKey)
                // and the layer will not be active.
                customImagesLayer.layerActive = 0
            }

        })

        // go ahead and delete the svgKeyToBeDelete
        await state.dexie.svgs.where('svgKey').equals(svgKeyToDelete).delete();
        let _svgs = state.svgs.filter(svg => Number(svg.svgKey) !== Number(svgKeyToDelete))
        actions.svgs(_svgs)

        // so now we (may) have entries in state.activeLayerValues that need to be removed
        // and we now will have one or more layers that will need to have their svg list updated, and possibly
        // their layerHidden and layerActive values updated.
        await Utility.dexieRemoveLayersFromActive(state.dexie, layerKeysToRemoveFromActiveLayerValues)
        actions.activeLayerValuesRemove(layerKeysToRemoveFromActiveLayerValues)
        for (let i = 0; i < customImagesLayersFromState.length; i++) {
            await Utility.updateDexieStoreSingleItem(state.dexie, 'layers', customImagesLayersFromState[i], 'layerKey')
        }
        actions.layersUpdate(customImagesLayersFromState)

        // clear drawn svg
        let parent = document.getElementById('svg_custom_image_' + svgKeyToDelete)
        if (parent && parent.lastElementChild) {
            var child = parent.lastElementChild;
            while (child) {
                parent.removeChild(child);
                child = parent.lastElementChild;
            }
            parent.innerHTML = ''
        }

        let _data = data.filter(dt => dt.svgKey !== svgKeyToDelete)
        if (_data && _data.length > 0) {
            setData(_data)
        }
        else {
            _data = []
            setNoItemsMessage('there are no ' + state.dataDisplay)
            setData([])
            setKeys(null)
        }
    }

    return (
        <div ref={dataDisplayRef} className={state.dataDisplay ? 'data-display' : 'display-none'}>
            <div className="data-display-top">
                <div className="display-title">{state.dataDisplay}</div>
                <div className="close-button">
                    <XButton r="12" closeAction={closeAction} />
                </div>
            </div>

            {state.dataDisplay === 'saved counters' || state.dataDisplay === 'saved sheets' ?
                <div className="data-container">
                    {keys && data && data.length > 0 ?
                        <table className={state.dataDisplay === 'saved counters' || state.dataDisplay === 'saved sheets' ? `${state.dataDisplay.replace(' ', '-')}` : 'display-none'}>
                            <thead>
                                <tr>
                                    <th>rendered</th>
                                    {keys.map((key, index) => <th key={index}>{key}</th>)}
                                </tr>
                            </thead>
                            <tbody>

                                {data.map((row, rowIndex) =>
                                    <React.Fragment key={rowIndex}>
                                        <tr key={`row_${rowIndex}`}>

                                            <td ref={ref => renderRefs.current.push(ref)} key={`svg_${rowIndex}`} className={`rendered-column td-adjust-${rowIndex}`}>
                                                <div className={state.dataDisplay === 'saved counters' ? 'svg-container counters' : 'svg-container sheets'} >
                                                    <svg id={`svg_${row.hash}`} width="180" height="180" />
                                                </div>
                                                <div className="buttons">
                                                    <button className="action-button cyanish" onClick={() => loadSaved(row.hash)} >{state.dataDisplay === 'saved counters' ? 'edit' : 'load'}</button>
                                                    <button className="action-button reddish" onClick={() => deleteSaved(row.hash)} >delete</button>
                                                </div>
                                            </td>
                                            {keys.map((key, index) =>


                                                <td key={`td_${index}`} className={`td-adjust-${rowIndex}`}>
                                                    {

                                                        typeof row[key] === 'string' || typeof row[key] === 'number' || row[key] === null ?
                                                            key === 'svg' ? <pre>{formatXml(row[key])}</pre> : row[key]
                                                            :
                                                            <div style={{ textAlign: 'left' }}><pre><code>{JSON.stringify(row[key], null, 2)}</code></pre></div>

                                                    }
                                                </td>
                                            )}

                                        </tr>
                                        <tr key={`row_adjuster_${rowIndex}`}>
                                            <td ref={ref => rowAdjusterRefs.current.push(ref)} id={`td_adjust_${rowIndex}`} className="row-grabber"><i id={`i_${rowIndex}`} onMouseDown={attachDrag} onClick={removeDraggable} className="material-icons">unfold_more</i></td>
                                        </tr>
                                    </React.Fragment>
                                )}

                            </tbody>
                        </table>
                        : <div className="there-are-none">{noItemsMessage}</div>}


                </div> : ''}


            {state.dataDisplay === 'installed svgs' ?
                <div className="data-container">
                    {keys && data && data.length > 0 ?
                        <table className={state.dataDisplay === 'installed svgs' ? `${state.dataDisplay.replace(' ', '-')}` : 'display-none'}>
                            <thead>
                                <tr>
                                    <th>rendered</th>
                                    {keys.map((key, index) => <th key={index}>{key}</th>)}
                                </tr>
                            </thead>
                            <tbody>

                                {data.map((row, rowIndex) =>
                                    <React.Fragment key={rowIndex}>
                                        <tr key={`row_${rowIndex}`}>

                                            <td ref={ref => renderRefs.current.push(ref)} key={`svg_${rowIndex}`} className={`rendered-column td-adjust-${rowIndex}`}>

                                                <div className="svg-container">
                                                    <svg id={`svg_custom_${row.svgKey}`} width="180" height="180" />
                                                </div>

                                                <div>{row.svgName}</div>
                                                <div className="buttons">
                                                    <button className="action-button reddish" onClick={() => deleteSvg(row.svgKey)} >delete</button>
                                                </div>
                                                <div className="rename-installed-svg">
                                                    rename:
                                                    <input type="text" id={`svgname_${row.svgKey}`} value={changeSvgNames[row.svgKey] ? changeSvgNames[row.svgKey].name : row.svgName} onChange={changeSvgName} />
                                                    <div>
                                                        <button id={`submitSvgNameChange_${row.svgKey}`} className={changeSvgNames.hasOwnProperty(String(row.svgKey)) && changeSvgNames[String(row.svgKey)].valid ? 'action-button blueish' : 'action-button disabled'} onClick={changeSvgNames.hasOwnProperty(String(row.svgKey)) && changeSvgNames[String(row.svgKey)].valid ? submitSvgNameChange : null}>submit</button>
                                                    </div>
                                                    <div className={changeSvgNames.hasOwnProperty(String(row.svgKey)) ? changeSvgNames[String(row.svgKey)].valid ? 'display-none' : 'already-taken' : 'display-none'}>That name is already taken.</div>
                                                    <div className={svgNameUpdatedKey === row.svgKey ? 'name-updated' : 'display-none'}>updated</div>
                                                </div>

                                            </td>
                                            {keys.map((key, index) =>


                                                <td key={`td_${index}`} className={`td-adjust-${rowIndex}`}>
                                                    {

                                                        typeof row[key] === 'string' || typeof row[key] === 'number' || row[key] === null ?
                                                            key === 'svgCode' ? <pre>{formatXml(row[key])}</pre> : row[key]
                                                            :
                                                            <div style={{ textAlign: 'left' }}><pre><code>{JSON.stringify(row[key], null, 2)}</code></pre></div>

                                                    }
                                                </td>
                                            )}

                                        </tr>
                                        <tr key={`row_adjuster_${rowIndex}`}>
                                            <td ref={ref => rowAdjusterRefs.current.push(ref)} id={`td_adjust_${rowIndex}`} className="row-grabber"><i id={`i_${rowIndex}`} onMouseDown={attachDrag} onClick={removeDraggable} className="material-icons">unfold_more</i></td>
                                        </tr>
                                    </React.Fragment>
                                )}

                            </tbody>
                        </table>
                        : <div className="there-are-none">{noItemsMessage}</div>}


                </div> : ''}




            {state.dataDisplay === 'installed images' ?
                <div className="data-container">
                    {keys && data && data.length > 0 ?
                        <table className={state.dataDisplay === 'installed images' ? `${state.dataDisplay.replace(' ', '-')}` : 'display-none'}>
                            <thead>
                                <tr>
                                    <th>rendered</th>
                                    {keys.map((key, index) => <th key={index}>{key}</th>)}
                                </tr>
                            </thead>
                            <tbody>

                                {data.map((row, rowIndex) =>
                                    <React.Fragment key={rowIndex}>
                                        <tr key={`row_${rowIndex}`}>

                                            <td ref={ref => renderRefs.current.push(ref)} key={`svg_${rowIndex}`} className={`rendered-column td-adjust-${rowIndex}`}>

                                                <div className="svg-container">
                                                    <svg id={`svg_custom_image_${row.svgKey}`} width="180" height="180" />
                                                </div>

                                                <div>{row.svgName}</div>
                                                <div className="buttons">
                                                    <button className="action-button reddish" onClick={() => deleteImage(row.svgKey)} >delete</button>
                                                </div>
                                                <div className="rename-installed-svg">
                                                    rename:
                                                    <input type="text" id={`svgname_${row.svgKey}`} value={changeSvgNames[row.svgKey] ? changeSvgNames[row.svgKey].name : row.svgName} onChange={changeSvgName} />
                                                    <div>
                                                        <button id={`submitSvgNameChange_${row.svgKey}`} className={changeSvgNames.hasOwnProperty(String(row.svgKey)) && changeSvgNames[String(row.svgKey)].valid ? 'action-button blueish' : 'action-button disabled'} onClick={changeSvgNames.hasOwnProperty(String(row.svgKey)) && changeSvgNames[String(row.svgKey)].valid ? submitSvgNameChange : null}>submit</button>
                                                    </div>
                                                    <div className={changeSvgNames.hasOwnProperty(String(row.svgKey)) ? changeSvgNames[String(row.svgKey)].valid ? 'display-none' : 'already-taken' : 'display-none'}>That name is already taken.</div>
                                                    <div className={svgNameUpdatedKey === row.svgKey ? 'name-updated' : 'display-none'}>updated</div>
                                                </div>

                                            </td>
                                            {keys.map((key, index) =>


                                                <td key={`td_${index}`} className={`td-adjust-${rowIndex}`}>
                                                    {

                                                        typeof row[key] === 'string' || typeof row[key] === 'number' || row[key] === null ?
                                                            key === 'svgCode' ? <pre>{formatXml(row[key])}</pre> : row[key]
                                                            :
                                                            <div style={{ textAlign: 'left' }}><pre><code>{JSON.stringify(row[key], null, 2)}</code></pre></div>

                                                    }
                                                </td>
                                            )}

                                        </tr>
                                        <tr key={`row_adjuster_${rowIndex}`}>
                                            <td ref={ref => rowAdjusterRefs.current.push(ref)} id={`td_adjust_${rowIndex}`} className="row-grabber"><i id={`i_${rowIndex}`} onMouseDown={attachDrag} onClick={removeDraggable} className="material-icons">unfold_more</i></td>
                                        </tr>
                                    </React.Fragment>
                                )}

                            </tbody>
                        </table>
                        : <div className="there-are-none">{noItemsMessage}</div>}


                </div> : ''}

            {state.dataDisplay === 'fonts' && !Utility.emptyCheck(fontsCustomSettings) ?
                <div className="data-container">
                    {keys && data && data.length > 0 ?
                        <table className="fonts">
                            <thead>
                                <tr>
                                    <th>sample</th>
                                    <th>font info</th>
                                </tr>
                            </thead>
                            <tbody>

                                {data.map((row, rowIndex) =>
                                    <React.Fragment key={rowIndex}>
                                        <tr id={`tr_font_${row.fontFamily.replaceAll(' ', '_')}`} key={`row_${rowIndex}`}>

                                            <td ref={ref => renderRefs.current.push(ref)} key={`svg_${rowIndex}`} className={`rendered-column td-adjust-${rowIndex}`}>
                                                <div className="controls">
                                                    <div className="controls-container">
                                                        <div>{row.fontFamily} sample text:</div>
                                                        <div>
                                                            <input id={`fontSampleText_${row.fontFamily.replaceAll(' ', '_')}@${row.fontStyle}@${row.fontWeight}`} type="text" onChange={changeFontSampleText} value={fontsCustomSettings[row.fontFamily.replaceAll(' ', '_') + '@' + row.fontStyle + '@' + row.fontWeight]['sampleText']} />
                                                        </div>
                                                    </div>
                                                    <div className="control-inputs">
                                                        <label><input id={`boldFont_${row.fontFamily.replaceAll(' ', '_')}@${row.fontStyle}@${row.fontWeight}`} type="checkbox" name="checkbox" value="bold" onChange={changeBoldFont} />Bold</label>
                                                        <label><input id={`italicFont_${row.fontFamily.replaceAll(' ', '_')}@${row.fontStyle}@${row.fontWeight}`} type="checkbox" name="checkbox" value="italic" onChange={changeItalicFont} />Italic</label>
                                                        <input id={`fontSize_${row.fontFamily.replaceAll(' ', '_')}@${row.fontStyle}@${row.fontWeight}`} type="range" min="1" max="100" value={fontsCustomSettings[row.fontFamily.replaceAll(' ', '_') + '@' + row.fontStyle + '@' + row.fontWeight]['fontSize']} className="slider" onChange={changeFontSize} /> {fontsCustomSettings[row.fontFamily.replaceAll(' ', '_') + '@' + row.fontStyle + '@' + row.fontWeight]['fontSize']}px
                                                    </div>
                                                </div>
                                                <div className="font-sample"
                                                    id={`fontSample_${rowIndex}`}
                                                    style={{
                                                        fontFamily: "'" + row.fontFamily + "'",
                                                        fontSize: fontsCustomSettings[row.fontFamily.replaceAll(' ', '_') + '@' + row.fontStyle + '@' + row.fontWeight]['fontSize'] + "px",
                                                        fontWeight: fontsCustomSettings[row.fontFamily.replaceAll(' ', '_') + '@' + row.fontStyle + '@' + row.fontWeight]['boldFont'] ? 'bold' : 'normal',
                                                        fontStyle: fontsCustomSettings[row.fontFamily.replaceAll(' ', '_') + '@' + row.fontStyle + '@' + row.fontWeight]['italicFont'] ? 'italic' : 'normal'
                                                    }}>
                                                    {fontsCustomSettings[row.fontFamily.replaceAll(' ', '_') + '@' + row.fontStyle + '@' + row.fontWeight]['sampleText']}
                                                </div>

                                            </td>
                                            <td key={`td_2`} className={`td-adjust-${rowIndex}`}>
                                                {keys.map((key, index) =>

                                                    <div key={`kvf_${index}`} className="key-value-flex">
                                                        <div>
                                                            {key}
                                                        </div>
                                                        <div>
                                                            {row[key]}
                                                            {key === 'fontFamily' && row[key] !== 'Material Icons' ? <span><button className="action-button reddish" onClick={() => clickDeleteFont(row.fontFamily.replaceAll(' ', '_') + '@' + row.fontStyle + '@' + row.fontWeight)}>delete font</button></span> : ''}
                                                        </div>
                                                    </div>

                                                )}
                                            </td>
                                        </tr>
                                        <tr id={`tr_font_adjuster_${row.fontFamily.replaceAll(' ', '_')}`} key={`row_adjuster_${rowIndex}`}>
                                            <td ref={ref => rowAdjusterRefs.current.push(ref)} id={`td_adjust_${rowIndex}`} className="row-grabber"><i id={`i_${rowIndex}`} onMouseDown={attachDrag} onClick={removeDraggable} className="material-icons">unfold_more</i></td>
                                        </tr>
                                    </React.Fragment>
                                )}

                            </tbody>
                        </table>
                        : <div className="there-are-none">{noItemsMessage}</div>}


                </div> : ''}




        </div>
    );
}
export default DataDisplay