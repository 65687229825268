import React, { useState, useEffect } from 'react'

import './ExplodeCounter.scss'

const ExplodeCounter = ({ useText, explodeState }) => {
    const [active, setActive] = useState(false)

    const explode = () => {
        setActive(!active)
    }

    useEffect(() => {
        explodeState(active)
    },[active]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="explode-counter" id="explodeCounter">
        <button className={ active ? 'action-button orangish' : 'action-button cyanish'} onClick={explode} >{useText}</button>
        </div>
    )
}
export default ExplodeCounter