import React, { useState, useEffect } from 'react'
import './XButton.scss'
const XButton = ({ r, closeAction }) => {
    const [closeBoxCoords, setCloseBoxCoords] = useState({ cx: 0, cy: 0, r: 0, x1: 0, y1: 0, x2: 0, y2: 0, x3: 0, y3: 0, x4: 0, y4: 0, width: 0, height: 0 })

    useEffect(() => {
        buildX()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const buildX = () => {
        r = Number(r)
        let offset = Number(r + 2 )
        let cx = offset
        let cy = offset

        let x = Math.cos(0.785398) * r
        let y = Math.sin(0.785398) * r
        x *= 0.8
        y *= 0.8

        let x1 = -x
        let y1 = -y
        let x2 = x
        let y2 = y

        let x3 = x
        let y3 = -y
        let x4 = -x
        let y4 = y

        x1 += offset
        y1 += offset
        x2 += offset
        y2 += offset
        x3 += offset
        y3 += offset
        x4 += offset
        y4 += offset

        let width = r * 2
        let height = r * 2

        setCloseBoxCoords({ cx, cy, r, x1, y1, x2, y2, x3, y3, x4, y4, width, height })
    }

    return (
        <div className="x-button" onClick={closeAction}>
            <svg version="1.1"
                baseProfile="full"
                width={r*2+4}
                height={r*2+4}
                
                xmlns="http://www.w3.org/2000/svg">
                    
                <circle cx={closeBoxCoords.cx}
                    cy={closeBoxCoords.cy}
                    r={closeBoxCoords.r}
                    strokeWidth="1" />

                <line
                    x1={closeBoxCoords.x1}
                    y1={closeBoxCoords.y1}
                    x2={closeBoxCoords.x2}
                    y2={closeBoxCoords.y2} strokeWidth="2" />
                <line
                    x1={closeBoxCoords.x3}
                    y1={closeBoxCoords.y3}
                    x2={closeBoxCoords.x4}
                    y2={closeBoxCoords.y4} strokeWidth="2" />
            </svg>
        </div>
    );
}
export default XButton