import React, { useState, useEffect, useContext, useRef } from 'react'
import { StoreContext } from "../../context/StoreContext"
import Utility from "../../objects/Utility"
import XButton from '../XButton/XButton'
import JSZip from 'jszip'
import './RestoreWorkspace.scss'

const RestoreWorkspace = () => {
    const { state, actions } = useContext(StoreContext)
    const [restoreResult, setRestoreResult] = useState({ state: null, message: '' })
    const [stackRestore, setStackRestore] = useState(null)
    const restoreWorkspaceRef = useRef(null)

    useEffect(() => {
        setStackRestore({
            activeLayerValues: { data: null, dexieLoaded: false },
            fonts: { data: null, dexieLoaded: false },
            layers: { data: null, dexieLoaded: false },
            savedCounters: { data: null, dexieLoaded: false },
            savedSheets: { data: null, dexieLoaded: false },
            sheetSettings: { data: null, dexieLoaded: false },
            slots: { data: null, dexieLoaded: false },
            svgs: { data: null, dexieLoaded: false }
        })

        let copyOfRef = restoreWorkspaceRef.current // linter said I should copy this to a variable so the cleanup function can be reliable.
        restoreWorkspaceRef.current.addEventListener('mouseup', function (e) {
            e.stopPropagation();
        });

        return () => {
            if (copyOfRef) {
                copyOfRef.removeEventListener('mouseup', function (e) {
                    e.stopPropagation();
                });
            }
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps



    useEffect(() => {
        exitView()
    }, [state.mouseClick]) // eslint-disable-line react-hooks/exhaustive-deps

    const reset = () => {
        window.scrollTo(0, 0)
        document.getElementById("workspaceFile").value = "";
        setRestoreResult({ state: null, message: '' })
    }

    const closeAction = () => {
        exitView()
    }

    useEffect(() => {

    }, [state.installView])

    const exitView = () => {
        if (state.installView === 'restore workspace') {
            reset()
            actions.installView('')
        }
    }

    const loadFromFile = evt => {
        setRestoreResult({ state: null, message: '' })
        let file = evt.target.files[0]

        JSZip.loadAsync(file).then((zip) => {
            Object.keys(zip.files).forEach((filename) => {
                zip.files[filename].async('string').then(async (fileData) => {
                    let data = null
                    try {
                        data = JSON.parse(fileData)
                    }
                    catch (e) {
                        setRestoreResult({ state: false, message: 'error loading file' })
                        return
                    }
                    if (data.app === undefined || data.app !== 'snapCounter') {
                        setRestoreResult({ state: false, message: 'this file does not appear to be a snapCounter file' })
                        return
                    }
                    if (data.type === undefined || data.type !== 'workspace') {
                        setRestoreResult({ state: false, message: 'this file does not have workspace data' })
                        return
                    }
                    if (data.workspace === undefined) {
                        setRestoreResult({ state: false, message: 'there is no data to reconstruct the workspace.' })
                        return
                    }

                    if (data.workspace.activeLayerValues &&
                        data.workspace.fonts &&
                        data.workspace.layers &&
                        data.workspace.savedCounters &&
                        data.workspace.savedSheets &&
                        data.workspace.sheetSettings &&
                        data.workspace.slots &&
                        data.workspace.svgs) {
                        // we have data
                        let activeLayerValues = data.workspace.activeLayerValues
                        let fonts = data.workspace.fonts
                        let layers = data.workspace.layers
                        let savedCounters = data.workspace.savedCounters
                        let savedSheets = data.workspace.savedSheets
                        let sheetSettings = data.workspace.sheetSettings
                        let slots = data.workspace.slots
                        let svgs = data.workspace.svgs
                        let _stackRestore = { ...stackRestore }
                        _stackRestore.layers.data = layers
                        _stackRestore.savedCounters.data = savedCounters
                        _stackRestore.savedSheets.data = savedSheets
                        _stackRestore.sheetSettings.data = sheetSettings
                        _stackRestore.fonts.data = fonts
                        _stackRestore.svgs.data = svgs
                        _stackRestore.slots.data = slots
                        _stackRestore.activeLayerValues.data = activeLayerValues
                        setStackRestore(_stackRestore)
                    }
                    else {
                        setRestoreResult({ state: false, message: 'The data seems to be corrupted!' })
                        return
                    }

                })
            })

        })
    }

    useEffect(() => {
        if (stackRestore && state.installView) {
            let notReadyItems = Object.values(stackRestore).filter(v => v.data === null)
            if (notReadyItems.length === 0) {
                loadUpDexie()
            }
        }
    }, [stackRestore]) // eslint-disable-line react-hooks/exhaustive-deps

    const loadUpDexie = async () => {
        let keys = Object.keys(stackRestore)
        for (let i = 0; i < keys.length; i++) {
            await Utility.dexieClearTable(state.dexie, keys[i])
        }
        for (const [store, value] of Object.entries(stackRestore)) {
            let data = value.data

            // these come in an array of objects with their hash values as keys and the data as the value, ex: {13213213: {"somekey":val,...}. 1231231: ... 
            //We need to make it a simple array for dexie. The hash value is duplicated in the values.
            if (store === 'savedCounters' || store === 'savedSheets') {
                data = Object.values(data)
            }

            // activeLayerValues in state is store as an object with their pair keys: [layerKey]_[inputKey], ie: 2_214.
            // We have for dexie identified these combined key with the name "lik" (layer key) I forget what the i was for. Don't matter.
            if (store === 'activeLayerValues') {
                let newArray = []
                for (const [key, value] of Object.entries(data)) {
                    newArray.push({ lik: key, value })
                }
                data = newArray
            }
            if (store === 'sheetSettings') { // this comes in as a single object. We need to make it an array of length 1.
                data = [data]
            }

            let db = state.dexie
            if (store === 'fonts') {
                for (let d = 0; d < data.length; d++) {
                    await Utility.dexieAddPutFont(db, data[d])
                }
            }
            else { // we feed in the primaryKeyName cause we need to avoid the "duplicate key" warnings on add and put.
                let primaryKeyName = db[store].schema.primKey.name
                for (let d = 0; d < data.length; d++) {
                    await Utility.dexieAddPutItem(db, store, data[d], primaryKeyName)
                }
            }
        }

        actions.restoreWorkspace(true)
    }

    return (
        <div ref={restoreWorkspaceRef} className={state.installView === 'restore workspace' ? 'restore-workspace' : 'display-none'}>

            <div className="restore-workspace-top">
                <div className="title">Restore Workspace</div>
                <div className="close-button">
                    <XButton r="12" closeAction={closeAction} />
                </div>
            </div>
            <div className="intro">
                <p>
                    You can select a previously saved Workspace backup to load into the app.
                </p>
                <p>
                    This will replace all the data in the app with data in the backup, which includes<br />
                    the menus, counter, sheet, sheet settings, saved counters, saved sheets, and fonts.
                </p>
            </div>

            <div className="form-area">
                <input type="file" id="workspaceFile" accept=".zip" onChange={loadFromFile} />
            </div>
            {restoreResult.message ? <div className={restoreResult.state ? 'restore-result' : 'restore-result error'}>{restoreResult.message}</div> : ''}


        </div>
    );
}
export default RestoreWorkspace