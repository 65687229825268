import React, { useState, useContext, useEffect } from 'react'
import { StoreContext } from "../../context/StoreContext"
import Utility from "../../objects/Utility"
import GridDisplay from "../GridDisplay/GridDisplay"
import GridOptions from "../GridOptions/GridOptions"
import Counter from "../Counter/Counter"
import SaveCounter from "../SaveCounter/SaveCounter"
import LoadCounter from "../LoadCounter/LoadCounter"
import MoveCounter from "../MoveCounter/MoveCounter"
import ExplodeCounter from "../ExplodeCounter/ExplodeCounter"
import ClearCounter from "../ClearCounter/ClearCounter"
import OutOfBoundsMask from "../OutOfBoundsMask/OutOfBoundsMask"
import ExplodeDragger from "../ExplodeDragger/ExplodeDragger"
import './CounterDraw.scss'
const CounterDraw = () => {
    const { state } = useContext(StoreContext)
    const [showingGrid, setShowingGrid] = useState(false)
    const [usingGridColor, setUsingGridColor] = useState('light')
    const [saveCounterText, setSaveCounterText] = useState('Save Counter')
    const [loadCounterText, setLoadCounterText] = useState('Load Counter')
    const [moveCounterText, setMoveCounterText] = useState('Move to Sheet')
    const [explodeCounterText, setExplodeCounterText] = useState('Explode')
    const [explodeState, setExplodeState] = useState(false)

    const [perspective] = useState('3500px')
    const [rotateX, setRotateX] = useState('75deg')
    const [rotateY] = useState('0deg')
    const [startY, setStartY] = useState(35)
    const [translateY, setTranslateY] = useState(80)
    const [scaleX] = useState('0.9')
    const [scaleY] = useState('1.5')
    const [t, setT] = useState({})

    useEffect(() => {
        let newT = { perspective, rotateX, rotateY, startY, translateY, scaleX, scaleY }
        setT(newT)
    }, [state.activeLayerValues, explodeState, perspective, rotateX, rotateY, startY, translateY, scaleX, scaleY])

    useEffect(() => {
        // let ele = document.getElementById('layerKey1')
        let elementIds = []

        // get the active layers by layerOrder, since we need to apply the tranformY value from bottom up
        let layersClone = [...state.layers]
        layersClone.sort((a, b) => a.layerOrder - b.layerOrder)

        let activeLayerKeys = Utility.activeLayerKeys(state.activeLayerValues)
        let orderedVisibleLayers = []
        layersClone.forEach(lc => {
            if (activeLayerKeys.includes(lc.layerKey)) {
                orderedVisibleLayers.push(lc.layerKey)
            }
        })

        orderedVisibleLayers.forEach((layerKey) => {
            elementIds.push('layerKey' + layerKey)
        })

        // use number of active layers to determine how much to space them, since we have limited height.
        let numberOfLayersToShow = elementIds.length
        let elevateFactor = translateY / numberOfLayersToShow
        let ele = null
        let elevate = startY
        elementIds.forEach(elementId => {
            ele = document.getElementById(elementId)
            if (explodeState) {
                ele.style.transform = `
            translateY(${elevate}%)
            scaleY(${t.scaleY})
            perspective(${t.perspective})
            rotateX(${t.rotateX})
            scaleX(${t.scaleX})
            `;
            }
            else {
                ele.style.transform = 'none'
            }
            elevate -= elevateFactor
        })
    }, [t]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

        setSaveCounterText('save')
        setLoadCounterText('load')
        setMoveCounterText('move')
        setExplodeCounterText('exp')

        if (state.windowWidthHeight.width > 1060) {
            setSaveCounterText('Save Counter')
        }
        if (state.windowWidthHeight.width > 1140) {
            setLoadCounterText('Load Counter')
        }
        if (state.windowWidthHeight.width > 1310) {
            setMoveCounterText('Move to Sheet')
        }
        if (state.windowWidthHeight.width > 1390) {
            setExplodeCounterText('Explode')
        }

    }, [state.windowWidthHeight])

    const explodeStateChange = active => {
        setExplodeState(active)
    }

    const explodeDistance = d => {
        let newRotateX = 70 + ((d - 50) * 0.3)
        setRotateX(newRotateX + 'deg')
        let newStartY = 17.5 + ((d - 50) * 0.34)
        if (newStartY < 15) {
            newStartY += (42 - d) * 0.05
        }
        setStartY(newStartY)
        let newTranslateY = 44 + ((d - 50) * 0.7)
        setTranslateY(newTranslateY)
    }

    return (
        <div className="counter-draw">
            <div className="counter-draw-operations">
                <GridOptions showingGrid={setShowingGrid} usingGridColor={setUsingGridColor} />
                <SaveCounter useText={saveCounterText} />
                <LoadCounter useText={loadCounterText} />
                <MoveCounter useText={moveCounterText} />
                <ExplodeCounter useText={explodeCounterText} explodeState={explodeStateChange} showExplodeDragger={explodeState} />
                <ClearCounter />
            </div>
            <div className={explodeState ? 'counter-draw-container exploded' : 'counter-draw-container'}>
                <Counter />
                <OutOfBoundsMask />
                <GridDisplay showingGrid={showingGrid} usingGridColor={usingGridColor} />
                <ExplodeDragger showExplodeDragger={explodeState} explodeDistance={explodeDistance} />
            </div>
        </div>
    );
}
export default CounterDraw