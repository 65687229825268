import React, { useState, useEffect, useContext, useRef } from 'react'
import Snap from 'snapsvg-cjs'
import { StoreContext } from "../../context/StoreContext"
import XButton from '../XButton/XButton'
import Utility from "../../objects/Utility"
import './InstallSvg.scss'

const InstallSvg = () => {
    const { state, actions } = useContext(StoreContext)
    const [svgLoadedResult, setSvgLoadedResult] = useState('')
    const [svgLoadError, setSvgLoadError] = useState(false)
    const [svgLoaded, setSvgLoaded] = useState(false)
    const [newSvgName, setNewSvgName] = useState('')
    const [acceptedSvg, setAcceptedSvg] = useState('')
    const [nameAlreadyUsed, setNameAlreadyUsed] = useState('')
    const [svgInstalled, setSvgInstalled] = useState(false)
    const [originalViewBox, setOriginalViewBox] = useState('')
    const [viewBoxArray, setViewBoxArray] = useState([0, 0, 0, 0])
    const [viewBoxArrayAllowed, setViewBoxArrayAllowed] = useState([0, 0, 0, 0])
    const [viewBoxArrayValid, setViewBoxArrayValid] = useState([true, true, true, true])
    const [importPaper, setImportPaper] = useState(null)
    const [uniquePrepend, setUniquePrepend] = useState('')
    const [nativeId, setNativeId] = useState(null)
    const installSvgRef = useRef(null)

    useEffect(() => {
        let copyOfRef = installSvgRef.current // linter said I should copy this to a variable so the cleanup function can be reliable.
        installSvgRef.current.addEventListener('mouseup', function (e) {
            e.stopPropagation();
        });

        return () => {
            if (copyOfRef) {
                copyOfRef.removeEventListener('mouseup', function (e) {
                    e.stopPropagation();
                });
            }
        }

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        reset()
        exitView()
    }, [state.mouseClick]) // eslint-disable-line react-hooks/exhaustive-deps

    const changeNewSvgName = evt => {
        let svgName = evt.target.value
        setNewSvgName(svgName)
    }

    const closeAction = () => {
        exitView()
    }

    const exitView = () => {
        if (state.installView) {
            reset()
            window.scrollTo(0, 0)
            actions.installView('')
        }
    }

    const tryAgain = () => {
        if (state.installView !== '') {
            reset()
            window.scrollTo(0, 0)
        }
    }

    const loadFromFileOnChange = evt => {
        let file = evt.target.files[0]
        let testString = file.name.toLowerCase()
        let fileName = file.name
        if (testString.endsWith('.svg')) {
            fileName = testString.substring(0, testString.length - 4)
        }
        fileName = fileName.length > 20 ? fileName.substring(0,20) : fileName
        setNewSvgName(fileName)
        var reader = new FileReader();
        reader.onload = function (event) {
            setSvgLoadedResult(event.target.result)
        }
        reader.readAsText(file)
    }

    useEffect(() => {
        if (newSvgName) {
            if (newSvgName) {
                checkNameIsAvailable()
            }
        }
    }, [newSvgName])  // eslint-disable-line react-hooks/exhaustive-deps

    const checkNameIsAvailable = async () => {
        const names = await state.dexie.svgs.orderBy('svgName').keys();
        let isContained = arrContainsCaseInsensitiveStr(names, newSvgName)
        if (isContained) {
            setNameAlreadyUsed(true)
            return
        }
        setNameAlreadyUsed(false)
    }

    const extractNativeViewBoxArray = str => {
        // find out if there is a viewBox coming in on this svg.
        let viewBoxString = ''
        let _viewBoxNativeArray = null
        let svgTag = extractTopSvgTag(str)
        if (svgTag) {
            let beginningViewboxStringIndex = svgTag.indexOf('viewBox="')
            if (beginningViewboxStringIndex > -1) {
                let endingViewboxStringIndex = svgTag.indexOf('"', beginningViewboxStringIndex + 1)
                if ((beginningViewboxStringIndex > -1 &&
                    endingViewboxStringIndex > -1) &&
                    (endingViewboxStringIndex > beginningViewboxStringIndex)) {
                    let viewboxEndIndex = svgTag.indexOf('"', beginningViewboxStringIndex + 10)
                    if (viewboxEndIndex > -1) {
                        viewBoxString = svgTag.substring(beginningViewboxStringIndex + 9, viewboxEndIndex)
                        viewBoxString = viewBoxString.replaceAll(',', ' ')
                        viewBoxString = viewBoxString.replace(/  +/g, ' ');
                        _viewBoxNativeArray = viewBoxString.split(' ').map(n => Number(n))
                        if (!_viewBoxNativeArray ||
                            !Array.isArray(_viewBoxNativeArray) ||
                            _viewBoxNativeArray.length !== 4) {
                            _viewBoxNativeArray = null
                        }
                    }
                }
            }
        }

        return _viewBoxNativeArray
    }

    const extractNativeWidthHeightStrings = str => {
        let nativeWidthString = null
        let nativeHeightString = null

        let svgTag = extractTopSvgTag(str)
        if (svgTag) {
            let splitted = Utility.strSplitOnNonEnclosedSpaces(svgTag)
            splitted.forEach(str => {
                if (str.startsWith('width=')) {
                    //nativeWidth = Utility.extractNumber(str)
                    nativeWidthString = str
                }
                if (str.startsWith('height=')) {
                    //nativeHeight = Utility.extractNumber(str)
                    nativeHeightString = str
                }
            })

        }

        if (nativeWidthString && nativeHeightString) {
            return { widthString: nativeWidthString, heightString: nativeHeightString }
        }
        return null
    }

    const extractNativeId = str => {
        let idResult = null
        let svgTag = extractTopSvgTag(str)
        if (svgTag) {
            let splitted = Utility.strSplitOnNonEnclosedSpaces(svgTag)
            for (let i = 0; i < splitted.length; i++) {
                let testStr = splitted[i]
                if (testStr.startsWith('id=') || testStr.startsWith('ID=')) {
                    let splittedId = testStr.split('=')
                    idResult = splittedId[1].replaceAll('"', '')
                    break
                }
            }
        }

        return idResult
    }

    const extractTopSvgTag = str => {
        let startSvgTag = str.indexOf('<svg')
        if (startSvgTag > -1) {
            let endSvgTag = str.indexOf('>', startSvgTag + 1)
            if ((startSvgTag > -1 && endSvgTag > -1) && (endSvgTag > startSvgTag)) {
                return str.substring(startSvgTag, endSvgTag)
            }
        }
        return null
    }

    useEffect(() => {
        // the method I use to import and manage svgs is to append the incoming svg string data to the snap paper.
        // if there is a imported viewBox, we use that to determine the width height (aspect ratio'd to 200x200) for 
        // the snap paper.
        // Then I see if there is width/height and a viewport stated in the imported svg.
        // I use the width/height of the imported svg to determine the width height of the snap paper, but if there is no
        // imported width/height determined, then I used bbox to get the width/height.

        if (svgLoadedResult) {
            let _paper = importPaper
            if (_paper === null) {
                _paper = Snap("#importSvg_") 
                setImportPaper(_paper)
            }
            else {
                _paper.clear()
            }
            let svgString = svgLoadedResult.trim()
            let nativeViewBoxArray = null
            let nativeWidthHeightStrings = null
            let viewBoxString = null
            let nativeId = null
            if (svgString.includes('<svg')) {
                // if the svg has a viewBox setting, we need to know what it is.
                nativeViewBoxArray = extractNativeViewBoxArray(svgString)
                nativeWidthHeightStrings = extractNativeWidthHeightStrings(svgString)
                nativeId = extractNativeId(svgString)

                // if there isn't an id on it, give it one.
                if( nativeId === null ) {
                    nativeId = 'c' + Utility.randomString(4)
                    svgString = svgString.replace('<svg', '<svg id="' + nativeId + '" ')
                }
                setNativeId(nativeId)

                // remove the native width and height, since we are setting that at the
                // top (snap) svg tag.
                if (nativeWidthHeightStrings) {
                    svgString = svgString.replace(nativeWidthHeightStrings.widthString, '')
                    svgString = svgString.replace(nativeWidthHeightStrings.heightString, '')
                }


                // deploy the incoming svg
                let parsed = Snap.parse(svgString)
                _paper.append(parsed)
                // now we have to identify all the ids used, and replace them
                // with unique ids, so that each instance of this imported svg
                // does not conflict the ids of other copies of this svg.
                let foundIds = Utility.distillIdsFromSnapElement(_paper)
                // create a new unique string and stick it onto all the ids of the incoming svg
                let _uniquePrepend = 'c' + Utility.randomString(8)
                _uniquePrepend.replaceAll('_', '') // get rid of any underscores cause we need to use it as a known separator.
                _uniquePrepend += '_'
                // change all the ids with their unique versions.
                svgString = Utility.replaceIdsInSvgString(foundIds, svgString, _uniquePrepend)
                setUniquePrepend(_uniquePrepend)
 
                // ok lets parse, clear the paper, and deploy the svg again
                parsed = Snap.parse(svgString)
                _paper.clear()
                _paper.append(parsed)
 
                let paperWidth = 0
                let paperHeight = 0
                let viewBox_x = 0
                let viewBox_y = 0
                let nw = 0
                let nh = 0
                if (nativeViewBoxArray) {
                    // figure out width and height needed
                    nw = nativeViewBoxArray[2]
                    nh = nativeViewBoxArray[3]
                    viewBoxString = nativeViewBoxArray.join(' ')
                }
                else {
                    let svgBbox = _paper.getBBox({ fill: false, clipped: true })
                    viewBox_x = svgBbox.x
                    viewBox_y = svgBbox.y
                    nw = svgBbox.width
                    nh = svgBbox.height
                    viewBoxString = viewBox_x + ' ' + viewBox_y + ' ' + Utility.roundFloat(nw, 1) + ' ' + Utility.roundFloat(nh, 1)
                }
                if (nw > nh) {
                    paperWidth = 200
                    paperHeight = (nh / nw) * 200
                }
                else {
                    paperHeight = 200
                    paperWidth = (nw / nh) * 200
                }
                _paper.attr({ width: Utility.roundFloat(paperWidth, 1), height: Utility.roundFloat(paperHeight, 1) })

                var svgSource = _paper.toString()
                if (svgSource) {
                    setAcceptedSvg(svgSource)
                    setSvgLoaded(true)
                    setSvgLoadError(false)
                    setOriginalViewBox(viewBoxString)
                    setViewBoxArray(viewBoxString.split(' ').map(n => Utility.roundFloat(Number(n),1)))
                    return
                }
                
            }

            // setSvgLoaded(false)
            // setSvgLoadError(true)
        }
    }, [svgLoadedResult])  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (acceptedSvg) {
            let ele = document.getElementById("newSvgNameInput")
            if (ele) {
                //  ele.focus()
            }
        }
    }, [acceptedSvg])  // eslint-disable-line react-hooks/exhaustive-deps

    const reset = () => {




        setSvgLoadedResult('')
        setSvgLoadError(false)
        setSvgLoaded(false)
        setNewSvgName('')
        if (importPaper) {
            importPaper.clear()
            importPaper.attr({ width: 200, height: 200 })
            importPaper.attr({viewBox: ''})
        }
        setNewSvgName('')
        setAcceptedSvg('')
        setNameAlreadyUsed('')
        setSvgInstalled(false)


        setOriginalViewBox('')
        setViewBoxArray([0, 0, 0, 0])
        setViewBoxArrayAllowed([0, 0, 0, 0])
        setViewBoxArrayValid([true, true, true, true])
        setImportPaper(null)
        setUniquePrepend('')
        setNativeId(null)



        let fileInputElement = document.getElementById('installSvgFile')
        if (fileInputElement) {
            fileInputElement.value = ''
        }
        if (importPaper) {
            importPaper.attr({ width: "200", height: "200" })
          //  importPaper.attr({ viewBox: '0 0 200 200' }); why the f*** was I doing this???
        }
    }

    const arrContainsCaseInsensitiveStr = (arr, str) => {
        return arr.filter(astr => {
            return astr.toLowerCase() === str.toLowerCase();
        }).length > 0 ? true : false
    }

    const installCustomSvg = async () => {
        let useSvgName = newSvgName

        ///////////////////////////////////////////////////////////////////////
        // insert new svg into dexie svgs data structure
        ///////////////////////////////////////////////////////////////////////
        let replaceIdString = useSvgName.replaceAll(' ', '_')
        // generated new uniquePrepend since we dont want it to conflict with the copy
        // of the svg that is displayed on the installSvg dialog.
        let _uniquePrepend = 'c' + Utility.randomString(8)
        _uniquePrepend.replaceAll('_', '') // get rid of any underscores cause we need to use it as a known separator.
        _uniquePrepend += '_'
        let updatedAcceptedSvg = acceptedSvg.replaceAll(uniquePrepend, _uniquePrepend)
        updatedAcceptedSvg = updatedAcceptedSvg.replace('id="importSvg_"', 'id="' +_uniquePrepend + replaceIdString + '"')

        let last = await state.dexie.svgs.orderBy('svgKey').last()
        let nextKey = last.svgKey + 1
        let svgItem = {
            svgKey: nextKey,
            svgCode: updatedAcceptedSvg,
            svgName: useSvgName,
            uniquePrepend: _uniquePrepend
        }
        await Utility.dexiePutItem(state.dexie, 'svgs', svgItem)

        ///////////////////////////////////////////////////////////////////////
        // insert new svg into state.svgs data structure
        ///////////////////////////////////////////////////////////////////////
        let found = state.svgs.find(s => s.svgKey === svgItem.svgKey)
        if (!found) {
            actions.svgsAdd(svgItem)
        }

        ///////////////////////////////////////////////////////////////////////
        // insert new svg into layers svg_group list array
        ///////////////////////////////////////////////////////////////////////
        let foundLayer = state.layers.find(ly => ly.layerName === 'custom svgs')
        let customSvgsLayerKey = foundLayer.layerKey
        let customSvgsLayers = state.layers.filter(ly => ly.layerKey === customSvgsLayerKey || ly.parentLayerKey === customSvgsLayerKey)
        customSvgsLayers.forEach(_layer => {
            let input = _layer.inputs.find(li => li.named === 'svgKey')
            if (input) {
                if (input.list.includes(nextKey) === false) {
                    input.list.push(nextKey)
                }
            }
            // lets also set hidden to 0, since the user probably wants to be able to see if it inserted correctly.
            _layer.layerHidden = 0
            actions.layerUpdate(JSON.parse(JSON.stringify(_layer)))
        })

        for (let i = 0; i < customSvgsLayers.length; i++) {
            let layer = customSvgsLayers[i]
            ///////////////////////////////////////////////////////////////////////
            // insert new svg into dexie custom svgs layers' svg_group list array
            ///////////////////////////////////////////////////////////////////////
            await Utility.updateDexieStoreSingleItem(state.dexie, 'layers', layer, 'layerKey')
        }

        setSvgInstalled(true)
    }

    const changeViewbox = evt => {
        let forParameter = evt.target.id.replace('viewbox_', '')
        let obj = {
            x: Number(viewBoxArray[0]),
            y: Number(viewBoxArray[1]),
            width: Number(viewBoxArray[2]),
            height: Number(viewBoxArray[3])
        }
        obj[forParameter] = evt.target.value.trim()
        let values = Object.values(obj)
        setViewBoxArray(values)
    }

    useEffect(() => {
        if (viewBoxArray) {
            // check items in viewBoxArray to see if they are numerics. If not, we will make the input box pinkish
            // to indicate the problem.
            let _viewBoxArrayValid = [false, false, false, false]
            viewBoxArray.forEach((v, index) => {
                _viewBoxArrayValid[index] = Utility.isNumeric(v)
            })
            setViewBoxArrayValid(_viewBoxArrayValid)
        }
    }, [viewBoxArray])  // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // check to see if the values in viewBoxArray are valid.
        // special case needed for numbers like NNN. cause javascript says the dot at the end is a valid number.
        // but svg viewBox will zonk out the viewBox if a number(string) has a trailing . on it.
        let allValid = viewBoxArrayValid.reduce((valid, bool) => valid && bool)
        if (allValid && importPaper) {
            let _viewBoxArray = viewBoxArray.map( n => {
                if( typeof n === 'string' && n.endsWith('.') ) {
                    return n.substring(0,n.length - 1)
                }
                return n
            })
            let viewBoxString = _viewBoxArray.join(' ')

            setViewBoxArrayAllowed(viewBoxArray)
            let id = uniquePrepend + nativeId
            id = id.trim()

            let ele = document.getElementById(id)
            ele.setAttribute('viewBox', viewBoxString);

            var svgSource = importPaper.toString()
            if (svgSource) {
                setAcceptedSvg(svgSource)
            }

        }
    }, [viewBoxArrayValid]) // eslint-disable-line react-hooks/exhaustive-deps

    const resetViewbox = () => {
        setViewBoxArray(originalViewBox.split(' ').map(n => Number(n)))
    }

    return (
        <div ref={installSvgRef} className={state.installView === 'install svg' ? 'install-svg' : 'display-none'}>
            <div className="install-svg-top">
                <div className="title">Install Svg</div>
                <div className="close-button">
                    <XButton r="12" closeAction={closeAction} />
                </div>
            </div>

            <div className="intro">
                <p>
                    You can install your own svg images into this app. They will become
                    selectable in the "Custom Svgs" layer menu option, for placing on your counter.

                </p>
            </div>

            <div className="install-contain" >
                <div className="install">
                
                    <div id="importSvgContainer" className={svgLoadError ? 'svg-container svgLoadError' : svgInstalled ? 'svg-container installed' : 'svg-container'}>
                        <div className={svgInstalled ? 'background-for-svg installed' : 'background-for-svg'}>
                            <svg id="importSvg_" width="200" height="200" />
                        </div>
                    </div>

                    {svgInstalled ? <div className="svg-installed">
                        Your svg has been installed into the Custom Svgs layer.
                    </div>
                        :
                        <div>
                            <div className={svgLoaded || svgLoadError ? 'display-none' : 'input-svg-select'}>
                                <div>Choose a svg file from your computer -</div>
                                <input type="file" id="installSvgFile" accept=".svg" onChange={loadFromFileOnChange} />
                            </div>

                            <div className={svgLoadError ? 'svg-loaded-error' : 'display-none'}>
                                <p>
                                    There was an error loading your svg. Is it really a svg file?
                                </p>
                                <p>Is it perhaps in zip format? If so please unzip it first.</p>
                                <p>
                                    If it is a valid svg file, then it may be too complicated, or too large, for the app to use.
                                </p>
                                <p>
                                    <button className="action-button" onClick={reset}>try again</button>
                                </p>
                            </div>

                            <div className={svgLoaded ? 'input-svg-select' : 'display-none'}>

                                <div className="adjust-viewbox">
                                    <div>You can customize the viewBox if you wish to show more or less of the svg.</div>
                                    <div className="current-viewbox">viewBox="{viewBoxArrayAllowed.join(' ')}"
                                        <span className={originalViewBox === viewBoxArray.join(' ') ? 'no-change' : ''} onClick={resetViewbox}>reset</span>
                                    </div>
                                    <div className="viewbox-controls">
                                        <div>x: <input className={viewBoxArrayValid[0] ? '' : 'invalid'} id="viewbox_x" type="text" value={viewBoxArray[0]} onChange={changeViewbox} /></div>
                                        <div>y: <input className={viewBoxArrayValid[1] ? '' : 'invalid'} id="viewbox_y" type="text" value={viewBoxArray[1]} onChange={changeViewbox} /></div>
                                        <div>width: <input className={viewBoxArrayValid[2] ? '' : 'invalid'} id="viewbox_width" type="text" value={viewBoxArray[2]} onChange={changeViewbox} /></div>
                                        <div>height: <input className={viewBoxArrayValid[3] ? '' : 'invalid'} id="viewbox_height" type="text" value={viewBoxArray[3]} onChange={changeViewbox} /></div>
                                    </div>
                                </div>

                                <div className="install-custom-svg">
                                    <div className="input-items">
                                        <input id="newSvgNameInput" type="text" placeholder="no name" value={newSvgName} onChange={changeNewSvgName} />
                                        {nameAlreadyUsed ?
                                            <button className="action-button disabled" onClick={null}>install svg</button>
                                            :
                                            <button className="action-button blueish" onClick={installCustomSvg}>install svg</button>
                                        }
                                    </div>
                                    <div className={nameAlreadyUsed ? 'name-taken' : 'display-none'}>
                                        That name is already taken. Please choose another.
                                    </div>
                                </div>
                            </div>
                        </div>}
                </div>
            </div>

            <div className={svgLoaded || svgLoadError ? 'other-options' : 'display-none'}>
                <div className="actions">
                    <button className="action-button blueish" onClick={exitView}>Exit</button>
                    <button className={svgLoadError ? 'display-none' : 'action-button blueish'} onClick={tryAgain}>{svgInstalled ? <span>install another</span> : <span>Let's try again</span>}</button>
                </div>
            </div>

        </div>
    );
}
export default InstallSvg