import React, { useState, useEffect, useContext, useRef } from 'react'
import { StoreContext } from "../../context/StoreContext"
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import Utility from "../../objects/Utility"
import './SaveCounter.scss'
const SaveCounter = ({ useText }) => {
    const { actions, state } = useContext(StoreContext)
    const [dialogOpen, setDialogOpen] = useState(true)
    const [saveName, setSaveName] = useState('')
    const [saveNameWarning/*, setSaveNameWarning*/] = useState(false)
    const saveCounterDialogRef = useRef(null)
    const saveCounterInputRef = useRef(null)
    const [feedbackOnFocus, setFeedbackOnFocus] = useState('')
    const [feedback, setFeedback] = useState('')

    useEffect(() => {
        if (state.focus) {
            if (feedbackOnFocus && feedback === '') {
                setFeedback(feedbackOnFocus)
                setFeedbackOnFocus('')
                setTimeout(() => setFeedback(''), 1700)
            }
            actions.focus(null)
        }
    }, [state.focus]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // get savedCounters from Dexie
        // let savedCounters = await db.savedCounters.toArray()
        // savedCounters.sort((a, b) => a.layerOrder - b.layerOrder)
        // actions.savedCounters(savedCounters)

        // prevent overlay from knowing the mouse was clicked, if it was clicked inside the save counter dialog area.
        let copyOfRef = saveCounterDialogRef.current // linter said I should copy this to a variable so the cleanup function can be reliable.
        saveCounterDialogRef.current.addEventListener('mouseup', function (e) {
            e.stopPropagation();
        });

        return () => {
            if (copyOfRef) {
                copyOfRef.removeEventListener('mouseup', function (e) {
                    e.stopPropagation();
                });
            }
        }

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const toggleSaveCounterDialog = () => {
        setDialogOpen(!dialogOpen)
    }

    useEffect(() => {
        actions.overlay(dialogOpen)
    }, [dialogOpen]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (dialogOpen) {
            setDialogOpen(false)
        }
    }, [state.mouseClick]) // eslint-disable-line react-hooks/exhaustive-deps


    const saveNameChange = evt => {
        setSaveName(evt.target.value)
    }

    const packageCounterState = () => {
        let activeLayerKeys = Utility.getVisibleLayerKeysOrdered(state.activeLayerValues, state.layers)
        let counterStateWithSvg = state.layers.map(sl => {
            let serializedSvgForLayer = ''
            let uniquePrepend = ''
            // we'll know which layers need a svg created for them if they have their layerKeys in activeLayerValues
            if (activeLayerKeys.includes(sl.layerKey)) {
                // generate the svg for this layer
                let svgEle = document.getElementById(`drawLayer${sl.layerKey}`)
                if (svgEle) {
                    // make the ids in the svg document all unique, by using a random string to prepend them with.
                    uniquePrepend = 'l' + sl.layerKey + 'l' + Utility.randomString(6)
                    uniquePrepend.replaceAll('_', '') // get rid of any underscores cause we need to use it as a known separator.
                    uniquePrepend += '_'
                    let allIds = svgEle.querySelectorAll('*[id]')
                    let idsInSvg = []
                    allIds.forEach(aid => {
                        //onsole.log('typeof aid:', (typeof aid), ': ', aid)
                        idsInSvg.push(aid.id)
                    })
                    serializedSvgForLayer = new XMLSerializer().serializeToString(document.getElementById(`drawLayer${sl.layerKey}`))
                    if (idsInSvg.length > 0) {
                        idsInSvg.forEach(sid => {
                            serializedSvgForLayer = serializedSvgForLayer.replaceAll('"' + sid + '"', '"' + uniquePrepend + sid + '"')
                            serializedSvgForLayer = serializedSvgForLayer.replaceAll("url(#" + sid + "')", "url('#" + uniquePrepend + sid + ")")
                            serializedSvgForLayer = serializedSvgForLayer.replaceAll('id="drawLayer' + sl.layerKey + '"', 'id="' + uniquePrepend + 'drawLayer' + sl.layerKey + '"')
                        })
                    }
                }
                else {
                    console.warn('cant find element for drawLayer' + sl.layerKey)
                }
            }
            sl.svg = serializedSvgForLayer
            sl.svgIdPrepend = uniquePrepend

            return sl
        })

        return {
            layers: counterStateWithSvg,
            activeLayerValues: state.activeLayerValues
        }
    }

    const saveToFileCommit = () => {
        // let dataPackage = { counter: data, layersOrder: layersOrder }
        // let file = new File([JSON.stringify(dataPackage)], saveLayersUnderName + ".json", { type: "text/plain;charset=utf-8" });
        // saveAs(file);
        let packagedCounterState = packageCounterState()
        let fileCounterObj = {
            fileName: saveName,
            app: 'snapCounter',
            type: 'counter',
            dateSaved: Utility.currentDate(),
            counterState: packagedCounterState
        }

        var zip = new JSZip();
        zip.file(saveName + ".json", JSON.stringify(fileCounterObj));
        zip.generateAsync({ type: "blob", compression: "DEFLATE" })
            .then(function (content) {
                // see FileSaver.js
                saveAs(content, saveName + '.scz');
                setDialogOpen(false)
            });

        if (feedbackOnFocus === '') {
            setFeedbackOnFocus('saved ' + saveName + ' to file.')
        }
    }

    const saveInAppCommit = async () => {
        if (saveName.length < 1) {
            return
        }
        let hash = Utility.cyrb53(saveName, 'snapcounter')

        let packagedCounterState = packageCounterState()
        let svgSource = await createCounterSvg()
        let saveCounterObj = {}
        saveCounterObj[hash] = {
            hash,
            name: saveName,
            counterState: packagedCounterState,
            dateSaved: Utility.currentDate(),
            svg: svgSource
        }
        actions.savedCounters(saveCounterObj)
        try {
            state.dexie.savedCounters.put(saveCounterObj[hash])
        }
        catch (e) {
            console.warn('caught dixie error: ', e)
        }

        // successfully saved. Close dialog.
        setDialogOpen(false)
        setFeedback('saved ' + saveName + ' in app.')
        setTimeout(() => setFeedback(''), 1800)
    }

    const detectFonts = () => {
        let fontFamilies = []
        let activeLayerKeys = Utility.extractLayerKeys(state.activeLayerValues)
        state.layers.forEach(ly => {
            if (activeLayerKeys.includes(ly.layerKey)) {
                if (ly.layerName === 'Google material symbols') {
                    let fontFamily = 'Material Icons'
                    if (fontFamilies.includes(fontFamily) === false) {
                        fontFamilies.push(fontFamily)
                    }
                }
                ly.inputs.forEach(ip => {
                    if (ip.named === 'font') {
                        if (state.activeLayerValues[ly.layerKey + '_' + ip.inputKey]) {
                            let fontFamily = state.activeLayerValues[ly.layerKey + '_' + ip.inputKey]
                            if (fontFamily) {
                                if (fontFamilies.includes(fontFamily) === false) {
                                    fontFamilies.push(fontFamily)
                                }
                            }
                        }
                    }
                })
            }
        })
        return fontFamilies
    }

    const generateCustomSvgLayerKeys = () => {
        let visibleOrdered = Utility.getVisibleLayerKeysOrdered(state.activeLayerValues, state.layers)
        let originalName = null
        let activeLayers = []
        let customSvgLayerKeys = []
        visibleOrdered.forEach(vlk => {
            let layer = state.layers.find(ly => ly.layerKey === vlk)
            if (layer) {
                activeLayers.push(layer)
            }
        })
        activeLayers.forEach(al => {
            if (al.parentLayerKey > -1) { // meaning its a dupe
                let originalLayer = state.layers.find(ly => ly.layerKey === al.parentLayerKey)
                if (originalLayer) {
                    originalName = originalLayer.layerName
                }
            }
            if (al.layerName === 'custom svgs' || originalName === 'custom svgs' ||
                al.layerName === 'custom images' || originalName === 'custom images') {
                customSvgLayerKeys.push(al.layerKey)
            }
        })
        return customSvgLayerKeys
    }

    const generateWw2VehicleSvgLayerKeys = () => {
        let visibleOrdered = Utility.getVisibleLayerKeysOrdered(state.activeLayerValues, state.layers)
        let originalName = null
        let activeLayers = []
        let wvSvgLayerKeys = []
        visibleOrdered.forEach(vlk => {
            let layer = state.layers.find(ly => ly.layerKey === vlk)
            if (layer) {
                activeLayers.push(layer)
            }
        })
        activeLayers.forEach(al => {
            if (al.parentLayerKey > -1) { // meaning its a dupe
                let originalLayer = state.layers.find(ly => ly.layerKey === al.parentLayerKey)
                if (originalLayer) {
                    originalName = originalLayer.layerName
                }
            }
            if (al.layerName === 'ww2 vehicles' || originalName === 'ww2 vehicles') {
                wvSvgLayerKeys.push(al.layerKey)
            }
        })
        return wvSvgLayerKeys
    }

    const createCounterSvg = async (size) => {
        // step through each drawn counters to get the svgs from them.
        // then put them into groups, and pile them into one svg.
        // then save the file as <name>.svg
        let visibleOrdered = Utility.getVisibleLayerKeysOrdered(state.activeLayerValues, state.layers)
        let customSvgLayerKeys = generateCustomSvgLayerKeys()
        let ww2vehiclesSvgLayerKeys = generateWw2VehicleSvgLayerKeys()
        let svgNS = "http://www.w3.org/2000/svg";
        var mergedSvg = document.createElementNS(svgNS, 'svg');
        let defsElement = document.createElementNS('http://www.w3.org/2000/svg', 'defs');

        if (size === 'large') {
            mergedSvg.setAttribute('viewBox', "-500, -500, 1000, 1000")
            mergedSvg.setAttribute('width', '1000');
            mergedSvg.setAttribute('height', '1000');
        }

        let groups = []
        let defs = []
        visibleOrdered.forEach(vo => {
            let svgElement = document.getElementById('drawLayer' + vo)
            /////////////////////////////////////////////////////////////////////////////
            // snap counter created svgs
            /////////////////////////////////////////////////////////////////////////////
            if (customSvgLayerKeys.includes(vo) === false) {
                let svgContent = Array.from(svgElement.childNodes);
                svgContent.forEach(sc => {
                    let scStr = sc.toString()
                    if (scStr.includes('SVGGElement')) {
                        groups.push(sc) // we clone all the children, so no need to iterate this.
                    }
                    if (scStr.includes('SVGDefsElement')) {
                        var defsContent = Array.from(sc.childNodes);
                        defsContent.forEach(defChild => {
                            defs.push(defChild)
                        })
                    }
                })
            }


            //////////////////////////////////////////////////////////////////////////////
            // external svgs
            //////////////////////////////////////////////////////////////////////////////
            else {
                let svgContent = Array.from(svgElement.childNodes);
                svgContent.forEach(sc => {
                    if (JSON.stringify(sc) !== '{}') {
                        groups.push(sc) // we clone all the children, so no need to iterate this.
                    }
                })
            }
        })


        mergedSvg.appendChild(defsElement);
        for (let d = 0; d < defs.length; d++) {
            defsElement.appendChild(defs[d].cloneNode(true))
        }

        mergedSvg.setAttribute('id', saveName);
        mergedSvg.setAttribute('viewBox', "-100, -100, 200, 200")
        let ww2vehicleResets = []
        for (let i = 0; i < groups.length; i++) {
            let gid = groups[i].id
            let extractedInt = Utility.extractInteger(gid)
            let speciallyProcessed = false
            if (customSvgLayerKeys.includes(extractedInt)) {
                let svgNS = "http://www.w3.org/2000/svg"
                var customG = document.createElementNS(svgNS, 'g')
                let matrixString = 'matrix(1, 0, 0, 1, -120, -120)'
                customG.setAttribute('transform', matrixString);
                customG.appendChild(groups[i].cloneNode(true))
                mergedSvg.append(customG)
                speciallyProcessed = true
            }
            if (ww2vehiclesSvgLayerKeys.includes(extractedInt)) {
                let g = groups[i]

                var transform = window.getComputedStyle(g, null).transform
                ww2vehicleResets.push({ g, transform }) // gotta save originals to put them back after we muck with it.
                // basically were are ignoring the top level svg wrapped around the ww2 vehicle svgs that come from the db, and calculating a new matrix
                // that keeps the svg in the same position, size, and rotation, so we can just have a <g> around the path.
                // but after mucking with the matrix, after we get the svg string out, we need to put the original matrix back. Kinda convoluted, and there
                // is Im sure a better way, but ... eh ... I dont want to spend another 3 days trying to figure out a better way.
                transform = transform.replace('matrix(', '').replace(')', '').replaceAll(',', '')
                let splitted = transform.split(' ')
                let splitedFloat = splitted.map(tn => Number(tn))
                let scaleX = splitedFloat[0]
                let r1 = splitedFloat[1] * 1.2 // these two have to do with I think shear and perspective. For some reason multiplying
                let r2 = splitedFloat[2] * 1.2 // by the ration of 120 to 100 adjusts them correctly.
                let scaleY = splitedFloat[3]
                let xoffset = splitedFloat[4]
                let yoffset = splitedFloat[5]
                let newxoffset = (-120) + xoffset
                let newyoffset = (-120) + yoffset
                scaleX = scaleX * 1.2
                scaleY = scaleY * 1.2
                let newmatrixString = 'matrix(' + Utility.roundFloat(scaleX, 3) + ', ' + Utility.roundFloat(r1, 3) + ', ' + Utility.roundFloat(r2, 3) + ', ' + Utility.roundFloat(scaleY, 3) + ', ' + Utility.roundFloat(newxoffset, 3) + ', ' + Utility.roundFloat(newyoffset, 3) + ')'
                g.setAttribute('transform', newmatrixString);
            }

            if (speciallyProcessed === false) {
                mergedSvg.appendChild(groups[i].cloneNode(true));
            }
        }

        let fontFamilies = detectFonts()
        let fontStyleString = ''
        if (fontFamilies.length > 0) {

            for (let i = 0; i < fontFamilies.length; i++) {
                let fontFamily = fontFamilies[i]
                let fontData = await Utility.getDexieStoreSingleItem(state.dexie, 'fonts', fontFamily, 'fontFamily')
                if (fontData) {
                    fontStyleString += "@font-face { font-family: '" + fontFamily + "'; "
                    fontStyleString += 'font-weight: ' + fontData.fontWeight + ';'
                    if (fontData.fontSrc.includes("format('woff2')") === false) {
                        fontStyleString += 'src: ' + fontData.fontSrc + " format('woff2');"
                    }
                    else {
                        fontStyleString += 'src: ' + fontData.fontSrc + ';'
                    }
                    fontStyleString += '} '
                    fontStyleString = fontStyleString.replaceAll(';;', ';')
                }

            }
            if (fontStyleString) {
                fontStyleString = '<style>' + fontStyleString + '</style>'
            }

        }



        var serializer = new XMLSerializer();
        var svgSource = serializer.serializeToString(mergedSvg);
        // we can put the original matrix back into the counter drawing area.
        ww2vehicleResets.forEach(r => {
            r.g.setAttribute('transform', r.transform);
        })

        svgSource = svgSource.replaceAll(' style="pointer-events: visiblepainted;"', ' ')
        svgSource = '<?xml version="1.0" encoding="UTF-8" standalone="yes" ?>' + svgSource

        if (!svgSource.match(/^<svg[^>]+"http:\/\/www\.w3\.org\/1999\/xlink"/)) {
            svgSource = svgSource.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
        }
        svgSource = svgSource.replaceAll('&quot;', "'")
        if (fontStyleString) {
            let defSlashPos = svgSource.indexOf('<defs/>')
            let fullDefPos = svgSource.indexOf('<defs>')
            if (defSlashPos === -1) {
                defSlashPos = 99999999
            }
            if (fullDefPos === -1) {
                fullDefPos = 99999999
            }
            if (defSlashPos < fullDefPos) {
                svgSource = svgSource.replace('<defs/>', fontStyleString + '<defs/>')
            }
            else {
                svgSource = svgSource.replace('<defs>', fontStyleString + '<defs>')
            }
        }

        return svgSource
    }

    const exportToSvgCommit = async () => {
        let svgSource = await createCounterSvg()

        // not sure why the main envelope of the svg has matrix 
        let blob = new Blob([svgSource], { type: 'image/svg+xml' });
        saveAs(blob, saveName + '.svg');
        setDialogOpen(false)
        if (feedbackOnFocus === '') {
            setFeedbackOnFocus('exporting ' + saveName + ' to svg file.')
        }
    }

    const getImageDataURL = (image, type = 'png') => {
        if (type === 'jpg') type = 'jpeg';
        const { width, height } = image;
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.width = width;
        canvas.height = height;
        if (type === 'jpeg') {
            context.fillStyle = 'white';
            context.fillRect(0, 0, width, height);
        }
        context.drawImage(image, 0, 0, width, height);
        return canvas.toDataURL(`image/${type}`);
    }

    const convertSvg = (svgString, type = 'png') => new Promise((res, rej) => {
        const image = new Image();
        image.onload = () => res(getImageDataURL(image, type));
        image.src = `data:image/svg+xml,${encodeURIComponent(svgString)}`;
    });

    const exportToPngCommit = async () => {
        let source = await createCounterSvg('large') //combineCounterSvgs()

        convertSvg(source, 'png').then(dataURL => {
            /* do something with it */
            saveAs(dataURL, saveName + '.png');
            setDialogOpen(false)
            if (feedbackOnFocus === '') {
                setFeedbackOnFocus('saving ' + saveName + ' to png file.')
            }
        });

    }

    // this is specific to exporting to png
    // const combineCounterSvgs = async () => {
    //     let layerKeys = Utility.getVisibleLayerKeysOrdered(state.activeLayerValues, state.layers)
    //     let serializedSvgForLayer = null
    //     let counterSvgsGroupsData = []
    //     let defNodes = []
    //     let customSvgLayerKeys = generateCustomSvgLayerKeys()
    //     let ww2vehiclesSvgLayerKeys = generateWw2VehicleSvgLayerKeys()
    //     let ww2vehicleResets = []
    //     layerKeys.forEach(lk => {

    //         serializedSvgForLayer = new XMLSerializer().serializeToString(document.getElementById(`layerGroup_${lk}`))

    //         let svgElement = document.getElementById('drawLayer' + lk)
    //         var svgContent = Array.from(svgElement.childNodes);
    //         let groups = []

    //         svgContent.forEach(sc => {
    //             let scStr = sc.toString()
    //             if (scStr.includes('SVGGElement')) {
    //                 groups.push(sc) // we clone all the children, so no need to iterate this.
    //             }
    //             if (scStr.includes('SVGDefsElement')) {
    //                 var defsContent = Array.from(sc.childNodes);
    //                 defsContent.forEach(defChild => {
    //                     // don't duplicate the same defs, we only need one for the whole svg.
    //                     if (!defNodes.find(dn => dn.id === defChild.id)) {
    //                         defNodes.push(defChild)
    //                     }
    //                 })
    //             }
    //         })
    //         counterSvgsGroupsData.push({ layerKey: lk, serializedSvg: serializedSvgForLayer, groups })
    //     })

    //     var svgNS = "http://www.w3.org/2000/svg";
    //     var mergedSvg = document.createElementNS(svgNS, 'svg');
    //     let defsElement = document.createElementNS('http://www.w3.org/2000/svg', 'defs');
    //     mergedSvg.appendChild(defsElement);
    //     mergedSvg.setAttribute('id', 'merged');

    //     // expanded viewbox lets a thumbnail look more like the image (not cropped) (at least on Ubuntu).
    //     // it does give a transparent 5 pixel border around it, which I guess is ok.
    //     mergedSvg.setAttribute('viewBox', "-500, -500, 1000, 1000")
    //     mergedSvg.setAttribute('width', '1000');
    //     mergedSvg.setAttribute('height', '1000');
    //     let speciallyProcessed = false
    //     // we could have used the svgStrings instead of cloning groups, but, I'm not sure if either one is better than the other.
    //     counterSvgsGroupsData.forEach(csgd => {
    //         if (customSvgLayerKeys.includes(csgd.layerKey)
    //             ||
    //             ww2vehiclesSvgLayerKeys.includes(csgd.layerKey)
    //         ) {
    //             var svgNS = "http://www.w3.org/2000/svg";
    //             var customG = document.createElementNS(svgNS, 'g');
    //             // need to recenter since most svgs have origin at 0,0, not in the center like the svg this app has natively.
    //             let matrixString = 'matrix(1, 0, 0, 1, -120, -120)'
    //             customG.setAttribute('transform', matrixString);
    //             csgd.groups.forEach(group => {
    //                 customG.appendChild(group.cloneNode(true));
    //             })
    //             mergedSvg.append(customG)
    //             speciallyProcessed = true
    //         }



    //         if (speciallyProcessed === false) {

    //             csgd.groups.forEach(group => {
    //                 mergedSvg.appendChild(group.cloneNode(true));
    //             })
    //         }
    //     })

    //     for (let d = 0; d < defNodes.length; d++) {
    //         defsElement.appendChild(defNodes[d].cloneNode(true))
    //     }
    //     mergedSvg.setAttribute("transform", "scale(5)");

    //     var serializer = new XMLSerializer();
    //     var source = serializer.serializeToString(mergedSvg);
    //     console.log('source:', source)
    //     if (!source.match(/^<svg[^>]+"http:\/\/www\.w3\.org\/1999\/xlink"/)) {
    //         source = source.replace(/^<svg/, '<svg xmlns:xlink="http://www.w3.org/1999/xlink"');
    //     }

    //     let fontFamilies = detectFonts()
    //     let fontStyleString = ''
    //     if (fontFamilies.length > 0) {

    //         for (let i = 0; i < fontFamilies.length; i++) {
    //             let fontFamily = fontFamilies[i]
    //             let fontData = await Utility.getDexieStoreSingleItem(state.dexie, 'fonts', fontFamily, 'fontFamily')
    //             if (fontData) {
    //                 fontStyleString += "@font-face { font-family: '" + fontFamily + "'; "
    //                 fontStyleString += 'font-weight: ' + fontData.fontWeight + ';'
    //                 if (fontData.fontSrc.includes("format('woff2')") === false) {
    //                     fontStyleString += 'src: ' + fontData.fontSrc + " format('woff2');"
    //                 }
    //                 else {
    //                     fontStyleString += 'src: ' + fontData.fontSrc + ';'
    //                 }
    //                 fontStyleString += '} '
    //                 fontStyleString = fontStyleString.replaceAll(';;', ';')
    //             }

    //         }
    //         if (fontStyleString) {
    //             fontStyleString = '<style>' + fontStyleString + '</style>'
    //         }

    //     }
    //     if (fontStyleString) {
    //         // get first defs
    //         let defSlashPos = source.indexOf('<defs/>')
    //         let fullDefPos = source.indexOf('<defs>')
    //         if (defSlashPos === -1) {
    //             defSlashPos = 99999999
    //         }
    //         if (fullDefPos === -1) {
    //             fullDefPos = 99999999
    //         }
    //         if (defSlashPos < fullDefPos) {
    //             source = source.replace('<defs/>', fontStyleString + '<defs/>')
    //         }
    //         else {
    //             source = source.replace('<defs>', fontStyleString + '<defs>')
    //         }


    //     }
    //     //add xml declaration
    //     source = '<?xml version="1.0" standalone="yes"?>\r\n' + source;

    //     return source
    // }

    const applyDateAndTime = () => {
        var currentdate = new Date();
        let mth = currentdate.getMonth() + 1
        if (mth < 10) {
            mth = '0' + mth
        }
        let yr = currentdate.getFullYear()
        let day = currentdate.getDate()
        let hour = currentdate.getHours()
        let min = currentdate.getMinutes()
        if (min < 10) {
            min = '0' + min
        }
        var datetime = `_${yr}-${mth}-${day}_${hour}-${min}`
        if (saveName.includes(datetime) === false) { // dont repeatedly insert it
            setSaveName(saveName + datetime)
        }
    }

    return (
        <div className="save-counter" id="saveCounter">
            <button className="action-button cyanish" onClick={toggleSaveCounterDialog} >{useText}</button>

            <div ref={saveCounterDialogRef} id="dialog" className={dialogOpen ? 'dialog' : 'display-none'}>
                <div className="apply-date-time" onClick={applyDateAndTime}>click to append date+time to name</div>
                <div className="save-input-inline">

                    <div>save name:</div>
                    <div><input className={saveNameWarning ? 'warning' : ''} ref={saveCounterInputRef} type="text"
                        value={saveName} onChange={saveNameChange} /></div>
                </div>

                <div className="save-counter-options-container">
                    <div className="save-counter-option">
                        <button className={saveName ? 'action-button cyanish' : 'action-button disabled'} onClick={saveName ? saveInAppCommit : null}>
                            save in app
                        </button>
                    </div>
                    <div className="save-counter-option">
                        <button className={saveName ? 'action-button cyanish' : 'action-button disabled'} onClick={saveName ? saveToFileCommit : null}>
                            save to file
                        </button>
                    </div>
                    <div className="save-counter-option">
                        <button className={saveName ? 'action-button cyanish' : 'action-button disabled'} onClick={saveName ? exportToSvgCommit : null}>
                            export to svg<span>*</span>
                        </button>
                    </div>
                    <div className="save-counter-option">
                        <button className={saveName ? 'action-button cyanish' : 'action-button disabled'} onClick={saveName ? exportToPngCommit : null}>
                            export to png<span>*</span>
                        </button>
                    </div>
                </div>
                <div className="asterisk-explanation">
                    These options are not importable back into the app.
                </div>
            </div>
            <div className={feedback ? 'saved-counter-feedback' : 'saved-counter-feedback display-none'}>{feedback}</div>
        </div>
    )
}
export default SaveCounter 
