import React, { useState, useEffect, useContext } from 'react'
import Utility from "../../objects/Utility";
import { StoreContext } from "../../context/StoreContext"
import './SvgGroup.scss'
import SvgButton from "../SvgButton/SvgButton"
const SvgGroup = ({ layerKey, input, setValue, useValue, setValid, delayIndex, largeButtons }) => {
    const { state } = useContext(StoreContext)
    const [selectedSvgButton, setSelectedSvgButton] = useState(useValue)
    const [initialEffect, setInitialEffect] = useState(true)
    const [dupedInput, setDupedInput] = useState(input)
    const [savedInput, setSavedInput] = useState(null)

    useEffect(() => {
            // we need to ensure any new svg buttons coming in due to a update get displayed.
            // Therefore, we need to remove the existing buttons, and redraw them.
            setDupedInput({list:[]})
            setSavedInput(JSON.parse(JSON.stringify(input)))
    }, [input])

    useEffect(() => {
        if( savedInput !== null ) {
            setDupedInput(savedInput)
            setSavedInput(null)
        }
    },[savedInput])

    useEffect(() => {
        if (initialEffect) {
            setInitialEffect(false)
            return
        }

        setValue(input.inputKey, selectedSvgButton)
    }, [selectedSvgButton]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (useValue !== null && useValue !== undefined) {
            if (state.activeLayerValues[layerKey + '_' + input.inputKey] !== undefined) {
                if (selectedSvgButton !== useValue) {
                    setSelectedSvgButton(useValue)
                }
            }
        }
    }, [useValue]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (selectedSvgButton === null || selectedSvgButton === undefined) {
            setValid(input.inputKey, false)
        }
        else {
            setValid(input.inputKey, true)
        }
    }, [selectedSvgButton]) // eslint-disable-line react-hooks/exhaustive-deps

    const selectButton = (svgKey) => {
        if (svgKey === selectedSvgButton) {
            setSelectedSvgButton(null) // null is for 'none'
        }
        else {
            // see if layer is active. If not, we want to auto-turn it on.
            let layerIsActive = Utility.layerIsActive(layerKey, state.activeLayerValues)
            if (layerIsActive === false) { // I dont seem to be using this.
                // auto-turn on layer
               // actions.activateLayer({ layerKey, layerActive: true, userAction: true })
            }
            setSelectedSvgButton(svgKey)
        }
    }

    const delaySelectButton = (svgKey) => {
        if (svgKey !== null && svgKey !== undefined) {
            selectButton(svgKey)
        }
    }

    return (
        <div id={`input_svg_group_${input.inputKey}`} className="svg-group">
            {input.message ? <div className="group-label">{input.message}</div> : ''}
            <div className="svg-buttons">
                {dupedInput.list.map(svgKey => <SvgButton key={svgKey}
                    svgKey={svgKey}
                    selectButton={delaySelectButton}
                    selected={selectedSvgButton === svgKey}
                    largeButton={largeButtons}
                    layerKey={layerKey} />)}
            </div>
        </div>
    )
}
export default SvgGroup