import React, { useState, useEffect, useContext } from 'react'
import { StoreContext } from "../../context/StoreContext"
import Utility from "../../objects/Utility";
import './InputCounterShapes.scss'
const InputCounterShapes = ({ layerKey, input, setValue, useValue, delayIndex }) => {
    const [selectedShape, setSelectedShape] = useState(useValue);
    const [initialEffect, setInitialEffect] = useState(true)
    const { state } = useContext(StoreContext)

    useEffect(() => {
        if (useValue !== null && useValue !== undefined) {
            if (state.activeLayerValues[layerKey + '_' + input.inputKey] !== undefined) {
                if (selectedShape !== useValue) {
                    setSelectedShape(parseInt(useValue))
                }
            }
        }
    }, [useValue]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if( initialEffect ) {
            setInitialEffect(false)
            return
        }
        if (parseInt(selectedShape) > -1) {
            let existingShape = Utility.currentLayerInputValue(layerKey, input.inputKey, state.activeLayerValues)
            // if the shape in this input is already the same value as whats in state.activeLayerValues, do not re-send the same value back.
            if(parseInt(existingShape) !== parseInt(selectedShape)) {
                setValue(input.inputKey, parseInt(selectedShape))
            }
        }
    }, [selectedShape]) // eslint-disable-line react-hooks/exhaustive-deps

    const clickedShape = (shape) => {
        shape = parseInt(shape)
        setSelectedShape(shape)
    }

    return (
        <div  id={`input_counter_shapes_${input.inputKey}`} className="input-counter-shapes">
            <div className="message">select counter shape</div>
            <div className="selections">
                <div className={selectedShape === 0 ? 'selection selected' : 'selection'} onClick={() => clickedShape(0)}>
                    <div className="square"></div>
                    <div>square</div>
                </div>
                <div className={selectedShape === 1 ? 'selection selected' : 'selection'} onClick={() => clickedShape(1)}>
                    <div className="circle"></div>
                    <div>circle</div>
                </div>
                <div className={selectedShape === 2 ? 'selection selected' : 'selection'} onClick={() => clickedShape(2)}>
                    <div className="rectangle"></div>
                    <div>rectangle</div>
                </div>
            </div>
        </div>
    )
}
export default InputCounterShapes