import Utility from '../objects/Utility'
import Snap from 'snapsvg-cjs'

const CombatMovement = {

    draw: (g, settings) => {
        let derivedValues = {}
        let centerX = 0
        let centerY = 0

        settings.forEach(setting => {
            switch (setting.name) {
                case 'label': break
                case 'variable spacer': break
                case 'combat': derivedValues.combat = setting.value
                    break
                case 'movement': derivedValues.movement = setting.value
                    break
                case 'separator': derivedValues.separator = setting.value // none, dot, dash
                    break
                case 'font size':
                    if (setting.comment.includes('combat')) {
                        derivedValues.combatFactorFontSize = Number(setting.value)
                    }
                    if (setting.comment.includes('movement')) {
                        derivedValues.movementFactorFontSize = Number(setting.value)
                    }
                    break
                case 'font':
                    if (setting.comment.includes('combat')) {
                        derivedValues.combatFactorFontFamily = setting.value
                    }
                    if (setting.comment.includes('movement')) {
                        derivedValues.movementFactorFontFamily = setting.value
                    }
                    break
                case 'fill color':
                    if (setting.comment.includes('combat')) {
                        derivedValues.combatFactorFillColor = setting.value
                    }
                    if (setting.comment.includes('movement')) {
                        derivedValues.movementFactorFillColor = setting.value
                    }
                    if (setting.comment.includes('separator')) {
                        derivedValues.separatorFillColor = setting.value
                    }
                    if (setting.comment.includes('box combat')) {
                        derivedValues.boxCombatFillColor = setting.value
                    }
                    if (setting.comment.includes('box movement')) {
                        derivedValues.boxMovementFillColor = setting.value
                    }
                    break
                case 'stroke color':
                    if (setting.comment.includes('combat')) {
                        derivedValues.combatFactorStrokeColor = setting.value
                    }
                    if (setting.comment.includes('movement')) {
                        derivedValues.movementFactorStrokeColor = setting.value
                    }
                    if (setting.comment.includes('separator')) {
                        derivedValues.separatorStrokeColor = setting.value
                    }
                    if (setting.comment.includes('box combat')) {
                        derivedValues.boxCombatStrokeColor = setting.value
                    }
                    if (setting.comment.includes('box movement')) {
                        derivedValues.boxMovementStrokeColor = setting.value
                    }
                    break
                case 'font weight':
                    if (setting.comment.includes('combat')) {
                        derivedValues.combatFactorFontWeight = setting.value
                    }
                    if (setting.comment.includes('movement')) {
                        derivedValues.movementFactorFontWeight = setting.value
                    }
                    break
                case 'center xy position': derivedValues.centerXyXpos =0
                    derivedValues.centerXyYpos = 0
                    if (Array.isArray(setting.value) === false || setting.value.length !== 2) {
                        setting.value = [1, 1]
                    }
                    if (isNaN(setting.value[0]) ) {
                        setting.value[0] = 1
                    }
                    if (isNaN(setting.value[1]) ) {
                        setting.value[1] = 1
                    }
                    centerX = Utility.roundFloat(setting.value[0], 2)
                    centerY = Utility.roundFloat(setting.value[1], 2)
                    break
                case 'spread':
                    derivedValues.spread = Number(setting.value)
                    break
                case 'stroke width':
                    if (setting.comment.includes('combat')) {
                        derivedValues.combatFactorStrokeWidth = (setting.value / 100) * 10
                    }
                    if (setting.comment.includes('movement')) {
                        derivedValues.movementFactorStrokeWidth = (setting.value / 100) * 10
                    }
                    if (setting.comment.includes('separator')) {
                        derivedValues.separatorStrokeWidth = (setting.value / 100) * 10
                    }
                    if (setting.comment.includes('box combat')) {
                        derivedValues.boxCombatStrokeWidth = (setting.value / 100) * 10
                    }
                    if (setting.comment.includes('box movement')) {
                        derivedValues.boxMovementStrokeWidth = (setting.value / 100) * 10
                    }
                    break
                case 'relative xy position':
                    if (setting.comment.includes('separator')) {
                        derivedValues.separatorRelativeXyXpos = Utility.roundFloat(setting.value[0], 2)
                        derivedValues.separatorRelativeXyYpos = Utility.roundFloat(setting.value[1], 2)
                    }
                    if (setting.comment.includes('combat')) {
                        derivedValues.combatFactorRelativeXyXpos = Utility.roundFloat(setting.value[0], 2)
                        derivedValues.combatFactorRelativeXyYpos = Utility.roundFloat(setting.value[1], 2)
                    }
                    if (setting.comment.includes('movement')) {
                        derivedValues.movementFactorRelativeXyXpos = Utility.roundFloat(setting.value[0], 2)
                        derivedValues.movementFactorRelativeXyYpos = Utility.roundFloat(setting.value[1], 2)
                    }
                    break
                case 'width':
                    if (setting.comment.includes('Combat-Movement separator')) {
                        derivedValues.separatorWidth = Utility.roundFloat(setting.value, 2)
                    }
                    if (setting.comment.includes('box combat')) {
                        derivedValues.boxCombatWidth = Utility.roundFloat(setting.value, 2)
                    }
                    if (setting.comment.includes('box movement')) {
                        derivedValues.boxMovementWidth = Utility.roundFloat(setting.value, 2)
                    }
                    break
                case 'height':
                    if (setting.comment.includes('Combat-Movement separator')) {
                        derivedValues.separatorHeight = Utility.roundFloat(setting.value, 2)
                    }
                    if (setting.comment.includes('box combat')) {
                        derivedValues.boxCombatHeight = Utility.roundFloat(setting.value, 2)
                    }
                    if (setting.comment.includes('box movement')) {
                        derivedValues.boxMovementHeight = Utility.roundFloat(setting.value, 2)
                    }
                    break
                case 'boxed combat': derivedValues.boxedCombat = setting.value === 'yes' ? true : false
                    break
                case 'boxed movement': derivedValues.boxedMovement = setting.value === 'yes' ? true : false
                    break
                case 'corner radius': derivedValues.boxesCornerRadius = Number(setting.value)
                    break
                default: console.warn('we seem to have missed one: ', setting.name, ' with value ', setting.value, ' setting:', setting)
            }
        })

        CombatMovement.drawText(g, derivedValues)



        if (centerX !== 0 || centerY !== 0) {
            CombatMovement.applyTranslation(g, centerX, centerY)
        }



    },
    
    drawText: (g, derivedValues) => {

        g.text(
            derivedValues.combatFactorRelativeXyXpos + derivedValues.centerXyXpos + (Utility.roundFloat(derivedValues.spread / 2, 1) * -1),
            derivedValues.combatFactorRelativeXyYpos + derivedValues.centerXyYpos,
            derivedValues.combat ? derivedValues.combat : '').attr(
                {
                    fontFamily: derivedValues.combatFactorFontFamily,
                    // fontStyle: derivedValues.fontStyle,
                    textAnchor: "middle",
                    dominantBaseline: "central",
                    fontWeight: derivedValues.combatFactorFontWeight,
                    fill: derivedValues.combatFactorFillColor,
                    stroke: derivedValues.combatFactorStrokeColor,
                    strokeWidth: derivedValues.combatFactorStrokeWidth,
                    fontSize: derivedValues.combatFactorFontSize
                })

        g.text(
            derivedValues.movementFactorRelativeXyXpos + derivedValues.centerXyXpos + Utility.roundFloat(derivedValues.spread / 2),
            derivedValues.movementFactorRelativeXyYpos + derivedValues.centerXyYpos, 
            derivedValues.movement ? derivedValues.movement : '').attr(
                {
                    fontFamily: derivedValues.movementFactorFontFamily,
                    // fontStyle: derivedValues.fontStyle,
                    textAnchor: "middle",
                    dominantBaseline: "central",
                    fontWeight: derivedValues.movementFactorFontWeight,
                    fill: derivedValues.movementFactorFillColor,
                    stroke: derivedValues.movementFactorStrokeColor,
                    strokeWidth: derivedValues.movementFactorStrokeWidth,
                    fontSize: derivedValues.movementFactorFontSize
                })

        if (derivedValues.separator === 'dot') {
            g.ellipse(derivedValues.separatorRelativeXyXpos + derivedValues.centerXyXpos,
                derivedValues.separatorRelativeXyYpos + derivedValues.centerXyYpos,
                derivedValues.separatorWidth,
                derivedValues.separatorHeight).attr({
                    fill: derivedValues.separatorFillColor,
                    stroke: derivedValues.separatorStrokeColor,
                    strokeWidth: derivedValues.separatorStrokeWidth
                })
        }

        if (derivedValues.separator === 'dash') {
            let dashRectX = derivedValues.centerXyXpos - (derivedValues.separatorWidth / 2) + derivedValues.separatorRelativeXyXpos
            let dashRectY = derivedValues.centerXyYpos - (derivedValues.separatorHeight / 2) + derivedValues.separatorRelativeXyYpos
            dashRectX = Utility.roundFloat(dashRectX, 2)
            dashRectY = Utility.roundFloat(dashRectY, 2)
            let dashRectWidth = derivedValues.separatorWidth
            let dashRectHeight = derivedValues.separatorHeight
            g.rect(dashRectX, dashRectY, dashRectWidth, dashRectHeight).attr({
                fill: derivedValues.separatorFillColor,
                stroke: derivedValues.separatorStrokeColor,
                strokeWidth: derivedValues.separatorStrokeWidth
            })
        }

    },

    applyTranslation: (g, xpos, ypos) => {
        let currentMatrix = g.transform().localMatrix;
        let addMatrix = new Snap.Matrix()
        addMatrix.translate(xpos, ypos)
        addMatrix.add(currentMatrix)
        g.transform(addMatrix)
    },

}
export default CombatMovement