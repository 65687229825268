import Utility from './Utility'

const Line = {
    previous: { x1: -9999, y1: -9999, x2: -9999, y2: -9999 },
    draw: (g, settings, dragResult) => {
        let derivedValues = {}
        settings.forEach(setting => {
            switch (setting.name) {
                case 'label': break
                case 'variable spacer': break
                case 'line start': derivedValues.startXpos = Utility.roundFloat(setting.value[0], 3)
                    derivedValues.startYpos = Utility.roundFloat(setting.value[1], 3)
                    break
                case 'line end': derivedValues.endXpos = Utility.roundFloat(setting.value[0], 3)
                    derivedValues.endYpos = Utility.roundFloat(setting.value[1], 3)
                    break
                case 'thickness':
                    derivedValues.thickness = Number(setting.value)
                    break
                case 'linecap':
                    derivedValues.linecap = setting.value
                    break
                case 'color':
                    derivedValues.color = setting.value
                    break

                default: console.warn('we seem to have missed one: ', setting.name, ' with value ', setting.value, ' setting:', setting)
            }
        })
        if (dragResult &&
            (Line.previous.x1 !== derivedValues.startXpos ||
                Line.previous.y1 !== derivedValues.startYpos) &&
            Line.previous.x2 === derivedValues.endXpos &&
            Line.previous.y2 === derivedValues.endYpos) {

                // predict end points
            let diffX =  derivedValues.startXpos - Line.previous.x1
            let diffY =  derivedValues.startYpos - Line.previous.y1
            derivedValues.endXpos = Line.previous.x2 + diffX
            derivedValues.endYpos = Line.previous.y2 + diffY
        }
        
            Line.drawLine(g, derivedValues)
        

    },

    drawLine: (g, derivedValues) => {
        let x1 = derivedValues.startXpos
        let y1 = derivedValues.startYpos
        let x2 = derivedValues.endXpos
        let y2 = derivedValues.endYpos
        Line.previous = { x1, y1, x2, y2 }
        g.line(x1, y1, x2, y2).attr({
            stroke: derivedValues.color,
            strokeWidth: derivedValues.thickness,
            strokeLinecap: derivedValues.linecap
        })
    },

}
export default Line