import React, {useEffect, useContext} from 'react'
import { StoreContext } from "../../context/StoreContext"
import './Overlay.scss'
const Overlay = () => {
    const { state, actions } = useContext(StoreContext)

    useEffect(() => {
        if( state.mouseClick ) {
            actions.overlay(false)
        }
    }, [state.mouseClick]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={state.overlay ? 'overlay' : 'display-none'}/>
    );
}
export default Overlay;