import React, { useState, useEffect, useContext, useRef } from 'react'
import Utility from "../../objects/Utility";
import { StoreContext } from "../../context/StoreContext"
import './InputFill.scss'
const InputFill = ({ layerKey, input, setValue, useValue, delayIndex }) => {
    const { state, actions } = useContext(StoreContext)
    const [fillType, setFillType] = useState(useValue ? useValue.fillType ? useValue.fillType : useValue.fillType : 'solid')
    const [solidFillColor, setSolidFillColor] = useState(useValue ? useValue.fillColor ? useValue.fillColor : useValue.fillColor : '#ffffff')
    const [solidFillColorText, setSolidFillColorText] = useState(useValue ? useValue.fillColor ? useValue.fillColor : useValue.fillColor : '#ffffff')
    const launchSolidFillColorRef = useRef(null)
    const [gradientSettingsOpen, setGradientSettingsOpen] = useState(true)
    const [multicolorButton, setMulticolorButton] = useState([])
    const [gradientDirection, setGradientDirection] = useState(useValue ? useValue.gradientDirection ? useValue.gradientDirection : useValue.gradientDirection : 'linear')
    const [xyValStart, setXyValStart] = useState(
        
        useValue ?
            useValue.xyPositionStart ?

                  [Number(useValue.xyPositionStart.split(',')[0]),
                   Number(useValue.xyPositionStart.split(',')[1])]

                : [0,0] : [0,0]
        
        )
    const [xyValEnd, setXyValEnd] = useState(
        
        useValue ?
            useValue.xyPositionEnd ? 
        
        [Number(useValue.xyPositionEnd.split(',')[0]),
        Number(useValue.xyPositionEnd.split(',')[1])]
        
        : [0,0] : [0,0]
        
        )


    const [colorPickerOwned, setColorPickerOwned] = useState(false)
    const [colorGradientPickerOwned, setColorGradientPickerOwned] = useState(-1)
    const [timer, setTimer] = useState(null)
    const [colorsArray, setColorsArray] = useState([
        { name: 'start', value: useValue ? useValue.colorStart ? useValue.colorStart : '#000000' : '#000000', launchColorPickerRef: useRef(null), isValid: true },
        { name: 'middle', value: useValue ? useValue.colorMid ? useValue.colorMid : '#000000' : '#000000', launchColorPickerRef: useRef(null), isValid: true },
        { name: 'end', value: useValue ? useValue.colorEnd ? useValue.colorEnd : '#000000' : '#000000', launchColorPickerRef: useRef(null), isValid: true }])


    useEffect(() => {
        executeCode()
        return () => {
            if (timer) {
                clearTimeout(timer)
            }
        };

    }, []) // eslint-disable-line react-hooks/exhaustive-deps



    useEffect(() => {
        let _obj = useValue
        let xyArrayStart = _obj.xyPositionStart.split(',')
        xyArrayStart = xyArrayStart.map(item => Number(item))
        if (xyArrayStart[0] === xyValStart[0] && xyArrayStart[1] === xyValStart[1]) {
            return
        }
        _obj.xyPositionStart = xyValStart.join(',')
        let jsonObj = JSON.stringify(_obj)
        // debounce
        if (timer) {
            clearTimeout(timer)
        }
        let thisTimer = setTimeout(() => {
            setValue(input.inputKey, jsonObj)
            setTimer(null)
        }, 100)
        setTimer(thisTimer)
    }, [xyValStart]) // eslint-disable-line react-hooks/exhaustive-deps

    const changeXyValStart = evt => {
        if (evt.target.id === 'input_x_start') {
            setXyValStart([Utility.roundFloat(evt.target.value, 3), xyValStart[1]])
        }
        if (evt.target.id === 'input_y_start') {
            setXyValStart([xyValStart[0], Utility.roundFloat(evt.target.value, 3)])
        }
    }

    useEffect(() => {
        let _obj = useValue
        let xyArrayEnd = _obj.xyPositionEnd.split(',')
        xyArrayEnd = xyArrayEnd.map(item => Number(item))
        if (xyArrayEnd[0] === xyValEnd[0] && xyArrayEnd[1] === xyValEnd[1]) {
            return
        }
        _obj.xyPositionEnd = xyValEnd.join(',')
        let jsonObj = JSON.stringify(_obj)
        // debounce
        if (timer) {
            clearTimeout(timer)
        }
        let thisTimer = setTimeout(() => {
            setValue(input.inputKey, jsonObj)
            setTimer(null)
        }, 100)
        setTimer(thisTimer)
    }, [xyValEnd]) // eslint-disable-line react-hooks/exhaustive-deps

    const changeXyValEnd = evt => {
        if (evt.target.id === 'input_x_end') {
            setXyValEnd([Utility.roundFloat(evt.target.value, 3), xyValEnd[1]])
        }
        if (evt.target.id === 'input_y_end') {
            setXyValEnd([xyValEnd[0], Utility.roundFloat(evt.target.value, 3)])
        }
    }





    useEffect(() => {
        setSolidFillColorText(solidFillColor)
    }, [solidFillColor])

    const executeCode = () => {

        function hslToHex(h, s, l) {
            l /= 100;
            const a = s * Math.min(l, 1 - l) / 100;
            const f = n => {
                const k = (n + h / 30) % 12;
                const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
                return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
            };
            return `#${f(0)}${f(8)}${f(4)}`;
        }

        let paths = []
        let colors = []
        for (var x = 0; x < 360; x = x + 12) {
            let h = hslToHex(x, 81, 50)
            colors.push(h)
        }
        let arr = [0, 12, 24, 36, 48, 60, 72, 84, 96, 108, 120, 132, 144, 156, 168, 180, 192, 204, 216, 228, 240, 252, 264, 276, 288, 300, 312, 324, 336, 348]


        arr.forEach((a, index) => {
            let d = drawPieSlice(0, 0, 19, a, a + 12)
            paths.push({ d, color: colors[index] })
        })
        setMulticolorButton(paths)
    }

    const drawPieSlice = (centerX, centerY, radius, startAngle, endAngle) => {

        var startRad = startAngle * Math.PI / 180.0;
        let x1 = radius * Math.sin(startRad)
        let y1 = radius * Math.cos(startRad)

        var endRad = endAngle * Math.PI / 180.0;
        let x2 = radius * Math.sin(endRad)
        let y2 = radius * Math.cos(endRad)

        //  var startArc = polarToCartesian(centerX, centerY, radius, endAngle);
        //  var endArc = polarToCartesian(centerX, centerY, radius, startAngle);

        //  var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

        let d = 'M ' + centerX + ', ' + centerY + ' ' +
            'L ' + x1 + ',' + y1 + ','
            + x2 + ',' + y2 + ' Z'
        return d

    }

    useEffect(() => {
        if (colorPickerOwned) {
            let launchColorPickerRefClientRef = launchSolidFillColorRef.current.getBoundingClientRect()
            let x = launchColorPickerRefClientRef.left + launchColorPickerRefClientRef.width + 10 // the 10 is to give in some spacing
            let y = launchColorPickerRefClientRef.top
            let hexColor = solidFillColor
            actions.colorPicker({ x, y, hexColor })
        }
    }, [colorPickerOwned]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (state.colorPicker.x === -1) {
            setColorPickerOwned(false)
            setColorGradientPickerOwned(-1)
        }
        else {
            if (colorPickerOwned) {
                setSolidFillColor(state.colorPicker.hexColor)
                //setSolidFillColorText(state.colorPicker.hexColor)
                // we need to validate the text input field to remove any error coloring.
                // Utility.validateHexColor(state.colorPicker.hexColor)
            }
            if (colorGradientPickerOwned !== -1) {
                let _colorsArray = [...colorsArray]
                _colorsArray[colorGradientPickerOwned].value = state.colorPicker.hexColor
                setColorsArray(_colorsArray)
            }
        }
    }, [state.colorPicker]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let _obj = { ...useValue }
        let changed = false
        if ((colorsArray[0].value !== useValue.colorStart) && (colorsArray[0].value === 'transparent' || Utility.validateHexColor(colorsArray[0].value))) {
            _obj.colorStart = colorsArray[0].value
            changed = true
        }
        if ((colorsArray[0].value !== useValue.colorMid) && (colorsArray[1].value === 'transparent' || Utility.validateHexColor(colorsArray[1].value))) {
            _obj.colorMid = colorsArray[1].value
            changed = true
        }
        if ((colorsArray[0].value !== useValue.coloreEnd) && (colorsArray[2].value === 'transparent' || Utility.validateHexColor(colorsArray[2].value))) {
            _obj.colorEnd = colorsArray[2].value
            changed = true
        }
        if (changed) {
            let jsonObj = JSON.stringify(_obj)
            setValue(input.inputKey, jsonObj)
        }
    }, [colorsArray]) // eslint-disable-line react-hooks/exhaustive-deps

    const openColorPicker = () => {
        setColorPickerOwned(true)
    }

    useEffect(() => {
        if (colorGradientPickerOwned > -1) {
            let ref = colorsArray[colorGradientPickerOwned].launchColorPickerRef.current
            let bbox = ref.getBoundingClientRect()
            let x = bbox.left + bbox.width + 10 // the 10 is to give in some spacing
            let y = bbox.top
            let hexColor = colorsArray[colorGradientPickerOwned].value
            actions.colorPicker({ x, y, hexColor })
        }
    }, [colorGradientPickerOwned]) // eslint-disable-line react-hooks/exhaustive-deps

    const openColorGradientPicker = colorIndex => {
        setColorGradientPickerOwned(colorIndex)
    }

    const changeFillType = (_fillType) => {
        setFillType(_fillType)
    }

    const fakeChangeFillType = evt => {
    }

    useEffect(() => {
        let _obj = useValue
        if (_obj.fillType === fillType) {
            return
        }
        _obj.fillType = fillType
        let jsonObj = JSON.stringify(_obj)
        setValue(input.inputKey, jsonObj)
    }, [fillType]) // eslint-disable-line react-hooks/exhaustive-deps

    const changeGradientDirection = evt => {
        let _gradientDirection = evt.target.value
        setGradientDirection(_gradientDirection)
    }

    const clickGradientDirection = evt => {
        let _gradientDirection = evt.target.value
        setGradientDirection(_gradientDirection)
    }

    useEffect(() => {
        let _obj = { ...useValue }
        if (_obj.gradientDirection === gradientDirection) {
            return
        }
        _obj.gradientDirection = gradientDirection
        let jsonObj = JSON.stringify(_obj)
        setValue(input.inputKey, jsonObj)
    }, [gradientDirection]) // eslint-disable-line react-hooks/exhaustive-deps

    const changeColorStopsText = evt => {
        if (evt.target.id) {
            let colorIndex = evt.target.id.split('_').pop()
            let typedValue = evt.target.value
            let _colorsArray = [...colorsArray]
            _colorsArray[colorIndex].value = typedValue
            setColorsArray(_colorsArray)
        }
    }

    const changeSolidFillColorText = evt => {
        setSolidFillColorText(evt.target.value)
    }

    useEffect(() => {
        if (solidFillColorText === useValue.fillColor) {
            return
        }
        if (solidFillColorText === 'transparent' || Utility.validateHexColor(solidFillColorText)) {
            let _obj = { ...useValue }
            _obj.fillColor = solidFillColorText
            let jsonObj = JSON.stringify(_obj)
            setValue(input.inputKey, jsonObj)
        }
    }, [solidFillColorText]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (state.mouseDblClick) {
            if (state.mouseDblClick.target && state.mouseDblClick.target.id) {
                if (state.mouseDblClick.target.id === 'xy_start_' + layerKey + '_' + input.inputKey + '_gradient') {
                    setXyValStart([0.5, 0.5])
                }
                if (state.mouseDblClick.target.id === 'xy_start_' + layerKey + '_' + input.inputKey + '_gradient_x') {
                    setXyValStart([0.5, xyValStart[1]])
                }
                if (state.mouseDblClick.target.id === 'xy_start_' + layerKey + '_' + input.inputKey + '_gradient_y') {
                    setXyValStart([xyValStart[0], 0.5])
                }
                if (state.mouseDblClick.target.id === 'xy_end_' + layerKey + '_' + input.inputKey + '_gradient') {
                    setXyValEnd([1, 1])
                }
            }
        }
    }, [state.mouseDblClick]) // eslint-disable-line react-hooks/exhaustive-deps

    const toggleGradientSettingsOpen = () => {
        setGradientSettingsOpen(!gradientSettingsOpen)
    }

    //
    //
    // gradient direction, linear or radial
    // x y start position. We are only doing relative, since I don't see the point of absolute in this application.
    // the mid color stop position. We won't deal with multiple color stops. This value will be 0 - 1, represented by
    // 0% to 100% (0% really is pointless, but, we'll allow it so people aren't wondering "why doesn't it start at 0%?"
    // Then we got the 3 colors, start, mid, and end.
    return (
        <div className="input-fill">


            <div className="radio-choice">
                <div className="fillType-named">solid fill color or gradient</div>
                <div className="fillType-radio-options">
                    <div className="click-enabled" onClick={() => changeFillType('solid')}>
                        <input type="radio"
                            id={`inputFillType_${layerKey}_${input.inputKey}_solid`}
                            name={`inputFillType_${layerKey}_${input.inputKey}_solid`}
                            value="solid"
                            checked={fillType === 'solid'}
                            onChange={fakeChangeFillType}
                        //onClick={clickFillType}
                        />
                        <label htmlFor={`inputFillType_${layerKey}_${input.inputKey}_solid`} >solid</label>
                    </div>
                    <div className="click-enabled" onClick={() => changeFillType('gradient')}>
                        <input type="radio"
                            id={`inputFillType_${layerKey}_${input.inputKey}_gradient`}
                            name={`inputFillType_${layerKey}_${input.inputKey}_gradient`}
                            value="gradient"
                            checked={fillType === 'gradient'}
                            onChange={fakeChangeFillType}
                        //onClick={clickFillType}
                        />
                        <label htmlFor={`inputFillType_${layerKey}_${input.inputKey}_gradient`} >gradient</label>
                    </div>
                </div>
            </div>

            {fillType === 'solid' ?
                <div id={`solid-color_${input.inputKey}_${layerKey}`} className="input-color solid">
                    <div className="named">solid fill:</div>
                    <div><input type="text" spellCheck="false" className={solidFillColorText === 'transparent' || Utility.validateHexColor(solidFillColorText) ? 'input-color-pick' : 'input-color-pick invalid'} value={solidFillColorText} onChange={changeSolidFillColorText} /></div>
                    <div className="color-sample" style={{ "background": useValue.fillColor }} onClick={openColorPicker} />
                    <div ref={launchSolidFillColorRef}
                        className="multicolor-button"
                        onClick={openColorPicker}>
                        <svg id={`colorPickerButton${input.inputKey}_${layerKey}`} width="28" height="28" viewBox="-14, -14, 28, 28">
                            {multicolorButton.map((dc, index) => {
                                return <path key={index} d={`${dc.d}`} fill={`${dc.color}`} />
                            })}
                            <circle cx="0" cy="0" r="14" stroke="white" strokeWidth="2px" fill="none" />
                            <circle cx="0" cy="0" r="13" stroke="white" strokeWidth="2px" fill="none" opacity="0.5" />
                        </svg>
                    </div>
                </div>

                :


                <div className="gradient-settings">
                    <div className="title">Gradient Settings <span 
                    onClick={toggleGradientSettingsOpen}
                    className={gradientSettingsOpen ? 'material-icons' : 'material-icons closed'}>
                        edit
                    </span></div>
                    <div className={gradientSettingsOpen ? '' : 'display-none'}>
                        <div className="radio-choice-gradient-direction">
                            <div className="named">gradient direction:</div>
                            <div className="radio-options">
                                <div>
                                    <input type="radio"
                                        id={`inputGradientDirection_${layerKey}_${input.inputKey}_linear`}
                                        name={`inputGradientDirection_${layerKey}_${input.inputKey}_linear`}
                                        value='linear'
                                        checked={gradientDirection === 'linear'}
                                        onChange={changeGradientDirection}
                                        onClick={clickGradientDirection}
                                    />
                                    <label htmlFor={`inputGradientDirection_${layerKey}_${input.inputKey}_linear`} >linear</label>
                                </div>
                                <div>
                                    <input type="radio"
                                        id={`inputGradientDirection_${layerKey}_${input.inputKey}_radial`}
                                        name={`inputGradientDirection_${layerKey}_${input.inputKey}_radial`}
                                        value='radial'
                                        checked={gradientDirection === 'radial'}
                                        onChange={changeGradientDirection}
                                        onClick={clickGradientDirection}
                                    />
                                    <label htmlFor={`inputGradientDirection_${layerKey}_${input.inputKey}_radial`} >radial</label>
                                </div>
                            </div>
                        </div>

                        <div id={`input_xy_position_start_${layerKey}_${input.inputKey}`} className="input-xy-position_gradient">
                            <div id={`xy_start_${layerKey}_${input.inputKey}_gradient`} className="named">gradient start position</div>
                            <div className="coordinate-container">
                                <span id={`xy_start_${layerKey}_${input.inputKey}_gradient_x`}>x:</span>
                                <input type="range" min="0" max="1" className="slider" id="input_x_start"
                                    value={xyValStart[0]}
                                    step="0.01"
                                    onChange={changeXyValStart}
                                />
                                <div className="coordinate-readout">{xyValStart[0]}</div>
                            </div>
                            <div className="coordinate-container">
                                <span id={`xy_start_${layerKey}_${input.inputKey}_gradient_y`}>y:</span>
                                <input type="range" min="0" max="1" className="slider" id="input_y_start"
                                    value={xyValStart[1]}
                                    step="0.01"
                                    onChange={changeXyValStart}
                                />
                                <div className="coordinate-readout">{xyValStart[1]}</div>
                            </div>
                        </div>


                        <div id={`input_xy_position_end_${layerKey}_${input.inputKey}`} className="input-xy-position_gradient">
                            <div id={`xy_end_${layerKey}_${input.inputKey}_gradient`} className="named">gradient end position</div>
                            <div className="coordinate-container">
                                <span id={`xy_end_${layerKey}_${input.inputKey}_gradient_x`}>{gradientDirection === 'linear' ? 'x' : 'r'}:</span>
                                <input type="range" min="0" max="1" className="slider" id="input_x_end"
                                    value={xyValEnd[0]}
                                    step="0.01"
                                    onChange={changeXyValEnd}
                                />
                                <div className="coordinate-readout">{xyValEnd[0]}</div>
                            </div>
                            <div className={gradientDirection === 'linear' ? 'coordinate-container' : 'coordinate-container disabled'}>
                                <span id={`xy_end_${layerKey}_${input.inputKey}_gradient_y`}>y:</span>
                                <input type="range" min="0" max="1" className={gradientDirection === 'linear' ? 'slider' : 'slider disabled'} id="input_y_end"
                                    value={xyValEnd[1]}
                                    step="0.01"
                                    onChange={changeXyValEnd}
                                    disabled={gradientDirection === 'radial' ? true : false}
                                />
                                <div className="coordinate-readout">{xyValEnd[1]}</div>
                            </div>
                        </div>



                        {colorsArray.map((clr, index) =>
                            <div key={index} id={`input_${clr.name}_${layerKey}_${input.inputKey}`} className="input-color">
                                <div className="named">{clr.name}:</div>

                                <div><input type="text" spellCheck="false"
                                    className={clr.value === 'transparent' || Utility.validateHexColor(clr.value) ? 'input-color-pick' : 'input-color-pick invalid'}
                                    id={`color_${clr.name}_${layerKey}_${input.inputKey}_textColor_${index}`}
                                    value={clr.value} onChange={changeColorStopsText} /></div>

                                <div id={`color_${clr.name}_${layerKey}_${input.inputKey}_colorBox`} className="color-sample" style={{ "background": clr.value }} onClick={() => openColorGradientPicker(index)} />
                                <div id={`color_${clr.name}_${layerKey}_${input.inputKey}_colorWheel`}
                                    ref={clr.launchColorPickerRef}

                                    className="multicolor-button"

                                    onClick={() => openColorGradientPicker(index)}>
                                    <svg id={`colorPickerButton_${layerKey}_${input.inputKey}`} width="28" height="28" viewBox="-14, -14, 28, 28">
                                        {multicolorButton.map((dc, index) => {
                                            return <path key={index} d={`${dc.d}`} fill={`${dc.color}`} />
                                        })}
                                        <circle cx="0" cy="0" r="14" stroke="white" strokeWidth="2px" fill="none" />
                                        <circle cx="0" cy="0" r="13" stroke="white" strokeWidth="2px" fill="none" opacity="0.5" />
                                    </svg>

                                </div>
                            </div>
                        )}


                    </div>



                </div>




            }
        </div>
    )
}
export default InputFill