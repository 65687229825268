import Utility from './Utility'
import Snap from 'snapsvg-cjs'


/*
<defs>
  <style>
    @font-face {
     font-family: foo;
     src: url('data:application/font-woff;charset=utf-8;base64,...')
    }
  </style>
</defs>

// https://observablehq.com/@leonelgalan/embedding-fonts-into-an-svg
async function addWebFont(selection, fontName, fontURL, fontType = 'woff2') {
    const fontData = await toDataURL(fontURL);
    return selection.append('style').text(`
      @font-face {
        font-family: '${fontName}';
        src: url(${fontData}) format('${fontType}');
      }
    `);
  };
*/

const IdentifierText = {

    draw: (g, settings) => {
        let derivedValues = {}
        settings.forEach(setting => {
            switch (setting.name) {
                case 'identifier': derivedValues.text = setting.value
                    break
                case 'font': derivedValues.fontFamily = setting.value
                    break
                case 'font style': derivedValues.fontStyle = setting.value
                    break
                case 'font size': derivedValues.fontSize = Number(setting.value)
                    break
                case 'font weight': derivedValues.fontWeight = setting.value
                    break
                case 'stroke color': derivedValues.strokeColor = setting.value
                    break
                case 'stroke width': derivedValues.strokeWidth = setting.value
                    break
                case 'dimensions':
                    if (!setting.value || Array.isArray(setting.value) === false) {
                        derivedValues.width = 55
                        derivedValues.height = 55
                    }
                    else {
                        derivedValues.width = Utility.roundFloat(setting.value[0], 1)
                        derivedValues.height = Utility.roundFloat(setting.value[1], 1)
                    }
                    break
                case 'xy position':
                    if (Array.isArray(setting.value) === false || setting.value.length !== 2) {
                        setting.value = [1, 1]
                    }
                    if (isNaN(setting.value[0])) {
                        setting.value[0] = 1
                    }
                    if (isNaN(setting.value[1])) {
                        setting.value[1] = 1
                    }
                    derivedValues.xpos = Utility.roundFloat(setting.value[0], 1)
                    derivedValues.ypos = Utility.roundFloat(setting.value[1], 1)
                    break
                case 'line color': derivedValues.textColor = setting.value
                    break
                case 'fill color': derivedValues.fillColor = setting.value
                    break
                case 'opacity': derivedValues.opacity = Utility.roundFloat(setting.value / 100, 3)
                    break
                case 'rotation': derivedValues.rotation = Utility.roundFloat(setting.value, 2)
                    break
                case 'letter gap': derivedValues.letterGap = parseInt(setting.value)
                    break
                default: console.warn('we seem to have missed one: ', setting.name)
            }
        })

        if (derivedValues.width === undefined || derivedValues.height === undefined) {
            derivedValues.width = 66
            derivedValues.height = 66
        }

        derivedValues.strokeWidth = (derivedValues.fontSize) * (derivedValues.strokeWidth / 100) / 15
        if (derivedValues.strokeColor === '') {
            derivedValues.strokeColor = 'transparent'
        }
        if (derivedValues.fillColor === '') {
            derivedValues.fillColor = 'transparent'
        }
        if (derivedValues.fontWeight === 'thin') {
            derivedValues.fontWeight = 100
        }
        if (derivedValues.fontWeight === 'normal') {
            derivedValues.fontWeight = 500
        }
        if (derivedValues.fontWeight === 'bold') {
            derivedValues.fontWeight = 900
        }

        let scaleWidth = derivedValues.width
        let scaleHeight = derivedValues.height
        let xpos = derivedValues.xpos
        let ypos = derivedValues.ypos
        let rotation = derivedValues.rotation
        let opacity = derivedValues.opacity

        IdentifierText.drawText(g, derivedValues)

        g.transform(new Snap.matrix().scale(scaleWidth / 100, scaleHeight / 100))

        if (opacity < 1) {
            g.attr({ "opacity": opacity })
        }

        if (rotation > 0) {
            IdentifierText.applyRotation(g, rotation)
        }
        if (xpos !== 0 || ypos !== 0) {
            IdentifierText.applyTranslation(g, xpos, ypos)
        }

    },

    drawText: (g, derivedValues) => {
        g.text(0, 0, derivedValues.text ? derivedValues.text : '').attr(
            {
                fontFamily: derivedValues.fontFamily,
                fontStyle: derivedValues.fontStyle,
                textAnchor: "middle",
                dominantBaseline: "central",
                fontWeight: derivedValues.fontWeight,
                fill: derivedValues.fillColor,
                stroke: derivedValues.strokeColor,
                strokeWidth: derivedValues.strokeWidth,
                fontSize: derivedValues.fontSize,
                letterSpacing: derivedValues.letterGap,
                opacity: derivedValues.opacity
            })

    },

    drawPath: (g, d, derivedValues) => {
        g.path(d).attr({ stroke: derivedValues.textColor, strokeWidth: derivedValues.lineThickness, fill: derivedValues.textColor, 'stroke-linejoin': derivedValues.linejoin });
    },

    drawLine: (g, x1, y1, x2, y2, derivedValues) => {
        x1 = Utility.roundFloat(x1, 2)
        y1 = Utility.roundFloat(y1, 2)
        x2 = Utility.roundFloat(x2, 2)
        y2 = Utility.roundFloat(y2, 2)
        g.line(Utility.roundFloat(x1, 2), Utility.roundFloat(y1), Utility.roundFloat(x2), Utility.roundFloat(y2)).attr({
            "stroke": derivedValues.textColor,
            "strokeWidth": derivedValues.lineThickness,
            "strokeLinecap": "round"
        })
    },

    drawBlankEllipse: (g, x, y, rx, ry, derivedValues) => {
        x = Utility.roundFloat(x, 2)
        y = Utility.roundFloat(y, 2)
        rx = Utility.roundFloat(rx, 2)
        ry = Utility.roundFloat(ry, 2)
        g.ellipse(Utility.roundFloat(x, 2), Utility.roundFloat(y, 2), Utility.roundFloat(rx, 2), Utility.roundFloat(ry, 2)).attr({
            "stroke": derivedValues.textColor,
            "strokeWidth": derivedValues.lineThickness,
            "fill": "none"
        })
    },

    drawEllipse: (g, x, y, rx, ry, derivedValues) => {
        x = Utility.roundFloat(x, 2)
        y = Utility.roundFloat(y, 2)
        rx = Utility.roundFloat(rx, 2)
        ry = Utility.roundFloat(ry, 2)
        g.ellipse(Utility.roundFloat(x, 2), Utility.roundFloat(y, 2), Utility.roundFloat(rx, 2), Utility.roundFloat(ry, 2)).attr({
            "stroke": derivedValues.textColor,
            "strokeWidth": derivedValues.lineThickness,
            "fill": "none"
        })
    },

    drawTriangle: (g, tx1, ty1, tx2, ty2, tx3, ty3, derivedValues) => {
        tx1 = Utility.roundFloat(tx1, 2)
        ty1 = Utility.roundFloat(ty1, 2)
        tx2 = Utility.roundFloat(tx2, 2)
        ty2 = Utility.roundFloat(ty2, 2)
        tx3 = Utility.roundFloat(tx3, 2)
        ty3 = Utility.roundFloat(ty3, 2)
        g.path(`M ${tx1} ${ty1} ${tx2} ${ty2} ${tx3} ${ty3} z`).attr({
            "stroke": "none",
            "strokeWidth": 0,
            "fill": derivedValues.textColor,
        })
    },

    drawCircle: (g, x, y, r, derivedValues) => {
        x = Utility.roundFloat(x, 2)
        y = Utility.roundFloat(y, 2)
        r = Utility.roundFloat(r, 2)
        g.circle(x, y, r).attr({
            "stroke": "none",
            "fill": derivedValues.textColor
        })
    },

    drawBlankCircle: (g, x, y, r, derivedValues) => {
        x = Utility.roundFloat(x, 2)
        y = Utility.roundFloat(y, 2)
        r = Utility.roundFloat(r, 2)
        g.circle(x, y, r).attr({
            "stroke": derivedValues.textColor,
            "strokeWidth": derivedValues.lineThickness,
            "fill": "none"
        })
    },

    applyRotation: (g, rotation) => {
        let measureG = g.getBBox()
        let currentMatrix = g.transform().localMatrix;
        let addMatrix = new Snap.Matrix()
        addMatrix.rotate(rotation, measureG.cx, measureG.cy)
        addMatrix.add(currentMatrix)
        g.transform(addMatrix)
    },

    applyTranslation: (g, xpos, ypos) => {
        let currentMatrix = g.transform().localMatrix;
        let addMatrix = new Snap.Matrix()
        addMatrix.translate(xpos, ypos)
        addMatrix.add(currentMatrix)
        g.transform(addMatrix)
    },

}
export default IdentifierText