import React, { useState, useEffect, useContext, useRef } from 'react'
import { StoreContext } from "../../context/StoreContext"
import JSZip from 'jszip'
// import { saveAs } from 'file-saver'
import Utility from "../../objects/Utility"
import './LoadCounter.scss'

const LoadCounter = ({ useText }) => {
    const { actions, state } = useContext(StoreContext)
    const [dialogOpen, setDialogOpen] = useState(true)
    const [issueMissingElementsError, setIssueMissingElementsError] = useState(false)
    const loadCounterDialogRef = useRef(null)

    const [savedCounters, setSavedCounters] = useState([])

    useEffect(() => {
        // prevent overlay from knowing the mouse was clicked, if it was clicked inside the load counter dialog area.
        let copyOfRef = loadCounterDialogRef.current // linter said I should copy this to a variable so the cleanup function can be reliable.
        loadCounterDialogRef.current.addEventListener('mouseup', function (e) {
            e.stopPropagation();
        });

        return () => {
            if (copyOfRef) {
                copyOfRef.removeEventListener('mouseup', function (e) {
                    e.stopPropagation();
                });
            }
        }

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (state.loadCounter && state.loadCounter.activeLayerValues && state.loadCounter.layers) {
            // let activeLayerValues = state.loadCounter.activeLayerValues
            // let layers = state.loadCounter.layers
            // actions.activeLayerValuesReset(activeLayerValues)
            // actions.layers(layers)
            // actions.loadCounter(null)
            setupCounter(state.loadCounter)
            actions.loadCounter(null)
        }
    }, [state.loadCounter]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (issueMissingElementsError) {
            setTimeout(() => {
                actions.addErrorMessages(['there is a reference to a missing custom item!'])
            }, 100)
        }
        setIssueMissingElementsError(false)
    }, [issueMissingElementsError]) // eslint-disable-line react-hooks/exhaustive-deps

    const setupCounter = async (counterData) => {
        // we are ignoring counterData.svgs that come in, since they may conflict with what the user has installed svgs and images.
        let activeLayerValues = counterData.activeLayerValues
        let layers = counterData.layers

        // see if any layers are custom item layers, and if their required svg key points to a non-existant
        // svg. If it does, we will remove that layer's info from the incoming activeLayerValues, and
        // set its layers version of the custom layer to non active and possibly hidden, if the live
        // laye
        let customLayers = layers.filter(ly => Utility.isCustomLayer(ly.layerKey, layers))
        if (customLayers && customLayers.length > 0) {
            customLayers = customLayers.filter(ly => ly.layerActive === 1)
            if (customLayers && customLayers.length > 0) {
                customLayers.forEach(customLayer => {
                    let availableCustomItems = inspectLayerForAvailableCustomItems(customLayer)
                    // if there are no availableSvgItems, obviously this layer should be set to active=0 and hidden=1
                    if (availableCustomItems.length === 0) {
                        setIssueMissingElementsError('there is a reference to a missing custom item!')
                        customLayer.layerActive = 0
                        customLayer.layerHidden = 1
                        activeLayerValues = deleteLayerRefsFromActiveLayerValues(customLayer.layerKey, activeLayerValues)
                    }
                    else {
                        // ok we need to see if the svgKey specified as selected exists as available.
                        let keyRequired = customLayer.layerActiveRequiredInputKey
                        let svgKeyRequired = activeLayerValues[customLayer.layerKey + '_' + keyRequired]
                        if (!availableCustomItems.includes(svgKeyRequired)) {
                            activeLayerValues = deleteLayerRefsFromActiveLayerValues(customLayer.layerKey, activeLayerValues)
                            // this layer wants a svgKey that doesnt exist for it.
                            setIssueMissingElementsError('there is a reference to a missing custom item!')
                            customLayer.layerActive = 0
                        }
                    }
                })
            }
        }

        // extract deprecated widths and heights.
        let deprecatedWidths = JSON.parse(JSON.stringify(Utility.deprecatedWidths))

        deprecatedWidths.forEach(dw => {
            let lk = dw.layerKeys[0]
            layers.forEach(ly => {
                if (Number(ly.parentLayerKey) === Number(lk)) {
                    dw.layerKeys.push(Number(ly.layerKey))
                }
            })
        })

        deprecatedWidths.forEach(dw => {
            dw.layerKeys.forEach(lk => {
                let width = null
                let height = null
                if (activeLayerValues[lk + '_' + dw.width] &&
                    activeLayerValues[lk + '_' + dw.height]) {
                    width = Number(activeLayerValues[lk + '_' + dw.width])
                    height = Number(activeLayerValues[lk + '_' + dw.height])
                    activeLayerValues[lk + '_' + dw.dimensions] = [width, height]
                    delete activeLayerValues[lk + '_' + dw.width]
                    delete activeLayerValues[lk + '_' + dw.height]
                }
                else {
                    width = 66
                    height = 66
                }

                let layer = layers.find(ly => ly.layerKey === lk)
                if (layer) {
                    if (layer.layerInputKeys.find(ik => ik === dw.width)) {
                        let startDeleteIndex = layer.layerInputKeys.findIndex(ik => ik === dw.width)
                        // console.log('in layerInputKeys, we found the dw.width', dw.width,'at index', startDeleteIndex)
                        if (startDeleteIndex > -1) {
                            let upgradedInputKeys = JSON.parse(JSON.stringify(layer.layerInputKeys))
                            upgradedInputKeys = upgradedInputKeys.filter((data, idx) => idx !== startDeleteIndex);
                            upgradedInputKeys = upgradedInputKeys.filter((data, idx) => idx !== startDeleteIndex);
                            upgradedInputKeys = upgradedInputKeys.filter((data, idx) => idx !== startDeleteIndex);
                            upgradedInputKeys = Utility.arrayInsertAtIndexItem(upgradedInputKeys, startDeleteIndex, dw.dimensions)
                            layer.layerInputKeys = upgradedInputKeys
                        }
                    }

                    if (layer.inputs.find(lin => lin.inputKey === dw.width)) {
                        let deleteIndex = layer.inputs.findIndex(lin => lin.inputKey === dw.width)
                        if (deleteIndex > -1) {
                            let newLayerInput = { "defaultArrayMinMaxValue": dw.defaultArrayMinMaxValue, comment: dw.comment, "defaultFloatArrayValue": dw.defaultFloatArrayValue, "defaultIntValue": null, "defaultStrValue": null, "inputKey": dw.dimensions, "list": null, "message": "width height for " + dw.name, "named": "dimensions", "stringForArray": null, "type": "width_height", "typeKey": 16, "valueClass": "array" }
                            let upgradedInputs = JSON.parse(JSON.stringify(layer.inputs))
                            upgradedInputs = upgradedInputs.filter((data, idx) => idx !== deleteIndex);
                            upgradedInputs = upgradedInputs.filter((data, idx) => idx !== deleteIndex);
                            upgradedInputs = upgradedInputs.filter((data, idx) => idx !== deleteIndex);
                            upgradedInputs = Utility.arrayInsertAtIndexItem(upgradedInputs, deleteIndex, newLayerInput)
                            layer.inputs = upgradedInputs
                        }
                    }
                }
            })
        })
        // console.log('updating state with activeLayerValues:', activeLayerValues)
        // console.log('updating state with layers:', layers)
        actions.activeLayerValuesReset(activeLayerValues)
        //actions.layers(layers)

        let newArray = []
        for (const [key, value] of Object.entries(activeLayerValues)) {
            newArray.push({ lik: key, value })
        }
        // console.log('clearing dexie activeLayerValues and replacing with:', newArray)
        await state.dexie['activeLayerValues'].clear()
        for (let na = 0; na < newArray.length; na++) {
            let activeLayerValue = newArray[na]
            await state.dexie['activeLayerValues'].put(activeLayerValue)
        }
        // some layers will be marked as active or not active
        for (let ld = 0; ld < layers.length; ld++) {
            await state.dexie['layers'].put(layers[ld])
        }

        let existingLayers = JSON.parse(JSON.stringify(state.layers))
        for (let n = 0; n < layers.length; n++) {
            let layerKey = layers[n].layerKey
            let existingIndex = existingLayers.findIndex(elr => elr.layerKey === layerKey)
            if (existingIndex > -1) {
                existingLayers[existingIndex] = layers[n]
            }
            else {
                // new layer, needs to be reordered into old spot.
                layers[n].layerOrder -= 0.5
                existingLayers.push(layers[n])
            }
        }

        existingLayers.sort((a, b) => a.layerOrder - b.layerOrder)
        existingLayers.forEach((ly, index) => {
            ly.layerOrder = index
        })

        if (existingLayers.length > 6) {
            // update Dexie
            //await Utility.dexieClearTable(state.dexie, 'layers')
            await Utility.dexieBulkAdd(state.dexie, 'layers', existingLayers)

            actions.layers(existingLayers)
        }
    }

    useEffect(() => {
        if (Utility.emptyCheck(state.savedCounters) === false) {
            let values = Object.values(state.savedCounters) // values gets the object thats
            // pushes values to savedCounters state array.
            setSavedCounters(values) // for local use
        }
    }, [state.savedCounters]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (Utility.emptyCheck(state.savedCounters) === false) {
            let values = Object.values(state.savedCounters)
            setSavedCounters(values) // for local use
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const toggleLoadCounterDialog = async () => {
        setDialogOpen(!dialogOpen)
    }

    useEffect(() => {
        actions.overlay(dialogOpen)
    }, [dialogOpen]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (dialogOpen) {
            setDialogOpen(false)
        }

    }, [state.mouseClick]) // eslint-disable-line react-hooks/exhaustive-deps

    const loadFromApp = async(evt) => {
        actions.overlay(true)
        let hash = evt.target.value
        let counterObj = state.savedCounters[hash]
                // need to change:
        // 1. state activeLayerValues - remove layer keys other than 1
        stateClearActiveLayerValues()
        // 2. state layers - active: 0
        stateDeactivateLayers()
        // 3. dexie activeLayerValues - remove layer keys other than 1
        await dexieClearActiveLayerValues()
        // 4. dexie layers - active: 0
        await dexieDeactivateLayers()

        setupCounter(counterObj.counterState)
        setDialogOpen(false)
    }

    useEffect(() => {
        if (state.activeLayerValues) {
            let dexieCompatible = []
            for (const [inputKey, value] of Object.entries(state.activeLayerValues)) {
                dexieCompatible.push({ lik: inputKey, value })
            }
            Utility.updateDexieStore(state.dexie, 'activeLayerValues', dexieCompatible, 'lik')
        }
    }, [state.activeLayerValues]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // async function clearTable() {
        //     //console.log('clearTable')
        //     await Utility.dexieClearTable(state.dexie, 'layers')
        //     console.log('is it here?')
        //     Utility.dexieBulkAdd(state.dexie, 'layers', state.layers)
        // }
        async function updateLayers() {
            for (let i = 0; i < state.layers.length; i++) {
                let ly = state.layers[i]
                await Utility.dexieAddPutItem(state.dexie, 'layers', ly, 'layerKey')
            }
        }
        if (state.layers.length > 15) {
            updateLayers()
        }
    }, [state.layers]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

    }, [state.layers])

    const stateClearActiveLayerValues = () => {
        let clearedActiveLayerValues = {}
        for (const [key, value] of Object.entries(state.activeLayerValues)) {
            if (key.startsWith('1_')) {
                clearedActiveLayerValues[key] = value
            }
        }
        actions.activeLayerValues(clearedActiveLayerValues)
    }

    const stateDeactivateLayers = () => {
        let _layers = state.layers.map(ly => {
            if (ly.layerKey > 1) {
                ly.layerActive = 0
            }
            return ly
        })
        actions.layers(_layers)
    }

    const dexieClearActiveLayerValues = async () => {
        let activeLayerValueKeys = await state.dexie.activeLayerValues.orderBy('lik').keys()
        let removeKeys = activeLayerValueKeys.filter(alvk => !alvk.startsWith('1_'))
        await state.dexie.activeLayerValues.where('lik').anyOf(...removeKeys).delete()
    }

    const dexieDeactivateLayers = async () => {
        let layerKeys = await state.dexie.layers.orderBy('layerKey').keys()
        layerKeys.shift() // leave the base layer
        for( let i = 0; i < layerKeys.length; i++ ) {
            await state.dexie.layers.update( layerKeys[i], { active: 0 } )
        }
    }

    const loadFromFile = async (evt) => {
        // no,  actions.counterClear(true)
        actions.overlay(true)
        // need to change:
        // 1. state activeLayerValues - remove layer keys other than 1
        stateClearActiveLayerValues()
        // 2. state layers - active: 0
        stateDeactivateLayers()
        // 3. dexie activeLayerValues - remove layer keys other than 1
        await dexieClearActiveLayerValues()
        // 4. dexie layers - active: 0
        await dexieDeactivateLayers()

        let file = evt.target.files[0]

        JSZip.loadAsync(file).then((zip) => {
            Object.keys(zip.files).forEach((filename) => {
                zip.files[filename].async('string').then(async (fileData) => {
                    let data = null
                    try {
                        data = JSON.parse(fileData)
                    }
                    catch (e) {
                        console.warn('error loading file')
                        return
                    }
                    if (data.app === undefined || data.app !== 'snapCounter') {
                        console.warn('this file does not appear to be a snapCounter file')
                        return
                    }
                    if (data.type === undefined || data.type !== 'counter') {
                        console.warn('this file does not have counter data')
                        return
                    }
                    if (data.counterState === undefined) {
                        console.warn('there is no data to reconstruct a counter.')
                        return
                    }
                    if (data.hasOwnProperty('counterState')) {
                        // we have data
                        setupCounter(data.counterState)
                        setDialogOpen(false)
                    }

                })
            })
            document.getElementById("counterFile").value = "";
        })
    }

    const deleteLayerRefsFromActiveLayerValues = (layerKey, activeLayerValues) => {
        let alvKeys = Object.keys(activeLayerValues)
        alvKeys.forEach(alvKey => {
            if (alvKey.startsWith(layerKey + '_')) {
                delete activeLayerValues[alvKey]
            }
        })
        return activeLayerValues
    }

    const inspectLayerForAvailableCustomItems = (customLayer) => {
        let layerKey = customLayer.layerKey
        let parentLayerKey = customLayer.parentLayerKey
        let originalLayer = null
        if (parentLayerKey === -1) {
            originalLayer = state.layers.find(ly => ly.layerKey === layerKey)
        }
        else {
            originalLayer = state.layers.find(ly => ly.layerKey === parentLayerKey)
        }
        let availableSvgKeys = []
        let inputs = originalLayer.inputs
        let svgsInput = inputs.find(input => input.named === 'svgKey')
        if (svgsInput) {
            if (svgsInput.list.length > 0) {
                svgsInput.list.forEach(svgKey => {
                    let found = state.svgs.find(svg => svg.svgKey === svgKey)
                    if (found) {
                        availableSvgKeys.push(svgKey)
                    }
                })
            }
        }
        return availableSvgKeys
    }

    return (
        <div className="load-counter" id="loadCounter">
            <button className="action-button cyanish" onClick={toggleLoadCounterDialog} >{useText}</button>

            <div ref={loadCounterDialogRef} id="dialog" className={dialogOpen ? 'dialog' : 'display-none'}>

                <div className="load-section">
                    <div className="load-section-title">LOAD FROM APP</div>
                    <div className="load-input-inline">
                        <div>load counter:</div>
                        <select id="savedCounter" value="" onChange={loadFromApp}>
                            <option key="none" value="">choose counter</option>
                            {
                                savedCounters.map((sc, index) => <option key={index} value={sc.hash}>{sc.name}</option>)
                            }
                        </select>

                    </div>

                </div>



                <div className="load-section load-from-file">
                    <div className="load-section-title">LOAD FROM FILE</div>
                    <input type="file" id="counterFile" accept=".scz" onChange={loadFromFile} />
                </div>

            </div>

        </div>
    )
}
export default LoadCounter