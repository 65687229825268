import React, { useEffect, useState } from 'react'
import './ExplodeDragger.scss'
const ExplodeDragger = ({ showExplodeDragger, explodeDistance }) => {
    
  const [rangeVal, setRangeVal] = useState(50)
    
    const changeRangeVal = evt => {
      setRangeVal(Number(evt.target.value))
    }

    useEffect(() => {
      explodeDistance(rangeVal)
    }, [rangeVal]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
     <div className={showExplodeDragger ? 'explode-dragger' : 'display-none'}>
          <input type="range" 
          min="0" max="100"
          step="1"
          value={rangeVal}
          onChange={changeRangeVal}
          />
     </div>
    )

}
export default ExplodeDragger