import { types } from "./reducers";
import Utility from "../objects/Utility";
export const useActions = (state, dispatch) => {

    function restoreCounterState(message) {
        dispatch({ type: types.RESTORE_COUNTER_STATE, payload: message })
    }

    function browser(message) {
        dispatch({ type: types.BROWSER, payload: message })
    }
    function os(message) {
        dispatch({ type: types.OS, payload: message })
    }

    function help(message) {
        dispatch({ type: types.HELP, payload: message })
    }

    function restoreDatabase(message) {
        dispatch({ type: types.RESTORE_DATABASE, payload: message })
    }

    function restoreWorkspace(message) {
        dispatch({ type: types.RESTORE_WORKSPACE, payload: message })
    }

    function sheetPaper(message) {
        dispatch({ type: types.SHEET_PAPER, payload: message })
    }

    function loadCounter(message) {
        dispatch({ type: types.LOAD_COUNTER, payload: message })
    }

    function blur(message) {
        dispatch({ type: types.BLUR, payload: message })
    }
    
    function focus(message) {
        dispatch({ type: types.FOCUS, payload: message })
    }
    
    function mouseDown(evt) {
        dispatch({ type: types.MOUSE_DOWN, payload: evt });
    }

    function mouseClick(evt) {
        dispatch({ type: types.MOUSE_CLICK, payload: evt });
    }

    function mouseDblClick(evt) {
        dispatch({ type: types.MOUSE_DOUBLE_CLICK, payload: evt });
    }

    function returnKey(evt) {
        dispatch({ type: types.RETURN_KEY, payload: evt });
    }

    function keyUp(evt) {
        dispatch({ type: types.KEY_UP, payload: evt });
    }

    function windowWidthHeight(evt) {
        dispatch({ type: types.WINDOW_WIDTH_HEIGHT, payload: evt })
    }

    function counterDrawWidth(evt) {
        dispatch({ type: types.COUNTER_DRAW_WIDTH, payload: evt })
    }

    function addErrorMessages(message) {
        dispatch({ type: types.ERROR_MESSAGES, payload: message })
    }

    function clearErrorMessages() {
        dispatch({ type: types.CLEAR_ERROR_MESSAGES, payload: [] })
    }

    function colorPicker(evt) {
        dispatch({ type: types.COLOR_PICKER, payload: evt })
    }

    function overlay(evt) {
        dispatch({ type: types.OVERLAY, payload: evt })
    }

    function dataDisplay(evt) {
        dispatch({ type: types.DATA_DISPLAY, payload: evt })
    }

    function installView(evt) {
        dispatch({ type: types.INSTALL_VIEW, payload: evt })
    }

    function layers(evt) {
        dispatch({ type: types.LAYERS, payload: evt })
    }

    function addLayer(evt) {
        dispatch({ type: types.ADD_LAYER, payload: evt })
    }

    function layersOrder(evt) {
        dispatch({ type: types.LAYERS_ORDER, payload: evt })
    }

    function layerUpdate(evt) {
        dispatch({ type: types.LAYER_UPDATE, payload: evt })
    }

    function layersUpdate(evt) {
        dispatch({ type: types.LAYERS_UPDATE, payload: evt })
    }

    function savedCounters(evt) {
        dispatch({ type: types.SAVED_COUNTERS, payload: evt })
    }

    function savedCountersRemove(evt) {
        dispatch({ type: types.SAVED_COUNTERS_REMOVE, payload: evt })
    }

    function savedSheets(evt) {
        dispatch({ type: types.SAVED_SHEETS, payload: evt })
    }

    function savedSheetsRemove(evt) {
        dispatch({ type: types.SAVED_SHEETS_REMOVE, payload: evt })
    }

    function activeLayerValues(evt) {
        // trying to avoid occassional stackoverflow errors.
        if( Utility.deepEqual(state.activeLayerValues,evt) ) {
            return
        }
        dispatch({ type: types.ACTIVE_LAYER_VALUES, payload: evt })
    }

    function activeLayerValuesRemove(evt) {
        dispatch({ type: types.ACTIVE_LAYER_VALUES_REMOVE, payload: evt })
    }

    function activeLayerValuesReset(evt) {
        dispatch({ type: types.ACTIVE_LAYER_VALUES_RESET, payload: evt })
    }

    function svgs(evt) {
        dispatch({ type: types.SVGS, payload: evt })
    }

    function svgsAdd(evt) {
        dispatch({ type: types.SVGS_ADD, payload: evt })
    }

    function svgsRemove(evt) {
        dispatch({ type: types.SVGS_REMOVE, payload: evt })
    }

    function fonts(evt) {
        dispatch({ type: types.FONTS, payload: evt })
    }

    function dexie(message) {
        dispatch({ type: types.DEXIE, payload: message })
    }

    function draggingLayer(message) {
        dispatch({ type: types.DRAGGING_LAYER, payload: message })
    }

    function dragUpdate(message) {
        dispatch({ type: types.DRAG_UPDATE, payload: message })
    }

    function moveCounterToSheet(message) {
        dispatch({ type: types.MOVE_COUNTER_TO_SHEET, payload: message })
    }

    function cloneCounterToSlot(message) {
        dispatch({ type: types.CLONE_COUNTER_TO_SLOT, payload: message })
    }

    function sheetSettings(message) {
        dispatch({ type: types.SHEET_SETTINGS, payload: message })
    }

    function slots(evt) {
        dispatch({ type: types.SLOTS, payload: evt })
    }

    function counterClear(evt) {
        dispatch({ type: types.COUNTER_CLEAR, payload: evt })
    }

    function sheetSave(evt) {
        dispatch({ type: types.SHEET_SAVE, payload: evt })
    }

    function sheetSaved(evt) {
        dispatch({ type: types.SHEET_SAVED, payload: evt })
    }

    function activateLayer(evt) {
        dispatch({ type: types.ACTIVATE_LAYER, payload: evt })
    }

    function userLoadSlotsSheet(evt) {
        dispatch({ type: types.USER_LOAD_SLOTS_SHEET, payload: evt })
    }

    return {
        restoreCounterState,
        browser,
        os,
        help,
        restoreDatabase,
        restoreWorkspace,
        sheetPaper,
        loadCounter,
        blur,
        focus,
        mouseDown,
        mouseClick,
        mouseDblClick,
        returnKey,
        keyUp,
        windowWidthHeight,
        counterDrawWidth,
        addErrorMessages,
        clearErrorMessages,
        colorPicker,
        overlay,
        dataDisplay,
        installView,
        layers,
        addLayer,
        layersOrder,
        layerUpdate,
        layersUpdate,
        savedCounters,
        savedCountersRemove,
        savedSheets,
        savedSheetsRemove,
        activeLayerValues,
        activeLayerValuesRemove,
        activeLayerValuesReset,
        svgs,
        svgsAdd,
        svgsRemove,
        fonts,
        dexie,
        draggingLayer,
        dragUpdate,
        moveCounterToSheet,
        cloneCounterToSlot,
        sheetSettings,
        slots,
        counterClear,
        sheetSave,
        sheetSaved,
        activateLayer,
        userLoadSlotsSheet,
    };
};