import React, { useState, useEffect, useContext, useRef } from 'react'
import { StoreContext } from "../../context/StoreContext"
import XButton from '../XButton/XButton'
import JSZip from 'jszip'
import './RestoreMenu.scss'

const RestoreMenu = () => {
    const { state, actions } = useContext(StoreContext)
    const [restoreResult, setRestoreResult] = useState({ state: null, message: '' })
    const restoreMenuRef = useRef(null)

    useEffect(() => {
        let copyOfRef = restoreMenuRef.current // linter said I should copy this to a variable so the cleanup function can be reliable.
        restoreMenuRef.current.addEventListener('mouseup', function (e) {
            e.stopPropagation();
        });

        return () => {
            if (copyOfRef) {
                copyOfRef.removeEventListener('mouseup', function (e) {
                    e.stopPropagation();
                });
            }
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps



    useEffect(() => {
        exitView()
    }, [state.mouseClick]) // eslint-disable-line react-hooks/exhaustive-deps

    const reset = () => {
        window.scrollTo(0, 0)
        document.getElementById("menuFile").value = "";
        setRestoreResult({ state: null, message: '' })
    }

    const closeAction = () => {
        exitView()
    }

    useEffect(() => {

    }, [state.installView])

    const exitView = () => {
        if (state.installView === 'restore menu') {
            reset()
            actions.installView('')
        }
    }

    const loadFromFile = evt => {
        setRestoreResult({ state: null, message: '' })
        let file = evt.target.files[0]

        JSZip.loadAsync(file).then((zip) => {
            Object.keys(zip.files).forEach((filename) => {
                zip.files[filename].async('string').then(async (fileData) => {
                    let data = null
                    try {
                        data = JSON.parse(fileData)
                    }
                    catch (e) {
                        setRestoreResult({ state: false, message: 'error loading file' })
                        return
                    }
                    if (data.app === undefined || data.app !== 'snapCounter') {
                        setRestoreResult({ state: false, message: 'this file does not appear to be a snapCounter file' })
                        return
                    }
                    if (data.type === undefined || data.type !== 'menu') {
                        setRestoreResult({ state: false, message: 'this file does not have menu data' })
                        return
                    }
                    if (data.menu === undefined) {
                        setRestoreResult({ state: false, message: 'there is no data to reconstruct the menu.' })
                        return
                    }
                    if (data.menu.fonts &&
                        data.menu.layers &&
                        data.menu.svgs) {
                        // we have data
                        let fonts = data.menu.fonts
                        let layers = data.menu.layers
                        let svgs = data.menu.svgs
                        actions.fonts(fonts)
                        actions.svgs(svgs)
                        actions.layers(layers)
                        setRestoreResult({ state: true, message: 'Menus have been restored.' })
                    }
                    else {
                        setRestoreResult({ state: false, message: 'The data seems to be corrupted!' })
                        return
                    }

                })
            })
            
        })


    }

    return (
        <div ref={restoreMenuRef} className={state.installView === 'restore menu' ? 'restore-menu' : 'display-none'}>

            <div className="restore-menu-top">
                <div className="title">Restore Menu</div>
                <div className="close-button">
                    <XButton r="12" closeAction={closeAction} />
                </div>
            </div>
            <div className="intro">
                <p>
                    You can select a previously saved Menu backup to load into the app.
                </p>
                <p>
                    This will remove any customizations you may have made to your current menu, such as<br />
                    duplicating/hiding layers, or adding svgs and images.
                </p>
                <p>
                    If you have counters that use duplicated layers, or custom svgs or images, they will<br />
                    only display properly if those layers and svgs are present in the App's menu environment.
                </p>
            </div>

            <div className="form-area">
                <input type="file" id="menuFile" accept=".zip" onChange={loadFromFile} />
            </div>
            {restoreResult.message ? <div className={restoreResult.state ? 'restore-result' : 'restore-result error'}>{restoreResult.message}</div> : ''}


        </div>
    );
}
export default RestoreMenu