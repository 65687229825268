import React, { useContext, useEffect, useState, useRef } from 'react'
import { StoreContext } from "../../context/StoreContext"
import Utility from "../../objects/Utility"
import './SheetControl.scss'

const SheetControl = () => {
    const { state, actions } = useContext(StoreContext)
    const [countersColumnsRows, setCountersColumnsRows] = useState(null)
    const [counterSize, setCounterSize] = useState(null)
    const [counterCustomSize, setCounterCustomSize] = useState(null)
    const [useCounterCustomSize, setUseCounterCustomSize] = useState(null)

    const [counterMargins, setCounterMargins] = useState(null)
    const [pageMargins, setPageMargins] = useState(null)

    const [inputWarningArray, setInputWarningArray] = useState([])
    const counterCustomSizeRef = useRef(null)
    const counterMarginTopRef = useRef(null)
    const counterMarginBottomRef = useRef(null)
    const counterMarginLeftRef = useRef(null)
    const counterMarginRightRef = useRef(null)
    const [counterMarginsOk, setCounterMarginsOk] = useState(true)

    const pageMarginTopRef = useRef(null)
    const pageMarginBottomRef = useRef(null)
    const pageMarginLeftRef = useRef(null)
    const pageMarginRightRef = useRef(null)
    const [pageMarginsOk, setPageMarginsOk] = useState(true)
    const [counterSizeStartup, setCounterSizeStartup] = useState(true) // prevent default counterSize useEffect from turning off useCustomCounterSize

    const [sheetControlsOpen, setSheetControlsOpen] = useState(true)

    // startup
    useEffect(() => {
        copyStateSheetSettingsToLocalState()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (state.userLoadSlotsSheet) {
            if (Utility.objectsEqual(state.sheetSettings, state.userLoadSlotsSheet.sheetSettings) === false) {
                let incomingSheetSettings = state.userLoadSlotsSheet.sheetSettings
                if (JSON.stringify(countersColumnsRows) !== JSON.stringify(incomingSheetSettings.countersColumnsRows)) {
                    setCountersColumnsRows(incomingSheetSettings.countersColumnsRows)
                }
                if (counterSize !== incomingSheetSettings.counterSize) {
                    setCounterSize(incomingSheetSettings.counterSize)
                }
                if (counterCustomSize !== incomingSheetSettings.counterCustomSize) {
                    setCounterCustomSize(incomingSheetSettings.counterCustomSize)
                }
                if (useCounterCustomSize !== incomingSheetSettings.useCounterCustomSize) {
                    setUseCounterCustomSize(incomingSheetSettings.useCounterCustomSize)
                }
                if (JSON.stringify(counterMargins) !== JSON.stringify(incomingSheetSettings.counterMargins)) {
                    setCounterMargins(incomingSheetSettings.counterMargins)
                }
                if (JSON.stringify(pageMargins) !== JSON.stringify(incomingSheetSettings.pageMargins)) {
                    setPageMargins(incomingSheetSettings.pageMargins)
                }
                //actions.sheetSettings(state.userLoadSlotsSheet.sheetSettings)
            }

            actions.userLoadSlotsSheet(null)
        }
    }, [state.userLoadSlotsSheet]) // eslint-disable-line react-hooks/exhaustive-deps

    const copyStateSheetSettingsToLocalState = () => {
        let countersColumnsRows = state.sheetSettings.countersColumnsRows.map(m => Number(m))
        let counterSize = Utility.roundFloat(state.sheetSettings.counterSize, 4)
        let counterCustomSize = state.sheetSettings.counterCustomSize
        let useCounterCustomSize = state.sheetSettings.useCounterCustomSize
        let counterMargins = state.sheetSettings.counterMargins.map(m => Number(m))
        let pageMargins = state.sheetSettings.pageMargins.map(m => Number(m))
        setCountersColumnsRows(countersColumnsRows)
        setCounterSize(counterSize)
        setCounterCustomSize(counterCustomSize)
        setUseCounterCustomSize(useCounterCustomSize)
        setCounterMargins(counterMargins)
        setPageMargins(pageMargins)
    }

    // countersColumnsRows
    useEffect(() => {
        if (state && state.sheetSettings && state.sheetSettings.countersColumnsRows && countersColumnsRows && Array.isArray(countersColumnsRows) && countersColumnsRows.length === 2) {
            if ((state.sheetSettings.countersColumnsRows[0] !== countersColumnsRows[0]) ||
                (state.sheetSettings.countersColumnsRows[1] !== countersColumnsRows[1])) {
                actions.sheetSettings({ countersColumnsRows })
            }
        }
    }, [countersColumnsRows]) // eslint-disable-line react-hooks/exhaustive-deps

    // counterSize
    useEffect(() => {
        if (counterSize) {
            // this means that any useCounterCustomSize is set to false.
            if (counterSizeStartup) {
                setCounterSizeStartup(false)
                actions.sheetSettings({ counterSize })
            }
            else {
                setUseCounterCustomSize(false)
                actions.sheetSettings({ counterSize, useCounterCustomSize: false })
            }
        }
    }, [counterSize]) // eslint-disable-line react-hooks/exhaustive-deps

    // countersColumnsRows
    // useEffect(() => {
    //     if (countersColumnsRows !== null) {
    //         let cols = parseInt(countersColumnsRows[0])
    //         let rows = parseInt(countersColumnsRows[1])
    //         if (state.sheetSettings.countersColumnsRows[0] !== cols ||
    //             state.sheetSettings.countersColumnsRows[1] !== rows) {
    //             //actions.sheetSettings({ countersColumnsRows })
    //         }

    //     }

    // }, [countersColumnsRows]) // eslint-disable-line react-hooks/exhaustive-deps

    // counterMargins
    useEffect(() => {
        if (counterMargins !== null) {
        }
    }, [counterMargins]) // eslint-disable-line react-hooks/exhaustive-deps

    // pageMargins
    useEffect(() => {
        if (pageMargins !== null) {
        }
    }, [pageMargins]) // eslint-disable-line react-hooks/exhaustive-deps



    const onChangeCountersColumnsRows = (evt) => {
        let columns = countersColumnsRows[0]
        let rows = countersColumnsRows[1]
        if (evt.target.id === 'countersColumnsSelect') {
            columns = evt.target.value
        }
        if (evt.target.id === 'countersRowsSelect') {
            rows = evt.target.value
        }
        setCountersColumnsRows([Number(columns), Number(rows)])
    }



    const onChangeCounterSize = (evt) => {
        let _counterSize = Number(evt.target.value)
        // setting of select dropdown changeCounterSize will force useCustomCounterSize to false always.
        setCounterSize(_counterSize)
    }

    const onClickCounterSize = size => {
        if( size !== null && size !== undefined) {
            setCounterSize(size)
        }
    }

    const onChangeCounterCustomSize = evt => {
        let _counterCustomSize = evt.target.value
        setCounterCustomSize(_counterCustomSize) // let them type whatever they want in here.

        setWarningOnInput(counterCustomSizeRef.current, false)
        if (isValidNumeric(evt.target.value, 12.7, 99) === false) {
            setWarningOnInput(counterCustomSizeRef.current, true)
        }
    }

    const onClickUseCounterCustomSize = evt => {
        if (Utility.isNumeric(counterCustomSize)) {
            let _counterCustomSize = Number(counterCustomSize)
            if (_counterCustomSize >= 12.7 && _counterCustomSize < 100) {
                setUseCounterCustomSize(true)
                actions.sheetSettings({ counterCustomSize: _counterCustomSize, useCounterCustomSize: true })
            }
        }
    }



    // page margins can be freely changed with no effect. Its when the user presses the apply button, that they take effect.
    const applyCounterMargins = () => {
        if (JSON.stringify(counterMargins) !== JSON.stringify(state.sheetSettings.counterMargins)) {
            let _counterMargins = counterMargins.map(m => Number(m))
            actions.sheetSettings({ counterMargins: _counterMargins })
        }
    }


    // page margins can be freely changed with no effect. Its when the user presses the apply button, that they take effect.
    const applyPageMargins = () => {
        if (JSON.stringify(pageMargins) !== JSON.stringify(state.sheetSettings.pageMargins)) {
            let _pageMargins = pageMargins.map(m => Number(m))
            actions.sheetSettings({ pageMargins: _pageMargins })
        }
    }

    useEffect(() => {
        if (Utility.emptyCheck(state.sheetSettings) === false && state.dexie) {



            // check if slots went off the page.
            let maximums = maximumFitColumnsRows()
            let overflow = [false, false]
            if (state.sheetSettings && state.sheetSettings.countersColumnsRows && state.sheetSettings.countersColumnsRows[0] > maximums[0]) {
                overflow[0] = true
            }
            if (state.sheetSettings && state.sheetSettings.countersColumnsRows && state.sheetSettings.countersColumnsRows[1] > maximums[1]) {
                overflow[1] = true
            }

            if (overflow[0] && overflow[1]) {
                setCountersColumnsRows(maximums)
            }
            if (overflow[0] && !overflow[1]) {
                setCountersColumnsRows([maximums[0], state.sheetSettings.countersColumnsRows[1]])
            }
            if (overflow[1] && !overflow[0]) {
                setCountersColumnsRows([state.sheetSettings.countersColumnsRows[0], maximums[1]])
            }

            // in case sheetSettings was updated.
            // if (Utility.emptyCheck(state.sheetSettings) === false) {
            //     if (state.sheetSettings.counterSize === counterSize &&
            //         state.sheetSettings.countersColumnsRows[0] === countersColumnsRows[0] &&
            //         state.sheetSettings.countersColumnsRows[1] === countersColumnsRows[1] &&
            //         state.sheetSettings.counterCustomSize === counterCustomSize &&
            //         state.sheetSettings.counterMargins[0] === counterMargins[0] &&
            //         state.sheetSettings.counterMargins[1] === counterMargins[1] &&
            //         state.sheetSettings.counterMargins[2] === counterMargins[2] &&
            //         state.sheetSettings.counterMargins[3] === counterMargins[3] &&
            //         state.sheetSettings.pageMargins[0] === pageMargins[0] &&
            //         state.sheetSettings.pageMargins[1] === pageMargins[1] &&
            //         state.sheetSettings.pageMargins[2] === pageMargins[2] &&
            //         state.sheetSettings.pageMargins[3] === pageMargins[3] &&
            //         state.sheetSettings.useCounterCustomSize === useCounterCustomSize) {
            //         return
            //     }
            //     //checkForColumnsRowsOverflow()
            //     if (Utility.roundFloat(counterSize, 2) !== Utility.roundFloat(state.sheetSettings.counterSize, 2)) {
            //         setCounterSize(Utility.roundFloat(state.sheetSettings.counterSize, 2))
            //     }
            //     if (JSON.stringify(countersColumnsRows) != JSON.stringify(state.sheetSettings.countersColumnsRows)) {
            //         setCountersColumnsRows(state.sheetSettings.countersColumnsRows)
            //     }
            //     if (counterCustomSize !== state.sheetSettings.counterCustomSize) {
            //         setCounterCustomSize(state.sheetSettings.counterCustomSize === -1 ? '' : state.sheetSettings.counterCustomSize)
            //     }
            //     if (useCounterCustomSize !== state.sheetSettings.useCounterCustomSize) {
            //         setUseCounterCustomSize(state.sheetSettings.useCounterCustomSize)
            //     }
            //     if (JSON.stringify(counterMargins) !== JSON.stringify(state.sheetSettings.counterMargins)) {
            //         setCounterMargins(state.sheetSettings.counterMargins)
            //     }
            //     if (JSON.stringify(pageMargins) !== JSON.stringify(state.sheetSettings.pageMargins)) {
            //         setPageMargins(state.sheetSettings.pageMargins)
            //     }

            // state.dexie.currentSheetSettings.put(state.sheetSettings)

            //}

            state.dexie.table('sheetSettings').orderBy('index').first().then(first => {
                if (JSON.stringify(first) !== JSON.stringify(state.sheetSettings)) {
                    Utility.updateDexieStore(state.dexie, 'sheetSettings', state.sheetSettings, 'index')
                }
            })
        }
    }, [state.sheetSettings]) // eslint-disable-line react-hooks/exhaustive-deps






    const onClickMaximumToFit = (evt) => {
        let [countersHorizontally, countersVertically] = maximumFitColumnsRows()
        setCountersColumnsRows([countersHorizontally, countersVertically])
    }

    const maximumFitColumnsRows = () => {
        if (state.sheetSettings) {
            let counterMargins = state.sheetSettings.counterMargins.map(m => Number(m))
            let counterSizeMM = Utility.roundFloat(state.sheetSettings.counterSize, 4)
            if (state.sheetSettings.useCounterCustomSize) {
                counterSizeMM = state.sheetSettings.counterCustomSize
            }
            let _pageMargins = state.sheetSettings.pageMargins.map(m => Number(m))
            // let widthAvailableMM = 215.9 - (_pageMargins[1] + _pageMargins[3])
            // let heightAvailableMM = 279.4 - (_pageMargins[0] + _pageMargins[2])

            let widthAvailableMM = state.sheetSettings.printableArea[0] - (_pageMargins[1] + _pageMargins[3])
            let heightAvailableMM = state.sheetSettings.printableArea[1] - (_pageMargins[0] + _pageMargins[2])

            let countersHorizontally = Math.floor(widthAvailableMM / (counterSizeMM + counterMargins[1] + counterMargins[3]))
            let countersVertically = Math.floor(heightAvailableMM / (counterSizeMM + counterMargins[0] + counterMargins[2]))

            return [countersHorizontally, countersVertically]
        }
    }

    const counterCustomSizeIsValid = () => {
        if (state.sheetSettings) {
            if (Utility.isNumeric(counterCustomSize) === false) {
                return false
            }

            let _counterCustomSize = Utility.roundFloat(counterCustomSize, 3)
            if (_counterCustomSize < 12.7 || _counterCustomSize >= 100) {
                return false
            }

            // show as disabled if the user is already using custom size, and the size in the input box is the size active for the sheet.
            // since pressing the button again shouldn't do anything.
            if (Number(counterCustomSize) === state.sheetSettings.counterCustomSize &&
                state.sheetSettings.useCounterCustomSize) {
                return false
            }
        }
        return true
    }





    const unusedCustomSize = () => {
        if (Utility.emptyCheck(state.sheetSettings) === false && state.sheetSettings.useCounterCustomSize) {
            return ''
        }
        return 'unused'
    }

    const setWarningOnInput = (inputRef, trueOrFalse) => {
        let _inputWarningArray = [...inputWarningArray]
        if (trueOrFalse === true) {
            if (!inputWarningArray.includes(inputRef)) {
                _inputWarningArray.push(inputRef)
            }
        }
        else {
            _inputWarningArray = _inputWarningArray.filter(wa => wa !== inputRef)
        }
        setInputWarningArray(_inputWarningArray)
    }

    useEffect(() => {
        if (inputWarningArray.includes(counterMarginTopRef.current) ||
            inputWarningArray.includes(counterMarginBottomRef.current) ||
            inputWarningArray.includes(counterMarginRightRef.current) ||
            inputWarningArray.includes(counterMarginLeftRef.current)
        ) {
            setCounterMarginsOk(false)
        }
        else {
            setCounterMarginsOk(true)
        }

        if (inputWarningArray.includes(pageMarginTopRef.current) ||
            inputWarningArray.includes(pageMarginBottomRef.current) ||
            inputWarningArray.includes(pageMarginRightRef.current) ||
            inputWarningArray.includes(pageMarginLeftRef.current)
        ) {
            setPageMarginsOk(false)
        }
        else {
            setPageMarginsOk(true)
        }

    }, [inputWarningArray])

    const inputWarning = (refHandle) => {
        if (inputWarningArray.includes(refHandle)) {
            return 'warning'
        }
        else {
            return ''
        }
    }

    const isValidNumeric = (value, low, high) => {
        if (Utility.isNumeric(value)) {
            if (Number(value) < low || Number(value) > high) {
                return false
            }
        }
        else {
            return false
        }
        return true
    }

    const changeCounterMargin = evt => {
        let _counterMargins = JSON.parse(JSON.stringify(counterMargins))
        if (evt.target.id === 'counterMarginTop') {
            _counterMargins[0] = evt.target.value
            setWarningOnInput(counterMarginTopRef.current, false)
            if (isValidNumeric(evt.target.value, 0, 99) === false) {
                setWarningOnInput(counterMarginTopRef.current, true)
            }
        }
        if (evt.target.id === 'counterMarginRight') {
            _counterMargins[1] = evt.target.value
            setWarningOnInput(counterMarginRightRef.current, false)
            if (isValidNumeric(evt.target.value, 0, 99) === false) {
                setWarningOnInput(counterMarginRightRef.current, true)
            }
        }
        if (evt.target.id === 'counterMarginBottom') {
            _counterMargins[2] = evt.target.value
            setWarningOnInput(counterMarginBottomRef.current, false)
            if (isValidNumeric(evt.target.value, 0, 99) === false) {
                setWarningOnInput(counterMarginBottomRef.current, true)
            }
        }
        if (evt.target.id === 'counterMarginLeft') {
            _counterMargins[3] = evt.target.value
            setWarningOnInput(counterMarginLeftRef.current, false)
            if (isValidNumeric(evt.target.value, 0, 99) === false) {
                setWarningOnInput(counterMarginLeftRef.current, true)
            }
        }
        setCounterMargins(_counterMargins)
    }

    const clearCounterMargins = () => {
        setCounterMargins([0, 0, 0, 0])
    }

    const changePageMargin = evt => {
        let _pageMargins = JSON.parse(JSON.stringify(pageMargins))
        if (evt.target.id === 'pageMarginTop') {
            _pageMargins[0] = evt.target.value
            setWarningOnInput(pageMarginTopRef.current, false)
            if (isValidNumeric(evt.target.value, 0, 99) === false) {
                setWarningOnInput(pageMarginTopRef.current, true)
            }
        }
        if (evt.target.id === 'pageMarginRight') {
            _pageMargins[1] = evt.target.value
            setWarningOnInput(pageMarginRightRef.current, false)
            if (isValidNumeric(evt.target.value, 0, 99) === false) {
                setWarningOnInput(pageMarginRightRef.current, true)
            }
        }
        if (evt.target.id === 'pageMarginBottom') {
            _pageMargins[2] = evt.target.value
            setWarningOnInput(pageMarginBottomRef.current, false)
            if (isValidNumeric(evt.target.value, 0, 99) === false) {
                setWarningOnInput(pageMarginBottomRef.current, true)
            }
        }
        if (evt.target.id === 'pageMarginLeft') {
            _pageMargins[3] = evt.target.value
            setWarningOnInput(pageMarginLeftRef.current, false)
            if (isValidNumeric(evt.target.value, 0, 99) === false) {
                setWarningOnInput(pageMarginLeftRef.current, true)
            }
        }

        setPageMargins(_pageMargins)
    }

    const clearPageMargins = () => {
        setPageMargins([0, 0, 0, 0])
    }

    const clickOnSheetControlsOpen = () => {
        setSheetControlsOpen(!sheetControlsOpen)
    }

    if (countersColumnsRows !== null &&
        counterSize !== null &&
        useCounterCustomSize !== null &&
        countersColumnsRows !== null &&
        counterMargins !== null &&
        pageMargins !== null) {

        return (
            <div className="sheet-control">
                <div onClick={clickOnSheetControlsOpen}>sheet controls <div className={sheetControlsOpen ?
                    'sheet-controls-control open' :
                    'sheet-controls-control closed'}
                >{sheetControlsOpen ? '-' : '+'}</div></div>

                <div className={sheetControlsOpen ? 'controls' : 'display-none'}>


                    <div className="control">
                        <div className="control-title"><span>COUNTERS LAYOUT</span></div>
                        <div className="options-container">
                            <div className="options">
                                <div className="option">
                                    <span className="option-title">counters across:</span>
                                    <select className=""
                                        name="countersColumnsSelect"
                                        id="countersColumnsSelect"
                                        value={countersColumnsRows[0]}
                                        onChange={onChangeCountersColumnsRows}>
                                        <option value="">-</option>
                                        {Array(18).fill().map((_, i) => {
                                            return <option key={i} value={i}>{i}</option>
                                        })}
                                    </select>
                                </div>


                                <div className="option">
                                    <span className="option-title">counters down:</span>
                                    <select className=""
                                        name="countersRowsSelect"
                                        id="countersRowsSelect"
                                        value={countersColumnsRows[1]}
                                        onChange={onChangeCountersColumnsRows}>
                                        <option value="">-</option>
                                        {Array(23).fill().map((_, i) => {
                                            return <option key={i} value={i}>{i}</option>
                                        })}
                                    </select>
                                </div>


                                <div className="option">
                                    <button className="action-button yellowish" onClick={onClickMaximumToFit}>&nbsp;maximum to fit&nbsp;</button>
                                </div>

                            </div>
                        </div>
                    </div>


                    <div className="control">
                        <div className="control-title"><span>COUNTER SIZE</span></div>
                        <div className="options-container">
                            <div className="options counter-size">
                                <div className="option">
                                    standard sizes:
                                    <select className={useCounterCustomSize ? 'unused' : ''}
                                        name="sheetControlCounterSize"
                                        id="sheetControlCounterSize"
                                        value={counterSize}
                                        onChange={onChangeCounterSize}>
                                        <option value="">-</option>
                                        <option key="counterSize12.7" onClick={() => onClickCounterSize('12.7')} value="12.7">1/2 inch (12.7mm)</option>
                                        <option key="counterSize14.2875" onClick={() => onClickCounterSize('14.2875')} value="14.2875">9/16 inch (14.2875mm)</option>
                                        <option key="counterSize15.875" onClick={() => onClickCounterSize('15.875')} value="15.875">5/8 inch (15.875mm)</option>
                                        <option key="counterSize17.4625" onClick={() => onClickCounterSize('17.4625')} value="17.4625">11/16 inch (17.4625mm)</option>
                                        <option key="counterSize19.05" onClick={() => onClickCounterSize('19.05')} value="19.05">3/4 inch (19.05mm)</option>
                                        <option key="counterSize22.225" onClick={() => onClickCounterSize('22.225')} value="22.225">7/8 inch (22.225mm)</option>
                                        <option key="counterSize25.4" onClick={() => onClickCounterSize('25.4')} value="25.4">1 inch (25.4mm)</option>
                                    </select>
                                </div>



                                <div className="option">
                                    <span className="option-title">custom size:</span>

                                    <input type="text"
                                        id="counterCustomSize"
                                        ref={counterCustomSizeRef}
                                        className={`counter-custom-size ${unusedCustomSize()} ${inputWarning(counterCustomSizeRef.current)}`}
                                        value={counterCustomSize} onChange={onChangeCounterCustomSize} /><span className="subscript">mm</span>

                                </div>
                                <div className="option use-custom">
                                    <button
                                        className={counterCustomSizeIsValid() ? 'action-button yellowish' : 'action-button disabled'}
                                        onClick={counterCustomSizeIsValid() ? onClickUseCounterCustomSize : null}>&nbsp;use custom size&nbsp;</button>
                                </div>

                            </div>
                        </div>
                    </div>





                    <div className="control">
                        <div className="control-title"><span>COUNTER MARGINS</span></div>
                        <div className="options-container">
                            <div className="options">
                                <div className="grid">

                                    <div className="row">
                                        <div className='column'>
                                            <span className="option-title">top:</span>
                                            <input ref={counterMarginTopRef} type="text" id="counterMarginTop" className={`mm ${inputWarning(counterMarginTopRef.current)}`} onChange={changeCounterMargin} value={counterMargins[0]} /><span className="subscript">mm</span>
                                        </div>
                                        <div className='column'>
                                            <span className="option-title">bottom:</span>
                                            <input ref={counterMarginBottomRef} type="text" id="counterMarginBottom" className={`mm ${inputWarning(counterMarginBottomRef.current)}`} onChange={changeCounterMargin} value={counterMargins[2]} /><span className="subscript">mm</span>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='column'>
                                            <span className="option-title">left:</span>
                                            <input ref={counterMarginLeftRef} type="text" id="counterMarginLeft" className={`mm ${inputWarning(counterMarginLeftRef.current)}`} onChange={changeCounterMargin} value={counterMargins[3]} /><span className="subscript">mm</span>
                                        </div>
                                        <div className='column'>
                                            <span className="option-title">right:</span>
                                            <input ref={counterMarginRightRef} type="text" id="counterMarginRight" className={`mm ${inputWarning(counterMarginRightRef.current)}`} onChange={changeCounterMargin} value={counterMargins[1]} /><span className="subscript">mm</span>
                                        </div>
                                    </div>

                                    <div className="row reset-apply">
                                        <button className="action-button yellowish" onClick={clearCounterMargins}>clear</button>
                                        <button className={counterMarginsOk ? 'action-button cyanish' : 'action-button disabled'}
                                            onClick={counterMarginsOk ? applyCounterMargins : null}>apply</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>




                    <div className="control">
                        <div className="control-title"><span>PAGE MARGINS</span></div>
                        <div className="options-container">
                            <div className="options">
                                <div className="grid">

                                    <div className="row">
                                        <div className='column'>
                                            <span className="option-title">top:</span>
                                            <input ref={pageMarginTopRef} type="text" id="pageMarginTop" className={`mm ${inputWarning(pageMarginTopRef.current)}`} onChange={changePageMargin} value={pageMargins[0]} /><span className="subscript">mm</span>
                                        </div>
                                        <div className='column'>
                                            <span className="option-title">bottom:</span>
                                            <input ref={pageMarginBottomRef} type="text" id="pageMarginBottom" className={`mm ${inputWarning(pageMarginBottomRef.current)}`} onChange={changePageMargin} value={pageMargins[2]} /><span className="subscript">mm</span>
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='column'>
                                            <span className="option-title">left:</span>
                                            <input ref={pageMarginLeftRef} type="text" id="pageMarginLeft" className={`mm ${inputWarning(pageMarginLeftRef.current)}`} onChange={changePageMargin} value={pageMargins[3]} /><span className="subscript">mm</span>
                                        </div>
                                        <div className='column'>
                                            <span className="option-title">right:</span>
                                            <input ref={pageMarginRightRef} type="text" id="pageMarginRight" className={`mm ${inputWarning(pageMarginRightRef.current)}`} onChange={changePageMargin} value={pageMargins[1]} /><span className="subscript">mm</span>
                                        </div>
                                    </div>

                                    <div className="row reset-apply">
                                        <button className="action-button yellowish" onClick={clearPageMargins}>clear</button>
                                        <button className={pageMarginsOk ? 'action-button cyanish' : 'action-button disabled'}
                                            onClick={pageMarginsOk ? applyPageMargins : null}>apply</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )

    }
    else {
        return <div></div>
    }
}
export default SheetControl

/*


                <div className="control">
                    <div><span>COUNTER SIZE</span></div>
                    <div className="options-container">
                        <div className="options">

                            
                                <select className=""
                                    name="sheetControlCounterSize"
                                    id="sheetControlCounterSize"
                                    value={counterSize}
                                    onChange={onChangeCounterSize}>
                                    <option value="">-</option>
                                    <option key="counterSize12.7" value="12.7">1/2 inch (12.7mm)</option>
                                    <option key="counterSize14.2875" value="14.2875">9/16 inch (14.2875mm)</option>
                                    <option key="counterSize15.875" value="15.875">5/8 inch (15.875mm)</option>
                                    <option key="counterSize17.4625" value="17.4625">11/16 inch (17.4625mm)</option>
                                    <option key="counterSize19.05" value="19.05">3/4 inch (19.05mm)</option>
                                    <option key="counterSize22.225" value="22.225">7/8 inch (22.225mm)</option>
                                    <option key="counterSize25.4" value="25.4">1 inch (25.4mm)</option>
                                </select>
                        </div>

                        <div className="options">
                            <span>custom width:</span>
                            <input type="text" id="customWidth" className="custom-width" /><span className="subscript">mm</span>
                        </div>
                    </div>
                </div>
                */