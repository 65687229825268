import React, { useEffect, useState, useContext } from 'react'
import Snap from 'snapsvg-cjs'
import { StoreContext } from "../../context/StoreContext"
import './OutOfBoundsMask.scss'
const OutOfBoundsMask = () => {
    const { state } = useContext(StoreContext)
    const [paper, setPaper] = useState(null)

    useEffect(() => {
        // drawOverlay()
        let _paper = Snap("#out_of_bounds_svg");
        _paper.attr({ id: "out_of_bounds_svg", viewBox: "-120, -120, 240, 240" });
        setPaper(_paper)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

        if (state.activeLayerValues.length > 0) {
            let alv = state.activeLayerValues.find(alv => alv.lik === '1_38') // 
            if (alv) {
                switch (alv.value) {
                    case 0:
                        drawSquareMask()
                        break;
                    case 1:
                        drawCircleMask()
                        break;
                    case 2:
                        drawRectangleMask()
                        break;
                    default: console.warn('no case for ', alv.value)
                }
            }
            else {
                console.warn('could not locate 1_38 in activeLayerValues')
            }
        }

    }, [state.activeLayerValues]) // eslint-disable-line react-hooks/exhaustive-deps

    const drawSquareMask = () => {
        paper.clear()



        var p = paper.path("M-1,1 l2-2 M0,4 l4-4 M3,5 l2-2").attr({
            stroke: "#555",
            strokeWidth: 1.8,
            opacity: 0.4

        }).pattern(0, 0, 2.2, 2.2)

        // Now create the mask.
        // We want the mask to be a hole through a rectangle, so the mask
        // needs to be a white (solid) rectangle with a black (hole) circle
        // on top of it.
        var maskrect = paper.rect(-120, -120, 240, 240);   // the rect
        maskrect.attr({
            fill: "white"
        });
        var maskHole = paper.rect(-100, -100, 200, 200) // the hole
        // Now group these two items to create the combined object that becomes the mask.
        var spotlightmask = paper.group(maskrect, maskHole);


        // var f = paper.filter(Snap.filter.invert(0.5))

        // Add a black foreground rectangle that we will apply the mask to.
        var blackfg = paper.rect(-120, -120, 240, 240)
            .attr({ fill: p, opacity: 0.5 });
        // Attch the mask to the black foreground rect.
        blackfg.attr({
            mask: spotlightmask
        });
    }

    const drawCircleMask = () => {
        paper.clear()


        var p = paper.path("M-1,1 l2-2 M0,4 l4-4 M3,5 l2-2").attr({
            stroke: "#777",
            strokeWidth: 1.8,
            opacity: 0.5

        }).pattern(0, 0, 2.2, 2.2)

        // Now create the mask.
        // We want the mask to be a hole through a rectangle, so the mask
        // needs to be a white (solid) rectangle with a black (hole) circle
        // on top of it.
        var maskrect = paper.rect(-120, -120, 240, 240);   // the rect
        maskrect.attr({
            fill: "white"
        });
        var maskcircle = paper.circle(0, 0, 100, 100)//paper.circle(0,0, 20);  // the circular hole
        // Now group these two items to create the combined object that becomes the mask.
        var spotlightmask = paper.group(maskrect, maskcircle);



        // Add a black foreground rectangle that we will apply the mask to.
        var blackfg = paper.rect(-120, -120, 240, 240)
            .attr({ fill: p, opacity: 0.5 });
        // Attach the mask to the black foreground rect.
        blackfg.attr({
            mask: spotlightmask
        });



    }
    const drawRectangleMask = () => {
        paper.clear()



        var p = paper.path("M-1,1 l2-2 M0,4 l4-4 M3,5 l2-2").attr({
            stroke: "#555",
            strokeWidth: 1.8,
            opacity: 0.4

        }).pattern(0, 0, 2.2, 2.2)

        // Now create the mask.
        // We want the mask to be a hole through a rectangle, so the mask
        // needs to be a white (solid) rectangle with a black (hole) circle
        // on top of it.
        var maskrect = paper.rect(-120, -120, 240, 240);   // the rect
        maskrect.attr({
            fill: "white"
        });
        var maskHole = paper.rect(-100, -50, 200, 100)// the hole
        // Now group these two items to create the combined object that becomes the mask.
        var spotlightmask = paper.group(maskrect, maskHole);


        //    // var f = paper.filter(Snap.filter.invert(0.5))

        // Add a black foreground rectangle that we will apply the mask to.
        var blackfg = paper.rect(-120, -120, 240, 240)
            .attr({ fill: p, opacity: 0.5 });
        // Attch the mask to the black foreground rect.
        blackfg.attr({
            mask: spotlightmask
        });
    }

    // useEffect(() => {
    //     if (paper) {
    //         paper.clear()
    //         let circumventingPath = `M 
    //         -120 -120 
    //         120 -120 
    //         120 120 
    //         -120 120
    //         -120 -100
    //         -100 -100

    //         -100 100
    //         100 100

    //         100 -100
    //         -120 -100
    //         z`

    //         paper.path(circumventingPath).attr({ fill: "#000", opacity: 0.8 });
    //         var p = paper.path("M-1,1 l2-2 M0,4 l4-4 M3,5 l2-2").attr({
    //             stroke: "#555",
    //             strokeWidth: 1.8,
    //             opacity: 0.4

    //         }).pattern(0, 0, 4, 4),
    //             c = paper.path(circumventingPath);
    //         c.attr({
    //             fill: p
    //         });
    //     }
    // }, [paper])

    return (
        <div className="out-of-bounds-mask">
            <svg id="out_of_bounds_svg" className="out-of-bounds-svg" />
        </div>
    );
}
export default OutOfBoundsMask