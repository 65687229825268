import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'
import App from './App'
import { StoreProvider } from "./context/StoreContext"
import reportWebVitals from './reportWebVitals'

ReactDOM.render(
  <StoreProvider><App /></StoreProvider>, document.getElementById('root')
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
