import React, { useState, useEffect, useContext, useRef } from 'react'
import { StoreContext } from "../../context/StoreContext"
import XButton from '../XButton/XButton'
import Utility from "../../objects/Utility"
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import './BackupWorkspace.scss'

const BackupWorkspace = () => {
    const { state, actions } = useContext(StoreContext)
    const [saveName, setSaveName] = useState('')
    const [saveResult, setSaveResult] = useState({ state: null, message: '' })
    const backupWorkspaceRef = useRef(null)
    const nameInputRef = useRef(null)

    useEffect(() => {
        let copyOfRef = backupWorkspaceRef.current // linter said I should copy this to a variable so the cleanup function can be reliable.
        backupWorkspaceRef.current.addEventListener('mouseup', function (e) {
            e.stopPropagation();
        });

        return () => {
            if (copyOfRef) {
                copyOfRef.removeEventListener('mouseup', function (e) {
                    e.stopPropagation();
                });
            }
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps



    useEffect(() => {
        exitView()
    }, [state.mouseClick]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (state.installView === 'backup workspace') {
            nameInputRef.current.focus()
        }
    }, [state.installView])

    const reset = () => {
        setSaveResult('')
        setSaveName('')
        setSaveResult({ state: null, message: '' })
        nameInputRef.current.value = ''
    }

    const closeAction = () => {
        exitView()
    }

    const exitView = () => {
        if (state.installView) {
            reset()
            window.scrollTo(0, 0)
        }
        actions.installView('')
    }

    const saveNameChange = () => {
        setSaveResult('')
        setSaveName(nameInputRef.current.value)
    }

    const packageWorkspace = async() => {
        // need to get font data from dexie cause state doesnt carry it (I think it may eat up too much memory storing gigs of font data.
        // We just retrieve the font data from the dexie store when we need it for particular fonts.
        let fontsData = await state.dexie['fonts'].toArray()
        let svgsData = await state.dexie['svgs'].toArray()
        return {
            activeLayerValues: state.activeLayerValues,
            fonts: fontsData,
            layers: state.layers,
            savedCounters: state.savedCounters,
            savedSheets: state.savedSheets,
            sheetSettings: state.sheetSettings,
            slots: state.slots,
            svgs: svgsData
        }
    }

    const saveFile = async() => {
        let packagedWorkspace = await packageWorkspace()
        let fileObj = {
            fileName: saveName,
            app: 'snapCounter',
            type: 'workspace',
            dateSaved: Utility.currentDate(),
            workspace: packagedWorkspace
        }

        var zip = new JSZip();
        zip.file(saveName + ".json", JSON.stringify(fileObj));
        zip.generateAsync({ type: "blob", compression: "DEFLATE" })
            .then(function (content) {
                saveAs(content, saveName + '.zip');
                setTimeout(() => {
                    if (state.installView === 'backup workspace') {
                        setSaveResult({ state: true, message: 'Backup "' + saveName + '" created.' })
                        setSaveName('')
                    }
                }, 2000)
            }).catch(function () {
                setSaveResult({ state: false, message: 'Could not save file.' })
            })

    }

    return (
        <div ref={backupWorkspaceRef} className={state.installView === 'backup workspace' ? 'backup-workspace' : 'display-none'}>

          
                <div className="backup-workspace-top">
                    <div className="title">Backup Workspace</div>
                    <div className="close-button">
                        <XButton r="12" closeAction={closeAction} />
                    </div>
                </div>
                <div className="intro">
                    <p>
                        This will allow you to export your entire workspace to a file on your computer.
                    </p>
                </div>

                <div className="form-area">
                    <div>save name:</div>
                    <div><input ref={nameInputRef} type="text"
                        value={saveName} onChange={saveNameChange} /></div>
                    <div><button className={saveName ? 'action-button bluish' : 'action-button disabled'} onClick={saveName ? saveFile : null}>save</button></div>
                </div>
                {saveResult.message ? <div className={saveResult.state ? 'save-result' : 'save-result error'}>{saveResult.message}</div> : ''}
          

        </div>
    );
}
export default BackupWorkspace