import React, { useContext, useState, useEffect } from 'react'
import Utility from "../../objects/Utility"
import { StoreContext } from "../../context/StoreContext"

import './MoveCounter.scss'

const MoveCounter = ({ useText }) => {
    const { actions, state } = useContext(StoreContext)
    const [movingCounterInProgress, setMovingCounterInProgress] = useState(false)

    const packageCounterState = () => {
        let activeLayerKeys = Utility.getVisibleLayerKeysOrdered(state.activeLayerValues, state.layers)
        let customSvgsArray = []
        let counterStateWithSvg = state.layers.map(sl => {
            let serializedSvgForLayer = ''
            let uniquePrepend = ''
            // we'll know which layers need a svg created for them if they have their layerKeys in activeLayerValues
            if (activeLayerKeys.includes(sl.layerKey)) {
                // generate the svg for this layer
                let svgEle = document.getElementById(`drawLayer${sl.layerKey}`)
                if (svgEle) {
                    // make the ids in the svg document all unique, by using a random string to prepend them with.
                    uniquePrepend = 'l' + sl.layerKey + 'l' + Utility.randomString(6)
                    uniquePrepend.replaceAll('_', '') // get rid of any underscores cause we need to use it as a known separator.
                    uniquePrepend += '_'
                    let foundIds = Utility.distillIdsFromSvgElement(svgEle)
                    serializedSvgForLayer = new XMLSerializer().serializeToString(svgEle)
                    serializedSvgForLayer = Utility.replaceIdsInSvgString(foundIds, serializedSvgForLayer, uniquePrepend)
                    //serializedSvgForLayer = serializedSvgForLayer.replaceAll("drawLayer" + sl.layerKey, uniquePrepend + "drawLayer" + sl.layerKey)
                    // is this a custom svg layer?
                    if (Utility.isCustomLayer(sl.layerKey, state.layers)) {

                        let layer = state.layers.find(ly => ly.layerKey === sl.layerKey)
                        if (layer && layer.inputs) {
                            layer.inputs.forEach(li => {
                                if (li.comment === 'svgKey for Custom Svg' || li.comment === 'svgKey for Custom Image') {
                                    let combinedKey = layer.layerKey + '_' + li.inputKey
                                    let svgKey = state.activeLayerValues[combinedKey]
                                    let svgItem = state.svgs.find(svg => svg.svgKey === svgKey)
                                    if (svgItem) {
                                        customSvgsArray.push(svgItem)
                                    }
                                }
                            })
                        }
                    }
                }
                else {
                    console.warn('cant find element for drawLayer' + sl.layerKey)
                }
            }

            sl.svg = serializedSvgForLayer
            sl.svgIdPrepend = uniquePrepend

            return sl
        })

        return {
            layers: counterStateWithSvg,
            activeLayerValues: JSON.parse(JSON.stringify(state.activeLayerValues)),
            customSvgs: customSvgsArray
        }
    }

    const moveCounter = () => {
        if (movingCounterInProgress === false) {
            setMovingCounterInProgress(true)
            setTimeout(() => {
                setMovingCounterInProgress(false)
            }, 150)
        }
    }

    useEffect(() => {
        if (movingCounterInProgress) {
            // Include all layers, to avoid having to do exceptions all over the place for custom layers.
            setTimeout(() => {
                let checkAnimateEle = document.getElementById('rectAnimate')
                if (checkAnimateEle) {
                    checkAnimateEle.remove()
                }
                let packagedCounterState = packageCounterState()
                actions.cloneCounterToSlot(packagedCounterState)
                setMovingCounterInProgress(false)
            }, 120)
        }
    }, [movingCounterInProgress]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="load-counter" id="loadCounter">
            <button className={ movingCounterInProgress ? 'action-button disabled' : 'action-button cyanish'} onClick={moveCounter} >{useText}</button>

        </div>
    )
}
export default MoveCounter