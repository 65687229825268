import React, { useContext, useEffect, useState, useRef } from 'react'
import { StoreContext } from "../../context/StoreContext"
import Utility from "../../objects/Utility"
//import ErrorMessage from "../ErrorMessage/ErrorMessage"
import './CounterMenuLayerCommonOptions.scss'
const CounterMenuLayerCommonOptions = ({ layer, active }) => {
    const { state, actions } = useContext(StoreContext)
    const [duplicateLayerNamePending, setDuplicateLayerNamePending] = useState('')
    const [disableSubmit, setDisableSubmit] = useState(true)
    const [maxLayerOrder, setMaxLayerOrder] = useState(0)
    const [duplicateLayerOpen, setDuplicateLayerOpen] = useState(false)
    const [orderingLayerOpen, setOrderingLayerOpen] = useState(false)
    const dialogRef = useRef(null)

    useEffect(() => {
        let _maxLayerOrder = Math.max(...state.layers.map(ly => parseInt(ly.layerOrder)), 0)
        setMaxLayerOrder(_maxLayerOrder)

        // prevent dialog from knowing the mouse was clicked, if it was clicked inside the dialog area.
        let copyOfRef = dialogRef.current // linter said I should copy this to a variable so the cleanup function can be reliable.
        dialogRef.current.addEventListener('mouseup', function (e) {
            e.stopPropagation();
        });

        return () => {
            if (copyOfRef) {
                copyOfRef.removeEventListener('mouseup', function (e) {
                    e.stopPropagation();
                });
            }
        }

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (duplicateLayerOpen) {
            // get current names
            let layerKeysParentsOriginalNames = Utility.condenceLayersToKeysNames(state.layers)

            let currentLayerOriginalName = layerKeysParentsOriginalNames.find(lor => lor.layerKey === layer.layerKey).originalName
            // come up with a duplicate name
            let newName = ''
            const lf = i_copy => {
                newName = currentLayerOriginalName + i_copy
                return !layerKeysParentsOriginalNames.find(lor => lor.layerName === newName)
            }
            for (let i = 2; i < 100; i++) {
                if (lf(i)) {
                    break;
                }
            }
            setDuplicateLayerNamePending(newName)
        }
    }, [duplicateLayerOpen]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setDisableSubmit(false)
        if (duplicateLayerNamePending) {
            if (duplicateLayerNamePending.trim().length < 2 || duplicateLayerNamePending.trim().length > 32) {
                setDisableSubmit(true)
            }
            if (Utility.strHasSpecialCharacters(duplicateLayerNamePending)) {
                setDisableSubmit(true)
            }
            if (state.layers.find(ly => ly.layerName.toLowerCase() === duplicateLayerNamePending.toLowerCase())) {
                setDisableSubmit(true)
            }
            if (state.layers.find(ly => ly.layerName.toLowerCase() === duplicateLayerNamePending.trim().toLowerCase())) {
                setDisableSubmit(true)
            }
        }
    }, [duplicateLayerNamePending]) // eslint-disable-line react-hooks/exhaustive-deps

    const submitDuplicateLayerNamePending = () => {
        let duplicateName = duplicateLayerNamePending.trim()
        let newLayer = { ...layer }
        newLayer.layerName = duplicateName
        if (layer.parentLayerKey === -1) {
            newLayer.parentLayerKey = layer.layerKey
        }
        else {
            newLayer.parentLayerKey = layer.parentLayerKey
        }
        let maxLayerKey = Math.max(...state.layers.map(ly => parseInt(ly.layerKey)), 0)
        newLayer.layerKey = maxLayerKey + 1
        newLayer.layerOrder = Number(layer.layerOrder) + 0.5

        processNewLayer(newLayer)
    }

    const processNewLayer = newLayer => {
        // we need to figure out the orderings of layers, since we are cramming a new one in.
        let layersClone = [...state.layers]
        layersClone.push(newLayer)
        layersClone.sort((a, b) => a.layerOrder - b.layerOrder)
        layersClone.forEach((ly, index) => {
            ly.layerOrder = index
        })
        if (layersClone.length > 6) {
            actions.layers(layersClone)
            // update Dexie
            //Utility.dexieClearTable(state.dexie, 'layers')
            Utility.dexieBulkAdd(state.dexie, 'layers', layersClone)
        }
    }

    const onChangeDuplicateLayerName = evt => {
        let pendingName = evt.target.value
        setDuplicateLayerNamePending(pendingName)
    }

    const deleteLayer = () => {
        actions.activeLayerValuesRemove(layer.layerKey)
        let layersClone = [...state.layers]
        layersClone = layersClone.filter(lc => lc.layerKey !== layer.layerKey)

        layersClone.forEach((ly, index) => {
            ly.layerOrder = index
        })
        if (layersClone.length > 6) {
            actions.layers(layersClone)
            // update Dexie
            //Utility.dexieClearTable(state.dexie, 'layers')
            Utility.dexieBulkAdd(state.dexie, 'layers', layersClone)
        }
    }

    const changeLayerOrder = (layerKey, order) => {
        let layersClone = [...state.layers]
        let layerToChange = layersClone.find(lc => lc.layerKey === layerKey)
        if (layerToChange) {
            if (order < layer.layerOrder) {
                layerToChange.layerOrder = order - 0.5
            }
            else {
                layerToChange.layerOrder = order + 0.5
            }
        }
        layersClone.sort((a, b) => a.layerOrder - b.layerOrder)
        layersClone.forEach((ly, index) => {
            ly.layerOrder = index
        })
        if (layersClone.length > 6) {
            actions.layers(layersClone)
            // update Dexie
            //Utility.dexieClearTable(state.dexie, 'layers')
            Utility.dexieBulkAdd(state.dexie, 'layers', layersClone)
        }
    }

    useEffect(() => {
        if (active === false) {
            setDuplicateLayerOpen(false)
            setOrderingLayerOpen(false)
        }
    }, [active])


    return <div ref={dialogRef} className={active ? 'counter-menu-layer-common-options' : 'display-none'}>

        {layer.parentLayerKey > -1 ?
            <div className="common-option">
                <div><button onClick={deleteLayer} className="action-button reddish">Delete layer {layer.layerName.length < 20 ? layer.layerName : ''}</button></div>
            </div>
            : ''}

        <div className="common-option">
            <div className="info1" onClick={() => setDuplicateLayerOpen(!duplicateLayerOpen)}>duplicate layer <span>{layer.layerName}</span></div>
            <div className={duplicateLayerOpen ? 'open' : 'display-none'}>
                <div className="info2">please supply a unique name:</div>
                <div className="input-container"><input type="text"
                    id="duplicateLayerName" value={duplicateLayerNamePending}
                    className={disableSubmit ? 'error' : ''}
                    onChange={onChangeDuplicateLayerName}
                /></div>
                <div className="submit-div"><button
                    className={disableSubmit ? 'action-button disabled' : 'action-button'}
                    onClick={disableSubmit ? null : submitDuplicateLayerNamePending}
                >submit</button></div>
            </div>
        </div>

        <div className={layer.layerOrder !== 0 ? 'common-option' : 'display-none'}>
            <div className="info1" onClick={() => setOrderingLayerOpen(!orderingLayerOpen)}>change <span>{layer.layerName}</span> order</div>
            <div className={orderingLayerOpen ? 'open' : 'display-none'}>
                <div className="info2">please select the new order:</div>
                <div className="info3">
                    <div>Order of drawing is from low number to high.</div>
                    <div>The base counter layer cannot be changed from 0</div>
                </div>
                <div className="input-container">
                    <div className="layer-ordering">
                        {state.layers.map((ly, index) => <div key={index} className="layer-order-row">
                            {ly.layerOrder > 0
                                && ly.layerOrder !== layer.layerOrder
                                && ly.layerOrder !== (layer.layerOrder + 1)
                                ? <div className="choose-order" onClick={() => changeLayerOrder(layer.layerKey, layer.layerOrder < ly.layerOrder ? (ly.layerOrder - 1) : ly.layerOrder)}>move {layer.layerName} to here ({layer.layerOrder < ly.layerOrder ? (ly.layerOrder - 1) : ly.layerOrder})</div> : ''}
                            <div className={ly.layerOrder === layer.layerOrder ? 'layer-info current' : 'layer-info'}>
                                <span className="order-number">{ly.layerOrder}</span><span className="order-layer-name">{ly.layerName}</span>
                            </div>

                        </div>)}
                        {layer.layerOrder !== maxLayerOrder ? <div className="choose-order" onClick={() => changeLayerOrder(layer.layerKey, maxLayerOrder)}>move {layer.layerName} to here ({maxLayerOrder})</div> : ''}
                    </div>
                </div>
            </div>
        </div>

    </div>

}
export default CounterMenuLayerCommonOptions