import React, { useState, useEffect, useContext } from 'react'
import { StoreContext } from "../../context/StoreContext"
import Utility from "../../objects/Utility"
import './InputRadioGroup.scss'
const InputRadioGroup = ({ layerKey, input, setValue, useValue }) => {
    const [initialEffect, setInitialEffect] = useState(true)
    const [radioOptions, setRadioOptions] = useState([])
    const [valuePicked, setValuePicked] = useState(null)
    const { state } = useContext(StoreContext)

    useEffect(() => {
        let stringForArray = input.stringForArray
        let rdos = stringForArray.split(',')
        setRadioOptions(rdos)
        setValuePicked(useValue)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const onChange = evt => {
         ;
    }

    useEffect(() => {
        if (useValue !== null && useValue !== undefined) {
            if (state.activeLayerValues[layerKey + '_' + input.inputKey] !== undefined) {
                if (valuePicked !== useValue) {
                    setValuePicked(useValue)
                }
            }
        }
    }, [useValue]) // eslint-disable-line react-hooks/exhaustive-deps

    const onClick = evt => {
        setValuePicked(evt.target.value)
    }

    useEffect(() => {
        if (valuePicked) {
            if (initialEffect) {
                setInitialEffect(false)
                return
            }
            if (valuePicked) {

                let existingValuePicked = Utility.currentLayerInputValue(layerKey, input.inputKey, state.activeLayerValues)
                // if this input is already the same value as whats in state.activeLayerValues, do not re-send the same value back.
                if(existingValuePicked !== valuePicked) {
                    setValue(input.inputKey, valuePicked)
                }
            }
        }
    }, [valuePicked]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div  id={`input_radio_group_${input.inputKey}`} className="input-radio-group">
            <div className="named">{input.named}:</div>

            <div className="radio-options">
                {
                    radioOptions.map((rdo, index) => <div key={index}>
                        <input type="radio" id={`inputRadio_${layerKey}${input.inputKey}_${index}`} name={`inputRadio_${layerKey}${input.inputKey}_${index}`} value={rdo}
                            checked={valuePicked === rdo}
                            onChange={onChange}
                            onClick={onClick}
                        />
                        <label htmlFor={`inputRadio_${layerKey}${input.inputKey}_${index}`} >{radioOptions[index]}</label>
                    </div>)
                }

            </div>
        </div>

    )
}
export default InputRadioGroup