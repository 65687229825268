import React, { useState, useEffect, useContext } from 'react'
import { StoreContext } from "../../context/StoreContext"
import Utility from "../../objects/Utility"
import './InputText.scss'
const InputText = ({ layerKey, input, setValue, useValue, setValid, delayIndex }) => {
    const [textVal, setTextVal] = useState(useValue);
    const [inputTextWidth, setInputTextWidth] = useState('248px')
    const { state } = useContext(StoreContext)

    useEffect(() => {
        if (input.comment && input.comment.includes('custom width')) {
            let customWidth = input.comment.match(/\d+/)[0]
            setInputTextWidth(customWidth + 'px')
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (textVal === null || textVal === undefined) {
            return
        }
        let existingValue = Utility.currentLayerInputValue(layerKey, input.inputKey, state.activeLayerValues)
        // if this input is already the same value as whats in state.activeLayerValues, do not re-send the same value back.
        if (existingValue !== textVal) {
            setValue(input.inputKey, textVal)
        }

    }, [textVal]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (useValue !== null && useValue !== undefined) {
            if (state.activeLayerValues[layerKey + '_' + input.inputKey] !== undefined) {
                if (textVal !== useValue) {
                    setTextVal(useValue)
                }
            }
        }
    }, [useValue]) // eslint-disable-line react-hooks/exhaustive-deps

    const onChange = evt => {
        setTextVal(evt.target.value)
    }

    return (
        <div id={`input_text_${input.inputKey}`} className="input-text">
            <span className="named">{input.named}:</span>
            <input type="text" id={`input_${input.inputKey}`}
                value={textVal ? textVal : ''}
                onChange={onChange}
                style={{ width: inputTextWidth }}
            />
        </div>
    )
}
export default InputText