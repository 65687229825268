import Utility from './Utility'
import Snap from 'snapsvg-cjs'

const MaterialSymbols = {

    draw: (g, settings) => {
        let derivedValues = {}
        settings.forEach(setting => {
            switch (setting.name) {

                case 'material symbol': derivedValues.text = setting.value
                    break
                case 'font': derivedValues.fontFamily = 'Material Icons' //setting.value
                    break
                case 'font style': derivedValues.fontStyle = 'normal' //setting.value
                    break
                case 'size': derivedValues.size = Number(setting.value)
                    break
                case 'font weight': derivedValues.fontWeight = 'normal' //setting.value
                    break
                case 'outline color': derivedValues.outlineColor = setting.value //setting.value
                    break
                case 'outline width': derivedValues.outlineWidth = setting.value //setting.value
                    break
                case 'fill color': derivedValues.fillColor = setting.value //setting.value
                    break
                case 'scale width': derivedValues.scaleWidth = Utility.roundFloat(setting.value, 2)
                    break
                case 'scale height': derivedValues.scaleHeight = Utility.roundFloat(setting.value, 2)
                    break
                case 'xy position':
                    if (Array.isArray(setting.value) === false || setting.value.length !== 2) {
                        setting.value = [1, 1]
                    }
                    if (isNaN(setting.value[0]) ) {
                        setting.value[0] = 1
                    }
                    if (isNaN(setting.value[1]) ) {
                        setting.value[1] = 1
                    }
                    derivedValues.xpos = Utility.roundFloat(setting.value[0], 1)
                    derivedValues.ypos = Utility.roundFloat(setting.value[1], 1)
                    break
                case 'opacity': derivedValues.opacity = Utility.roundFloat(Number(setting.value) / 100, 3)
                    break
                case 'rotation': derivedValues.rotation = Utility.roundFloat(setting.value, 2)
                    break
                default: console.warn('we seem to have missed one: ', setting.name)
            }
        })
        derivedValues.fontFamily = 'Material Icons'
        derivedValues.fontStyle = 'normal'
        derivedValues.size *= 12 / 5.5 // get the icon to fill the counter space if set to 100.
        derivedValues.outlineWidth = (derivedValues.outlineWidth / 25)
        derivedValues.strokeWidth = (derivedValues.fontSize) * (derivedValues.outlineWidth / 100) / 15
        derivedValues.lineThickness = derivedValues.strokeWidth
        if (derivedValues.strokeColor === '') {
            derivedValues.strokeColor = 'transparent'
        }
        if (derivedValues.fillColor === '') {
            derivedValues.fillColor = 'transparent'
        }
        if (derivedValues.fontWeight === 'thin') {
            derivedValues.fontWeight = 100
        }
        if (derivedValues.fontWeight === 'normal') {
            derivedValues.fontWeight = 500
        }
        if (derivedValues.fontWeight === 'bold') {
            derivedValues.fontWeight = 900
        }

        let ratio = 0.8 / 100
        let scaleWidth = derivedValues.scaleWidth * ratio
        let scaleHeight = derivedValues.scaleHeight * ratio
        let xpos = derivedValues.xpos
        let ypos = derivedValues.ypos
        let rotation = derivedValues.rotation

        MaterialSymbols.drawText(g, derivedValues)

        // if (Utility.roundFloat(scaleWidth, 2) !== 0.00 || Utility.roundFloat(scaleHeight, 2) !== 0.00) {
        //     let scaleFactorX = 1
        //     let scaleFactorY = 1
        //     if (scaleWidth < 0) {
        //         scaleFactorX = 1 + (scaleWidth / 3)
        //     }
        //     else {
        //         scaleFactorX = 1 + (2 * scaleWidth / 3)  // 0 - 3
        //     }
        //     if (scaleHeight < 0) {
        //         scaleFactorY = 1 + (scaleHeight / 3)
        //     }
        //     else {
        //         scaleFactorY = 1 + (2 * scaleHeight / 3)  // 0 - 3
        //     }
        g.transform(new Snap.matrix().scale(scaleWidth, scaleHeight))
        //}

        if (derivedValues.opacity < 1) {
            g.attr({ "opacity": derivedValues.opacity })
        }

        if (rotation > 0) {
            MaterialSymbols.applyRotation(g, rotation)
        }
        if (xpos !== 0 || ypos !== 0) {
            MaterialSymbols.applyTranslation(g, xpos, ypos)
        }

    },

    drawText: (g, derivedValues) => {
        g.text(0, 0, derivedValues.text ? derivedValues.text : '').attr(
            {
                fontFamily: derivedValues.fontFamily,
                fontStyle: derivedValues.fontStyle,
                textAnchor: "middle",
                dominantBaseline: "central",
                fontWeight: derivedValues.fontWeight,
                fill: derivedValues.fillColor,
                stroke: derivedValues.outlineColor,
                strokeWidth: derivedValues.outlineWidth,
                fontSize: derivedValues.size,
                opacity: derivedValues.opacity,
            })
    },

    drawPath: (g, d, derivedValues) => {
        g.path(d).attr({ stroke: derivedValues.textColor, strokeWidth: derivedValues.lineThickness, fill: derivedValues.textColor, 'stroke-linejoin': derivedValues.linejoin });
    },

    drawLine: (g, x1, y1, x2, y2, derivedValues) => {
        x1 = Utility.roundFloat(x1, 2)
        y1 = Utility.roundFloat(y1, 2)
        x2 = Utility.roundFloat(x2, 2)
        y2 = Utility.roundFloat(y2, 2)
        g.line(Utility.roundFloat(x1, 2), Utility.roundFloat(y1), Utility.roundFloat(x2), Utility.roundFloat(y2)).attr({
            "stroke": derivedValues.textColor,
            "strokeWidth": derivedValues.lineThickness,
            "strokeLinecap": "round"
        })
    },

    drawBlankEllipse: (g, x, y, rx, ry, derivedValues) => {
        x = Utility.roundFloat(x, 2)
        y = Utility.roundFloat(y, 2)
        rx = Utility.roundFloat(rx, 2)
        ry = Utility.roundFloat(ry, 2)
        g.ellipse(Utility.roundFloat(x, 2), Utility.roundFloat(y, 2), Utility.roundFloat(rx, 2), Utility.roundFloat(ry, 2)).attr({
            "stroke": derivedValues.textColor,
            "strokeWidth": derivedValues.lineThickness,
            "fill": "none"
        })
    },

    drawEllipse: (g, x, y, rx, ry, derivedValues) => {
        x = Utility.roundFloat(x, 2)
        y = Utility.roundFloat(y, 2)
        rx = Utility.roundFloat(rx, 2)
        ry = Utility.roundFloat(ry, 2)
        g.ellipse(Utility.roundFloat(x, 2), Utility.roundFloat(y, 2), Utility.roundFloat(rx, 2), Utility.roundFloat(ry, 2)).attr({
            "stroke": derivedValues.textColor,
            "strokeWidth": derivedValues.lineThickness,
            "fill": "none"
        })
    },

    drawTriangle: (g, tx1, ty1, tx2, ty2, tx3, ty3, derivedValues) => {
        tx1 = Utility.roundFloat(tx1, 2)
        ty1 = Utility.roundFloat(ty1, 2)
        tx2 = Utility.roundFloat(tx2, 2)
        ty2 = Utility.roundFloat(ty2, 2)
        tx3 = Utility.roundFloat(tx3, 2)
        ty3 = Utility.roundFloat(ty3, 2)
        g.path(`M ${tx1} ${ty1} ${tx2} ${ty2} ${tx3} ${ty3} z`).attr({
            "stroke": "none",
            "strokeWidth": 0,
            "fill": derivedValues.textColor,
        })
    },

    drawCircle: (g, x, y, r, derivedValues) => {
        x = Utility.roundFloat(x, 2)
        y = Utility.roundFloat(y, 2)
        r = Utility.roundFloat(r, 2)
        g.circle(x, y, r).attr({
            "stroke": "none",
            "fill": derivedValues.textColor
        })
    },

    drawBlankCircle: (g, x, y, r, derivedValues) => {
        x = Utility.roundFloat(x, 2)
        y = Utility.roundFloat(y, 2)
        r = Utility.roundFloat(r, 2)
        g.circle(x, y, r).attr({
            "stroke": derivedValues.textColor,
            "strokeWidth": derivedValues.lineThickness,
            "fill": "none"
        })
    },

    applyRotation: (g, rotation) => {
        let measureG = g.getBBox()
        let currentMatrix = g.transform().localMatrix;
        let addMatrix = new Snap.Matrix()
        addMatrix.rotate(rotation, measureG.cx, measureG.cy)
        addMatrix.add(currentMatrix)
        g.transform(addMatrix)
    },

    applyTranslation: (g, xpos, ypos) => {
        let currentMatrix = g.transform().localMatrix;
        let addMatrix = new Snap.Matrix()
        addMatrix.translate(xpos, ypos)
        addMatrix.add(currentMatrix)
        g.transform(addMatrix)
    },

}
export default MaterialSymbols